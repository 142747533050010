import {OEViewType} from '../lib/oe-types';
import {OEToolbox} from '../lib/oe-toolbox';
import OENotification from '../lib/oe-notification';

export class OEMainMenuBtnAdapter {

    constructor() {
    }

    onConnect(oe, menu)   {
    }

    onRelease(oe, menu)   {
    }

    onPressed(activated, menu)   {
        return false;
    }

    isActive(menu)   {
        return false;
    }

    isEnabled(menu) {
        return false;
    }
};

export class OEMainMenuBtnAdapterView extends OEMainMenuBtnAdapter {

    constructor(link, options, feature) {
        super();
        this.link = link;
        this.options = options;
        this.feature = feature;

        this.state = {
            active: false,
            enabled: false
        };

        this.connected = false;

        this.updateIsFeatureEnabled();

        this.onViewOpenStateChanged = this.onViewOpenStateChanged.bind(this);
        this.onFeatureAccessChanged = this.onFeatureAccessChanged.bind(this);
    }

    setState(state) {
        let newState = Object.assign({}, this.state, state);
        if(OEToolbox.jsonEqual(newState, this.state)) return;
        this.state = newState;
        if(this.menu) this.menu.updateForAdapter(this);
    }

    onConnect(oe, menu)  {
        this.connected = true;
        this.updateIsFeatureEnabled();
        this.updateState(menu);
        menu.props.appComponent.uiLayer.notificationCenter.register(OENotification.viewOpenStateChanged, this.onViewOpenStateChanged);
        oe.sharedNotificationCenter.register(this.oe.NotificationName.featureAccessChanged, this.onFeatureAccessChanged);
    }

    onRelease(oe, menu)  {
        this.connected = false;
        menu.props.appComponent.uiLayer.notificationCenter.unregister(OENotification.viewOpenStateChanged, this.onViewOpenStateChanged);
        oe.sharedNotificationCenter.unregister(this.oe.NotificationName.featureAccessChanged, this.onFeatureAccessChanged);
        this.setViewOpen(false, menu);
        this.updateIsFeatureEnabled();
        this.updateState(menu);
    }

    updateIsFeatureEnabled()  {
        if(typeof(this.feature) !== 'number' || !this.oe || !this.oe.isReady() || !this.connected)   {
            this.isFeatureEnabled = typeof(this.feature) !== 'number'; return;
        }
        let featureSet = this.oe.sharedInterface.getUIControllerFeatureAccess().getFeatureSet();
        this.isFeatureEnabled = featureSet.hasFeature({value: this.feature});
    }

    setViewOpen(open, menu)   {
        menu = menu || this.menu;
        if(!menu)   return;
        let view = OEViewType.getRef(this.link, menu.props.appComponent);
        if(view && view.setOpen) view.setOpen(open, this.options);
    }

    onViewOpenStateChanged(message, userInfo)   {
        this.updateState();
    }

    onFeatureAccessChanged(message, userInfo)   {
        this.updateIsFeatureEnabled();
        if(!this.isFeatureEnabled)   this.setViewOpen(false);
        this.updateState();
    }

    updateState(menu)   {
        menu = menu || this.menu;
        let connected = this.oe && this.oe.isReady() && this.connected;
        let view = menu ? OEViewType.getRef(this.link, menu.props.appComponent) : null;

        this.setState({
            active: view && view.isOpen(),
            enabled: view && connected && this.isFeatureEnabled ? true : false
        });
    }

    onPressed(activated, menu)   {
        if(!this.state.enabled) return this.state.active;
        this.setViewOpen(!activated, menu);
        this.updateState(menu);
        return this.state.active;
    }

    isActive(menu)   {
        return this.state.active;
    }

    isEnabled(menu) {
        return this.state.enabled;
    }
};

export class OEMainMenuBtnAdapterPostProcess extends OEMainMenuBtnAdapter {

    constructor() {
        super();

        this.state = {
            active: false,
            enabled: false
        };

        this.updateState = this.updateState.bind(this);
    }

    setState(state) {
        let newState = Object.assign({}, this.state, state);
        if(OEToolbox.jsonEqual(newState, this.state)) return;
        this.state = newState;
        if(this.menu) this.menu.updateForAdapter(this);
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.rendererPresetChanged, this.updateState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.updateState);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.rendererPresetChanged, this.updateState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.updateState);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({active: false, enabled: false});
            return;
        }

        let settings = this.oe.sharedInterface.getUIControllerSettings();

        this.setState({
            active: settings.getRendererPreset() === 'cartoon',
            enabled: settings.getUIEnabled()
        });
    }

    onPressed(activated, menu)   {
        if(!this.state.enabled) return this.state.active;
        if(!this.oe || !this.oe.isReady()) return this.state.active;
        let settings = this.oe.sharedInterface.getUIControllerSettings();
        settings.setRendererPreset(!activated ? 'cartoon' : 'std');
        this.state.active = settings.getRendererPreset() === 'cartoon';
        return this.state.active;
    }

    isActive(menu)   {
        return this.state.active;
    }

    isEnabled(menu) {
        return this.state.enabled;
    }
};