import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import {VelocityTransitionGroup} from 'velocity-react';

import {connectAppEnv} from './app-env';
import {withAddedUILevel, delegateOpenState, frefToRef} from '../lib/oe-higher-order-components';
import {UILevel} from '../lib/oe-types';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OETargetMenuDefs} from '../lib/oe-types'
import {OECustomDropdown} from './oe-controls';
import {retardUpdate} from '../lib/update-retarder';
import {OEToolbox} from '../lib/oe-toolbox';

export class OETargetMenu extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.updateEntries();

        this.state = {
            entries: clone(this.entries),
            activeIndex: this.activeIndexFromProps()
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onMenuRef = this.onMenuRef.bind(this);

        this.onDropDownClick = this.onDropDownClick.bind(this);
    }

    setOpen(open)   {
        if(this.menuRef)    this.menuRef.setOpen(open);
    }

    isOpen()    {
        return this.menuRef ? this.menuRef.isOpen() : false;
    }

    toggle() {
        if(this.menuRef)    this.menuRef.toggle();
    }

    updateEntries(props) {
        let propEntries = (props || this.props).entries || [];
        let prefix;
        if(!Array.isArray(propEntries))    {
            prefix = propEntries.prefix;
            propEntries = propEntries.parts || [];
        }
        this.entries = this.fromPropEntries(propEntries, prefix);
        this.updateLabels();
    }

    buildLabelText(text, style) {
        if(typeof(text) !== 'string') return text;
        if(style === OETargetMenuDefs.styles.major) {
            return '* ' + text + ' *';
        }
        return text;
    }

    fromPropEntries(entries, prefix)    {
        if(!entries) return [];
        return entries.map((entry) => {
            const labelIsObject = typeof(entry.label) === 'object';
            const id = labelIsObject && entry.label.id ? entry.label.id : ('target_menu_' + (prefix ? (prefix + '_') : '') + entry.target);
            const labelStyle = labelIsObject ? entry.label.style : null;
            const labelText = this.buildLabelText(labelIsObject ? entry.label.text : entry.label, labelStyle);
            const labelText_ = labelIsObject ? entry.label.text_ : undefined;
            return {target: entry.target, label: {text: labelText, text_: labelText_, id: id, style: labelStyle}, icon: entry.icon, parts: this.fromPropEntries(entry.parts, entry.prefix)}; 
        });
    }

    updateLabels(entries_)  {
        if(!this.oe.isReady()) return;
        const si = this.oe.sharedInterface;

        let entries = entries_ || this.entries;
        entries.forEach((entry) => {
            if(entry.label.text_)   {
                var labelText = this.buildLabelText(entry.label.text_, entry.label.style);
            } else {
                var labelText = si.getLocalizedStringEnc(entry.label.id);
                if(labelText === '#null') labelText = entry.label.text;
                labelText = this.buildLabelText(labelText, entry.label.style);
            }
            entry.label.text = labelText;
            this.updateLabels(entry.parts);
        });
    }

    activeIndexFromProps(props) {
		return this.activeIndex((props || this.props).activeTarget);
	}

	activeIndex(activeTarget, entries_, counter_) {
        let entries = entries_ || this.entries;
        let counter = counter_ || {counter: 0};
		for(let i = 0; i < entries.length; i++) {
			if((!entries[i].parts || !entries[i].parts.length) && entries[i].target === activeTarget) return counter.counter;
			counter.counter++;
			if(!entries[i].parts) continue;
			let index = this.activeIndex(activeTarget, entries[i].parts, counter);
			if(index) return index;
		}
    }
    
    entryForKey(key, entries_, counter_) {
        if(key < 0) return;
        let entries = entries_ || this.entries;
        let counter = counter_ || {counter: 0};
		for(let i = 0; i < entries.length; i++) {
			if(key === counter.counter) return entries[i];
            counter.counter++;
            if(!entries[i].parts) continue;
			let entry = this.entryForKey(key, entries[i].parts, counter);
			if(entry) return entry;
		}
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.jsonEqual(nextProps.entries, this.props.entries))     {
            this.updateEntries(nextProps);
            this.setState({entries: clone(this.entries), activeIndex: this.activeIndexFromProps(nextProps)});
        } else if(nextProps.activeTarget !== this.props.activeTarget)     {
            this.setState({activeIndex: this.activeIndexFromProps(nextProps)});
        }

        if(nextProps.hideWhenMediaCenterOpen !== this.props.hideWhenMediaCenterOpen)    {
            this.updateVisibility(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        this.updateLabels();
        this.setState({entries: clone(this.entries)});
    }

    updateVisibility(props)   {
        if(!this.oe.isReady()) return;
        let visible = true;
        if((props || this.props).hideWhenMediaCenterOpen && this.oe.sharedInterface.getUIControllerMediaCenter().getUIVisible())  {
            visible = false;
        }
        this.setState({
            visible: visible
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({visible: true});
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateVisibility();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type !== this.oe.Module.UIControllerType.media_center) return;
        this.updateVisibility();
    }

    onMenuRef(ref)  {
        this.menuRef = ref;
    }

    renderEntry(entry, key)  {
        return (
            <div key={key} className="entry">
                {!entry ? null : 
                    <div className="content">
                        {!entry.icon ? null : <img src={entry.icon}/>}{entry.label.text}
                    </div>
                }
            </div>
        );
    }

    renderEntries(entries, counter_) {
        let counter = counter_ || {counter: 0};
        return entries.map((entry) => { return {key: counter.counter, content: this.renderEntry(entry, counter.counter++), children: this.renderEntries(entry.parts, counter)}});
    }

    render() {
        if(!this.props.enabled || this.state.entries.length == 0) return null;
        const content = this.renderEntries(this.state.entries);

        const dropdown = !this.state.visible ? null : (
            <OECustomDropdown
                ref={this.onMenuRef}
                className={'std-label-text-color ' + (this.props.dropdownClassName ? this.props.dropdownClassName : '')}
                chosenKey={this.state.activeIndex}
                entries={content}
                subOrientation={this.props.subOrientation}
                onToggle={this.props.onToggle}
                onClick={this.onDropDownClick}
                toggleClassName={this.props.toggleClassName}
                disabledToggleClassName={this.props.disabledToggleClassName}
                toggleStyle={this.props.toggleStyle}
                disabledToggleStyle={this.props.disabledToggleStyle}
                itemClassName={'light-hover-bg-border std-label-text-color ' + (this.props.itemClassName ? this.props.itemClassName : '')}
                disabledItemClassName={this.props.disabledItemClassName}
                activeItemClassName={this.props.activeItemClassName}
                itemStyle={this.props.itemStyle}
                disabledItemStyle={this.props.disabledItemStyle}
                activeItemStyle={this.props.activeItemStyle}
            />
            );

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'target-menu ' + (this.props.className ? this.props.className : '')}>
                    {!this.props.fadeInOut ? dropdown :
                        <VelocityTransitionGroup enter={{animation: "fadeIn", duration: 333, easing: "ease-in-out"}} leave={{animation: "fadeOut", duration: 333, easing: "ease-in-out"}}>
                            {dropdown}
                        </VelocityTransitionGroup>
                    }
                </div>
            </React.Fragment>
        );
    }

    onDropDownClick(key)    {
        let entry = this.entryForKey(key);
        if(!entry) return;
        if(this.props.onTargetChoosen)    {
            this.props.onTargetChoosen(entry.target);
        } else if(this.props.app)  {
            this.props.app.changeTarget(entry.target);
        }
    }
}

OETargetMenu.subOrientation = OECustomDropdown.subOrientation;

OETargetMenu.defaultProps = {
    moduleId: '',
    enabled: true,
    hideWhenMediaCenterOpen: false,
    fadeInOut: true,
    entries: OETargetMenuDefs.all,
    activeTarget: '',
    subOrientation: OETargetMenu.subOrientation.right
};

OETargetMenu.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    hideWhenMediaCenterOpen: PropTypes.bool,
    fadeInOut: PropTypes.bool,
    entries: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    subOrientation: PropTypes.oneOf([OETargetMenu.subOrientation.right, OETargetMenu.subOrientation.left]),
    activeTarget: PropTypes.string,
    dropdownClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    disabledToggleClassName: PropTypes.string,
    toggleStyle: PropTypes.object,
    disabledToggleStyle: PropTypes.object,
    itemClassName: PropTypes.string,
    disabledItemClassName: PropTypes.string,
    activeItemClassName: PropTypes.string,
    itemStyle: PropTypes.string,
    disabledItemStyle: PropTypes.object,
    activeItemStyle: PropTypes.object,
    onToggle: PropTypes.func,
    onTargetChoosen: PropTypes.func
};

export default delegateOpenState(connectAppEnv((env) => { 
    const ui = env.config.module.uiLayerConfig;
    const ret = {
        enabled: ui.targetMenuConfig.enabled,
        entries: ui.targetMenuConfig.entries,
        activeTarget: env.config.target,
        app: env.component.app
    };
    ret[UILevel.simple] = ui.targetMenuConfig[UILevel.simple];
    ret[UILevel.advanced] = ui.targetMenuConfig[UILevel.advanced];
    return ret;
})(withAddedUILevel(frefToRef(OETargetMenu))));