import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import Draggable from 'react-draggable';

import {connectAppEnv} from '../app-env';
import {connectModuleEnv} from '../oe-module-env';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEButton, OEIcon} from '../oe-controls';
import {OEColorHelper} from '../oe-color-helper';
import {OEContextMenuContentType} from '../../lib/oe-types';
import {OEToolbox} from '../../lib/oe-toolbox';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OEContextMenuContentFactory from './oe-context-menu-content-factory';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {retardUpdate} from '../../lib/update-retarder';
import OEResizeObserver from '../../lib/oe-resize-observer';

export class OEContextMenu extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.id = null;

        this.position = {x: 0, y: 0};

        this.state = {
            id: this.id,
            position: clone(this.position),
            uiVisible: false,
            uiEnabled: false,
            label: '',
            subLabel: '',
            fadeActive: false,
            hideActive: false,
            fadeOtherActive: false,
            hideOtherActive: false,
            strings:   {
                fade: '',
                hide: '',
                fadeOther: '',
                hideOther: ''
            },
            backgroundColor: OEColorHelper.stdBackgroundColor(0.6),
            textColor: OEColorHelper.stdLabelTextColor(0.6),
			seperatorColor: OEColorHelper.stdSeparatorColor(0.6)
		};

        this.onWindowResized = this.onWindowResized.bind(this);

        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onBgColorChanged = this.onBgColorChanged.bind(this);
        this.onContextMenuChanged = this.onContextMenuChanged.bind(this);
        this.onContextMenuStateChanged = this.onContextMenuStateChanged.bind(this);

        this.onDrag = this.onDrag.bind(this);
        this.onResize = this.onResize.bind(this);

        this.onAreaRef = this.onAreaRef.bind(this);
        this.onContextMenuRef = this.onContextMenuRef.bind(this);

        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);
        this.onFadeBtnPressed = this.onFadeBtnPressed.bind(this);
        this.onHideBtnPressed = this.onHideBtnPressed.bind(this);
        this.onFadeOtherBtn = this.onFadeOtherBtn.bind(this);
        this.onHideOtherBtnPressed = this.onHideOtherBtnPressed.bind(this);
    }

    componentDidMount()    {
        this.updatePosition();
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onWindowResized);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.contextMenuChanged, this.onContextMenuChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.contextMenuStateChanged, this.onContextMenuStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.contextMenuChanged, this.onContextMenuChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.contextMenuStateChanged, this.onContextMenuStateChanged);
    }

    onWindowResized()   {
        this.updatePosition();
    }

    getConstrainedPos(pos)  {
        if(!this.contextMenuRef || !this.areaRef) return pos;
        let $area = $(this.areaRef);

        let cPos = $area.position();
        cPos = {x: cPos.left, y: cPos.top + this.props.ui.capBar.size.h};
        let cSize = {width: $area.width(), height: $area.height()};
        
        let size = this.getSize();
        
        let consPos = clone(pos);
        consPos.x = Math.min(Math.max(cPos.x, consPos.x), cPos.x + cSize.width - size.width - 1);
        consPos.y = Math.min(Math.max(cPos.y, consPos.y), cPos.y + cSize.height - size.height - 1);
        return consPos;
    }

    setPosition(pos)   {
        let consPos = this.getConstrainedPos(pos);
        if(this.position.x === consPos.x && this.position.y === consPos.y) {
            this.updateSelectionTrackerFrame(); return; // we use setPosition as a proxy for calling updateSelectionTrackerFrame thus we have to call it even if the position state does not need an update
        }
        this.position = consPos;
        this.setState({position: consPos});
        this.updateSelectionTrackerFrame();
    }

    updatePosition()    {
        this.setPosition(this.getPosition());
    }
    
    getPosition()    {
        return this.position;
    }
    
    getSize() {
        if(!this.contextMenuRef) return {width: 0, height: 0};
        let $frame = $(this.contextMenuRef);
        return {width: $frame.outerWidth(), height: $frame.outerHeight()};
    }

    updateSelectionTrackerFrame()   {
        if(!this.oe.isReady() || !this.contextMenuRef || !this.areaRef) return;

        let pos = this.position;
        let x = pos.x, y = pos.y;
        let size = this.getSize();

        let dpr = this.oe.Module.devicePixelRatio();
        y = dpr * (($(this.oe.element)).height() - y - size.height);
       
        let frame = {x: dpr * x, y: y, z: dpr * size.width, w: dpr * size.height}; 
        this.oe.sharedInterface.setSelectionTrackerFrame(frame);
    }

    updateLanguage()   {
        let component = this.oe.sharedInterface.getUIControllerComponent();

        this.setState({            
            label: this.id !== null ? OEToolbox.decode_utf8(component.getComponentName(this.id)) : '',
            subLabel: this.id !== null ? OEToolbox.decode_utf8(component.getComponentSecondaryName(this.id)) : '',
            strings: {
                fade: this.oe.sharedInterface.getLocalizedStringEnc('fade'),
                hide: this.oe.sharedInterface.getLocalizedStringEnc('hide'),
                fadeOther: this.oe.sharedInterface.getLocalizedStringEnc('fade_other'),
                hideOther: this.oe.sharedInterface.getLocalizedStringEnc('hide_other')
            }
        });
    }

    updateContextMenuState()    {
        let contextMenu = this.oe.sharedInterface.getUIControllerContextMenu();
        let selection = contextMenu.getSelection().getRepresentativeSelection();
        this.id = contextMenu.selectionSize() ? selection.get(0) : null;
        this.setState({
            id: this.id
        });
        this.updateContextMenuControlsState();
    }

    updateContextMenuControlsState()   {
        if(!this.oe.isReady()) return;

        if(this.id === null) {
            this.setState({
                label: '',
                subLabel: '',
                fadeActive: false,
                hideActive: false,
                fadeOtherActive: false,
                hideOtherActive: false,
                fadeBtnEnabled: false,
                hideBtnEnabled: false,
                fadeOtherBtnEnabled: false,
                hideOtherBtnEnabled: false
            });
            return;
        }

        let component = this.oe.sharedInterface.getUIControllerComponent();
        let controller = this.oe.sharedInterface.getUIControllerContextMenu();
    
        let state = controller.getState();

        this.setState({
            label: OEToolbox.decode_utf8(component.getComponentName(this.id)),
            subLabel: OEToolbox.decode_utf8(component.getComponentSecondaryName(this.id)),
            fadeActive: state.bFaded,
            hideActive: state.bHidden,
            fadeOtherActive: state.bOtherFaded,
            hideOtherActive: state.bOtherHidden,
            fadeBtnEnabled: state.bFadeEnabled,
            hideBtnEnabled: state.bHideEnabled,
            fadeOtherBtnEnabled: state.bFadeOtherEnabled,
            hideOtherBtnEnabled: state.bHideOtherEnabled
        });
    }

    updateUIState()   {
        this.setState({
            uiVisible: this.oe.sharedInterface.getUIControllerContextMenu().getUIVisible(),
            uiEnabled: this.oe.sharedInterface.getUIControllerContextMenu().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.id = null;
            this.setState({
                id: null,
                uiVisible: false,
                uiEnabled: false
            });
            return;
        }

        this.updateSelectionTrackerFrame();
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateUIState();
            this.onBgColorChanged();
            this.updateContextMenuState();
        });
    }

    onLanguageChanged() {
        this.updateLanguage();
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.context_menu) {
            this.updateUIState();
        }
    }

    onBgColorChanged(message, userInfo)  {
        let brightness = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness() : userInfo.brightness;
        this.setState({
            backgroundColor: OEColorHelper.stdBackgroundColor(brightness),
            textColor: OEColorHelper.stdLabelTextColor(brightness),
            seperatorColor: OEColorHelper.stdSeparatorColor(brightness)
        });
    }

    onContextMenuChanged(message, userInfo)    {
        let size = userInfo.size;
        let pos = userInfo.pos;
        let devicePR = 1 / this.oe.Module.devicePixelRatio();
        pos = {x: pos.x * devicePR + 100, y: pos.y * devicePR - 100};

        if(size !== 0) {
            let contextMenu = this.oe.sharedInterface.getUIControllerContextMenu();
            let selection = contextMenu.getSelection().getRepresentativeSelection();
            this.id = selection.get(0);

            this.setState({id: this.id});
            this.setPosition(pos);
            this.updateContextMenuControlsState();
        }
    }

    onContextMenuStateChanged(message, userInfo)  {
        this.updateContextMenuControlsState();
    }

    onDrag(e, u) {
        this.setPosition({x: u.x, y: u.y + this.props.ui.capBar.size.h});
    }

    onResize()  {
        this.updateSelectionTrackerFrame();
    }

    onAreaRef(ref)  {
        if(this.areaRef === ref) return;
        this.areaRef = ref;
        this.updatePosition();
    }

    onContextMenuRef(ref)   {
        if(this.contextMenuRef === ref) return;
        this.contextMenuRef = ref;
        this.updatePosition();
    }

    renderContextMenu() {
        let bgColor = this.state.backgroundColor;
        let textColor = this.state.textColor;
        const position = {x: this.state.position.x, y: this.state.position.y - this.props.ui.capBar.size.h};

        return (
            <Draggable
                defaultPosition={position}
                position={position}
                onDrag={this.onDrag}
                bounds={'#' + this.props.moduleId + '-context-menu-area'}
            >
                <div 
                    id="context-menu-controller"
                    className="context-menu std-bg std-label-text-color screenshot-filter-show"
                    ref={this.onContextMenuRef}
                >

                    <OEResizeObserver onResize={this.onResize} />
                    
                    <a className="cmc-close-button" onClick={this.onCloseBtnPressed}>
                        <OEIcon code={OEIconCodes.close} />
                    </a>

                    <div className="context-menu-top">
                        <h1><span>{this.state.label}</span></h1> <br/>
                        <h2><span>{this.state.subLabel}</span></h2> <br/>
                    </div>

                    <OEContextMenuContentFactory
                        moduleId={this.props.moduleId}
                        config={this.props.config.content}
                        backgroundColor={bgColor}
                        textColor={textColor}
                        searchString={this.state.label}
                        componentId={this.state.id}
                    />

                    <ul>
                        <li><OEButton
                            disabled={!this.state.uiEnabled || !this.state.hideBtnEnabled}
                            activated={this.state.hideActive}
                            className="iconTextBtn cmc-button transparent-btn"
                            style={{ border: '1px solid ' + OEColorHelper.vColorToStr(this.state.seperatorColor), borderLeftStyle: 'none', borderBottomStyle: 'none' }}
                            onPressed={this.onHideBtnPressed}
                        ><span><OEIcon code={OEIconCodes.contextMenuHide}/></span><span>{this.state.strings.hide}</span></OEButton></li>
                        <li><OEButton
                            disabled={!this.state.uiEnabled || !this.state.hideOtherBtnEnabled}
                            activated={this.state.hideOtherActive}
                            className="iconTextBtn cmc-button transparent-btn"
                            style={{ border: '1px solid ' + OEColorHelper.vColorToStr(this.state.seperatorColor), borderLeftStyle: 'none', borderBottomStyle: 'none' }}
                            onPressed={this.onHideOtherBtnPressed}
                        ><span><OEIcon code={OEIconCodes.contextMenuHideOther}/></span><span>{this.state.strings.hideOther}</span></OEButton></li>
                        <li><OEButton
                            disabled={!this.state.uiEnabled || !this.state.fadeBtnEnabled}
                            activated={this.state.fadeActive}
                            className="iconTextBtn cmc-button transparent-btn"
                            style={{ border: '1px solid ' + OEColorHelper.vColorToStr(this.state.seperatorColor), borderLeftStyle: 'none', borderBottomStyle: 'none' }}
                            onPressed={this.onFadeBtnPressed}
                        ><span><OEIcon code={OEIconCodes.contextMenuFade}/></span><span>{this.state.strings.fade}</span></OEButton></li>
                        <li><OEButton
                            disabled={!this.state.uiEnabled || !this.state.fadeOtherBtnEnabled}
                            activated={this.state.fadeOtherActive}
                            className="iconTextBtn cmc-button transparent-btn"
                            style={{ border: '1px solid ' + OEColorHelper.vColorToStr(this.state.seperatorColor), borderLeftStyle: 'none', borderBottomStyle: 'none', borderRightStyle: 'none' }}
                            onPressed={this.onFadeOtherBtn}
                        ><span><OEIcon code={OEIconCodes.contextMenuFadeOther}/></span><span>{this.state.strings.fadeOther}</span></OEButton></li>
                    </ul>
                </div>
            </Draggable>
        );
    }

    render() {
        let style = clone(this.props.ui.contextMenu.areaRestriction);
        style.bottom = Math.max(style.bottom, this.props.ui.bottomEdgeOffset.value);

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div id={this.props.moduleId + '-context-menu-area'} className="context-menu-area" style={style} ref={this.onAreaRef}>
                    {this.state.uiVisible ? this.renderContextMenu() : null}
                </div>
            </React.Fragment>
        );
    }

    onCloseBtnPressed() {
        this.oe.sharedInterface.setUIControllerVisible(false, this.oe.Module.UIControllerType.context_menu);
    }

    onFadeBtnPressed() {
        let controller = this.oe.sharedInterface.getUIControllerContextMenu();
        controller.setFade(!this.state.fadeActive);
    }
    
    onHideBtnPressed() {
        let controller = this.oe.sharedInterface.getUIControllerContextMenu();
        controller.setHide(!this.state.hideActive);
    }
    
    onFadeOtherBtn() {
        let controller = this.oe.sharedInterface.getUIControllerContextMenu();
        controller.setFadeOther(!this.state.fadeOtherActive);
    }
    
    onHideOtherBtnPressed() {
        let controller = this.oe.sharedInterface.getUIControllerContextMenu();
        controller.setHideOther(!this.state.hideOtherActive);
    }
}

OEContextMenu.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.contextMenu()
};

OEContextMenu.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        content: PropTypes.shape({
            type: PropTypes.oneOf(OEContextMenuContentType.array),
            showReferences: PropTypes.bool
        })
    })
};

export default connectAppEnv((env) => { 
    const ui = env.config.module.uiLayerConfig;
    return {
        config: ui.contextMenu
    };
})(connectModuleEnv((env) => {
    return {
        ui: env.ui
    };
})(OEContextMenu));