import React from 'react';  
import PropTypes from 'prop-types';

import {withIsOpenState} from '../lib/oe-higher-order-components';
import {OEButton, OEIcon} from './oe-controls';
import OEPopover from './oe-popover';

export class OEPopoverMenuController extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.onBtnPressed = this.onBtnPressed.bind(this);
    }

	listButtons(entries) {
		return !this.props.entries ? null : this.props.entries.map((entry) =>
			<div key={entry.id}>
				<OEButton
                    className={"transparent-btn"}
                    userData={entry.id}
					onPressed={() => this.props.onChange(entry.id)}
				>
					{entry.icon ? <OEIcon code={entry.icon}/> : null}
                    {entry.title ? entry.title : null}
				</OEButton>
			</div>
		);
	}

    render() {
		var buttons = this.listButtons(this.props.entries);
		return (
			<OEPopover
                className="popover-control"
                boundariesElement={this.props.boundariesElement}
				target={this.props.target}
				isOpen={this.props.isOpen}
                noHeader={true}
                buttonClassName="transparent-btn"
				moduleId={this.props.moduleId}
				backdrop={this.props.backdrop}
				backdropOptions={this.props.backdropOptions}
				onToggle={this.props.onToggle}
            >
				<div className={'popover-menu'}>
					<div>{buttons}</div>
				</div>
			</OEPopover>
		);
    }
    
    onBtnPressed(e, sender) {
        if(this.props.onChange) this.props.onChange(sender.props.userData);
    }
}

OEPopoverMenuController.defaultProps = {
    moduleId: '',
    target: '',
	backdrop: true
};

OEPopoverMenuController.propTypes = {
    moduleId: PropTypes.string,
    entries: PropTypes.arrayOf(PropTypes.shape(
        {
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            title: PropTypes.string,
            icon: PropTypes.string 
        }
    )),
    onChange: PropTypes.func,
	backdrop: PropTypes.bool,
    backdropOptions: PropTypes.shape({color: PropTypes.string, animated: PropTypes.bool, duration: PropTypes.number})
};

export default withIsOpenState(OEPopoverMenuController);