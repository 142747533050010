import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {OEVersion} from '../../../react-oe/oe-version';
import OEZeissOptiCapBarOpaque from './oe-zeiss-opti-cap-bar-opaque';
import OEZeissOptiCapBar from './oe-zeiss-opti-cap-bar';
import OEPhilipsIcarusCapBar from './oe-philips-icarus-cap-bar';

export function OECapBarFactory(props) {
    //if(props.version === OEVersion.zeissOpti && props.opaque)  return <OEZeissOptiCapBarOpaque {...props}/>
    if(props.version === OEVersion.zeissOpti && !props.opaque)  return <OEZeissOptiCapBar {...props}/>
    if(props.version === OEVersion.philipsIcarus && !props.opaque)  return <OEPhilipsIcarusCapBar {...props}/>
    return null;
}

OECapBarFactory.defaultProps = {
    moduleId: ''
};

OECapBarFactory.propTypes = {
    moduleId: PropTypes.string,
};

export default connectAppEnv((env) => { return {
    version: env.config.version,
    target: env.config.target
}})(OECapBarFactory);