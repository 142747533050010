import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import {OEIcon} from '../oe-controls';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OEToolbox} from '../../lib/oe-toolbox';

export class OEModal extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onRef = this.onRef.bind(this);
        this.onHeaderToggle = this.onHeaderToggle.bind(this);
        this.onModalToggle = this.onModalToggle.bind(this);
    }

    componentWillUpdate(nextProps)   {
        if(nextProps.rootClassName !== this.props.rootClassName)  {
            this.updateRoot(nextProps);
        }
    }

    renderHeader()  {
        let props;
        if(this.props.renderHeader || this.props.additionalButtons)  {
            props = {...this.props};
            props.onToggle = this.onHeaderToggle;
            return this.props.renderHeader(props);
        }

        return (
            <div>
                <h5 className="modal-title">{this.props.title}</h5>
                {this.props.additionalButtons ? this.props.additionalButtons(props) : null}
                {this.props.hasCloseBtn ? <button type="button" className="close-btn" onClick={this.onHeaderToggle}> <OEIcon code={OEIconCodes.close}/> </button> : null}
            </div>
        );
    }

    renderFooter()  {
        if(this.props.renderFooter)  return this.props.renderFooter({...this.props});
        if(!this.props.buttons || !this.props.buttons.length) return null;

        const btns = this.props.buttons.map((btn, index) =>
            <button
                key={index}
                type="button"
                className={'btn ' + (btn.className ? btn.className : '') }
                onClick={btn.onClick ? () => btn.onClick(this.props.key, index) : null}
            >
                {btn.title ? btn.title : ''}
            </button>
        );

        return (
            <ModalFooter>
                {btns}
            </ModalFooter>
        );
    }

    onRef(ref) {
        if(this.root === ref) return;
        this.root = null;
        if(!ref) return;
        let node = ref;
        let parent = node.parentElement;
        let parentParent = node.parentElement;
        while(parentParent.parentElement)    {
            node = parent;
            parent = parentParent;
            parentParent = parent.parentElement;
        }
        this.root = node;
        this.updateRoot();
    }

    updateRoot(nextProps)    {
        if(!this.root) return;

        nextProps = nextProps || this.props;
        let $root = $(this.root);

        $root.removeClass("oe-modal-root");
        $root.addClass("oe-modal-root");

        if(typeof(this.props.rootClassName) === 'string' && this.props.rootClassName !== '')    {
            $root.removeClass(this.props.rootClassName);
        }

        if(typeof(nextProps.rootClassName) === 'string' && nextProps.rootClassName !== '')    {
            $root.addClass(nextProps.rootClassName);
        }
    }

    onHeaderToggle(params) {
        this.props.onToggle(this.props.key, Object.assign({reason: 'header'}, params));
    }

    onModalToggle() {
        this.props.onToggle(this.props.key, {reason: 'modal'});
    }

    componentDidUpdate()    {
        this.updateRoot();
    }

    render() {
        let backdrop = this.props.backdrop;
        if(backdrop && !this.props.toggleOnBackdrop) backdrop = 'static';

        return (
            <Modal
                modalClassName={'oe-modal ' + this.props.className}
                backdropClassName={'oe-modal ' + this.props.className}
                toggle={this.onModalToggle}
                isOpen={this.props.isOpen}
                zIndex={this.props.zIndex}
                backdrop={backdrop}
                onEnter={this.props.onEnter}
                onExit={this.props.onExit}
                onOpened={this.props.onOpened}
                onClosed={this.props.onClosed}
            >
                <div className="oe-modal-content" style={this.props.style} ref={this.onRef}>
                    {this.props.noHeader ? null :
                        <div className="modal-header">
                            {this.renderHeader()}
                        </div>
                    }
                    <ModalBody>
                        {this.props.children}
                    </ModalBody>
                    {this.renderFooter()}
                </div>
            </Modal>
        );
    }
}

OEModal.defaultProps = {
    isOpen: false,
    rootClassName: '',
    className: '',
    style: null,
    title: '',
    zIndex: 1050,
    noHeader: false,
    hasCloseBtn: true,
    backdrop: true,
    toggleOnBackdrop: false,
    onToggle: () => {},
    onEnter: () => {},
    onExit: () => {},
    onOpened: () => {},
    onClosed: () => {}
};

OEModal.propTypes = {
    isOpen: PropTypes.bool,
    rootClassName: PropTypes.string,
    className: PropTypes.string,
    zIndex: PropTypes.number,
    noHeader: PropTypes.bool,
    hasCloseBtn: PropTypes.bool,
    renderHeader: PropTypes.func,
    additionalButtons: PropTypes.func,
    renderFooter: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string,
        title: PropTypes.string,
        onClick: PropTypes.func
    })),
    backdrop: PropTypes.bool,
    toggleOnBackdrop: PropTypes.bool,
    onToggle: PropTypes.func,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func
};

export class OEModalContainer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.counter = 0;

        this.state = {modals: new Object()};

        this.onToggle = this.onToggle.bind(this);
        this.onClosed = this.onClosed.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    addModal(modal) {

        if(typeof(modal) === 'string')  {
            // for call with arguments addModal(title, msg, buttonFunc)
            let args = [].slice.call(arguments, 1);
            let title = modal;
            let content = args.shift();
            let buttonFunc = args.shift();
            let options = args.shift();

            let container = this;

            let buttons = typeof(buttonFunc) === 'function' ? buttonFunc : (id, container) => [{title: 'Ok', onClick: () => {container.closeModal(id)}, className: 'btn-primary'}];

            if(typeof(content) === 'string')    {
                content = OEToolbox.stringToHtml(content);
            } else if(typeof(content) === 'function') {
                content = content(id, container);
            }

            let hasCloseBtn = true;
            let className = '';
            let zIndex = 1050;

            if(options) {
                if(typeof(options.hasCloseBtn) === 'boolean') hasCloseBtn = options.hasCloseBtn;
                if(options.className) className = options.className;
                if(options.zIndex) zIndex = options.zIndex;
            }

            return this.addModal((id) =>
                <OEModal
                    className={'alert-modal-controller ' + className}
                    isOpen={true}
                    title={title}
                    zIndex={zIndex}
                    hasCloseBtn={hasCloseBtn}
                    buttons={buttons(id, container)}
                >
                    {content}
                </OEModal>
            );
        }

        //
        if(typeof(modal) === 'function')    {
            return this.addModal(modal(this.counter));
        }

        //
        const id = this.counter; this.counter++;
        let entry = new Object();
        entry[id.toString()] = {id: id, isOpen: true, modal: modal};

        this.setStateUpdate({modals: {$merge: entry}});
        return id;
    }

    closeModal(id)  {
        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.modals[id.toString()].isOpen = false;
            return newState;
        });
    }

    onToggle(id)    {
        this.closeModal(id);
    }

    onClosed(id)    {
        this.setState((prevState, props) => {
            let newState = clone(prevState);
            delete newState.modals[id.toString()];
            return newState;
        });
    }

    render() {
        let modals = new Array();

        for(let id in this.state.modals) {
            if (this.state.modals.hasOwnProperty(id)) {
                modals.push(this.state.modals[id]);
            }
        }

        let modalElements = modals.map((entry) =>
            React.cloneElement(entry.modal, {key: entry.id, onToggle: () => this.onToggle(entry.id), isOpen: entry.isOpen, onClosed: () => this.onClosed(entry.id)})
        );

        return modalElements;
    }
}