import {OETarget} from '../react-oe/oe-target';

export const OEFeatureAccessTypes = {
    none: 'none',
    full: 'full',
    licensing: 'licensing',
    isOne: function(candidate)  {
        return candidate === this.none || candidate === this.full || candidate === this.licensing;
    }
};

export const OEViewType = {
    start: 'start',
    welcome: 'welcome',
    info: 'info',
    help: 'help',
    manual: 'manual',
    screenshot: 'screenshot',
    getRef: (type, appComponent) => {
        return appComponent.uiLayer[type + 'View'];
    }
};

export const UIControllerType = {
    dummy: 0, cut: 1, arrow: 2, note: 3, component: 4, substructure: 5, search: 6, selection_manager: 7, selection: 8, context_menu: 9, model_state: 10, label: 11,
    presentation: 12, animation: 13, timeline: 14, media_center: 15, settings: 16, info: 17, licensing_manager: 18, highlighting_tooltip: 19, feature_access: 20, color: 21,
    explosion: 22, media: 23, audio_player: 24, preset: 25, tutor: 26
};

export const OEMainMenuStdBtnType = {
    home: 'home',
    label: 'label',
    note: 'note',
    arrow: 'arrow',
    cut: 'cut',
    search: 'search',
    tree: 'tree',
    substructure: 'substructure',
    color: 'color',
    explode: 'explode',
    presentation: 'presentation',
    mediaCenter: 'mediaCenter',
    screenshot: 'screenshot',
    info: 'info',
    help: 'help',
    settings: 'settings',
    back: 'back',
	forth: 'forth'
};

export const OEContextMenuContentType = {
    none: 'none',
    infoBox: 'infoBox',
    array: ['none', 'infoBox']
};

// format { 
// target: one of the strings defined in OETarget,
// label: simple string or object { text: string (optional), id: string referencing a core localized string (optional), style: one of the strings in OETargetMenuDefs.styles (optional)}
// }
export const OETargetMenuDefs = {
    styles: {
        major: 'major'
    },
    earMacro: {target: OETarget.earMacro, label: 'Ear Macro'},
    earMacroAmboss: {target: OETarget.earMacroAmboss, label: 'Ear Macro Amboss'},
    earMicro: {target: OETarget.earMicro, label: 'Ear Micro'},
    skinMacroIONTO: {target: OETarget.skinMacroIONTO, label: 'Skin Macro'},
    skinMicroIONTO: {target: OETarget.skinMicroIONTO, label: 'Skin Micro'},
    eyeMacro: {target: OETarget.eyeMacro, label: 'Eye Macro'},
    eyeMicro: {target: OETarget.eyeMicro, label: 'Eye Retina'},
    eyeCornea: {target: OETarget.eyeCornea, label: 'Eye Cornea'},
    snail: {target: OETarget.snail, label: 'Snail'},
    toxNetz: {target: OETarget.toxNetz, label: 'Tox Netz'},
    zeissOpti: {target: OETarget.zeissOpti, label: 'Zeiss Opti'},
    zeissOpti2: {target: OETarget.zeissOpti2, label: 'Zeiss Opti 2'},
    zeissUV: {target: OETarget.zeissUV, label: 'Zeiss UV'},
    sensorySystems: {target: OETarget.sensorySystems, label: 'Sensory Systems'},
    hoofWeb: {target: OETarget.hoofWeb, label: 'Equine Hoof'},
    hoofVessels: {target: OETarget.hoofVessels, label: 'Vessels'},
    hoofDynamics: {target: OETarget.hoofDynamics, label: 'Dynamics'},
    hoofMicro: {target: OETarget.hoofMicro, label: 'Suspensory Apparatus'},
    humanSkull: {target: OETarget.humanSkull, label: 'Human Skull'},
    humanCranialNerves: {target: OETarget.humanCranialNerves, label: 'Cranial Nerves'},
    humanArm: {target: OETarget.humanArm, label: 'Human Arm'},
    humanArmPathways: {target: OETarget.humanArmPathways, label: 'Arm (pathways)'},
    humanUpperArmElbow: {target: OETarget.humanUpperArmElbow, label: 'Upper arm + elbow'},
    humanForearm: {target: OETarget.humanForearm, label: 'Forearm'},
    humanShoulder: {target: OETarget.humanShoulder, label: 'Human Shoulder'},
    humanElbow: {target: OETarget.humanElbow, label: 'Human Elbow'},
    humanHand: {target: OETarget.humanHand, label: 'Human Hand'},
    humanLeg: {target: OETarget.humanLeg, label: 'Human Leg'},
    humanAnkleFoot: {target: OETarget.humanAnkleFoot, label: 'Ankle / Foot'},
    humanHip2: {target: OETarget.humanHip2, label: 'Hip joint (Hip2)'},
    humanLegPathways: {target: OETarget.humanLegPathways, label: 'Leg Pathways'},
    humanLowerLeg: {target: OETarget.humanLowerLeg, label: 'Lower Leg'},
    humanThighKnee: {target: OETarget.humanThighKnee, label: 'Thigh / Knee'},
    humanHip: {target: OETarget.humanHip, label: 'Human Hip'},
    humanKnee: {target: OETarget.humanKnee, label: 'Human Knee'},
    humanKneeImplantcast: {target: OETarget.humanKneeImplantcast, label: 'Human Knee (Implantcast)'},
    humanFoot: {target: OETarget.humanFoot, label: 'Human Foot'},
    humanTrunk: {target: OETarget.humanTrunk, label: 'Human Trunk'},
    humanSpine: {target: OETarget.humanSpine, label: 'Human Spine'},
    humanSpineFracture: {target: OETarget.humanSpineFracture, label: 'Human Spine (fractures)'},
    humanAbdominalWall: {target: OETarget.humanAbdominalWall, label: 'Abdominal Wall'},
    humanChestWall: {target: OETarget.humanChestWall, label: 'Chest Wall'},
    humanPelvis: {target: OETarget.humanPelvis, label: 'Human Pelvis'},
    humanNeckBack: {target: OETarget.humanNeckBack, label: 'Neck, Back'},
    humanShoulderGirdle: {target: OETarget.humanShoulderGirdle, label: 'Shoulder Girdle'},
    humanHead: {target: OETarget.humanHead, label: 'Human Head'},
    humanHeadWIP: {target: OETarget.humanHeadWIP, label: 'Human Head (WIP)'},
    humanEye2: {target: OETarget.humanEye2, label: 'Human Eye (2)'},
    humanEyeRetina: {target: OETarget.humanEyeRetina, label: 'Human Eye Retina (2)'},
    humanLarynx: {target: OETarget.humanLarynx, label: 'Human Larynx'},
    humanLiverMicro: {target: OETarget.humanLiverMicro, label: 'Human Liver (microscopic)'},
    humanHeart: {target: OETarget.humanHeart, label: 'Human Heart'},
    animalCell: {target: OETarget.animalCell, label: 'Animal Cell'},
    plantCell: {target: OETarget.plantCell, label: 'Plant Cell'},
    humanCellEpithelium: {target: OETarget.humanCellEpithelium, label: 'Epithelium cell'},
    philipsIcarus: {target: OETarget.philipsIcarus, label: 'Philips Icarus'},
// @ADD_TARGET_BEGIN
//  TARGET_cC: {target: OETarget.$TARGET_cC$, label: '$TARGET_CCS$'},
// @ADD_TARGET_END
    void: {target: OETarget.void, label: 'Void'}
};

OETargetMenuDefs.all = [
    OETargetMenuDefs.void,
    OETargetMenuDefs.earMacro,
    OETargetMenuDefs.earMacroAmboss,
    OETargetMenuDefs.earMicro,
    OETargetMenuDefs.skinMacroIONTO,
    OETargetMenuDefs.skinMicroIONTO,
    OETargetMenuDefs.eyeMacro,
    OETargetMenuDefs.eyeMicro,
    OETargetMenuDefs.eyeCornea,
    OETargetMenuDefs.snail,
    OETargetMenuDefs.toxNetz,
    OETargetMenuDefs.zeissOpti,
    OETargetMenuDefs.zeissOpti2,
    OETargetMenuDefs.zeissUV,
    OETargetMenuDefs.sensorySystems,
    OETargetMenuDefs.hoofWeb,
    OETargetMenuDefs.hoofVessels,
    OETargetMenuDefs.hoofDynamics,
    OETargetMenuDefs.hoofMicro,
    OETargetMenuDefs.humanSkull,
    OETargetMenuDefs.humanCranialNerves,
    OETargetMenuDefs.humanArm,
    OETargetMenuDefs.humanArmPathways,
    OETargetMenuDefs.humanUpperArmElbow,
    OETargetMenuDefs.humanForearm,
    OETargetMenuDefs.humanShoulder,
    OETargetMenuDefs.humanElbow,
    OETargetMenuDefs.humanHand,
    OETargetMenuDefs.humanLeg,
    OETargetMenuDefs.humanAnkleFoot,
    OETargetMenuDefs.humanHip2,
    OETargetMenuDefs.humanLegPathways,
    OETargetMenuDefs.humanLowerLeg,
    OETargetMenuDefs.humanThighKnee,
    OETargetMenuDefs.humanHip,
    OETargetMenuDefs.humanKnee,
    OETargetMenuDefs.humanKneeImplantcast,
    OETargetMenuDefs.humanFoot,
    OETargetMenuDefs.humanTrunk,
    OETargetMenuDefs.humanSpine,
    OETargetMenuDefs.humanSpineFracture,
    OETargetMenuDefs.humanAbdominalWall,
    OETargetMenuDefs.humanChestWall,
    OETargetMenuDefs.humanPelvis,
    OETargetMenuDefs.humanNeckBack,
    OETargetMenuDefs.humanShoulderGirdle,
    OETargetMenuDefs.humanHead,
    OETargetMenuDefs.humanHeadWIP,
    OETargetMenuDefs.humanEye2,
    OETargetMenuDefs.humanEyeRetina,
    OETargetMenuDefs.humanLarynx,
    OETargetMenuDefs.humanLiverMicro,
    OETargetMenuDefs.humanHeart,
    OETargetMenuDefs.animalCell,
    OETargetMenuDefs.plantCell,
    OETargetMenuDefs.humanCellEpithelium,
    OETargetMenuDefs.philipsIcarus,
// @ADD_TARGET_BEGIN@
//   OETargetMenuDefs.TARGET_cC,
// @ADD_TARGET_END@
];

OETargetMenuDefs.app = [
    OETargetMenuDefs.eyeMacro,
    OETargetMenuDefs.eyeMicro,
    OETargetMenuDefs.eyeCornea,
    OETargetMenuDefs.earMacro, 
    OETargetMenuDefs.earMicro,
    OETargetMenuDefs.humanSkull,
    OETargetMenuDefs.humanArm,
    OETargetMenuDefs.humanShoulder,
    OETargetMenuDefs.humanElbow,
    OETargetMenuDefs.humanHand,
    OETargetMenuDefs.humanLeg,
    OETargetMenuDefs.humanHip,
    OETargetMenuDefs.humanKnee,
    OETargetMenuDefs.humanFoot,
    OETargetMenuDefs.humanTrunk,
    OETargetMenuDefs.humanSpine,
    OETargetMenuDefs.humanAbdominalWall,
    OETargetMenuDefs.humanChestWall,
    OETargetMenuDefs.humanPelvis,
    OETargetMenuDefs.humanNeckBack,
    OETargetMenuDefs.humanShoulderGirdle,
    OETargetMenuDefs.skinMacroIONTO,
    OETargetMenuDefs.skinMicroIONTO,
    OETargetMenuDefs.snail,
    OETargetMenuDefs.hoofWeb,
    OETargetMenuDefs.hoofVessels,
    OETargetMenuDefs.hoofDynamics,
    OETargetMenuDefs.hoofMicro
];

OETargetMenuDefs.ear = [
    {target: OETarget.earMacro, label: 'Macro'},
    {target: OETarget.earMicro, label: 'Corti organ'},
    {target: OETarget.sensorySystemsEar, label: 'Sensory pathways'}
];

OETargetMenuDefs.earAmboss = [
    {target: OETarget.earMacroAmboss, label: 'Macro'},
    {target: OETarget.earMicro, label: 'Corti organ'}
];

OETargetMenuDefs.anatomy = {
    prefix: 'anatomy',
    parts: [
        {prefix: 'anatomy', target: OETarget.humanHead, label: {text: 'Head and neck', id: 'target_menu_anatomy_humanHeadMenuItem'}, parts: [
            {target: OETarget.humanSkull, label: 'Skull'},
            {target: OETarget.humanCranialNerves, label: 'Cranial Nerves'},
            {target: OETarget.humanLarynx, label: 'Larynx'}
        ]},
        {prefix: 'anatomy', target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_anatomy_humanArmMenuItem'}, parts: [
            {target: OETarget.humanArm, label: 'Upper extremity'},
            {target: OETarget.humanShoulderGirdle, label: 'Shoulder girdle'},
            {target: OETarget.humanUpperArmElbow, label: 'Upper arm and elbow'},
            {target: OETarget.humanForearm, label: 'Forearm'},
            {target: OETarget.humanHand, label: 'Hand'},
            {target: OETarget.humanArmPathways, label: 'Upper extremity (pathways)'}
        ]},
        {prefix: 'anatomy', target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_anatomy_humanLegMenuItem'}, parts: [
            {target: OETarget.humanLeg, label: 'Lower extremity'},
            {target: OETarget.humanHip2, label: 'Hip'},
            {target: OETarget.humanThighKnee, label: 'Thigh and knee'},
            {target: OETarget.humanLowerLeg, label: 'Lower leg'},
            {target: OETarget.humanAnkleFoot, label: 'Ankle + foot'},
            {target: OETarget.humanLegPathways, label: 'Lower extremity (pathways)'}            
        ]},
        {prefix: 'anatomy', target: OETarget.humanTrunk, label: {text: 'Trunk', id: 'target_menu_anatomy_humanTrunkMenuItem'}, parts: [
            {target: OETarget.humanTrunk, label: 'Trunk'},
            {target: OETarget.humanNeckBack, label: 'Neck, Back'},
            {target: OETarget.humanChestWall, label: 'Chest wall'},
            {target: OETarget.humanAbdominalWall, label: 'Abdominal wall'},
            {target: OETarget.humanPelvis, label: 'Pelvis'}    
        ]},
        {prefix: 'anatomy', target: OETarget.void, label: {text: 'Biology', id: 'target_menu_anatomy_humanBiologyItem'}, parts: [
            {target: OETarget.humanCellEpithelium, label: 'Epithelium cell'}
             //{target: OETarget.humanSpine, label: 'Spine'},
            //{target: OETarget.humanSpineFracture, label: 'Spine (fractures)'}
        ]}
    ]
};

OETargetMenuDefs.amboss = {};

OETargetMenuDefs.amboss.unnested = [
    {target: OETarget.humanSkull, label: 'Skull'},
    {target: OETarget.humanTrunk, label: 'Torso'},
    {target: OETarget.humanArm, label: {text: 'Arm', style: OETargetMenuDefs.styles.major}},
    {target: OETarget.humanShoulder, label: 'Shoulder'},
    {target: OETarget.humanElbow, label: 'Elbow'},
    {target: OETarget.humanHand, label: 'Hand'},
    {target: OETarget.humanLeg, label: {text: 'Leg', style: OETargetMenuDefs.styles.major}},
    {target: OETarget.humanHip, label: 'Hip'},
    {target: OETarget.humanKnee, label: 'Knee'},
    {target: OETarget.humanFoot, label: 'Foot'}
];

OETargetMenuDefs.amboss.nested = {
    prefix: 'amboss',
    parts: [
        {prefix: 'amboss', target: OETarget.humanHead, label: {text: 'Head and neck', id: 'target_menu_amboss_humanHeadMenuItem'}, parts: [
//            {target: OETarget.humanHead, label: 'Head'},
            {target: OETarget.humanSkull, label: 'Skull'},
            {target: OETarget.humanCranialNerves, label: 'Cranial Nerves'},
            {target: OETarget.humanLarynx, label: 'Larynx'}
        ]},
        {prefix: 'amboss', target: OETarget.void, label: {text: 'Sensory organs', id: 'target_menu_amboss_humanSensoryOrgansMenuItem'}, parts: [
            {target: OETarget.eyeMacro, label: 'Eye'},
            {target: OETarget.earMacroAmboss, label: 'Ear'},
            {target: OETarget.skinMacroIONTO, label: 'Skin'}
        ]},
        {prefix: 'amboss', target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_amboss_humanArmMenuItem'}, parts: [
            {target: OETarget.humanArm, label: 'Upper extremity'},
            {target: OETarget.humanArmPathways, label: 'Upper extremity (pathways)'},
            //{target: OETarget.humanShoulder, label: 'Shoulder'},
            {target: OETarget.humanShoulderGirdle, label: 'Shoulder (new)'},
            {target: OETarget.humanUpperArmElbow, label: 'Upper arm + elbow'},
            //{target: OETarget.humanElbow, label: 'Elbow'},
            {target: OETarget.humanForearm, label: 'Forearm'},
            {target: OETarget.humanHand, label: 'Hand'}
        ]},
        {prefix: 'amboss', target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_amboss_humanLegMenuItem'}, parts: [
            {target: OETarget.humanLeg, label: 'Lower extremity'},
            {target: OETarget.humanLegPathways, label: 'Lower extremity (pathways)'},
            //{target: OETarget.humanHip, label: 'Hip'},
            {target: OETarget.humanHip2, label: 'Hip (new)'},
            {target: OETarget.humanThighKnee, label: 'Thigh + knee'},
            //{target: OETarget.humanKnee, label: 'Knee'},
            {target: OETarget.humanLowerLeg, label: 'Lower leg'},
            //{target: OETarget.humanFoot, label: 'Foot'},
            {target: OETarget.humanAnkleFoot, label: 'Ankle + foot'}
        ]},
        {prefix: 'amboss', target: OETarget.humanTrunk, label: {text: 'Trunk', id: 'target_menu_amboss_humanTrunkMenuItem'}, parts: [
            {target: OETarget.humanTrunk, label: 'Trunk'},
            {target: OETarget.humanNeckBack, label: 'Neck, Back'},
            {target: OETarget.humanChestWall, label: 'Chest wall'},
            {target: OETarget.humanAbdominalWall, label: 'Abdominal wall'},
            {target: OETarget.humanPelvis, label: 'Pelvis'}, 
            //{target: OETarget.humanSpine, label: 'Spine'},
            //{target: OETarget.humanSpineFracture, label: 'Spine (fractures)'}
        ]},
        {prefix: 'amboss', target: OETarget.void, label: {text: 'Biology', id: 'target_menu_amboss_humanBiologyItem'}, parts: [
            {target: OETarget.humanCellEpithelium, label: 'Epithelium cell'}
             //{target: OETarget.humanSpine, label: 'Spine'},
            //{target: OETarget.humanSpineFracture, label: 'Spine (fractures)'}
        ]}

    ]
};

OETargetMenuDefs.amboss.import = {
    prefix: 'amboss',
    parts: [
        {prefix: 'amboss', target: OETarget.humanHead, label: {text: 'Head and neck', id: 'target_menu_amboss_humanHeadMenuItem'}, parts: [
            {target: OETarget.humanSkull, label: 'Skull'},
            {target: OETarget.humanHead, label: 'Head'},
            {target: OETarget.humanHeadWIP, label: 'Head (WIP)'},
            {target: OETarget.humanCranialNerves, label: 'Cranial Nerves'},
            {target: OETarget.humanLarynx, label: 'Larynx'}
        ]},
        {prefix: 'amboss', target: OETarget.void, label: {text: 'Sensory organs', id: 'target_menu_amboss_humanSensoryOrgansMenuItem'}, parts: [
            {target: OETarget.eyeMacro, label: 'Eye'},
            {target: OETarget.humanEye2, label: 'Eye (2)'},
            {target: OETarget.eyeMicro, label: 'Retina'},
            {target: OETarget.humanEyeRetina, label: 'Retina (2)'},
            {target: OETarget.eyeCornea, label: 'Cornea'},
            {target: OETarget.earMacroAmboss, label: 'Ear'},
            {target: OETarget.earMicro, label: 'Corti organ'},
            {target: OETarget.skinMacroIONTO, label: 'Skin'},
            {target: OETarget.skinMicroIONTO, label: 'Skin cells'}
        ]},
        {prefix: 'amboss', target: OETarget.void, label: {text: 'Internal organs', id: 'target_menu_amboss_humanOrgansMenuItem'}, parts: [
            {target: OETarget.humanHeart, label: 'Heart'},
            {target: OETarget.humanLiverMicro, label: 'Liver (microscopic)'},
            {target: OETarget.humanCellEpithelium, label: 'Darmepithelzelle'}
        ]},
        {prefix: 'amboss', target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_amboss_humanArmMenuItem'}, parts: [
            {target: OETarget.humanArm, label: 'Upper extremity'},
            {target: OETarget.humanArmPathways, label: 'Upper extremity (pathways)'},
            {target: OETarget.humanShoulder, label: 'Shoulder'},
            {target: OETarget.humanShoulderGirdle, label: 'Shoulder (new)'},
            {target: OETarget.humanUpperArmElbow, label: 'Upper arm + elbow'},
            {target: OETarget.humanElbow, label: 'Elbow'},
            {target: OETarget.humanForearm, label: 'Forearm'},
            {target: OETarget.humanHand, label: 'Hand'}
        ]},
        {prefix: 'amboss', target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_amboss_humanLegMenuItem'}, parts: [
            {target: OETarget.humanLeg, label: 'Lower extremity'},
            {target: OETarget.humanLegPathways, label: 'Lower extremity (pathways)'},
            {target: OETarget.humanHip, label: 'Hip'},
            {target: OETarget.humanHip2, label: 'Hip (new)'},
            {target: OETarget.humanThighKnee, label: 'Thigh + knee'},
            {target: OETarget.humanKnee, label: 'Knee'},
            {target: OETarget.humanLowerLeg, label: 'Lower leg'},
            {target: OETarget.humanFoot, label: 'Foot'},
            {target: OETarget.humanAnkleFoot, label: 'Ankle + foot'}
        ]},
        {prefix: 'amboss', target: OETarget.humanTrunk, label: {text: 'Trunk', id: 'target_menu_amboss_humanTrunkMenuItem'}, parts: [
            {target: OETarget.humanTrunk, label: 'Trunk'},
            {target: OETarget.humanNeckBack, label: 'Neck, Back'},
            {target: OETarget.humanChestWall, label: 'Chest wall'},
            {target: OETarget.humanAbdominalWall, label: 'Abdominal wall'},
            {target: OETarget.humanPelvis, label: 'Pelvis'}, 
            {target: OETarget.humanSpine, label: 'Spine'},
            {target: OETarget.humanSpineFracture, label: 'Spine (fractures)'}
        ]}
    ]
};

OETargetMenuDefs.amboss.free = [
];

OETargetMenuDefs.kind = [
    {target: OETarget.earMacro, label: 'Ohr'},
    {target: OETarget.earMicro, label: 'Cortisches Organ'},
    {target: OETarget.eyeMacro, label: 'Auge'},
    {target: OETarget.eyeMicro, label: 'Retina'},
    {target: OETarget.eyeCornea, label: 'Cornea'},
    {target: OETarget.sensorySystems, label: 'Seh-/Hörbahn'}
];

OETargetMenuDefs.ortho = [
    {target: OETarget.humanSkull, label: {text: 'Skull', style: OETargetMenuDefs.styles.major}},
    {target: OETarget.humanTrunk, label: {text: 'Torso', style: OETargetMenuDefs.styles.major}},
    {target: OETarget.humanArm, label: {text: 'Arm', style: OETargetMenuDefs.styles.major}},
    {target: OETarget.humanShoulder, label: 'Shoulder'},
    {target: OETarget.humanElbow, label: 'Elbow'},
    {target: OETarget.humanHand, label: 'Hand'},
    {target: OETarget.humanLeg, label: {text: 'Leg', style: OETargetMenuDefs.styles.major}},
    {target: OETarget.humanHip, label: 'Hip'},
    {target: OETarget.humanKnee, label: 'Knee'},
    {target: OETarget.humanFoot, label: 'Foot'}
];

OETargetMenuDefs.ortho.nested = {
    prefix: 'ortho',
    parts: [
        {prefix: 'ortho', target: OETarget.humanHead, label: {text: 'Head', id: 'target_menu_ortho_humanHeadMenuItem'}, parts: [
            {target: OETarget.humanSkull, label: 'Skull'}
        ]},
        {prefix: 'ortho', target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_ortho_humanArmMenuItem'}, parts: [
            {target: OETarget.humanArm, label: 'Arm'},
            {target: OETarget.humanShoulder, label: 'Shoulder'},
            {target: OETarget.humanElbow, label: 'Elbow'},
            {target: OETarget.humanHand, label: 'Hand'}
        ]},
        {prefix: 'ortho', target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_ortho_humanLegMenuItem'}, parts: [
            {target: OETarget.humanLeg, label: 'Leg'},
            {target: OETarget.humanHip, label: 'Hip'},
            {target: OETarget.humanKnee, label: 'Knee'},
            {target: OETarget.humanFoot, label: 'Foot'}
        ]},
        {prefix: 'ortho', target: OETarget.humanTrunk, label: {text: 'Trunk', id: 'target_menu_ortho_humanTrunkMenuItem'}, parts: [
            {target: OETarget.humanTrunk, label: 'Trunk'}
        ]}
    ]
/*    {target: OETarget.humanSkull, label: 'Skull'},
    {target: OETarget.humanTrunk, label: 'Torso'},
    {target: OETarget.humanArm, label: 'Arm', parts:[
        {target: OETarget.humanArm, label: 'Arm'},
        {target: OETarget.humanShoulder, label: 'Shoulder'},
        {target: OETarget.humanElbow, label: 'Elbow'},
        {target: OETarget.humanHand, label: 'Hand'}    
    ]},
    {target: OETarget.humanArm, label: 'Leg', parts:[
        {target: OETarget.humanLeg, label: 'Leg'},
        {target: OETarget.humanHip, label: 'Hip'},
        {target: OETarget.humanKnee, label: 'Knee'},
        {target: OETarget.humanFoot, label: 'Foot'}
    ]} */
};

OETargetMenuDefs.skin = [
    {target: OETarget.skinMacroIONTO, label: {text: 'Gesamtmodell Haut', id: 'target_menu_skin_macro'}},
    {target: OETarget.skinMicroIONTO, label: {text: 'Teilmodell Oberhaut', id: 'target_menu_skin_micro'}},
];

OETargetMenuDefs.eye = {
    prefix: 'eye',
    parts: [
        {target: OETarget.eyeMacro, label: 'Macro'},
        {target: OETarget.eyeMicro, label: 'Retina'},
        {target: OETarget.eyeCornea, label: 'Cornea'},
        {target: OETarget.sensorySystemsEye, label: 'Sensory pathways'}
    ]
}

OETargetMenuDefs.eye2 = {
    prefix: 'eye',
    parts: [
        {target: OETarget.eyeMacro, label: 'Macro'},
        {target: OETarget.humanEye2, label: 'Eye2'},
        {target: OETarget.eyeMicro, label: 'Retina'},
        {target: OETarget.humanEyeRetina, label: 'Retina (2)'},
        {target: OETarget.eyeCornea, label: 'Cornea'},
        {target: OETarget.sensorySystemsEye, label: 'Sensory pathways'}
    ]
}

OETargetMenuDefs.hoof = [
    OETargetMenuDefs.hoofWeb,
    OETargetMenuDefs.hoofVessels,
    OETargetMenuDefs.hoofDynamics,
    OETargetMenuDefs.hoofMicro
];

OETargetMenuDefs.toxNetzAll = [
    {target: OETarget.toxNetz, label: {text: 'Torso', id: 'target_menu_toxnetz_torso'}},
    {target: OETarget.humanLiverMicro, label: {text: 'Liver (microscopic)', id: 'target_menu_toxnetz_liver_micro'}},
    {target: OETarget.eyeMacro, label: {text: 'Auge', id: 'target_menu_toxnetz_eye'}},
    {target: OETarget.earMacro, label: {text: 'Ohr', id: 'target_menu_toxnetz_ear'}},
    {target: OETarget.skinMacroIONTO, label: {text: 'Haut', id: 'target_menu_toxnetz_skin'}},
    {target: OETarget.humanHeart, label: {text: 'Herz', id: 'target_menu_toxnetz_humanHeart'}},
    {target: OETarget.humanLiverMicro, label: {text: 'Leber (mikroskopisch)', id: 'target_menu_toxnetz_humanLiverMicro'}}
];

OETargetMenuDefs.cell = [
    OETargetMenuDefs.animalCell,
    OETargetMenuDefs.plantCell
];

OETargetMenuDefs.philipsIcarus = {
    prefix: 'anatomy',
    parts: [
        /*
        {prefix: 'anatomy', target: OETarget.humanHead, label: {text: 'Head and neck', id: 'target_menu_anatomy_humanHeadMenuItem'}, parts: [
        ]},
        {prefix: 'anatomy', target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_anatomy_humanArmMenuItem'}, parts: [
        ]},
        {prefix: 'anatomy', target: OETarget.humanTrunk, label: {text: 'Trunk', id: 'target_menu_anatomy_humanTrunkMenuItem'}, parts: [  
        ]},
        */
        {prefix: 'anatomy', target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_anatomy_humanLegMenuItem'}, parts: [
            {target: OETarget.humanKnee, label: 'Knee'},
            {target: OETarget.humanFoot, label: 'Foot'}
        ]},
    ]
};

export const OEStartViewTypes = {
    std: 'std',
    amboss: 'amboss',
    anatomy: 'anatomy',
    zeissOpti: 'zeissOpti',
    cambridgeCell: 'cambridgeCell',
    ionto: 'ionto'
};

export const OEFNWelcomeEntryTypes = {
    text: 'text',
    menu: 'menu',
    home: 'home',
    licensing: 'licensing',
    mediaCenter : 'mediaCenter',
    help : 'help',
    quickStart: 'quickStart',
    manual: 'manual',
    spacer: 'spacer',
    dontShowCheckbox: 'dontShowCheckbox'
};

export const OEHelpViewTypes = {
    std: 'std',
    start: 'start',
    manual: 'manual'
};

export const OEHelpViewEntryType = {
    advice: 'advice',
    manual: 'manual',
    quickStart: 'quickStart',
    videoTutorials: 'videoTutorials',
    tutorReset: 'tutorReset',
    credits: 'credits',
    feedback: 'feedback'
};

export const OEManualViewLinks = {
    app: 'app',
    welcome: 'welcome',
    controls: 'controls',
    functions: 'functions',
    cut: 'cut',
    arrow: 'arrow',
    note: 'note',
    component: 'component',
    substructure: 'substructure',
    search: 'search',
    selection: 'selection',
    contextMenu: 'contextMenu',
    modelState: 'modelState',
    label: 'label',
    presentation: 'presentation',
    screenshot: 'screenshot',
    mediaCenter: 'mediaCenter',
    settings: 'settings'
};

export const OEManualViewLinkType = {
    none: 'none',
    youtube: 'youtube',
    pdf: 'pdf'
};

export const OECutControllerStyle = {
    std: 'std',
    minimal: 'minimal'
};

export const OEMediaCenterItemTypes = {
    none: -1,
    dummy: 0,
    animation: 1,
    accessory: 2,
    image: 3,
    video: 4,
    timeline: 5,
    html: 6,
    presentation: 7
};

export const OEMediaCenterMode = {
    std: 0,
    bottomBar: 1
};

export const OEFeature = {
    tree: 0, substructure: 1, color: 2, explosion: 3, cut: 4, arrows: 5, notes: 6, labels: 7, search: 8, selection: 9, context_menu: 10, play_pres: 11, edit_pres: 12, media: 13, animations: 14, state_collection: 15, tooltip: 16, highlight: 17, screenshot: 18
};

// module independent ui level representation  
export const UILevel = {
    std: 'std',
    simple: 'simple',
    advanced: 'advanced',
    levelFromModuleValue: function(value) {
        return value == 2 ? UILevel.advanced : (value == 1 ? UILevel.simple : UILevel.std);
    },
    moduleValueFromLevel: function(level)   {
        return level === UILevel.advanced ? 2 : (level === UILevel.simple ? 1 : 0);
    }
};

// OESettingsControllerDefs
export const OESettingsControllerDefs = {};

OESettingsControllerDefs.controlGroupTypes = {
    language: 'language',
    background: 'background',
    inputSensitivity: 'input_sensitivity',
    graphics: 'graphics',
    ui: 'ui',
    tutor: 'tutor'
};

OESettingsControllerDefs.controlTypes = {
    language: 'language',
    languageSwitch: 'language_switch',
    vignette: 'vignette',
    backgroundColor: 'backgroundColor',
    vpGizmo: 'vp_gizmo',
    touchSensitivity: 'touch_sensitivity',
    ambientOcclusion: 'ambient_occlusion',
    rendererPreset: 'renderer_preset',
    uiLevel: 'ui_level',
    componentHighlightingVP: 'component_gighlighting_vp',
    tutorReset: 'tutor_reset'
};

OESettingsControllerDefs.defaultLanguageControl = {
    type: OESettingsControllerDefs.controlGroupTypes.language,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.language, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.languageSwitch, enabled: true}
    ]
};

OESettingsControllerDefs.defaultBackgroundControl = {
    type: OESettingsControllerDefs.controlGroupTypes.background,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.vignette, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.backgroundColor, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.vpGizmo, enabled: true}
    ]
};

OESettingsControllerDefs.defaultInputSensitivityControl = {
    type: OESettingsControllerDefs.controlGroupTypes.inputSensitivity,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.touchSensitivity, enabled: true}
    ]
};

OESettingsControllerDefs.defaultGraphicsControl = {
    type: OESettingsControllerDefs.controlGroupTypes.graphics,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.ambientOcclusion, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.componentHighlightingVP, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.rendererPreset, enabled: true}
    ]
};

OESettingsControllerDefs.uiControlSimple = {
    type: OESettingsControllerDefs.controlGroupTypes.ui,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.uiLevel, enabled: true, data: [UILevel.simple, UILevel.std]}
    ]
};

OESettingsControllerDefs.uiControlAdvanced = {
    type: OESettingsControllerDefs.controlGroupTypes.ui,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.uiLevel, enabled: true, data: [UILevel.std, UILevel.advanced]}
    ]
};

OESettingsControllerDefs.uiControlSimpleAdvanced = {
    type: OESettingsControllerDefs.controlGroupTypes.ui,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.uiLevel, enabled: true, data: [UILevel.simple, UILevel.std, UILevel.advanced]}
    ]
};

OESettingsControllerDefs.defaultTutorControl = {
    type: OESettingsControllerDefs.controlGroupTypes.tutor,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.tutorReset, enabled: true}
    ]
};