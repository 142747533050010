export const OEVersion = {
    amboss: 'AMBOSS',
    anatomy: 'ANATOMY',
    ionto: 'IONTO',
    effigos: 'EFFIGOS',
    visionResearch: 'VISIONRESEARCH',
    licensingStart: 'licensingStart',
    toxNetz: 'TOXNETZ',
    zeissOpti: 'ZEISSOPTI',
    kind: 'KIND',
    ortho: 'ORTHO',
    cambridgeCell: 'CAMBRIDGECELL',
    philipsIcarus: 'PHILIPSICARUS',
    bayer: 'BAYER'
};