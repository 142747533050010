class OEUniqueIdGen {

    constructor() {
        var _id = 0;

        this.get = function()  {
            var ret = _id.toString();
            _id++;
            return ret;
        }
    }
};

export let oeUniqueIdGen = new OEUniqueIdGen();