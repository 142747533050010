class OEColorHelperType {
    
    themeColor() {
        return {x: 79/255, y: 163/255, z: 209/255, w: 1};
    }

    stdBgOpacity(brightness)    {
        return 0.7;
    }

    stdOverlayOpacity(brightness)    {
        return 0.65;
    }

    brightMode(brightness)  {
        return brightness > 0.5;
    }

    stdBackgroundColor(brightness)  {
        var opacity = this.stdBgOpacity(brightness);
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: opacity} : {x: 0.15, y: 0.15, z: 0.2, w: opacity};
    }

    stdActiveBackgroundColor(brightness)  {
        var opacity = this.stdBgOpacity(brightness);
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: opacity} : {x: 0.15, y: 0.15, z: 0.2, w: opacity};
    }

    stdHoverBackgroundColor(brightness)  {
        var opacity = this.stdBgOpacity(brightness);
        return this.brightMode(brightness) ? {x: 0.05, y: 0.05, z: 0.1, w: opacity} : {x: 0.2, y: 0.2, z: 0.25, w: opacity};
    }

    stdActiveHoverBackgroundColor(brightness)  {
        var opacity = this.stdBgOpacity(brightness);
        return this.brightMode(brightness) ? {x: 0.05, y: 0.05, z: 0.1, w: opacity} : {x: 0.2, y: 0.2, z: 0.25, w: opacity};
    }

    /*
    extraLightBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: 0.3} : {x: 0.3, y: 0.3, z: 0.4, w: 0.3};
    }

    extraLightActiveBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: 0.4} : {x: 0.3, y: 0.3, z: 0.4, w: 0.4};
    }

    extraLightHoverBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.05, y: 0.05, z: 0.1, w: 0.4} : {x: 0.35, y: 0.35, z: 0.45, w: 0.4};
    }

    extraLightActiveHoverBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: 0.4} : {x: 0.3, y: 0.3, z: 0.4, w: 0.4};
    }
    */

    lightBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: 0.5} : {x: 0.15, y: 0.15, z: 0.2, w: 0.5};
    }

    lightActiveBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: 0.6} : {x: 0.3, y: 0.3, z: 0.4, w: 0.6};
    }

    lightHoverBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.05, y: 0.05, z: 0.1, w: 0.6} : {x: 0.35, y: 0.35, z: 0.45, w: 0.6};
    }

    lightActiveHoverBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: 0.6} : {x: 0.3, y: 0.3, z: 0.4, w: 0.6};
    }

    mainMenuBackgroundColor(brightness)  {
        return this.stdBackgroundColor(brightness);
    }

    stdToolbarBackgroundColor(brightness)  {
        var opacity = this.stdBgOpacity(brightness);
        return this.brightMode(brightness) ? {x: 0.05, y: 0.05, z: 0.1, w: opacity} : {x: 0.1, y: 0.1, z: 0.15, w: opacity};
    }

    viewBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: 0.4} : {x: 0.15, y: 0.15, z: 0.2, w: 0.4};
    }

    startViewBackgroundColor(brightness)  {
        return this.brightMode(brightness) ? {x: 0.0, y: 0.0, z: 0.05, w: 0.6} : {x: 0.15, y: 0.15, z: 0.2, w: 0.6};
    }

    stdLabelTextColor(brightness)  {
        return brightness > 0.15 ? {x: 1, y: 1, z: 1, w: 1} : {x: 1, y: 1, z: 1, w: 1};
    }

    viewPortLabelTextColor(brightness)  {
        var b = typeof(brightness) === 'undefined' ? 0.6 : brightness;
        return b >= 0.6 ? {x: 0, y: 0, z: 0, w: 0.6666} : (b > 0.15 ? {x: 1, y: 1, z: 1, w: 1} : {x: 0.9, y: 0.9, z: 0.9, w: 1});
    }

    mainMenuTextColor(brightness)  {
        return brightness > 0.15 ? {x: 1, y: 1, z: 1, w: 1} : {x: 1, y: 1, z: 1, w: 1};
    }

    stdSeparatorColor(brightness)    {
        return {x: 0.333, y: 0.333, z: 0.333, w: 0.5};
    }

    stdOverlayViewBackgroundColor(brightness)   {
        var opacity = this.stdOverlayOpacity(brightness);
        return {x: 0.33, y: 0.33, z: 0.33, w: opacity};
    }

    vColorToStr(vColor)  {

        if(typeof(vColor) === 'number') {
            vColor = {
                x: ((vColor&0x00FF0000) >> 16) / 255.0,
                y: ((vColor&0x0000FF00) >> 8) / 255.0,
                z: ((vColor&0x000000FF)) / 255.0,
                w: (vColor - (vColor&0x00FFFFFF)) / 0x01000000
            };
        }

        if(typeof(vColor) === 'undefined' || vColor === null) {
            return 'rgb(0,0,0)';
        }
        
        var hasAlpha = typeof(vColor.w) !== 'undefined';
        
        if(hasAlpha)    {
            return 'rgba(' + (Math.round(vColor.x*255)).toString() + ',' + (Math.round(vColor.y*255)).toString() + ',' + (Math.round(vColor.z*255)).toString() + ',' + vColor.w.toString() + ')';
        } else {
            return 'rgb(' + (Math.round(vColor.x*255)).toString() + ',' + (Math.round(vColor.y*255)).toString() + ',' + (Math.round(vColor.z*255)).toString() + ')';
        }
    }
    
    toVColor(color) {
        var rgb = color.toRgb();
        return {x: rgb.r/255, y: rgb.g/255, z: rgb.b/255, w: rgb.a};
    }
}

export let OEColorHelper = new OEColorHelperType();