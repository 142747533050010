import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import {OEControl, OEToggleControl, OESliderControl, OEDropdown, OEButton} from '../oe-controls';
import {retardUpdate} from '../../lib/update-retarder';

export default class OERendererPresetPanel extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            entries: []
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onPrintBtnPressed = this.onPrintBtnPressed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {}

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false, entries: [] });
            return;
        }
        
        retardUpdate(this, () => {
            this.setState({ uiEnabled: true});
            
            this.updateEntries();
            this.updateLanguage();
        });
    }

    updateEntries() {
        var entries = [];
        const preset = this.oe.sharedInterface.getRendererPreset();
        const pp = preset.postProcess;
        const options = preset.options;
        const RendererOptions = this.oe.Module.RendererOptions;

        entries.push({ path: 'options', key: RendererOptions.no_color_mapping.value, type: 'bool', label: 'Color Mapping', value: !options.includes(RendererOptions.no_color_mapping) });
        entries.push({ path: 'options', key: RendererOptions.no_specular_mapping.value, type: 'bool', label: 'Specular Mapping', value: !options.includes(RendererOptions.no_specular_mapping) });
        entries.push({ path: 'options', key: RendererOptions.no_normal_mapping.value, type: 'bool', label: 'Normal Mapping', value: !options.includes(RendererOptions.no_normal_mapping) });

        entries.push({
            path: 'postProcess',
            key: 'name',
            type: 'list',
            label: pp.name != '' ? 'Cartoon' : 'None',
            entries: [{key: '', label: 'None'}, {key: 'cartoon', label: 'Cartoon'}],
            value:  pp.name
        });

        if(pp.name === 'cartoon')    {
            entries.push({ path: 'postProcess', key: 'strokeStrength', type: 'number', label: 'Pencil Hardness', range: [0, 7], value: pp.strokeStrength });
            entries.push({ path: 'postProcess', key: 'strokeCutoff', type: 'number', label: 'Stroke Cutoff', range: [0, 1], value: pp.strokeCutoff });
            entries.push({ path: 'postProcess', key: 'brightnessQuantization', type: 'number', label: 'Brightness Quantization', range: [2, 32], value: pp.brightnessQuantization });
            entries.push({ path: 'postProcess', key: 'saturationQuantization', type: 'number', label: 'Saturation Quantization', range: [2, 32], value: pp.saturationQuantization });
            entries.push({ path: 'postProcess', key: 'hueQuantization', type: 'number', label: 'Hue Quantization', range: [2, 32], value: pp.hueQuantization });
        }

        this.setState({ entries: entries });
    }

    onUIControllerStateChanged(message, userInfo)    {}

    renderEntry(enabled, entry) {
        if(entry.type === 'list')   {
            var entries = entry.entries.map((e) => { return {key: e.key, content: <span className="label">{e.label}</span>}; });

            return (
                <OEControl key={entry.key}>
                    <OEDropdown
                        title={entry.label}
                        disabled={!enabled}
                        chosenKey={entry.value}
                        entries={entries}
                        onClick={(key) => this.onEntryChanged(entry, key)}
                    />
                </OEControl>
            );
        } else if(entry.type === 'number')   {
            return (
                <OESliderControl
                    key={entry.key}
                    title = {entry.label}
                    disabled={!enabled}
                    min={entry.range[0]}
                    max={entry.range[1]}
                    step={0.01}
                    value={entry.value}
                    onSlide={(value) => this.onEntryChanged(entry, value)}
                />
            );
        } else if(entry.type === 'bool')   {
            return (
                <OEToggleControl
                    key={entry.key}
                    title = {entry.label}
                    disabled={!enabled}
                    active={entry.value}
                    onClick={() => this.onEntryChanged(entry, entry.value)}
                />
            );
        }
        return null;
    }

    render() {
        if(this.state.entries.length === 0) return null;
        var entries = this.state.entries.map((e) => this.renderEntry(this.state.uiEnabled, e));

        return (
            <div
                className={'light-bg post-process-panel std-label-text-color' + this.props.className}
                style={this.props.style}
            >
                {entries}
                <OEControl>
                    <OEButton
                        className="btn transparent-btn light-bg"
                        onPressed={this.onPrintBtnPressed}
                    >
                        {'Print'}
                    </OEButton>
                </OEControl>
            </div>
        );
    }

    onEntryChanged(entry, value)   {
        var preset = {};

        if(entry.path === 'postProcess')    {
            preset[entry.path] = {};
            preset[entry.path][entry.key] = value;
        } else if(entry.path === 'options')    {
            var options = this.oe.sharedInterface.getRendererPreset().options;
            preset.options = options.filter(option => (option.value != entry.key));
            if(value)   {
                preset.options.push({value: entry.key});
            }
        }

        this.oe.sharedInterface.applyRendererPreset(preset);
        this.updateEntries();
    }

    onPrintBtnPressed() {
        const entries = this.state.entries;

        var output = {};
        entries.forEach(entry => {
            output[(entry.path === 'postProcess' && entry.key === 'name') ? 'postFX' : entry.label] = entry.value;
        });

        console.log(output);
    }
}

OERendererPresetPanel.defaultProps = {
    moduleId: '',
    className: ''
};

OERendererPresetPanel.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string
};