import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip} from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';

import {connectModuleEnv} from './oe-module-env';
import {OEColorHelper} from './oe-color-helper';
import {OEIconCodes} from '../lib/oe-icon-codes';
import {OEToolbox} from '../lib/oe-toolbox';

export class OEGroupControl extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.collapsed
        };

        this.onHeaderClicked = this.onHeaderClicked.bind(this);
    }

    renderHeader()  {
        if(this.props.title === '' && this.props.type !== OEGroupControl.type.userCollapsable)   return null;

        const title = this.props.type === OEGroupControl.type.userCollapsable ?
            <a>
                <span className="group-control-title">
                    <div className={'rot-toggle' + (!this.state.collapsed ? ' rot-rotate-toggle' : '')}>
                        <OEIcon code={OEIconCodes.caretRight}/>
                    </div>{this.props.title}
                </span>
            </a> : 
            <span className="group-control-title">{this.props.title}</span>;

        return (
            <div 
                onClick={this.onHeaderClicked} 
                className={'group-control-header ' + (this.props.type === OEGroupControl.type.userCollapsable ? 'user-collapsable ' : '') + this.props.headerClassName}>
                {title}
            </div>
        );
    }

    renderBody()    {
        return (
            <div className={'group-control-body ' + this.props.bodyClassName}>{this.props.children}</div>
        );
    }

    renderSeparator()   {
        return <div className="group-control-separator"></div>;
    }

    render() {
        var header = this.renderHeader();
        var body = this.renderBody();
        var separator = this.props.separator ? this.renderSeparator() : null;
        var topSeparator = this.props.topSeparator ? this.renderSeparator() : null;

        if(this.props.type !== OEGroupControl.type.fullCollapsable)  {
            return (
                <div className={'group-control ' + this.props.className}>
                    {topSeparator}
                    <div>
                        {header} 
                        <Collapse isOpen={this.props.type === OEGroupControl.type.userCollapsable ? !this.state.collapsed : !this.props.collapsed}>
                            {body}
                        </Collapse>
                    </div>
                    {separator}
                </div>
            );
        } else {
            return (
                <div className={'group-control ' + this.props.className}>
                    <div> 
                        <Collapse isOpen={this.props.type === OEGroupControl.type.userCollapsable ? !this.state.collapsed : !this.props.collapsed}>
                            {topSeparator}
                            {header}
                            {body}
                        </Collapse>
                    </div>
                    {separator}
                </div>
            );
        }
    }

    onHeaderClicked()   {
        this.setState((prevState, props) => { return {collapsed: !prevState.collapsed}; });
    }
}

OEGroupControl.type = {
    standard: 0,
    userCollapsable: 1,
    fullCollapsable: 2
};

OEGroupControl.defaultProps = {
    className: '',
    headerClassName: '',
    bodyClassName: '',
    title: '',
    type: OEGroupControl.type.standard,
    collapsed: false,
    separator: false,
    topSeparator: false
};

OEGroupControl.propTypes = {
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.number,
    collapsed: PropTypes.bool,
    separator: PropTypes.bool,
    topSeparator: PropTypes.bool
};

export class OEControl extends React.PureComponent {
    render() {
        return !this.props.title ? <div className={'control-without-label ' + this.props.className}>{this.props.children}</div> :
            <div className={'control ' + this.props.className}>
                <div className="label">{this.props.title}</div>
                <div className="control-widget">{this.props.children}</div>
            </div>;
    }
}

OEControl.defaultProps = {
    className: ''
};

OEControl.propTypes = {
    className: PropTypes.string
};

export class OEToggleUnconnected extends React.PureComponent {
    render() {
        return (
            <Toggle
                className={'toggle-switch ' + this.props.className}
                onClick={this.props.onClick}
                on={this.props.strings.on}
                off={this.props.strings.off}
                size="small"
                disabled={this.props.disabled}
                active={this.props.active}
            />
        );
    }
}

OEToggleUnconnected.defaultProps = {
    className: '',
    strings: {
        on: 'On',
        off: 'Off'
    },
    disabled: false,
    active: true,
    onClick: function(){}
};

OEToggleUnconnected.propTypes = {
    className: PropTypes.string,
    strings: PropTypes.shape({
        on: PropTypes.string,
        off: PropTypes.string
    }),
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func
};

export const OEToggle = connectModuleEnv((env) => {
    return {
        strings: env.strings.toggle
    };
})(OEToggleUnconnected);

export class OEToggleControl extends React.PureComponent {
    render() {
        return (
            <OEControl className={this.props.className} title={this.props.title}>
                <OEToggle
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                    active={this.props.active}
                />
            </OEControl>
        );
    }
}

OEToggleControl.defaultProps = {
    className: '',
    title: null,
    disabled: false,
    active: true,
    onClick: function(){}
};

OEToggleControl.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func
};

export class OESlider extends React.Component {

    shouldComponentUpdate() {
        return false;
    }

    componentDidMount() {
        this.$node = $(this.refs.slider);

        this.$node.slider({
            disabled: this.props.disabled, 
            min: this.props.min, 
            max: this.props.max, 
            step: this.props.step, 
            value: this.props.value, 
            animate: this.props.animate,
            start: (event, ui) => this.onStart(event,ui),
            slide: (event, ui) => this.onSlide(event,ui),
            stop: (event, ui) => { this.onSlide(event,ui); this.onEnd(event,ui); }
        });

        let $handle = this.$node.children('span.ui-slider-handle');
        $handle.append('<span class="' + $handle.attr('class') + '"></span>');
    }

    componentWillUnmount() {
        this.$node.slider('destroy');
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.disabled !== this.props.disabled)     this.$node.slider('option', 'disabled', nextProps.disabled);
        if(nextProps.min !== this.props.min)     this.$node.slider('option', 'min', nextProps.min);
        if(nextProps.max !== this.props.max)     this.$node.slider('option', 'max', nextProps.max);
        if(nextProps.step !== this.props.step)     this.$node.slider('option', 'step', nextProps.step);
        if(nextProps.animate !== this.props.animate)     this.$node.slider('option', 'animate', nextProps.animate);
        if(nextProps.value !== this.props.value)     this.$node.slider('option', 'value', nextProps.value);
        if(nextProps.className != this.props.className)    {
            this.$node.attr('class', nextProps.className);
        }
    }

    onStart(event, ui)  {
        this.props.onStart(ui.value);
    }

    onSlide(event, ui)  {
        this.props.onSlide(ui.value);
    }

    onEnd(event, ui)  {
        this.props.onEnd(ui.value);
    }

    render() {
        return (
             <div className={this.props.className} ref="slider"/>
        );
    }
}

OESlider.defaultProps = {
    className: '',
    disabled: false,
    min: 0,
    max: 100,
    step: 1,
    value: 0,
    animate: true,
    onStart: function(value){},
    onSlide: function(value){},
    onEnd: function(value){}
};

OESlider.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    animate: PropTypes.bool,
    onStart: PropTypes.func,
    onSlide: PropTypes.func,
    onEnd: PropTypes.func
};

export class OESliderControl extends React.PureComponent {
    render() {
        return (
            <OEControl className={this.props.className} title={this.props.title}>
                <OESlider 
                    className="slider"
                    disabled={this.props.disabled}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    value={this.props.value}
                    animate={this.props.animate}
                    onStart={this.props.onStart}
                    onSlide={this.props.onSlide}
                    onEnd={this.props.onEnd}
                />
            </OEControl>
        );
    }
}

OESliderControl.defaultProps = {
    className: '',
    title: null,
    disabled: false,
    min: 0,
    max: 100,
    step: 1,
    value: 0,
    animate: true,
    onStart: function(value){},
    onSlide: function(value){},
    onEnd: function(value){}
};

OESliderControl.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    animate: PropTypes.bool,
    onStart: PropTypes.func,
    onSlide: PropTypes.func,
    onEnd: PropTypes.func
};

export class OESegment extends React.PureComponent {

    onClick(key)   {
        this.props.onClick(key);
    }

    render() {

        const items = this.props.entries.map((entry) =>
            <Button 
                key={entry.key} 
                active={entry.key === this.props.chosenKey} 
                disabled={this.props.disabled}
                onClick={() => this.onClick(entry.key)}
            >
                {entry.content}
            </Button>
        );

        return (
            <ButtonGroup>{items}</ButtonGroup>
        );
    }
}

OESegment.defaultProps = {
    disabled: false,
    chosenKey: '',
    entries: [],
    onClick: function(key){}
};

OESegment.propTypes = {
    disabled: PropTypes.bool,
    entries: PropTypes.array,
    onClick: PropTypes.func
};

export class OEDropdown extends React.PureComponent {

    constructor(props) {
        super(props);

        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_
        };

        this.toggle = this.toggle.bind(this);
    }

    setOpen(open)   {
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
    }

    isOpen()    {
        return this.isOpen_;
    }

    toggle() {
        this.setOpen(!this.isOpen());
    }

    onClick(key, userData)   {
        this.props.onClick(key, userData);
    }

    render() {

        const items = this.props.entries.map((entry) =>
            <DropdownItem
                key={entry.key}
                active={entry.key === this.props.chosenKey}
                disabled={this.props.disabled}
                onClick={() => this.onClick(entry.key, this.props.userData)}
            >
                {entry.content}
            </DropdownItem>
        );

        return (
            <Dropdown className={this.props.className} disabled={this.props.disabled} isOpen={this.state.isOpen && !this.props.disabled} toggle={this.toggle}>
                <DropdownToggle className={'d-flex w-100 justify-content-between ' + 'dropdown-toggle ' + this.props.toggleClassName} caret disabled={this.props.disabled}>
                    {this.props.title}
                </DropdownToggle>
                <DropdownMenu className="w-100">
                    {items}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

OEDropdown.defaultProps = {
    className: '',
    toggleClassName: '',
    title: null,
    disabled: false,
    chosenKey: '',
    entries: [],
    onClick: (key) => {}
};

OEDropdown.propTypes = {
    className: PropTypes.string,
    toggleClassName: PropTypes.string,
    disabled: PropTypes.bool,
    entries: PropTypes.array,
    onClick: PropTypes.func
};

export class OECustomDropdown extends React.PureComponent {

    constructor(props) {
        super(props);
    
        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_
        };

        this.onClick = this.onClick.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    setOpen(open)   {
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
    }

    isOpen()    {
        return this.isOpen_;
    }

    toggle() {
        this.setOpen(!this.isOpen());
    }

    onClick(key)   {
        if(this.props.onClick) this.props.onClick(key);
        if(typeof(this.props.isOpen) !== 'boolean') {
            this.setOpen(false);
        }
    }

    onToggle()  {
        if(this.props.onToggle)  this.props.onToggle(this.state.isOpen);
        this.toggle();
    }

    findActiveEntry(entries, key) {
        for(let i = 0; i < entries.length; i++) {
            if(entries[i].key === key) return entries[i];
            const entry = this.findActiveEntry(entries[i].children, key);
            if(entry) return entry;
        }
    }
    
    renderToggle(isOpen)    {
        const activeEntry = this.findActiveEntry(this.props.entries, this.props.chosenKey);

        let className = this.props.disabled ? ((this.props.disabledToggleClassName || this.props.toggleClassName) || (this.props.disabledItemClassName || this.props.itemClassName)) : (this.props.toggleClassName || this.props.itemClassName);
        className = (this.props.disabled ? 'disabled' : '') + (className ? ' ' + className : '');

        const style = this.props.disabled ? ((this.props.disabledToggleStyle || this.props.toggleStyle) || (this.props.disabledItemStyle || this.props.itemStyle)) : (this.props.toggleStyle || this.props.itemStyle);

        return (
            <a
                className={'item toggle ' + className}
                style={style}
                onClick={this.onToggle}
            >
                <div className={'rot-toggle ' + (isOpen ? 'rot-rotate-toggle' : '')}>
                    <OEIcon code={OEIconCodes.caretRight}/>
                </div>
                <div className='item-content'>
                    {activeEntry ? activeEntry.content : null}
                </div>
            </a>
        );
    }

    render() {
        const isOpen = !this.props.disabled && (typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen);
        const style = this.props.disabled ? (this.props.disabledStyle || this.props.style) : this.props.style;
        const className = 'oe-dropdown ' + this.props.subOrientation + ' ' + (this.props.disabled ? 'disabled ' : '') + (isOpen ? 'open ' : '') + this.props.className;
                    
        return (
            <div 
                className={className}
                style={style}
            >
                {this.renderToggle(isOpen)}

                <OECustomDropdownMenu
                    chosenKey={this.props.chosenKey}
                    entries={this.props.entries}
                    onClick={this.onClick}
                    itemClassName={this.props.itemClassName}
                    disabledItemClassName={this.props.disabledItemClassName}
                    activeItemClassName={this.props.activeItemClassName}
                    itemStyle={this.props.itemStyle}
                    disabledItemStyle={this.props.disabledItemStyle}
                    activeItemStyle={this.props.activeItemStyle}
                />
            </div>
        );
    }
}

OECustomDropdown.subOrientation = {
    right: 'sub-orientation-right',
    left: 'sub-orientation-left'
};

OECustomDropdown.defaultProps = {
    className: '',
    style: null,
    disabled: false,
    chosenKey: null,
    entries: [],
    subOrientation: OECustomDropdown.subOrientation.right
};

OECustomDropdown.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    entries: PropTypes.array,
    subOrientation: PropTypes.oneOf([OECustomDropdown.subOrientation.right, OECustomDropdown.subOrientation.left]),
    onToggle: PropTypes.func,
    onClick: PropTypes.func,
    toggleClassName: PropTypes.string,
    disabledToggleClassName: PropTypes.string,
    toggleStyle: PropTypes.object,
    disabledToggleStyle: PropTypes.object,
    itemClassName: PropTypes.string,
    disabledItemClassName: PropTypes.string,
    activeItemClassName: PropTypes.string,
    itemStyle: PropTypes.string,
    disabledItemStyle: PropTypes.object,
    activeItemStyle: PropTypes.object,
};

export class OECustomDropdownMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSubKey: -1
        }
        
        this.renderEntry = this.renderEntry.bind(this);
    }

    selectSubMenu(key) {
        this.setState({activeSubKey: this.props.entries.findIndex(entry => entry.key === key) < 0 ? -1 : key});
    }

    unselectSubMenu() {
        //this.setState({activeSubKey: -1});
    }

    renderEntry(entry, index)    {
        const isActive = entry.key === this.props.chosenKey;
        
        let className = this.props.disabled ? (this.props.disabledItemClassName || this.props.itemClassName) : (isActive ? (this.props.activeItemClassName || this.props.itemClassName) : this.props.itemClassName);
        className = (index == 0 ? 'first ' : '') + (this.props.disabled ? 'disabled' : (isActive ? 'active' : '')) + (className ? ' ' + className : '');

        const style = this.props.disabled ? (this.props.disabledItemStyle || this.props.itemStyle) : (isActive ? (this.props.activeItemStyle || this.props.itemStyle) : this.props.itemStyle);

        return (
            <div
                onMouseEnter={() => this.selectSubMenu(entry.key)}
                onMouseLeave={() => this.unselectSubMenu()}
                key={entry.key}
            >
                {entry.key !== this.state.activeSubKey ? null : 
                    <OECustomDropdownMenu
                        disabled={this.props.disabled}
                        chosenKey={this.props.chosenKey}
                        entries={entry.children}
                        onClick={this.props.onClick}
                        itemClassName={this.props.itemClassName}
                        disabledItemClassName={this.props.disabledItemClassName}
                        activeItemClassName={this.props.activeItemClassName}
                        itemStyle={this.props.itemStyle}
                        disabledItemStyle={this.props.disabledItemStyle}
                        activeItemStyle={this.props.activeItemStyle}
                    />
                }

                <a
                    className={'item ' + className}
                    style={style}
                    onClick={this.props.disabled || entry.children.length !== 0 ? null : () => this.props.onClick(entry.key)}
                >
                    <div className='item-content-sub-menu'>
                        {entry.content}
                    </div>
                </a>

            </div>
        );
    }

    render() {
        const items = this.props.entries.map(this.renderEntry);
        const style = this.props.disabled ? (this.props.disabledStyle || this.props.style) : this.props.style;
                    
        return (
            <div
                className={'menu ' + (this.props.disabled ? 'disabled' : '') + ' ' + this.props.className}
                style={style}
            >
                {items}
            </div>
        );
    }
}

OECustomDropdownMenu.defaultProps = {
    className: '',
    style: null,
    disabled: false,
    chosenKey: null,
    entries: [],
    onClick: (key) => {}
};

OECustomDropdownMenu.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    entries: PropTypes.array,
    onClick: PropTypes.func,
    itemClassName: PropTypes.string,
    disabledItemClassName: PropTypes.string,
    activeItemClassName: PropTypes.string,
    itemStyle: PropTypes.string,
    disabledItemStyle: PropTypes.object,
    activeItemStyle: PropTypes.object,
};

export class OEColorBtn extends React.Component {

    constructor(props) {
        super(props);

        this.vChoosenColor = null;
        this.vChoosenStrColor = null;
        this.lastColor = null;

        this.state = {ref: null};

        this.onRef = this.onRef.bind(this);
    }

    componentDidMount() {
        this.vChoosenColor = this.props.color;
        this.vChoosenStrColor = OEColorHelper.vColorToStr(this.props.color);
        this.lastColor = tinycolor(this.vChoosenStrColor);
    }

    componentWillUnmount() {
        this.onRef()
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.children !== this.props.children || nextState.ref !== this.state.ref;
    }

    spectrumSrcColorAlpha(color, props) {
        let props_ = props || this.props;
        if(props_.showAlpha) return color;
        let rgb = color.toRgb(); rgb.a = 1;
        return tinycolor(rgb);
    }

    spectrumDstColorAlpha(color, props) {
        let props_ = props || this.props;
        if(props_.showAlpha) return color;
        let rgb = color.toRgb(); rgb.a = typeof(props_.color.w) === 'number' ? props_.color.w : 1;
        return tinycolor(rgb);
    }

    componentWillReceiveProps(nextProps) {

        if(!OEToolbox.shallowEqual(nextProps.color, this.props.color) || nextProps.showAlpha != this.props.showAlpha)     {
            this.vChoosenColor = nextProps.color;
            this.vChoosenStrColor = OEColorHelper.vColorToStr(nextProps.color);
            this.lastColor = tinycolor(this.vChoosenStrColor);
            this.$node.spectrum('set', this.spectrumSrcColorAlpha(this.lastColor, nextProps));
        }

        if(!OEToolbox.shallowEqual(nextProps.defaultColor, this.props.defaultColor))     {
            let paletteColors = [this.spectrumSrcColorAlpha(tinycolor(OEColorHelper.vColorToStr(nextProps.defaultColor), nextProps))];
            this.$node.spectrum('option', 'palette', paletteColors);
            this.$node.spectrum('option', 'selectionPalette', paletteColors);
        }

        if(nextProps.showAlpha !== this.props.showAlpha)     {
            this.$node.spectrum('option', 'showAlpha', nextProps.showAlpha);
        }

        if (nextProps.disabled !== this.props.disabled)     {
            if(nextProps.disabled)  {
                this.$node.spectrum('hide');
                this.$node.spectrum('disable');
            } else {
                this.$node.spectrum('enable');
            }
        }

        if(nextProps.className !== this.props.className)    {
            this.$node.attr('class', nextProps.className);
        }
    }

    onRef(ref)  {
        if(this.ref === ref) return;

        if(this.ref)   {
            this.$node.spectrum('destroy');
            this.$node = null;
            this.ref = null;
        }

        if(!ref) {
            this.setState({ref: null});
            return;
        }

        this.ref = ref;
        this.$node = $(ref);

        let color = this.spectrumSrcColorAlpha(tinycolor(OEColorHelper.vColorToStr(this.props.color)));
        let paletteColors = [this.spectrumSrcColorAlpha(tinycolor(OEColorHelper.vColorToStr(this.props.defaultColor)))];

        this.$node.spectrum({
            disabled: this.props.disabled,
            color: color,
            showButtons: false,
            showPalette: true,
            showAlpha: this.props.showAlpha,
            showSelectionPalette: false,
            palette: paletteColors,
            selectionPalette: paletteColors,
            show: (color) => this.onShow(this.spectrumDstColorAlpha(color)),
            move: (color) => this.onMove(this.spectrumDstColorAlpha(color)),
            change: (color) => this.onChange(this.spectrumDstColorAlpha(color)),
            hide: (color) => this.onHide(this.spectrumDstColorAlpha(color))
        });

        this.setState({ref: ref});
    }

    onColorChanged(color)    {
        let rgb = color.toRgb();
        let colorChanged = true;
        let alphaChanged = true;
        if(this.lastColor)  {
            let rgbLast = this.lastColor.toRgb();
            colorChanged = rgb.r != rgbLast.r || rgb.g != rgbLast.g || rgb.b != rgbLast.b;
            alphaChanged = rgb.a != rgbLast.a;
        }

        this.lastColor = color;

        if(!this.props.separatedDelegation || colorChanged) this.props.onChange(OEColorHelper.toVColor(color));
        if(alphaChanged) this.props.onAlphaChange(rgb.a);
    }

    onShow(color)   {
        this.vChoosenColor = this.props.color;
        this.vChoosenStrColor = OEColorHelper.vColorToStr(this.props.color);
        this.lastColor = color;
        this.props.onShow(OEColorHelper.toVColor(color));
    }

    onMove(color)   {
        this.props.onMove(OEColorHelper.toVColor(color));
        this.onColorChanged(color);
    }

    onChange(color)   {
        this.vChoosenColor = OEColorHelper.toVColor(color);
        this.vChoosenStrColor = color;
        this.onColorChanged(color);
    }

    onHide(color)   {
        this.onColorChanged(tinycolor(this.vChoosenStrColor));
        this.props.onHide(this.vChoosenColor);
    }

    render() {
        return (
            !React.Children.count(this.props.children) ? <input className={this.props.className} ref={this.onRef}/> :
            <React.Fragment>
                <a className={this.props.className} ref={this.onRef}></a>
                {this.state.ref ? ReactDOM.createPortal(this.props.children, this.state.ref) : null }
            </React.Fragment>
        );
    }
}

OEColorBtn.defaultProps = {
    className: '',
    disabled: false,
    color: {x: 0, y: 0, z: 0, w: 0},
    defaultColor: {x: 0, y: 0, z: 0, w: 0},
    showAlpha: false,
    separatedDelegation: false,
    onShow: function(color){},
    onMove: function(color){},
    onChange: function(color){},
    onAlphaChange: function(alpha){},
    onHide: function(color){}
};

OEColorBtn.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    showAlpha: PropTypes.bool,
    separatedDelegation: PropTypes.bool,
    onShow: PropTypes.func,
    onMove: PropTypes.func,
    onChange: PropTypes.func,
    onAlphaChange: PropTypes.func,
    onHide: PropTypes.func
};

export class OEColorControl extends React.PureComponent {

    render() {
        return (
            <OEControl className={this.props.className} title={this.props.title}>
                <OEColorBtn className="color-button"
                    className="slider"
                    disabled={this.props.disabled}
                    color={this.props.color}
                    defaultColor={this.props.defaultColor}
                    onShow={this.props.onShow}
                    onMove={this.props.onMove}
                    onChange={this.props.onChange}
                    onHide={this.props.onHide}
                />
            </OEControl>
        );
    }
}

OEColorControl.defaultProps = {
    className: '',
    title: '',
    disabled: false,
    color: {x: 0, y: 0, z: 0, w: 0},
    defaultColor: {x: 0, y: 0, z: 0, w: 0},
    onShow: function(color){},
    onMove: function(color){},
    onChange: function(color){},
    onHide: function(color){}
};

OEColorControl.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onShow: PropTypes.func,
    onMove: PropTypes.func,
    onChange: PropTypes.func,
    onHide: PropTypes.func
};

export class OEButton extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false
        };

        this.onClicked = this.onClicked.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.onRef = this.onRef.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if((nextProps.disabled && !this.props.disabled) || (!nextProps.tooltip && nextProps.tooltip !== this.props.tooltip))     {
            this.finishTooltip();
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount()  {
        this.finishTooltip();
        this.mounted = false;
    }

    startTooltip()   {
        if(!this.mounted) return;
        if(this.timer || this.props.disabled) return;
        this.timer = setTimeout(() => { this.setState({tooltipOpen: true}); this.timer = null; }, 1000);
    }

    finishTooltip()   {
        if(!this.mounted) return;
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.setState({tooltipOpen: false});
    }

    onClicked(e)   {
        this.finishTooltip();
        if(!this.props.disabled) this.props.onPressed(e, this);
    }

    onMouseEnter()    {
        this.startTooltip();
    }

    onMouseLeave()    {
        this.finishTooltip();
    }

    onRef(ref) {
        this.setState({ref: ref});
    }

    render() {
        let className = this.props.className + (this.props.disabled === true ? ' disabled' : '') + (this.props.activated === true ? ' active' : '');

        let tooltip = !this.state.ref || !this.props.tooltip ? null :
            <Tooltip
                boundariesElement="body"
                placement="right"
                isOpen={this.state.tooltipOpen}
                target={this.state.ref}
                delay={{show: 500, hide: 0}}
            >
                {this.props.tooltip}
            </Tooltip>;

        return (
            <a id={this.props.id} className={className} style={this.props.style} onClick={this.onClicked} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} ref={this.onRef}>
                {this.props.children}
                {tooltip}
            </a>
        );
    }
}

OEButton.defaultProps = {
    id: '',
    className: '',
    disabled: false,
    activated: false,
    onPressed: function(e) {}
};

OEButton.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    activated: PropTypes.bool,
    onPressed: PropTypes.func
};

export class OEButtonControl extends React.PureComponent {

    render() {
        return (
            <OEControl className={this.props.className}>
                <OEButton {...this.props} className="btn"/>
            </OEControl>
        );
    }
}

OEButtonControl.defaultProps = {
    id: '',
    className: '',
    title: '',
    disabled: false,
    activated: false,
    onPressed: function(e) {}
};

OEButtonControl.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    activated: PropTypes.bool,
    onPressed: PropTypes.func
};

export class OECheckbox extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange()   {
        if(this.props.disabled || !this.props.onPressed) return;
        this.props.onPressed(this, this.props.userData);
    }

    render() {
        var className = 'checkbox' + this.props.className + (this.props.disabled === true ? ' disabled' : '');
        return (
            <div className={className}>
                <input type="checkbox" disabled={this.props.disabled} checked={this.props.checked} onChange={this.onChange}/>
            </div>
        );
    }
}

OECheckbox.defaultProps = {
    className: '',
    disabled: false,
    activated: false
};

OECheckbox.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onPressed: PropTypes.func
};

export class OEIcon extends React.PureComponent {
    render() {
        return (
            <div className={'icon font-awesome ' + this.props.className} style={this.props.style}>
                <div>
                    {this.props.code}{this.props.children}
                </div>
            </div>
        );
    }
}

OEIcon.defaultProps = {
    className: '',
    code: ''
};

OEIcon.propTypes = {
    className: PropTypes.string,
    code: PropTypes.string
};

export class OETextField extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onClearButtonPressed = this.onClearButtonPressed.bind(this);
    }

    onChange(evt)   {
        if(this.props.disabled || !this.props.onChange) return;
        this.props.onChange(this, evt.target.value);
    }

    onClearButtonPressed()  {
        if(this.props.onClearButtonPressed) this.props.onClearButtonPressed(this);
        if(this.props.onChange) this.props.onChange(this, '');
    }

    render()    {
        return (
            <div className={'text-field ' + (this.props.clearButton ? 'has-clear-btn ' : '') + (this.props.disabled ? 'disabled ' : '') + (this.props.className ? this.props.className : '')}>
                <div className="container">
                    <input
                        type={this.props.type}
                        spellCheck={this.props.spellCheck}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.onChange}
                    />
                    {!this.props.clearButton ? null :
                    <OEButton className='clear-btn' disabled={this.props.disabled} onPressed={this.onClearButtonPressed}>
                        <OEIcon code={OEIconCodes.textFieldClear}/>
                    </OEButton>
                    }
                </div>
            </div>
        );
    }
}

OETextField.defaultProps = {
    className: '',
    disabled: false,
    type: 'text',
    spellCheck: false,
    placeholder: '',
    value: '',
    clearButton: true
};

OETextField.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    spellCheck: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    clearButton: PropTypes.bool,
    onChange: PropTypes.func,
    onClearButtonPressed: PropTypes.func
};

export class OETextFieldControl extends React.PureComponent {
    render()    {
        const {className, ref, ...rest} = this.props;
        return (
            <OEControl className={'text-field ' + (className ? className : '')}>
                <OETextField
                    type="text"
                    {...rest}
                />
            </OEControl>
        );
    }
}

OETextFieldControl.defaultProps = {
    className: '',
    disabled: false,
    type: 'text',
    spellCheck: false,
    placeholder: '',
    value: '',
    clearButton: true
};

OETextFieldControl.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    spellCheck: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    clearButton: PropTypes.bool,
    onChange: PropTypes.func,
    onClearButtonPressed: PropTypes.func
};

export class OEAnimationControls extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.onFirstBtnPressed = this.onFirstBtnPressed.bind(this);
        this.onSecondBtnPressed = this.onSecondBtnPressed.bind(this);
        this.onProgressSliderStart = this.onProgressSliderStart.bind(this);
        this.onProgressSliderChanged = this.onProgressSliderChanged.bind(this);
        this.onProgressSliderEnd = this.onProgressSliderEnd.bind(this);
    }

    onFirstBtnPressed()  {
        this.props.onStopBtnPressed();
    }

    onSecondBtnPressed()  {
        this.props.onPlayPauseBtnPressed();
    }

    onProgressSliderStart(value)  {
        this.props.onProgressSliderStart(value);
    }

    onProgressSliderChanged(value)  {
        this.props.onProgressSliderChanged(value);
    }

    onProgressSliderEnd(value)  {
        this.props.onProgressSliderEnd(value);
    }

    stepSize()  {
        return Math.max(1e-6, Math.abs(this.props.endTime - this.props.startTime)/1000.0);
    }

    render() {

        var slider = Object.assign({}, OEAnimationControls.defaultProps.slider);
        slider = Object.assign(slider, this.props.slider);

        var control = Object.assign({}, OEAnimationControls.defaultProps.control);
        control = Object.assign(control, this.props.control);

        const firstBtnDisabled = this.props.disabled || control.disabled || (!control.doNotDisableStopBtn && (this.props.mode === OEAnimationControls.Mode.disabled || 
                                    (this.props.mode === OEAnimationControls.Mode.pause && this.props.startTime + 1e-6 >= this.props.time)));

        const secondBtnDisabled = this.props.disabled || control.disabled || (!control.doNotDisablePlayBtn && this.props.mode === OEAnimationControls.Mode.disabled);
        
        const sliderDisabled = this.props.disabled || slider.disabled || this.props.mode === OEAnimationControls.Mode.disabled;

        const modeClass = 'play-mode-' + (this.props.mode === OEAnimationControls.Mode.play ? 'play' : (this.props.mode === OEAnimationControls.Mode.pause ? 'pause' : 'disabled'));
        
        const iconCodes = Object.assign({stop: OEIconCodes.stop, play: OEIconCodes.play, pause: OEIconCodes.pause}, this.props.iconCodes || {});

        return (
            <div className={'animation-controls ' + modeClass + ' ' + this.props.className}>

                {!control.visible ? null :
                    <div className="animation-controls-left-btns">
                        {!control.stopBtnVisible ? null :
                            <OEButton
                                className="transparent-btn animation-controls-btn stop"
                                disabled={firstBtnDisabled}
                                onPressed={this.onFirstBtnPressed}
                            >
                                <OEIcon code={iconCodes.stop}/>
                            </OEButton>
                        }
                        {!control.playBtnVisible ? null :
                            <OEButton
                                className="transparent-btn animation-controls-btn play-pause"
                                disabled={secondBtnDisabled}
                                onPressed={this.onSecondBtnPressed}
                            >
                                <OEIcon code={this.props.mode === OEAnimationControls.Mode.play ? iconCodes.pause : iconCodes.play}/>
                            </OEButton>
                        }
                    </div>
                }

                {!slider.visible ? null :
                    <div className="animation-controls-slider-container">
                        <OESlider className="animation-controls-slider" 
                            disabled={sliderDisabled}
                            min={this.props.startTime}
                            max={this.props.endTime}
                            step={this.stepSize() }
                            value={this.props.time}
                            onStart={this.onProgressSliderStart}
                            onSlide={this.onProgressSliderChanged}
                            onEnd={this.onProgressSliderEnd}
                        />
                    </div>
                }

            </div>
        );
    }
}

OEAnimationControls.Mode = {
    disabled: 0,
    play: 1,
    pause: 2
};

OEAnimationControls.defaultProps = {
    className: '',
    mode: OEAnimationControls.Mode.disabled,
    disabled: false,
    slider: {visible: true, disabled: false},
    control: {visible: true, disabled: false, stopBtnVisible: true, playBtnVisible: true, doNotDisableStopBtn: false, doNotDisablePlayBtn: false},
    time: 0,
    startTime: 0,
    endTime: 1,
    onStopBtnPressed: function(){},
    onPlayPauseBtnPressed: function(){},
    onProgressSliderStart: function(value){},
    onProgressSliderChanged: function(value){},
    onProgressSliderEnd: function(value){}
};

OEAnimationControls.propTypes = {
    className: PropTypes.string,
    mode: PropTypes.number,
    disabled: PropTypes.bool,
    slider: PropTypes.shape({visible: PropTypes.bool, disabled: PropTypes.bool}),
    control: PropTypes.shape({visible: PropTypes.bool, disabled: PropTypes.bool, stopBtnVisible: PropTypes.bool, playBtnVisible: PropTypes.bool, doNotDisableStopBtn: PropTypes.bool, doNotDisablePlayBtn: PropTypes.bool}),
    time: PropTypes.number,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    onStopBtnPressed: PropTypes.func,
    onPlayPauseBtnPressed: PropTypes.func,
    onProgressSliderStart: PropTypes.func,
    onProgressSliderChanged: PropTypes.func,
    onProgressSliderEnd: PropTypes.func
};