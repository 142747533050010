import clone from 'clone';

import {AppMode} from './app-types';
import {OEFeatureAccessTypes, OEViewType, OEMainMenuStdBtnType, OEContextMenuContentType, OETargetMenuDefs, OEStartViewTypes, OECutControllerStyle, OEMediaCenterItemTypes, OEMediaCenterMode, UILevel, OESettingsControllerDefs} from '../lib/oe-types';
import {OEMainMenuBtnAdapterView, OEMainMenuBtnAdapterPostProcess} from './oe-main-menu-btn-adapter';
import {OEDefaultConfigFactory} from './oe-default-configs';
import {OETarget, OEResourceTarget} from '../react-oe/oe-target';
import {OEVersion} from '../react-oe/oe-version';
import {OEToolbox} from '../lib/oe-toolbox';
import {OELicensingAppConfigFactory} from './licensing-start/oe-licensing-app';
import {OEIconCodes} from '../lib/oe-icon-codes';

export default class AppConfig {

    constructor(params) {
        let {target, initial, ...otherParams} = params || {};

        this.fontFamily = 'Roboto,sans-serif';
        this.version = config.version;
        this.target = OETarget.humanSkull;
        this.isLicensingStart = false;
        this.startAppMode = AppMode.play;
        this.logo = null;

        this.module = {
            version: config.version,
            flags: Array.isArray(config.flags) ? config.flags : null,
            uiLayerConfig: OEDefaultConfigFactory.uiLayer(),
            labelsEnabled: OEToolbox.getUrlParameterBool('labelsEnabled', false),
            labelsLatin: OEToolbox.getUrlParameterBool('labelsLatin', false),
            featureAccess: {accessType: OEFeatureAccessTypes.isOne(config.accessType) ? config.accessType : OEFeatureAccessTypes.none},
            link: initial ? OEToolbox.getUrlParameter('link') : null,
            uiLink: initial ? OEToolbox.getUrlParameter('uiLink') : null,
            linkRef: initial ? OEToolbox.getUrlParameter('linkRef') : null,
            uiLinkRef: initial ? OEToolbox.getUrlParameter('uiLinkRef') : null,
            uiStateMC: !initial ? null : {
                visible: OEToolbox.getUrlParameterBool('mcVisible'),
                category: OEToolbox.getUrlParameterInt('category'),
                item: OEToolbox.getUrlParameterInt('item'),
                resourceId: OEToolbox.getUrlParameter('resourceId'),
                subItem: OEToolbox.getUrlParameterInt('subItem'),
                progress: OEToolbox.getUrlParameterFloat('progress')
            }
        };

        let hasLink = typeof(this.module.link) === 'string' || typeof(this.module.uiLink) === 'string' || typeof(this.module.linkRef) === 'string' || typeof(this.module.uiLinkRef) === 'string';

        this.licensingAppConfig = OELicensingAppConfigFactory.defaultConfig();

        //
        let isAmboss = config.version === OEVersion.amboss;
        let isAnatomy = config.version === OEVersion.anatomy;
        let isIonto = config.version === OEVersion.ionto;
        let isEffigos = config.version === OEVersion.effigos;
        let isVisionResearch = config.version === OEVersion.visionResearch;
        let isLicensingStart = config.version === OEVersion.licensingStart || isEffigos;
        let isToxNetz = config.version === OEVersion.toxNetz;
        let isZeissOpti = config.version === OEVersion.zeissOpti;
        let isKind = config.version === OEVersion.kind;
        let isOrtho = config.version === OEVersion.ortho;
        let isCambridgeCell = config.version === OEVersion.cambridgeCell;
        let isPhilipsIcarus = config.version === OEVersion.philipsIcarus;
        let isBayer = config.version === OEVersion.bayer;
        let isLicensing = config.accessType == OEFeatureAccessTypes.licensing;
        
        let isEnglish = config.flags.includes('english');
        let isImport = config.flags.includes('import');

        let noStartView = OEToolbox.getUrlParameterBool('noStartView', false);
        let showMainMenu = OEToolbox.getUrlParameterBool('showMainMenu');
        let isShowMainMenuSet = typeof(showMainMenu) === 'boolean';

        //
        this.isLicensingStart = isLicensingStart;

        //
        let uiLayerConfig = this.module.uiLayerConfig;
        let targetMenuConfig = this.module.uiLayerConfig.targetMenuConfig;
        const MMBtnT = OEMainMenuStdBtnType;

        uiLayerConfig.startViewConfig.showAtStartUp = !noStartView;
        if(isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = showMainMenu;

        uiLayerConfig.widgetConfig.componentController.searchBar = isImport;
        uiLayerConfig.widgetConfig.presentationController.showPresetBtn = isImport;
        uiLayerConfig.widgetConfig.presentationController.showNotesAreInDocumentButton = isImport;

        // some general configs for versions
        if(isIonto) {

            this.target = OETarget.skinMacroIONTO;
            this.resourceTarget = OEResourceTarget.skinIONTO;

            //uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => btn.type !== MMBtnT.presentation);

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.ionto;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            let infoBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.info);
            uiLayerConfig.mainMenuButtons.top.splice(infoBtnIndex, 1, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

            if(isLicensing) {
                let index = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                uiLayerConfig.mainMenuButtons.top.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});

                uiLayerConfig.infoViewConfig.isMaximized = true;
                uiLayerConfig.infoViewConfig.header = false;
                uiLayerConfig.infoViewConfig.tutorials = false;
            }
            
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            uiLayerConfig.widgetConfig.cutController.showHelp = false;

            uiLayerConfig.mediaCenterConfig = OEDefaultConfigFactory.mediaCenter(true);

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManual();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewPDF();

        } else if(isAmboss) {

            this.target = OETarget.humanTrunk;
            this.resourceTarget = isImport ? OEResourceTarget.ambossImport : OEResourceTarget.amboss;

            const isFree = OEToolbox.getUrlParameter('status') === 'free';
            const excludedButtons = isImport ? [MMBtnT.explode] : [MMBtnT.presentation, MMBtnT.note, MMBtnT.arrow, MMBtnT.color, MMBtnT.explode];          
           
            this.target = isFree ? OETarget.humanKnee : OETarget.humanTrunk;
            targetMenuConfig.entries = isFree ? OETargetMenuDefs.amboss.free : (isImport ? OETargetMenuDefs.amboss.import : OETargetMenuDefs.amboss.nested);

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.amboss;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => !excludedButtons.includes(btn.type));
            //uiLayerConfig.mainMenuButtons.top.find((btn) => btn.type === MMBtnT.info).icon = OEIconCodes.infoQuestionMark;
            uiLayerConfig.mainMenuButtons.bottom = uiLayerConfig.mainMenuButtons.bottom.filter((btn) => !excludedButtons.includes(btn.type));

            let infoBtn = uiLayerConfig.mainMenuButtons.top.find((btn) => btn.type === MMBtnT.info);
            infoBtn.adapter = new OEMainMenuBtnAdapterView(OEViewType.start);

            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            uiLayerConfig.contextMenu.content = {type: OEContextMenuContentType.infoBox, showReferences: false};

            uiLayerConfig.widgetConfig.labelController.enabled = false;
            uiLayerConfig.widgetConfig.cutController.style = OECutControllerStyle.minimal;
            uiLayerConfig.widgetConfig.componentController.colorBtnEnabled = isImport;
            uiLayerConfig.widgetConfig.componentController.catSwitchBtnEnabled = isImport;
            uiLayerConfig.widgetConfig.componentController.catActivationBtnEnabled = isImport;

            uiLayerConfig.mediaCenterConfig = OEDefaultConfigFactory.mediaCenter(true);

            let backgroundGroup = uiLayerConfig.widgetConfig.settingsController.controls.find((group) => group.type === OESettingsControllerDefs.controlGroupTypes.background);
            backgroundGroup.controls = backgroundGroup.controls.filter((control) => control.type !== OESettingsControllerDefs.controlTypes.vpGizmo);

            this.fontFamily = 'Lato,sans-serif';
            
         } else if(isAnatomy) {

            this.target = OETarget.humanSkull;
            this.resourceTarget = OEResourceTarget.anatomy;

            targetMenuConfig.entries = OETargetMenuDefs.anatomy;

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.anatomy;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            let infoBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.info);
            uiLayerConfig.mainMenuButtons.top.splice(infoBtnIndex, 1, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

            if(isLicensing) {
                let index = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                uiLayerConfig.mainMenuButtons.top.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});

                uiLayerConfig.infoViewConfig.isMaximized = true;
                uiLayerConfig.infoViewConfig.header = false;
                uiLayerConfig.infoViewConfig.tutorials = false;
            }

            uiLayerConfig.contextMenu.content = {type: OEContextMenuContentType.infoBox, showReferences: false};

            uiLayerConfig.widgetConfig.cutController.showHelp = false;

            uiLayerConfig.mediaCenterConfig = OEDefaultConfigFactory.mediaCenter(true);

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManual();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewPDF(true);

         } else if(isEffigos) {

            targetMenuConfig.entries = OETargetMenuDefs.app;
            
        } else if(isToxNetz) {

            this.target = OETarget.toxNetz;
            targetMenuConfig.entries = OETargetMenuDefs.toxNetzAll;

        } else if(isVisionResearch) {
        
            this.target = OETarget.sensorySystems;
            targetMenuConfig.enabled = false;
        
        } else if(isZeissOpti)  {

            this.target = OETarget.zeissOpti2;
            this.resourceTarget = OEResourceTarget.zeiss;
            uiLayerConfig.startViewConfig.type = OEStartViewTypes.zeissOpti;
            uiLayerConfig.startViewConfig.targetViewShowedAtStartFlag = true;
            uiLayerConfig.showRightTopBar = false;
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManualVideoTutorial();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewPDF();

        } else if(isKind) {

            this.target = OETarget.earMacro;
            targetMenuConfig.entries = OETargetMenuDefs.kind;

        } else if(isOrtho) {
            
            this.target = OETarget.humanSkull;
            targetMenuConfig.entries = OETargetMenuDefs.ortho.nested;
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

        } else if(isCambridgeCell)  {

            this.target = OETarget.animalCell; 
            this.resourceTarget = OEResourceTarget.cell;

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.cambridgeCell;
            let btnsToRemove = [MMBtnT.cut];
            uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => btnsToRemove.indexOf(btn.type) < 0);

            let infoBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.info);
            uiLayerConfig.mainMenuButtons.top[infoBtnIndex].adapter = new OEMainMenuBtnAdapterView(OEViewType.start);
            uiLayerConfig.mainMenuButtons.top.splice(infoBtnIndex + 1, 0, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});
        
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            uiLayerConfig.contextMenu.content = {type: OEContextMenuContentType.infoBox, showReferences: false};

            uiLayerConfig.widgetConfig.labelController.enabled = false;

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManualVideoTutorial();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewCambridgeCell();

        } else if(isPhilipsIcarus)  {

            this.target = OETarget.philipsIcarus;
            this.resourceTarget = OEResourceTarget.philipsIcarusAnatomy;
            uiLayerConfig.showRightTopBar = false;
            
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;
        } else if(isBayer) {

            this.target = OETarget.eyeMacro;
            this.resourceTarget = OEResourceTarget.eyeRes;
            targetMenuConfig.entries = OETargetMenuDefs.eye2;
        }

        // set final target
        let info = {targetChoiceTag: 0};
        this.target = this.selectTarget(target, info) || this.target;

        if(config.target == OETarget.eye) {
            this.resourceTarget = OEResourceTarget.eyeRes;
        }

        if(!this.resourceTarget)    this.resourceTarget = OEResourceTarget.isPureResourceTarget(config.target) ? config.target : this.target;

        if(this.resourceTarget == OEResourceTarget.sensorySystemsEar || this.resourceTarget == OEResourceTarget.sensorySystemsEye)  {
            this.resourceTarget = OEResourceTarget.sensorySystems;
        }

        if(this.target == OEResourceTarget.zeiss)   {
            this.target = OETarget.zeissOpti2;
        } else if(this.target == OEResourceTarget.hoof)   {
            this.target = OETarget.hoofWeb;
        } else if(this.target == OEResourceTarget.cell)   {
            this.target = OETarget.animalCell;
        } else if(this.target == OEResourceTarget.amboss)   {
            this.target = OETarget.humanTrunk;
        } else if(this.target == OEResourceTarget.anatomy)   {
            this.target = OETarget.humanSkull;
        } else if(this.target == OEResourceTarget.philipsIcarusAnatomy)   {
            this.target = OETarget.philipsIcarus;
        } else if(this.target == OEResourceTarget.skinIONTO)   {
            this.target = OETarget.skinMacroIONTO;
        } else if(this.target == OEResourceTarget.eyeRes)   {
            this.target = OETarget.eye;
        }

        // set app mode at start up
        this.startAppMode = isLicensingStart && info.targetChoiceTag == 0 ? AppMode.start : AppMode.play;

        // decide if we want to show all available targets in target menu or only micro/macro switch when appropriate
        let hasConfigTarget = typeof(config.target) === 'string' && config.target !== '';
        let hasFullTargetMenu = !(hasConfigTarget || isIonto || isLicensingStart || isZeissOpti || isCambridgeCell);

        if(!hasFullTargetMenu)  {

            // choose target menu config with respect to current target
            targetMenuConfig.enabled = false;
            switch (this.target) {
                case OETarget.earMacro:
                case OETarget.earMacroAmboss:
                case OETarget.earMicro:
                case OETarget.sensorySystemsEar:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.ear;
                    break;
                case OETarget.skinMacroIONTO:
                case OETarget.skinMicroIONTO:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.skin;
                    break;
                case OETarget.hoofWeb:
                case OETarget.hoofVessels:
                case OETarget.hoofDynamics:
                case OETarget.hoofMicro:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.hoof;
                    break;
                case OETarget.animalCell:
                case OETarget.plantCell:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.cell;
                    break;

// @ADD_TARGET_BEGIN
//              case OETarget.TARGET_cC': 
//                  targetMenuConfig.enabled = true;
//                  ...
// @ADD_TARGET_END
    
            }

            if(config.target == OETarget.eye || config.target == OEResourceTarget.eyeRes) {
                targetMenuConfig.enabled = true;
                targetMenuConfig.entries = OETargetMenuDefs.eye;
            }
        }

        //
        if(this.target == OETarget.eye) {
            this.target = OETarget.eyeMacro
        }

        // choose logo for start and info view
        let logo = this.logoFor(this.target, config.version);
        uiLayerConfig.startViewConfig.logo = logo;
        uiLayerConfig.infoViewConfig.logo = logo;

        // further ui configs with respect to choosen target
        //
        // -> for zeiss targets
        let isTargetZeissOpti = this.target === OETarget.zeissOpti || this.target === OETarget.zeissOpti2;
        let isTargetZeiss = isTargetZeissOpti || this.target === OETarget.zeissUV;
        let isZeiss = isTargetZeiss || isZeissOpti;
        if(isZeiss)   {

            let mmBtns = OEDefaultConfigFactory.mainMenuButtons();
            let infoBtnIndex = mmBtns.top.findIndex((btn) => btn.type === MMBtnT.info);
            mmBtns.top.splice(infoBtnIndex, 1, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

            let topBtnsRestricted = OEDefaultConfigFactory.mainMenuButtonArray([MMBtnT.home, MMBtnT.label, MMBtnT.color, MMBtnT.mediaCenter, MMBtnT.screenshot, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)}, MMBtnT.settings]);
            
            if(isLicensing) {
                let index = mmBtns.top.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                mmBtns.top.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});

                index = topBtnsRestricted.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                topBtnsRestricted.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});
            }

            if(isZeissOpti) {
                if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;
                uiLayerConfig.widgetConfig.cutController.showHelp = false;

                mmBtns.top.find(btn => btn.type === MMBtnT.color).tooltip = 'main_menu_tooltip_stylized';
                topBtnsRestricted.find(btn => btn.type === MMBtnT.color).tooltip = 'main_menu_tooltip_stylized';

                //mmBtns.top.splice(colorBtnIndex, 1, {type: 'postProcess', enabled: true, icon: OEIconCodes.zeissOpti.pencil, tooltip: 'main_menu_tooltip_stylized', adapter: new OEMainMenuBtnAdapterPostProcess()})
                //topBtnsRestricted.splice(colorBtnIndex, 1, {type: 'postProcess', enabled: true, icon: OEIconCodes.zeissOpti.pencil, tooltip: 'main_menu_tooltip_stylized', adapter: new OEMainMenuBtnAdapterPostProcess()})

                if(isTargetZeiss)    {

                    uiLayerConfig.mainMenuButtons.top = topBtnsRestricted.filter(btn => btn.type !== MMBtnT.mediaCenter && !(isTargetZeissOpti && btn.type === MMBtnT.color));
                    uiLayerConfig.mainMenuButtons.bottom = [];
                    uiLayerConfig.widgetConfig.labelController.enabled = false;
                    uiLayerConfig.widgetConfig.componentController.colorPurityControl = false;
                    
                    let bgGroup = uiLayerConfig.widgetConfig.settingsController.controls.find(group => group.type === OESettingsControllerDefs.controlGroupTypes.background);
                    bgGroup.controls = bgGroup.controls.filter(control => control.type !== OESettingsControllerDefs.controlTypes.vpGizmo);

                    this.logo = 'images/zeiss_logo.png'

                } else {
                    // add ui level entry to settings controller for non zeiss targets
                    uiLayerConfig.widgetConfig.settingsController.controls.push(clone(OESettingsControllerDefs.uiControlAdvanced));

                    targetMenuConfig[UILevel.advanced] = clone(targetMenuConfig);
                    //if(this.target === OETarget.eyeMacro)   targetMenuConfig.enabled = false;

                    // main menu config for ui level - advanced
                    uiLayerConfig.mainMenuButtons[UILevel.advanced] = mmBtns;

                    // main menu config for ui level - std 
                    uiLayerConfig.mainMenuButtons.top = topBtnsRestricted;
                    uiLayerConfig.mainMenuButtons.bottom = [];

                    // widget config for ui level
                    uiLayerConfig.widgetConfig.labelController.enabled = false;
                    uiLayerConfig.widgetConfig.labelController[UILevel.advanced] = OEDefaultConfigFactory.labelController();

                    uiLayerConfig.widgetConfig.componentController.enabled = false;
                    uiLayerConfig.widgetConfig.componentController.colorPurityControl = false;
                    uiLayerConfig.widgetConfig.componentController[UILevel.advanced] = OEDefaultConfigFactory.componentController({searchBar: isImport, colorPurityControl: false});
                }

                //uiLayerConfig.widgetConfig.settingsController.controls.push(clone(OESettingsControllerDefs.defaultTutorControl));

                let bgGroup = uiLayerConfig.widgetConfig.settingsController.controls.find(group => group.type === OESettingsControllerDefs.controlGroupTypes.graphics);
                bgGroup.controls = bgGroup.controls.filter(control => control.type !== OESettingsControllerDefs.controlTypes.rendererPreset);

            } else {

                if(isTargetZeissOpti)   {

                    uiLayerConfig.mainMenuButtons.top = topBtnsRestricted;
                    uiLayerConfig.mainMenuButtons.bottom = [];

                } else { // target is zeiss uv here
                    
                    // main menu config for legacy uv version 
                    uiLayerConfig.mainMenuButtons.top = OEDefaultConfigFactory.mainMenuButtonArray([MMBtnT.home, MMBtnT.tree, MMBtnT.label, MMBtnT.mediaCenter, MMBtnT.cut, MMBtnT.presentation, MMBtnT.screenshot, MMBtnT.info, MMBtnT.settings]);
                }
            }

            //
            uiLayerConfig.widgetConfig.cutController.enabled = !(isZeissOpti && isTargetZeiss);

            //
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && isZeissOpti;
            
            uiLayerConfig.infoViewConfig.logo = null;

            if(isZeissOpti || isTargetZeissOpti)  {
                uiLayerConfig.infoViewConfig.isMaximized = true;
                uiLayerConfig.infoViewConfig.header = false;
                uiLayerConfig.infoViewConfig.tutorials = false;
            } else {
                uiLayerConfig.infoViewConfig.mediaContent = ['info_view_media_video_1_youtube', 'info_view_media_video_2_youtube'];
            }

            // media center config changes
            if((isZeissOpti && isTargetZeiss) || (!isZeissOpti && isTargetZeissOpti))   {
                uiLayerConfig.mediaCenterConfig.mode = OEMediaCenterMode.bottomBar;
                uiLayerConfig.mediaCenterConfig.hideBottomBarControlsForItems = [OEMediaCenterItemTypes.none, OEMediaCenterItemTypes.animation, OEMediaCenterItemTypes.images];
                uiLayerConfig.mediaCenterConfig.hideSliderForItems = [OEMediaCenterItemTypes.animation, OEMediaCenterItemTypes.images];
            }
        }

        // -> for animal cell target
        /*
        if(this.target === OETarget.animalCell)   {
            let btnsToRemove = [MMBtnT.cut];
            uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => btnsToRemove.indexOf(btn.type) < 0);
        }
        */

        // -> for icarus
        if(isPhilipsIcarus)  {
            targetMenuConfig.enabled = this.target != OETarget.philipsIcarus;
            targetMenuConfig.entries = OETargetMenuDefs.philipsIcarus;

            uiLayerConfig.widgetConfig.labelController.enabled = false;

            uiLayerConfig.widgetConfig.showHelp = false;
            uiLayerConfig.widgetConfig.cutController.style = OECutControllerStyle.minimal;

            uiLayerConfig.startViewConfig.showAtStartUp = false;
            
            if(this.target == OETarget.philipsIcarus)   {

                uiLayerConfig.mediaCenterConfig.mode = OEMediaCenterMode.bottomBar;
                uiLayerConfig.mediaCenterConfig.hideBottomBarControlsForItems = [OEMediaCenterItemTypes.none, OEMediaCenterItemTypes.animation, OEMediaCenterItemTypes.images];
                uiLayerConfig.mediaCenterConfig.hideSliderForItems = [OEMediaCenterItemTypes.animation, OEMediaCenterItemTypes.images];

                uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => ![MMBtnT.presentation, MMBtnT.mediaCenter, MMBtnT.arrow, MMBtnT.note, MMBtnT.substructure, MMBtnT.explode, MMBtnT.info].includes(btn.type));

                if(isLicensing) {
                    let btnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings);
                    topBtnsRestricted.splice(btnIndex, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});
                }

                //let btnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings);
                //uiLayerConfig.mainMenuButtons.top.splice(btnIndex - 1, 0, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

                this.logo = 'images/philips_logo.png'

                let bgGroup = uiLayerConfig.widgetConfig.settingsController.controls.find(group => group.type === OESettingsControllerDefs.controlGroupTypes.background);
                bgGroup.controls = bgGroup.controls.filter(control => control.type !== OESettingsControllerDefs.controlTypes.vpGizmo);

            } else {

                uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter(btn => btn.type !== MMBtnT.info || isLicensing);

                if(isLicensing) {
                    let infoBtn = uiLayerConfig.mainMenuButtons[UILevel.advanced].top.find(btn => btn.type === MMBtnT.info);
                    infoBtn.icon = OEIconCodes.key;
                    infoBtn.tooltip = 'main_menu_tooltip_license';
                }
            }
        }

        // customer logo
        let customerParam = OEToolbox.getUrlParameter('customer');
        if (typeof(customerParam) === 'string') {
            switch (customerParam) {
                case 'med-el': this.logo = 'images/logo_medel.png'; break;
                default: this.logo = 'images/logo_' + customerParam + '.png';
            }
        }
    }

    selectTarget(target, info) {
        let isAmboss = config.version === OEVersion.amboss;

        info.targetChoiceTag = 0;

        // target is already choosen so just return
        if(typeof(target) === 'string') return target;
        
        // if a target is provided as url parameter with name 'target', then translate this target to a oe target and return
        let targetUrlParam = OEToolbox.getUrlParameter('target');
        if(typeof(targetUrlParam) === 'string') {
            let ret;

            switch (targetUrlParam) {
                case 'earMacro': ret = isAmboss ? OETarget.earMacroAmboss : OETarget.earMacro; break;
                case 'earMicro': ret = OETarget.earMicro; break;
                case 'earMacroAmboss': ret = OETarget.earMacroAmboss; break;
                case 'earMicroAmboss': ret = OETarget.earMicro; break;
                case 'sensorySystemsEar': ret = OETarget.sensorySystemsEar; break;
                case 'skinMacro': ret = OETarget.skinMacroIONTO; break;
                case 'skinMicro': ret = OETarget.skinMicroIONTO; break;
                case 'eyeMacro': ret = OETarget.eyeMacro; break;
                case 'eyeMicro': ret = OETarget.eyeMicro; break;
                case 'eyeCornea': ret = OETarget.eyeCornea; break;
                case 'sensorySystemsEye': ret = OETarget.sensorySystemsEye; break;
                case 'eye': ret = OEResourceTarget.eye; break;
                case 'eyeRes': ret = OEResourceTarget.eyeRes; break;
                case 'skin': ret = OETarget.skinMacroIONTO; break;
                case 'ear': ret = isAmboss ? OETarget.earMacroAmboss : OETarget.earMacro; break;
                case 'snail': ret = OETarget.snail; break;
                case 'humanSkull': ret = OETarget.humanSkull; break;
                case 'humanCranialNerves': ret = OETarget.humanCranialNerves; break;
                case 'toxNetz': ret = OETarget.toxNetz; break;
                case 'zeissOpti': ret = OETarget.zeissOpti; break;
                case 'zeissOpti2': ret = OETarget.zeissOpti2; break;
                case 'zeissUV': ret = OETarget.zeissUV; break;
                case 'zeiss': ret = OEResourceTarget.zeiss; break;
                case 'sensorySystems': ret = OETarget.sensorySystems; break;
                case 'hoofWeb': ret = OETarget.hoofWeb; break;
                case 'hoofVessels': ret = OETarget.hoofVessels; break;
                case 'hoofDynamics': ret = OETarget.hoofDynamics; break;
                case 'hoofMicro': ret = OETarget.hoofMicro; break;
                case 'hoof': ret = OEResourceTarget.hoof; break;
                case 'humanArm': ret = OETarget.humanArm; break;
                case 'humanArmPathways': ret = OETarget.humanArmPathways; break;
                case 'humanUpperArmElbow': ret = OETarget.humanUpperArmElbow; break;
                case 'humanForearm': ret = OETarget.humanForearm; break;
                case 'humanShoulder': ret = OETarget.humanShoulder; break;
                case 'humanElbow': ret = OETarget.humanElbow; break;
                case 'humanHand': ret = OETarget.humanHand; break;
                case 'humanLeg': ret = OETarget.humanLeg; break;
                case 'humanAnkleFoot': ret = OETarget.humanAnkleFoot; break;
                case 'humanHip2': ret = OETarget.humanHip2; break;
                case 'humanLegPathways': ret = OETarget.humanLegPathways; break;
                case 'humanLowerLeg': ret = OETarget.humanLowerLeg; break;
                case 'humanThighKnee': ret = OETarget.humanThighKnee; break;
                case 'humanHip': ret = OETarget.humanHip; break;
                case 'humanKnee': 
                case 'knee': ret = OETarget.humanKnee; break;
                case 'humanKneeImplantcast': ret = OETarget.humanKneeImplantcast; break;
                case 'humanFoot': 
                case 'foot': ret = OETarget.humanFoot; break;
                case 'humanTrunk': ret = OETarget.humanTrunk; break;
                case 'humanSpine': ret = OETarget.humanSpine; break;
                case 'humanSpineFracture': ret = OETarget.humanSpineFracture; break;
                case 'humanAbdominalWall': ret = OETarget.humanAbdominalWall; break;
                case 'humanChestWall': ret = OETarget.humanChestWall; break;
                case 'humanPelvis': ret = OETarget.humanPelvis; break;
                case 'humanNeckBack': ret = OETarget.humanNeckBack; break;
                case 'humanShoulderGirdle': ret = OETarget.humanShoulderGirdle; break;
                case 'humanHead': ret = OETarget.humanHead; break;
                case 'humanHeadWIP': ret = OETarget.humanHeadWIP; break;
                case 'humanEye2': ret = OETarget.humanEye2; break;
                case 'humanEyeRetina': ret = OETarget.humanEyeRetina; break;
                case 'humanLarynx': ret = OETarget.humanLarynx; break;
                case 'humanLiverMicro': ret = OETarget.humanLiverMicro; break;
                case 'humanHeart' : ret = OETarget.humanHeart; break;
                case 'animalCell': ret = OETarget.animalCell; break;
                case 'plantCell': ret = OETarget.plantCell; break;
                case 'humanCellEpithelium': ret = OETarget.humanCellEpithelium; break;
                case 'amboss': ret = OEResourceTarget.amboss; break;
                case 'ambossImport': ret = OEResourceTarget.ambossImport; break;
                case 'anatomy': ret = OEResourceTarget.anatomy; break;
                case 'cell': ret = OEResourceTarget.cell; break;
                case 'philipsIcarus': ret = OETarget.philipsIcarus; break;
                case 'philipsIcarusAnatomy': ret = OEResourceTarget.philipsIcarusAnatomy; break;
                case 'skinIONTO': ret = OEResourceTarget.skinIONTO; break;
// @ADD_TARGET_BEGIN@
//              case '$TARGET_cC$': this.target = OETarget.$TARGET_cC$; break;
// @ADD_TARGET_END@
                default: break;
            }

            if(ret) {
                info.targetChoiceTag = 2;
                return ret;
            }
        }

        // if config.target is set, i.e., the target is fixed by the build configuration, translate this target to a oe target and return
        if(typeof(config.target) === 'string' && config.target !== '')   {

            let confTarget = config.target;

            switch (config.target) {
                case 'ear':
                case 'earMacro':
                    confTarget = isAmboss ? OETarget.earMacroAmboss : OETarget.earMacro; break;
                case 'earMicroAmboss': confTarget = OETarget.earMicro; break;
                case 'skinMacro':
                case 'skin':
                    confTarget = OETarget.skinMacroIONTO;
                    break;
                case 'skinMicro': confTarget = OETarget.skinMicroIONTO; break;
            }

            info.targetChoiceTag = 1;

            return confTarget;
        }

        // target keeps undefined
        return target;
    }

    logoFor(target, version)  {
        if(version == OEVersion.anatomy)  return 'images/logo/logo_anatomy.png';
        if(version == OEVersion.ionto)  return 'images/logo/logo_skin_2.png';
        if(version == OEVersion.philipsIcarus)  return 'images/logo/logo_foot.png';
        if(version == OEVersion.zeissOpti)  return 'images/logo/logo_zeiss_opti.png';
        
        switch(target)   {
            case OETarget.humanSkull: return 'images/logo/logo_skull.png';

            case OETarget.earMacro:
            case OETarget.earMacroAmboss:
            case OETarget.earMicro:
                return 'images/logo/logo_ear.png';

            case OETarget.skinMacroIONTO:
            case OETarget.skinMicroIONTO:
                return 'images/logo/logo_skin.png';

            case OETarget.eyeMacro:
            case OETarget.eyeMicro:
                return 'images/logo/logo_eye.png';

            case OETarget.snail: return 'images/logo/logo_snail.png';

            case OETarget.humanKnee:
            case OETarget.humanKneeImplantcast:
                return 'images/logo/logo_knee.png';

            case OETarget.zeissOpti:
            case OETarget.zeissOpti2:
            case OETarget.zeissUV:
                return 'images/logo/logo_zeiss_opti.png';

            case OETarget.humanFoot:
                return 'images/logo/logo_foot.png';

            case OETarget.hoofDynamics:
            case OETarget.hoofVessels:
            case OETarget.hoofWeb:
            case OETarget.hoofMicro:
                return 'images/logo/logo_hoof.png';

            case OETarget.animalCell:
            case OETarget.plantCell:
                return 'images/logo/logo_animal_cell.png';
        }
        
        return 'images/logo/logo.png';
    }
}