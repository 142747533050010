import React from 'react';
import PropTypes from 'prop-types';
import {Progress} from 'reactstrap';
import {VelocityTransitionGroup} from 'velocity-react';

import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OEStatusType, OEStatus} from '../react-oe/oe-surface';
import {OETarget} from '../react-oe/oe-target';

export default class OELoadingScreen extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);
        this.progress = 0;

        this.state = {
            show: true,
            showSpinner: true,
            progress:   {
                show: true,
                value: 0,
                max: 100
            },
            msg: null,
            subMsg: null
        };

        this.onAppStatusChanged = this.onAppStatusChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.status !== this.props.status || nextProps.target !== this.props.target)     {
            this.updateState(false, nextProps);
        }
    }

    componentWillUnmount()    {
        this.unmounted = true;
    }

    onPreConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.appStatusChanged, this.onAppStatusChanged);
    }

    onConnect()  {}

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.appStatusChanged, this.onAppStatusChanged);
    }

    updateState(released, props_)   {
        if(this.unmounted) return;
        if(!this.oe.isPreReady() || released === true)   {
            this.appStatus = null;
            this.progress = 0;
            this.setState({
                show: true,
                showSpinner: true,
                progress:   {
                    show: true,
                    value: 0,
                    max: 100
                },
                msg: null,
                subMsg: null
            });
        }

        const props = props_ || this.props;
        const status = props.status;
        const appStatus = this.appStatus;

        let show = status.type !== OEStatusType.initialized && props.target !== OETarget.void;
        let showSpinner = ![OEStatusType.initialized, OEStatusType.error, OEStatusType.aborted, OEStatusType.envWarning].includes(status.type);
        let stateProgress = {show: true, value: 0, max: 100};
        let progress = (status.progress && status.progress.max) ? (100.0 * (status.progress.value / status.progress.max)) : 0; 

        let msg = props.status.msg;
        let subMsg = status.type === OEStatusType.aborted ? status.userInfo.checkEnvResult.msg : null;

        if(appStatus)  {
            const isRunning = appStatus.primaryStatus === this.oe.Module.AppPrimaryStatusType.running;
            var isInitializing = appStatus.primaryStatus === this.oe.Module.AppPrimaryStatusType.initializing;
            const isError = appStatus.primaryStatus === this.oe.Module.AppPrimaryStatusType.error;
            show = !isRunning;
            showSpinner = !isRunning && appStatus.primaryStatus !== this.oe.Module.AppPrimaryStatusType.error;
            stateProgress.show = isInitializing;
            progress = isRunning ? 100 : appStatus.blockingProgress;

            if(isError)  {
                msg = 'An error occured';
                subMsg = appStatus.error.localizedDesc !== '' ? appStatus.error.localizedDesc : appStatus.error.desc;
            }

            stateProgress.value = 1;
        }

        let newProgress = (stateProgress.value * 100 + progress) * 0.5;
        this.progress = isInitializing ? newProgress : Math.max(this.progress, newProgress);

        stateProgress.value = Math.round(this.progress);

        this.setState({
            show: show,
            showSpinner: showSpinner,
            progress: stateProgress,
            msg: msg,
            subMsg: subMsg
        });
    }

    onAppStatusChanged(message, userInfo)   {
        this.appStatus = userInfo.status;
        this.updateState();
    }

    renderContent() {
        if(!this.state.show) return null;
        return (
            <div className="loading-screen">
                <div className="inner-loading-wrapper">
                    {this.state.showSpinner ? <img className="spinner" src="images/loading_anim.gif"/> : null}
                    <div className="progress-container">
                        {this.state.progress.show ? <Progress className="progress" value={this.state.progress.value} max={this.state.progress.max}/> : null}
                    </div>
                    {!this.state.subMsg ? <span>{this.state.msg}</span> : <span>{this.state.msg}<br/>{this.state.subMsg}</span>}
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <VelocityTransitionGroup leave={{animation: "fadeOut"}}>
                    {this.renderContent()}
                </VelocityTransitionGroup>
            </React.Fragment>
        );
    }
}

OELoadingScreen.defaultProps = {
    moduleId: '',
    status: new OEStatus()
};

OELoadingScreen.propTypes = {
    moduleId: PropTypes.string,
    status: PropTypes.object
};