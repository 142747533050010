import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {translateToUILevel, withUILevel} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEComponentController from '../oe-component-controller';
import {OEColorHelper} from '../oe-color-helper';
import {OEToolbox} from '../../lib/oe-toolbox';

export class OELeftWidgetToolbar extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {};

        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.uiLevel !== this.props.uiLevel || !OEToolbox.jsonEqual(nextProps.widgetConfig, this.props.widgetConfig))   {
            this.updateUIState(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateUIState(props, visible, animated)   {
        let props_ = props || this.props;
        let componentEnabled = translateToUILevel(props_.widgetConfig.componentController, props_.uiLevel).enabled;

        let visible_ = typeof(visible) === 'boolean' ? visible : (this.oe.isReady() ? (componentEnabled && this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.component)) : false);
        let animated_ = typeof(animated) === 'boolean' ? animated : true;
        
        if(this.props.onShouldBeVisible) this.props.onShouldBeVisible(visible_, animated_);

        if(this.oe.isReady())   {
            this.oe.sharedInterface.setUIControllerVisible(visible_, this.oe.Module.UIControllerType.component);
        }
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.updateUIState();
            return;
        }
        
        this.updateUIState();
    }

    onUIControllerStateChanged(message, userInfo)   {
        if(userInfo.type !== this.oe.Module.UIControllerType.component) return;
        this.updateUIState();
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="left-widget-toolbar std-label-text-color">
                    <OEComponentController uiLevel={this.props.uiLevel} moduleId={this.props.moduleId}/>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

OELeftWidgetToolbar.defaultProps = {
    moduleId: ''
};

OELeftWidgetToolbar.propTypes = {
    moduleId: PropTypes.string,
    onShouldBeVisible: PropTypes.func
};

export default connectAppEnv((env) => { 
    const ui = env.config.module.uiLayerConfig;
    const ret = {
        widgetConfig: ui.widgetConfig
    };
    return ret;
})(withUILevel(OELeftWidgetToolbar));