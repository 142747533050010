import React from 'react';
import PropTypes from 'prop-types';

import {connectModuleEnv} from '../oe-module-env';
import {frefToRef} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEIcon, OEButton} from '../oe-controls';
import {OEToolbox} from '../../lib/oe-toolbox';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OEResizeObserver from '../../lib/oe-resize-observer';
import {retardUpdate} from '../../lib/update-retarder';

export const OEPhilipsIcarusCustomPropKey = {
    mode: 'philips_icarus_mode',
    animation: 'philips_icarus_animation',
    vpFrame: 'philips_icarus_vp_frame'
};

export const OEPhilipsIcarusMode = {
    uninitialized: 0,
    std: 1,
    xRay: 2
};

export const OEPhilipsIcarusAnimation = {
    uninitialized: -1,
    patient: 0,
    knee: 1,
    foot: 2
};

export class OEPhilipsIcarusOverlay extends React.PureComponent {

    constructor(props) {
        super(props);

        this.NotificationName = {
            cameraResetBtnPressed: 'philips_icarus_camera_reset_btn_pressed'
        };

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        this.visible = false;
        this.xRayVisible = true;

        this.xRayContainerSize = null;

        this.state = {
            visible: this.visible,
            xRay: {
                visible: this.xRayVisible,
                image: null
            },
            layout: {
                xRayContainerSize: this.xRayContainerSize
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onCustomPropertyChanged = this.onCustomPropertyChanged.bind(this);

        this.onWindowResized = this.onWindowResized.bind(this);
        this.onXRayContainerResize = this.onXRayContainerResize.bind(this);

        this.onResetCameraBtnPressed = this.onResetCameraBtnPressed.bind(this);
    }

    componentDidMount()    {
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()  {
        this.props.uiStateManager.updateState({contextMenu: {areaRestriction: { right: {$set: 0}}}});
        //this.props.updateModuleEnv({ui: {contextMenu: {areaRestriction: { right: {$set: 0}}}}});
        window.removeEventListener('resize', this.onWindowResized);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.jsonEqual(this.props.ui, nextProps.ui))     {
            this.updateVPFrame(nextProps);
        }
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    getEdgeOffsets()    {
        return this.edgeOffsets;
    }

    updateContextMenuContainerRestriction() {
        let right = this.xRayContainerSize && this.visible && this.xRayVisible ? this.xRayContainerSize.w : 0;
        
        this.props.uiStateManager.updateState({contextMenu: {areaRestriction: { right: {$set: right}}}});
        //this.props.updateModuleEnv({ui: {contextMenu: {areaRestriction: { right: {$set: right}}}}});
    }

    updateEdgeOffsets()    {
        let edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};
        if(OEToolbox.shallowEqual(this.edgeOffsets, edgeOffsets)) return;
        this.edgeOffsets = edgeOffsets;
        if(this.props.onEdgeOffsetsChanged) this.props.onEdgeOffsetsChanged(this, this.edgeOffsets, true);
    }

    update()   {
        if(!this.oe.isReady())  return;

        let mode = this.oe.sharedInterface.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;
        let animation = this.oe.sharedInterface.getCustomProperty(OEPhilipsIcarusCustomPropKey.animation).value;

        this.visible = mode != OEPhilipsIcarusMode.uninitialized;
        this.xRayVisible = mode == OEPhilipsIcarusMode.xRay;

        let images = [
            null,
            'knee.jpg',
            'foot.jpg'
        ];

        this.setStateUpdate({
            visible: {$set: this.visible},
            xRay: {
                visible: {$set: this.xRayVisible}
            }
        });

        if(images[animation])   {
            this.setStateUpdate({
                xRay: {
                    image: {$set: 'images/philipsIcarus/Xray/' + images[animation]}
                }
            });    
        }

        this.updateContextMenuContainerRestriction();
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    updateLanguage()   {}

    updateVPFrame(props) {
        if(!this.oe.isReady() || !this.xRayContainerSize || !window.innerWidth) return;
        props = props || this.props;
        let wSize = {w: window.innerWidth, h: window.innerHeight};
        let frame = {};
        frame.x = props.ui.mainMenuWidth;
        frame.y = props.ui.bottomEdgeOffset;
        frame.z = wSize.w - this.xRayContainerSize.w - frame.x;
        frame.w = wSize.h - props.ui.capBarSize.h - frame.y;
        frame = { x: 2 * frame.x / wSize.w - 1, y: 2 * frame.y / wSize.h - 1, z: 2 * frame.z / wSize.w, w: 2 * frame.w / wSize.h };
        this.oe.sharedInterface.setCustomFloatProperty(OEPhilipsIcarusCustomPropKey.vpFrame, {vec: frame});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.visible = false;
            this.setState({visible: false});
            this.updateEdgeOffsets();
            this.updateContextMenuContainerRestriction();
            return;
        }

        this.updateVPFrame();

        retardUpdate(this, () => {
            this.updateLanguage();
            this.update();
        });
    }

    onCustomPropertyChanged(message, userInfo)   {
        if(userInfo.key === OEPhilipsIcarusCustomPropKey.mode || userInfo.key === OEPhilipsIcarusCustomPropKey.animation)   {
            this.update();
        }
    }

    onWindowResized()   {
        this.updateVPFrame();
    }

    onXRayContainerResize(sender, size) {
        this.xRayContainerSize = size;
        this.setStateUpdate({
            layout: {
                xRayContainerSize: {$set: size}
            }
        });
    }

    renderXRayContainer()  {
        const visible = this.state.xRay.visible && this.state.visible;

        return (
            <div style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}} className="screenshot-filter-show">
                <div className="x-ray-container-backdrop" style={this.state.layout.xRayContainerSize ? {width: this.state.layout.xRayContainerSize.w} : null}>
                </div>
                <div className="x-ray-container">
                    <OEResizeObserver onResize={this.onXRayContainerResize} />
                    {this.renderResetCameraBtn()}
                    <img src={this.state.xRay.image}/>
                </div>
            </div>
        );
    }

    renderResetCameraBtn()  {
        const visible = this.state.xRay.visible && this.state.visible;
        return (
            <OEButton
                className="transparent-btn view-port-label-text-color reset-camera"
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}}
                onPressed={this.onResetCameraBtnPressed}
            >
                <OEIcon code={OEIconCodes.philipsIcarus.resetCamera}/>
            </OEButton>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="overlay philips-icarus std-label-text-color">

                    {this.renderXRayContainer()}

                </div>
            </React.Fragment>
        );
    }

    onResetCameraBtnPressed()   {
        this.oe.sharedInterface.postNotification(this.NotificationName.cameraResetBtnPressed, null);
    }
}

OEPhilipsIcarusOverlay.defaultProps = {
    moduleId: ''
};

OEPhilipsIcarusOverlay.propTypes = {
    moduleId: PropTypes.string
};

export default connectModuleEnv((env, setModuleEnv, updateModuleEnv) => {
    return {
        uiStateManager: env.component.uiStateManager,
        updateModuleEnv: updateModuleEnv,
        ui: {
            mainMenuWidth: env.ui.mainMenu.width,
            capBarSize: env.ui.capBar.size,
            bottomEdgeOffset: env.ui.bottomEdgeOffset.value
        }
    };
})(frefToRef(OEPhilipsIcarusOverlay));
