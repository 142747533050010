import React from 'react';
import PropTypes from 'prop-types';

import OEMediaCenterItemStd from './oe-media-center-item-std';
import OEMediaCenterItemPres from './oe-media-center-item-pres';

export const OEMediaCenterItemLayoutType = {
    std: 0,
    nameTop: 1
};

export const OEMediaCenterIconType = {
    awesome: 0,
    text: 1,
    std: 0
};

export class OEMediaCenterItemFactory extends React.PureComponent {

    render() {
        return (this.props.item.type.value === 7 && this.props.config.usePresItems ? 
            <OEMediaCenterItemPres {...this.props} /> :
            <OEMediaCenterItemStd {...this.props} />
        );
    }
}

OEMediaCenterItemFactory.defaultProps = {
    className:'',
    moduleId: '',
    enabled: true,
    selected: false,
    elementRef: function(ref, id)   {},
    onClick: function(index, id) {},
    config: {
        usePresItems: true,
        showDurationInSlidesForPresWithoutAudio: false
    }
};

OEMediaCenterItemFactory.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    selected: PropTypes.bool,
    elementRef: PropTypes.func,
    onClick: PropTypes.func,
    config: PropTypes.shape({
        usePresItems: PropTypes.bool,
        showDurationInSlidesForPresWithoutAudio: PropTypes.bool
    })
};