import React from 'react';
import PropTypes from 'prop-types';
import Plyr from 'react-plyr';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEStdModalController from '../../modals/oe-std-modal-controller';
import {OECarouselItem, OECarousel} from '../../oe-carousel';
import {OEDefaultConfigFactory} from '../../oe-default-configs';
import OEStartViewStdHeaderFactory from './oe-start-view-std-header-factory';

export default class OEStartViewStd extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_,
            strings: {
                back: 'Back',
                next: 'Next'
            },
            activeIndex: 0,
            items: this.getItems()
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
    }

    setOpen(open = true)   {
        if(typeof(this.props.isOpen) === 'boolean' || this.isOpen_ === open) return;
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
        if(typeof(this.props.onOpenStateChanged) === 'function')  this.props.onOpenStateChanged(this, this.isOpen_);
    }

    isOpen()    {
        return this.isOpen_;
    }

    getItems()   {
        var items = new Array();

        if(typeof(this.props.config) !== 'undefined' && typeof(this.props.config.videoContent) === 'string' && this.props.config.videoContent !== '')    {
            items.push({type: 'video', src: this.props.config.videoContent});
        }

        var langCode = this.oe.isReady() ? this.oe.sharedInterface.getUIControllerSettings().getLanguageCode() : 'en';
        langCode = langCode.toLowerCase();
        
        for(var i = 1; i < 5; ++i)    {
            const itemSrc = 'images/start/' + langCode + '/ui_maus_0' + i.toString() + '.png';
            items.push({type: 'image', src: itemSrc});
        }
    
        for(var i = 1; i < 4; ++i)    {
            const itemSrc = 'images/start/' + langCode + '/ui_touch_0' + i.toString() + '.png';
            items.push({type: 'image', src: itemSrc});
        }

        return items;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.target !== this.props.target)  {
            this.setState({items: this.getItems()});
        }
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        setTimeout(this.onTimeout.bind(this), 500);        
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {
        this.setState({ 
            strings: {
                back: this.oe.sharedInterface.getLocalizedStringEnc('start_view_back'),
                next: this.oe.sharedInterface.getLocalizedStringEnc('start_view_next')
            },
            items: this.getItems()
        }); 
    }

    onTimeout() {
        var config = this.props.config || OEDefaultConfigFactory.startView();

        if(config.showAtStartUp && this.oe.isReady() && localStorage.getItem('dontShowStartViewAtStartUp') !== 'true')   {
            this.setOpen(true);
        }
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }
        this.updateLanguage();
    }

    renderCarouselItemContent(item) {
        if(item.type === 'image')   {
            return (<img src={item.src} />);
        } else if(item.type === 'video')    {
            return (
                <Plyr
                    className="react-plyr"
                    type="youtube"
                    videoId={item.src} 
                    controls={['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']}/>
            );
        } else {
            return null;
        }
    }

    renderCarouselItem(item, index) {
        return (
            <OECarouselItem key={index}>
                {this.renderCarouselItemContent(item)}
            </OECarouselItem>
        );
    }

    renderCarousel()  {
        const slides = this.state.items.map((item, index) => this.renderCarouselItem(item, index));

        return (
            <OECarousel
                activeIndex={this.state.activeIndex}
            >
                {slides}
            </OECarousel>
        );
    }

    renderContent(videoContent) {
        const isFirst = this.state.activeIndex === 0;
        const isLast = this.state.activeIndex === this.state.items.length - 1;
        const indicatorActive = this.state.activeIndex + 1;
        const indicatorNum = this.state.items.length;

        return (
            <div className="aspect-dummy" >
                {this.renderCarousel()}

                <a 
                    className={'carousel-control-prev carousel-control-btn themecolor' + (isFirst ? ' disabled' : '')}
                    role="button" 
                    onClick={this.previous}
                >
                    <span>{this.state.strings.back}</span>
                </a>

                <a 
                    className={'carousel-control-next carousel-control-btn themecolor' + (isLast ? ' disabled' : '')}
                    role="button" 
                    onClick={this.next}
                >
                    <span>{this.state.strings.next}</span>
                </a>

                <div className="carousel-indicators">
                    {indicatorActive.toString() + '/' + indicatorNum.toString()}
                </div>
            </div>
        );
    }

    render() {
        var config = this.props.config || OEDefaultConfigFactory.startView();
        var isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;

        return (
            <OEStdModalController 
                moduleId={this.props.moduleId}
                headerStringId="start_view"
                className="start-view-std"
                isOpen={isOpen}
                onToggle={this.onToggle}
                logo={config.logo}
            >
               
                <OEStartViewStdHeaderFactory moduleId={this.props.moduleId} target={this.props.target} config={this.props.config}/>

                {this.renderContent()}

            </OEStdModalController>
        );
    }

    onToggle() { 
        if(typeof(this.props.onToggle) === 'function')  {
            this.props.onToggle();
        } else {
            this.setOpen(false);
        }
    }

    next() {
        if(this.state.activeIndex === this.state.items.length - 1) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if(this.state.activeIndex === 0) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }
}

OEStartViewStd.defaultProps = {
    moduleId: ''
};

OEStartViewStd.propTypes = {
    moduleId: PropTypes.string
};