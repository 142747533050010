import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import {Popover, PopoverHeader, PopoverBody} from 'reactstrap';

import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OEColorHelper} from './oe-color-helper';
import {OEButton, OEIcon} from './oe-controls';
import {oeUniqueIdGen} from '../lib/oe-unique-id-gen';
import {OEIconCodes} from '../lib/oe-icon-codes';
import {OEBackdrop} from '../lib/oe-window';
import {retardUpdate} from '../lib/update-retarder';

export default class OEPopover extends React.PureComponent {

    constructor(props) {
        super(props);

        this.uid = oeUniqueIdGen.get();

        if(this.props.moduleId) this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.uiControllerType = typeof(this.props.uiControllerType) === 'number' ? this.props.uiControllerType : null;

        this.state = {
            isOpen: false,
            uiControllerType: this.uiControllerType,
            backgroundColor: OEColorHelper.stdBackgroundColor(0.6),
            textColor: OEColorHelper.stdLabelTextColor(0.6),
        };

        this.onLayoutChanged = this.onLayoutChanged.bind(this);
        this.onBgColorChanged = this.onBgColorChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onToggle = this.onToggle.bind(this);

        this.onPopoverTagRef = this.onPopoverTagRef.bind(this);
        this.onPopoverPinRef = this.onPopoverPinRef.bind(this);
        this.onBackdrop = this.onBackdrop.bind(this);
    }

    setUIControllerType(uiControllerType)   {
        let uiControllerType_ = uiControllerType;
        if(typeof(uiControllerType_) === 'object')    {
            uiControllerType_ = uiControllerType_ === null ? uiControllerType_ : uiControllerType_.value;
        }
        if(uiControllerType_ !== null && typeof(uiControllerType_) !== 'number')    return;
        if(this.uiControllerType === uiControllerType_) return;
        this.uiControllerType = uiControllerType_;
        this.setState({uiControllerType: this.uiControllerType});
        this.updateUIController();
    }

    componentWillReceiveProps(nextProps) {
		if(nextProps.uiControllerType !== this.props.uiControllerType)     {
            let isOpenPrev = this.isOpen();
            this.setUIControllerType(nextProps.uiControllerType);
            if(isOpenPrev !== this.isOpen()) this.onIsOpenStateChanged();
        }
    }

    componentDidMount()    {
        window.addEventListener('oe-layout-changed', this.onLayoutChanged);
    }

    componentWillUnmount()    {
        window.removeEventListener('oe-layout-changed', this.onLayoutChanged);
    }

    componentDidUpdate(prevProps, prevState)    {
        let isOpenPrev = prevState.uiControllerType !== null ? prevState.isOpen : prevProps.isOpen;
        if(isOpenPrev !== this.isOpen()) this.onIsOpenStateChanged();
    }

    isOpen()    {
        return this.state.uiControllerType !== null ? this.state.isOpen : this.props.isOpen;
    }

    onIsOpenStateChanged()  {
        if(this.props.onIsOpenStateChanged) this.props.onIsOpenStateChanged(this, this.isOpen());
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    onLayoutChanged()   {
        this.forceUpdate();
    }

    onUIControllerStateChanged(message, userInfo)   {
        if(userInfo.type.value === this.uiControllerType)   {
            this.setState({isOpen: userInfo.visible});
        }
    }

    updateState(released)   {
        if(!this.oe || !this.oe.isReady() || released === true)   {
            this.setState({ isOpen: false });
            return;
        }

        retardUpdate(this, () => {
            this.updateUIController();
        });
    }

    updateUIController()  {
        if(this.uiControllerType === null || !this.oe || !this.oe.isReady())   {
            this.onBgColorChanged();
            return;
        } 

        this.setState({isOpen: this.oe.sharedInterface.getUIControllerVisible({value: this.uiControllerType})});
        this.onBgColorChanged();
    }

    onBgColorChanged(message, userInfo)  {
        if(!this.oe || !this.oe.isReady()) return;
        let brightness = !userInfo ? this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness() : userInfo.brightness;

        let bgColor = OEColorHelper.stdBackgroundColor(brightness);
        let textColor = OEColorHelper.stdLabelTextColor(brightness)

        let strBgColor = OEColorHelper.vColorToStr(bgColor);
        let strTextColor = OEColorHelper.vColorToStr(textColor);

        this.setState({
            backgroundColor: bgColor,
            textColor: textColor
        });

        // we set the background color and text color
        if(this.tagElement != null && this.tagElement.parentNode != null && this.tagElement.parentNode.parentNode != null) {
            this.tagElement.parentNode.parentNode.style.backgroundColor = strBgColor;
            this.tagElement.parentNode.parentNode.style.color = strTextColor;
            this.tagElement.parentNode.parentNode.style.borderRightColor = strBgColor;
        }
    }

    onToggle() {
        if(this.props.onToggle)    this.props.onToggle();

        if(this.uiControllerType === null || !this.oe || !this.oe.isReady()) return;
        this.oe.sharedInterface.setUIControllerVisible(false, {value: this.uiControllerType});
    }

    renderHeader()  {
        if(this.props.renderHeader)  {
            let props = {...this.props};
            props.onToggle = this.onToggle;
            return this.props.renderHeader(props);
        }

        return (
            <div>
                <span className="title">{this.props.title}</span>
                <div className="buttons">
                    {!this.props.onHelpBtnPressed ? null :
                        <OEButton className={this.props.buttonClassName + ' help-btn'} onPressed={this.props.onHelpBtnPressed}>
                            <OEIcon code={OEIconCodes.help} />
                        </OEButton>
                    }
                    {this.props.additionalButtons ? this.props.additionalButtons({...this.props}) : null}
                    <OEButton className={this.props.buttonClassName + ' close-btn'} onPressed={this.onToggle}>
                        <OEIcon code={OEIconCodes.close} />
                    </OEButton>
                </div>
            </div>
        );
    }

    render() {
        let isOpen = this.isOpen();

        let header = this.props.noHeader ? null : this.renderHeader();

        let bgColor = OEColorHelper.vColorToStr(this.state.backgroundColor);
        let textColor = OEColorHelper.vColorToStr(this.state.textColor);

        let popperModifiers = this.props.autoColor ? {
            styling: {
                enabled: true,
                fn: function(data, options) {
                    data.styles.backgroundColor = bgColor;
                    data.styles.color = textColor;
                    data.styles.borderRightColor = bgColor;
                    data.styles.borderLeftColor = bgColor;
                    data.styles.borderTopColor = bgColor;
                    data.styles.borderBottomColor = bgColor;
                    // arrowStyles are ignored by react-popper :(
                    // workaround -> inherit borderRightColor style in custom style sheets for arrow, see .popover.auto-color .arrow in styles.css
                    //data.arrowStyles.borderRightColor = bgColor;
                    return data;
                },
                order: 850
            }
        } : null;

        let explicitePosition = this.props.position || this.props.rect;

        let target = !explicitePosition ? this.props.target : ('target-id-' + this.uid);

        if(!target) return null;

        let popover = !target || (explicitePosition && !this.state.hasTarget) ? null : (
            <Popover
                className={this.props.className + (this.props.autoColor ? ' auto-color' : '') + (this.props.hideArrow ? ' hide-arrow' : '') + (this.props.backdrop ? ' with-backdrop' : '')}
                placement={this.props.placement}
                isOpen={isOpen}
                boundariesElement={this.props.boundariesElement}
                target={target}
                modifiers={popperModifiers}
                hideArrow={this.props.hideArrow}
                offset={this.props.offset}
            >
                <div ref={this.onPopoverTagRef} ></div>
                {header ? <PopoverHeader>{header}</PopoverHeader> : null}
                <PopoverBody>{this.props.children}</PopoverBody>
            </Popover>
        );

        if(explicitePosition)   {
            let rect = this.props.rect;
            let pos = !rect ? this.props.position : {x: rect.x, y: rect.y}; 
            let style = {left: typeof(pos.x) === 'string' ? pos.x : (pos.x.toString() + 'px'), top: typeof(pos.y) === 'string' ? pos.y : (pos.y.toString() + 'px')};

            if(rect)    {
                style.width = typeof(rect.w) === 'string' ? rect.w : (rect.w.toString() + 'px');
                style.height = typeof(rect.h) === 'string' ? rect.h : (rect.h.toString() + 'px');
            }

            popover = (
                <div id={target} className="popover-pin" style={style} ref={this.onPopoverPinRef}>
                    {popover}
                </div>
            );
        }

        if(this.props.backdrop && isOpen) {
            // popover insertion only if backdrop has been mounted is a trick to ensure that the backdrop is injected into the DOM in front of the popover
            popover = (
                <React.Fragment>
                    <OEBackdrop enabled={true} onClick={this.onToggle} options={this.props.backdropOptions} elementRef={this.onBackdrop}/>
                    {!this.state.hasBackdrop ? null : popover}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.props.moduleId ? <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/> : null}
                {popover}
            </React.Fragment>
        );
    }

    onPopoverTagRef(e)  {
        this.tagElement = e;
    }

    onPopoverPinRef(e)  {
        this.setState({hasTarget: e ? true : false});
    }

    onBackdrop(e)  {
        this.setState({hasBackdrop: e ? true : false});
    }
}

OEPopover.defaultProps = {
    className: '',
    placement: 'auto',
    position: null,
    rect: null,
    autoColor: true,
    hideArrow: false,
    noHeader: false,
    buttonClassName : '',
    isOpen: false,
    title: '',
    backdrop: false,
    backdropOptions: {color: 'rgba(0, 0, 0, 0.2)', animated: true, duration: 0.333}
};

OEPopover.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    placement: PropTypes.string,
    position: PropTypes.shape({x: PropTypes.number, y: PropTypes.number}),
    rect: PropTypes.shape({x: PropTypes.number, y: PropTypes.number, w: PropTypes.number, h: PropTypes.number}),
    autoColor: PropTypes.bool,
    hideArrow: PropTypes.bool,
    noHeader: PropTypes.bool,
    offset: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    buttonClassName: PropTypes.string,
    renderHeader: PropTypes.func,
    additionalButtons: PropTypes.func,
    isOpen: PropTypes.bool,
    onIsOpenStateChanged: PropTypes.func,
    onToggle: PropTypes.func,
    onHelpBtnPressed: PropTypes.func,
    title: PropTypes.string,
    backdrop: PropTypes.bool,
    backdropOptions: PropTypes.shape({color: PropTypes.string, animated: PropTypes.bool, duration: PropTypes.number}),
};