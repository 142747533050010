import React from 'react';
import PropTypes from 'prop-types';

import {OETarget} from '../../react-oe/oe-target';
import OEZeissUVOverlay from './oe-zeiss-uv-overlay';
import OEZeissOptiOverlay from './oe-zeiss-opti-overlay';
import OEAnimalCellOverlay from './oe-animal-cell-overlay';
import OEPhilipsIcarusOverlay from './oe-philips-icarus-overlay';
import {OEToolbox} from '../../lib/oe-toolbox';

export default class OEOverlayFactory extends React.PureComponent {

    constructor(props) {
        super(props);

        this.overlayRefs = null;
        this.edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        this.onRef = this.onRef.bind(this);

        this.onOverlayEdgeOffsetChanged = this.onOverlayEdgeOffsetChanged.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate()   {
        this.overlayRefs = this.nextOverlayRefs;
        this.nextOverlayRefs = null;
        this.updateEdgeOffsets(false);
    }

    onRef(ref)  {
        if(!ref) return;
        this.nextOverlayRefs = this.nextOverlayRefs || new Set();
        this.nextOverlayRefs.add(ref);
    }

    getEdgeOffsets()    {
        return this.edgeOffsets;
    }

    updateEdgeOffsets(animate) {
        let edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};
        if(this.overlayRefs)    {
            this.overlayRefs.forEach(ref => {
                if(!ref.getEdgeOffsets) return;
                let offsets = ref.getEdgeOffsets();
                if(!offsets) return;
                edgeOffsets = {
                    top: typeof(offsets.top) == 'number' ? Math.max(edgeOffsets.top, offsets.top) : offsets.top,
                    right: typeof(offsets.right) == 'number' ? Math.max(edgeOffsets.right, offsets.right) : offsets.right,
                    bottom: typeof(offsets.bottom) == 'number' ? Math.max(edgeOffsets.bottom, offsets.bottom) : offsets.bottom,
                    left: typeof(offsets.left) == 'number' ? Math.max(edgeOffsets.left, offsets.left) : offsets.left
                };
            });
        }

        if(OEToolbox.shallowEqual(edgeOffsets, this.edgeOffsets))   return;
        this.edgeOffsets = edgeOffsets;
        this.onEdgeOffsetsChanged(animate);
    }

    onEdgeOffsetsChanged(animate) {
        if(this.props.onEdgeOffsetsChanged) this.props.onEdgeOffsetsChanged(this, this.edgeOffsets, animate);
    }

    render() {
        let key = 0;
        let overlays = new Array();
        let onRef = this.onRef.bind(this);  // do not optimise away new fuction creation here, since we want to get a complete new set of overlay refs everytime we update 

        if(this.props.target === OETarget.zeissUV) {
            overlays.push(( <OEZeissUVOverlay key={key++} moduleId={this.props.moduleId} ref={onRef} onEdgeOffsetsChanged={this.onOverlayEdgeOffsetChanged}/> ));
        } else if(this.props.target === OETarget.zeissOpti || this.props.target === OETarget.zeissOpti2) {
            overlays.push(( <OEZeissOptiOverlay key={key++} moduleId={this.props.moduleId} ref={onRef} onEdgeOffsetsChanged={this.onOverlayEdgeOffsetChanged}/> ));
        } else if(this.props.target === OETarget.animalCell) {
            overlays.push(( <OEAnimalCellOverlay key={key++} moduleId={this.props.moduleId} ref={onRef} onEdgeOffsetsChanged={this.onOverlayEdgeOffsetChanged}/> ));
        } else if(this.props.target === OETarget.philipsIcarus) {
            overlays.push(( <OEPhilipsIcarusOverlay key={key++} moduleId={this.props.moduleId} fref={onRef} onEdgeOffsetsChanged={this.onOverlayEdgeOffsetChanged}/> ));
        }

        return (
            <div className={'overlay-factory'}>
                {overlays}
            </div>
        );
    }

    onOverlayEdgeOffsetChanged(animate)    {
        this.updateEdgeOffsets();
    }
}

OEOverlayFactory.defaultProps = {
    moduleId: ''
};

OEOverlayFactory.propTypes = {
    moduleId: PropTypes.string,
    onEdgeOffsetsChanged: PropTypes.func
};