import {OEMediaViewerContentType, OEMediaViewerPlaybackState, OEMediaViewerEvent} from '../media-viewer/oe-media-viewer-controller';

export const OEAnimationMode = {
    disabled: 0,
    play: 1,
    pause: 2
};

export class OEAnimationControlAdapterDummy  {

    constructor() {
        this.progress = 0;
    }

    registerControl(control)    {}

    unregisterControl(control)  {}

    getAnimationMode()  {
        return OEAnimationMode.disabled;
    }

    getProgress()   {
        return this.progress;
    }

    setProgress(progress)   {
        this.progress = progress;
    }

    setAnimationMode(mode)   {}

    play()  {}
    pause() {}
    stop()  {}
};

export class OEAnimationControlAdapterStd  {

    constructor() {

        this.disabledWhenStop = true;

        var _animationControls = [];

        this.registerControl = function(control)    {
            if(_animationControls.find(c => c === control)) return;
            _animationControls.push(control);
        }

        this.unregisterControl = function(control)    {
            _animationControls = _animationControls.filter(c => c !== control);
        }

        var _animationMode = OEAnimationMode.disabled;
        var _progress = 0;

        this.getAnimationMode = function()  {
            return _animationMode;
        }

        this.getProgress = function()   {
            return _progress;
        }

        var _oe = null;
        var _binding = OEAnimationControlAdapterStd.Binding.none;
        var _id = -1;
        var _activeId = -1;

        this.oe = function()    {
            return _oe;
        }

        this.getBinding = function()    {
            return _binding;
        };

        this.getId = function() {
            return _id;
        }

        this.getActiveId = function()   {
            return _activeId;
        }

        this.clearBinding = function() {
            if(_oe == null) return;

            this.unregisterNotificationListener();

            var animationModeOld = _animationMode;
            var progressOld = _progress;

            _animationMode = OEAnimationMode.disabled;
            _progress = 0;

            _oe = null
            _binding = OEAnimationControlAdapterStd.Binding.none;
            _id = -1;
            _activeId = -1;

            if(_animationMode !== animationModeOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onAnimationModeChanged) === 'function')    c.onAnimationModeChanged(this, _animationMode);
                }).bind(this));
            }

            if(_progress !== progressOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onProgressChanged) === 'function')    c.onProgressChanged(this, _progress);
                }).bind(this));
            }
        };

        this.setBinding = function(oe, binding, id) { 
            this.clearBinding();
            if(oe == null || binding === OEAnimationControlAdapterStd.Binding.none)  return;
            
            _oe = oe;
            _binding = binding;
            _id = id;

            this.registerNotificationListener();
            this.updateFromBinding();
        };

        this.updateFromBinding = function()    {
            if(!this.isBinded()) return;

            var animationModeOld = _animationMode;
            var progressOld = _progress;

            if(_binding === OEAnimationControlAdapterStd.Binding.animation) {
                var controller = _oe.sharedInterface.getUIControllerAnimation();
                _activeId = controller.getActiveAnimation();
                _animationMode = (_id >= 0 && _activeId === _id) ? controller.getAnimationMode().value : OEAnimationMode.disabled;
                _progress = _animationMode !== OEAnimationMode.disabled ? controller.getAnimationProgressIndex(_id) : 0;
            }

            if(_binding === OEAnimationControlAdapterStd.Binding.presentation) {
                var controller = _oe.sharedInterface.getUIControllerPresentation();
                _activeId = controller.getActivePresentationID();
                _animationMode = (_id >= 0 && _activeId === _id) ? controller.getAnimationMode().value : OEAnimationMode.disabled;
                _progress = _animationMode !== OEAnimationMode.disabled ? controller.getProgress() : 0;
            }

            if(_animationMode !== animationModeOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onAnimationModeChanged) === 'function')    c.onAnimationModeChanged(this, _animationMode);
                }).bind(this));
            }

            if(_progress !== progressOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onProgressChanged) === 'function')    c.onProgressChanged(this, _progress);
                }).bind(this));
            }
        }.bind(this);

        this.updateAnimationMode = function()   {

            var animationModeOld = _animationMode;
            var progressOld = _progress;

            if(_binding === OEAnimationControlAdapterStd.Binding.animation) {
                var controller = _oe.sharedInterface.getUIControllerAnimation();
                _animationMode = (_id >= 0 && _activeId === _id) ? controller.getAnimationMode().value : OEAnimationMode.disabled;
                _progress = _animationMode !== OEAnimationMode.disabled ? controller.getAnimationProgressIndex(_id) : 0;
            }

            if(_binding === OEAnimationControlAdapterStd.Binding.presentation) {
                var controller = _oe.sharedInterface.getUIControllerPresentation();
                _animationMode = (_id >= 0 && _activeId === _id) ? controller.getAnimationMode().value : OEAnimationMode.disabled;
                _progress = _animationMode !== OEAnimationMode.disabled ? controller.getProgress() : 0;
            }

            if(_animationMode !== animationModeOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onAnimationModeChanged) === 'function')    c.onAnimationModeChanged(this, _animationMode);
                }).bind(this));
            }

            if(_progress !== progressOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onProgressChanged) === 'function')    c.onProgressChanged(this, _progress);
                }).bind(this));
            }
        }.bind(this);

        this.updateProgress = function()   {

             var progressOld = _progress;

            if(_binding === OEAnimationControlAdapterStd.Binding.animation) {
                var controller = _oe.sharedInterface.getUIControllerAnimation();
                _progress = _animationMode !== OEAnimationMode.disabled ? controller.getAnimationProgressIndex(_id) : 0;
            }

            if(_binding === OEAnimationControlAdapterStd.Binding.presentation) {
                var controller = _oe.sharedInterface.getUIControllerPresentation();
                _progress = _animationMode !== OEAnimationMode.disabled ? controller.getProgress() : 0;
            }

            if(_progress !== progressOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onProgressChanged) === 'function')    c.onProgressChanged(this, _progress);
                }).bind(this));
            }
        }.bind(this);
    }

    isBinded()  {
        return this.oe() != null && this.oe().isReady() && this.getBinding() !== OEAnimationControlAdapterStd.Binding.none;
    }

    registerNotificationListener()  {
        if(!this.isBinded()) return;
        var oe = this.oe();

        if(this.getBinding() === OEAnimationControlAdapterStd.Binding.animation) {

            oe.sharedNotificationCenter.register(oe.NotificationName.activeAnimationChanged, this.updateFromBinding);
            oe.sharedNotificationCenter.register(oe.NotificationName.animationModeChanged, this.updateAnimationMode);
            oe.sharedNotificationCenter.register(oe.NotificationName.animationTimeChanged, this.updateProgress);

        } else if(this.getBinding() === OEAnimationControlAdapterStd.Binding.presentation) {

            oe.sharedNotificationCenter.register(oe.NotificationName.activePresentationChanged, this.updateFromBinding);
            oe.sharedNotificationCenter.register(oe.NotificationName.presentationAnimationModeChanged, this.updateAnimationMode);
            oe.sharedNotificationCenter.register(oe.NotificationName.presentationTimeChanged, this.updateProgress);
            oe.sharedNotificationCenter.register(oe.NotificationName.presentationDurationChanged, this.updateProgress);
        }
    }

    unregisterNotificationListener()    {
        if(!this.isBinded()) return;
        var oe = this.oe();

        if(this.getBinding() === OEAnimationControlAdapterStd.Binding.animation) {

            oe.sharedNotificationCenter.unregister(oe.NotificationName.activeAnimationChanged, this.updateFromBinding);
            oe.sharedNotificationCenter.unregister(oe.NotificationName.animationModeChanged, this.updateAnimationMode);
            oe.sharedNotificationCenter.unregister(oe.NotificationName.animationTimeChanged, this.updateProgress);

        } else if(this.getBinding() === OEAnimationControlAdapterStd.Binding.presentation) {

            oe.sharedNotificationCenter.unregister(oe.NotificationName.activePresentationChanged, this.updateFromBinding);
            oe.sharedNotificationCenter.unregister(oe.NotificationName.presentationAnimationModeChanged, this.updateAnimationMode);
            oe.sharedNotificationCenter.unregister(oe.NotificationName.presentationTimeChanged, this.updateProgress);
            oe.sharedNotificationCenter.unregister(oe.NotificationName.presentationDurationChanged, this.updateProgress);
        }
    }

    setProgress(progress)   {
        if(!this.isBinded()) return;

        if(this.getBinding() === OEAnimationControlAdapterStd.Binding.animation && this.getId() >= 0 && this.getId() === this.getActiveId()) {
            this.oe().sharedInterface.getUIControllerAnimation().setAnimationProgressIndex(this.getId(), progress);
        }

        if(this.getBinding() === OEAnimationControlAdapterStd.Binding.presentation && this.getId() >= 0 && this.getId() === this.getActiveId()) {
            this.oe().sharedInterface.getUIControllerPresentation().setProgress(progress);
        }
    }

    setAnimationMode(mode)   {
        if(!this.isBinded()) return;
        var oe = this.oe();

        if(this.getBinding() === OEAnimationControlAdapterStd.Binding.animation && this.getId() >= 0 && this.getId() === this.getActiveId()) {
            oe.sharedInterface.getUIControllerAnimation().setAnimationMode({value: mode});
        }

        if(this.getBinding() === OEAnimationControlAdapterStd.Binding.presentation && this.getId() >= 0 && this.getId() === this.getActiveId()) {
            oe.sharedInterface.getUIControllerPresentation().setAnimationMode({value: mode});
        }
    }

    play()  {
        if(!this.isBinded()) return;

        if(this.getBinding() === OEAnimationControlAdapterStd.Binding.animation && this.getId() >= 0) {
            if(this.getId() !== this.getActiveId()) {
                this.oe().sharedInterface.getUIControllerAnimation().setActiveAnimation(this.getId());
            }

            this.setAnimationMode(OEAnimationMode.play);
        }

        if(this.getBinding() === OEAnimationControlAdapterStd.Binding.presentation && this.getId() >= 0) {
            if(this.getId() !== this.getActiveId()) {
                this.oe().sharedInterface.getUIControllerPresentation().setActivePresentationID(this.getId());
            }

            this.setAnimationMode(OEAnimationMode.play);
        }
    }

    pause() {
        this.setAnimationMode(OEAnimationMode.pause);
    }
    
    stop()  {
        this.setProgress(0);
        this.setAnimationMode(this.disabledWhenStop ? OEAnimationMode.disabled : OEAnimationMode.pause);
    }
};

OEAnimationControlAdapterStd.Binding = {
    none: 'none',
    animation: 'animation',
    presentation: 'presentation'
};

export class OEAnimationControlAdapterMediaViewer  {

    constructor() {
        
        var _animationControls = [];

        this.registerControl = function(control)    {
            if(_animationControls.find(c => c === control)) return;
            _animationControls.push(control);
        }

        this.unregisterControl = function(control)    {
            _animationControls = _animationControls.filter(c => c !== control);
        }

        var _mediaViewer = null;
        var _animationMode = OEAnimationMode.disabled;
        var _progress = 0;

        this.getAnimationMode = function()  {
            return _animationMode;
        }

        this.getProgress = function()   {
            return _progress;
        }

        this.getBinding = function()    {
            return _mediaViewer;
        };

        this.clearBinding = function() {
            if(!_mediaViewer)    return;

            var animationModeOld = _animationMode;
            var progressOld = _progress;

            _animationMode = OEAnimationMode.disabled;
            _progress = 0;

            _mediaViewer.events.unregister(OEMediaViewerEvent.mediaItemChanged, this.onMediaItemChanged);
            _mediaViewer.events.unregister(OEMediaViewerEvent.playbackStateChanged, this.onPlaybackStateChanged);
            _mediaViewer.events.unregister(OEMediaViewerEvent.progressChanged, this.onProgressChanged);

            _mediaViewer = null;

            if(_animationMode !== animationModeOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onAnimationModeChanged) === 'function')    c.onAnimationModeChanged(this, _animationMode);
                }).bind(this));
            }

            if(_progress !== progressOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onProgressChanged) === 'function')    c.onProgressChanged(this, _progress);
                }).bind(this));
            }
        };

        this.setBinding = function(mediaViewer) {
            if(mediaViewer === _mediaViewer) return;

            //console.log('set binding ' + (!mediaViewer ? 'null' : 'ref'));

            this.clearBinding();
            if(!mediaViewer)  return;
            
            _mediaViewer = mediaViewer;

            _mediaViewer.events.register(OEMediaViewerEvent.mediaItemChanged, this.onMediaItemChanged);
            _mediaViewer.events.register(OEMediaViewerEvent.playbackStateChanged, this.onPlaybackStateChanged);
            _mediaViewer.events.register(OEMediaViewerEvent.progressChanged, this.onProgressChanged);

            this.updateFromBinding();
        };

        this.updateFromBinding = function()    {
            if(!this.isBinded()) return;

            this.updateAnimationMode();
            this.updateProgress();

        };

        this.updateAnimationMode = function(state)   {
            if(!this.isBinded()) return;

            var animationModeOld = _animationMode;

            var playbackState = state || _mediaViewer.getPlaybackState();
            var content = _mediaViewer.getActualContent();

            if(!content || content.type !== OEMediaViewerContentType.video)    {
                _animationMode = OEAnimationMode.disabled;
            } else {
                if(playbackState === OEMediaViewerPlaybackState.play)  {
                    _animationMode = OEAnimationMode.play;
                } else {
                    _animationMode = OEAnimationMode.pause;
                }
            }

            if(_animationMode !== animationModeOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onAnimationModeChanged) === 'function')    c.onAnimationModeChanged(this, _animationMode);
                }).bind(this));
            }
        };

        this.updateProgress = function(progress)   {
            if(!this.isBinded()) return;
            var progressOld = _progress;
            _progress = progress || _mediaViewer.getProgress();

            if(_progress !== progressOld) {
                _animationControls.forEach((c => {
                    if(typeof(c.onProgressChanged) === 'function')    c.onProgressChanged(this, _progress);
                }).bind(this));
            }
        };

        this.onMediaItemChanged = function(message, userInfo)   {
            this.updateAnimationMode();
        }.bind(this);

        this.onPlaybackStateChanged = function(message, userInfo)   {
            this.updateAnimationMode(userInfo.state);
        }.bind(this);

        this.onProgressChanged = function(message, userInfo)   {
            this.updateProgress(userInfo.progress);
        }.bind(this);
    }

    isBinded()  {
        return this.getBinding() != null;
    }

    setProgress(progress)   {
        if(!this.isBinded()) return;
        this.getBinding().setProgress(progress);
    }

    setAnimationMode(mode)   {
        if(!this.isBinded()) return;

        if(mode == OEAnimationMode.play)    {
            this.getBinding().play();
        } else if(mode == OEAnimationMode.pause)    {
            this.getBinding().pause();
        } else if(mode == OEAnimationMode.disabled)    {
            this.getBinding().stop();
        }
    }

    play()  {
        if(!this.isBinded()) return;
        this.getBinding().play();
    }

    pause() {
        this.getBinding().pause();
    }
    
    stop()  {
        this.getBinding().stop();
    }
};