import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import {OEColorHelper} from '../oe-color-helper';
import {OEIcon} from '../oe-controls';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OETransitionGroup} from '../../lib/oe-transitions';

export default class OEInfoViewItemView extends React.PureComponent {

    constructor(props) {
        super(props);
        
        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            textColor: OEColorHelper.stdLabelTextColor(0.6)
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onBgColorChanged = this.onBgColorChanged.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.onAnimFinished = this.onAnimFinished.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    onBgColorChanged(message, userInfo)  {
        let brightness = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness() : userInfo.brightness;
        this.setState({
            textColor: OEColorHelper.stdLabelTextColor(brightness)
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }
        
        this.onBgColorChanged();
    }
    
    renderHeader()  {
        if(!this.props.isMaximizeable)  return null;

        const toggle = this.props.notMinimizeable ? null : (
            <div className={'rot-toggle info-view-item-toggle' + (this.props.isMaximized ? ' rot-rotate-toggle' : '')}>
                <OEIcon code={OEIconCodes.caretRight}/>
            </div>
        );

        return ( 
            <div className='info-view-item-header' onClick={this.onToggle}>
                {toggle}
                <div className='info-view-item-title-label'>
                    <span>{this.props.title}</span>
                </div>
            </div>
        );
    }

    render() {
        let content = !this.props.isMaximizeable || this.props.isMaximized || this.props.notMinimizeable ? (
            <div className="info-view-item-content">
                {this.props.children}
            </div>
        ) : null;

        let separator = this.props.hasSeparator === true ? <div className='info-view-item-separator' style={{backgroundColor: OEColorHelper.vColorToStr(this.state.textColor)}}/> : null;

        return (
            <div className={this.props.className + " info-view-item"} ref={this.props.elementRef}>
                {separator}
                {this.renderHeader()}
                <OETransitionGroup duration={666} onAnimFinished={this.onAnimFinished}>
                    {content}
                </OETransitionGroup>
            </div>
        );
    }

    onToggle()  {
        if(typeof(this.props.onToggle) === 'function')  {
            this.props.onToggle();
        }
    }

    onAnimFinished()   {
        if(typeof(this.props.onAnimFinished) === 'function')    {
            this.props.onAnimFinished();
        }
    }
}

OEInfoViewItemView.defaultProps = {
    className: '',
    moduleId: '',
    elementRef: '',
    isMaximizeable: true,
    isMaximized: false,
    notMinimizeable: false,
    hasSeparator: true,
    title: ''
};

OEInfoViewItemView.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    isMaximizeable: PropTypes.bool,
    isMaximized: PropTypes.bool,
    notMinimizeable: PropTypes.bool,
    hasSeparator: PropTypes.bool,
    title: PropTypes.string
};