import React from 'react';
import PropTypes from 'prop-types';

import {connectModuleEnv} from '../oe-module-env';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEOverlayFactory from './oe-overlay-factory';
import OEResizeObserver from '../../lib/oe-resize-observer';
import {OEToolbox} from '../../lib/oe-toolbox';
import {OECustomPropertyName} from '../../react-oe/oe-custom-property-name';

export class OEOverlayContainer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        this.onResize = this.onResize.bind(this);

        this.onEdgeOffsetsChanged = this.onEdgeOffsetsChanged.bind(this);
    }

    getEdgeOffsets()    {
        return this.edgeOffsets;
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.shallowEqual(this.props.capBarSize, nextProps.capBarSize) || !OEToolbox.shallowEqual(this.props.insets, nextProps.insets))     {
            this.updateFrame(nextProps);
        }
    }

    onConnect()  {
        this.sendFrame();
    }

    sendFrame() {
        if(!this.frame || !this.oe.isReady()) return;
        let dpr = this.oe.Module.devicePixelRatio();
        this.oe.sharedInterface.setCustomFloatProperty(OECustomPropertyName.overlayFrame, {vec: {x: dpr * this.frame.x, y: dpr * this.frame.y, z: dpr * this.frame.w, w: dpr * this.frame.h}});
    }

    updateFrame(props_)   {
        if(!this.size) return;
        let props = props_ || this.props;
        let capBarSize = props.capBarSize || {w: 0, h: 0};
        let frame = {x: props.insets.left, y: capBarSize.h, w: this.size.w, h: this.size.h};

        if(!OEToolbox.jsonEqual(frame, this.frame)) {
            this.frame = frame;
            this.sendFrame();
            //console.log('OEOverlayContainer frame - x: ' + frame.x + ', y: ' + frame.y + ', w: ' + frame.w  + ', h: ' + frame.h);
        }
    }

    onResize(sender, size) {
        this.size = size;
        this.updateFrame();
    }

    render() {
        var animate = this.props.animate;
        if(typeof(animate) === 'boolean')   {
            animate = {top: animate, right: animate, bottom: animate, left: animate};
        }

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div
                    className={'overlay-container ' + this.props.className}
                    style={{
                            top: this.props.insets.top.toString() + 'px',
                            right: this.props.insets.right.toString() + 'px',
                            bottom: this.props.insets.bottom.toString() + 'px',
                            left: this.props.insets.left.toString() + 'px',
                            transition: OEToolbox.transitionForInsets(animate)
                        }}
                >
                    <OEResizeObserver onResize={this.onResize}/>
                    <OEOverlayFactory moduleId={this.props.moduleId} target={this.props.target} onEdgeOffsetsChanged={this.onEdgeOffsetsChanged}/>
                </div>
            </React.Fragment>
        );
    }

    onEdgeOffsetsChanged(sender, edgeOffsets, animate) {
        this.edgeOffsets = edgeOffsets;
        if(this.props.onEdgeOffsetsChanged) this.props.onEdgeOffsetsChanged(edgeOffsets); 

        this.props.uiStateManager.updateState({overlay: {edgeOffsets : {$set: Object.assign({animate: animate}, edgeOffsets)}}});
        //this.props.updateEnv({ui: {overlay: {edgeOffsets : {$set: Object.assign({animate: animate}, edgeOffsets)}}}});
    }
}

OEOverlayContainer.defaultProps = {
    className: '',
    moduleId: '',
    insets: {top: 0, right: 0, bottom: 0, left: 0},
    animate: false
};

OEOverlayContainer.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    insets: PropTypes.object,
    animate: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
        top: PropTypes.bool,
        right: PropTypes.bool,
        bottom: PropTypes.bool,
        left: PropTypes.bool,
      })]),
    onEdgeOffsetsChanged: PropTypes.func
};

export default connectModuleEnv((env, setEnv, updateEnv) => {
    return {
        uiStateManager: env.component.uiStateManager,
        updateEnv: updateEnv,
        capBarSize: env.ui.capBar.size
    };
})(OEOverlayContainer);