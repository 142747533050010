import React from 'react';
import PropTypes from 'prop-types';

import {OEIcon} from '../oe-controls';
import OEScrollbars from '../oe-scrollbars';

export class OEFNSpacerElement extends React.PureComponent {
    render() {
        let height = '34px';
        if(typeof(this.props.height) === 'number')  {
            height = this.props.height.toString() + 'px';
        } else if(typeof(this.props.height) === 'string')  {
            height = OEFNSpacerElement.heights[this.props.height] ? OEFNSpacerElement.heights[this.props.height] : this.props.height;
        } 
        return <div className={'flow-nav-spacer-element ' + this.props.className} style={{height: height}} />;
    }
}

OEFNSpacerElement.defaultProps = {
    className: '',
    height: '34px'
};

OEFNSpacerElement.propTypes = {
    className: PropTypes.string,
    height:  PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

OEFNSpacerElement.heights = {
    std: '34px',
    top: '74px',
    bottom: '106px'
}

export class OEFNMenuElement extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        return (
            <div className={'flow-nav-menu-element ' + (this.props.enabled ? 'enabled ' : 'disabled ') + this.props.className}>
                <div className="left" onClick={this.onClick}>
                    {this.props.iconCode === '' ? null : <OEIcon code={this.props.iconCode}/>}
                    {this.props.leftContent}
                </div>

                <div className={'spacer ' + (this.props.iconCode === '' ? 'opaque' : '')} onClick={this.onClick}/>

                <div className="main" onClick={this.onClick}>
                    {this.props.html ? <span dangerouslySetInnerHTML={{__html: this.props.html}}/> : <span>{this.props.title}</span>}
                    {this.props.children}
                </div>
            </div>
        );
    }

    onClick()   {
        if(!this.props.enabled) return;
        this.props.onClick(this.props.id, this);
    }
}

OEFNMenuElement.defaultProps = {
    id: '',
    className: '',
    iconCode: '',
    title: '',
    enabled: true,
    onClick: () => {}
};

OEFNMenuElement.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCode: PropTypes.string,
    title: PropTypes.string,
    html: PropTypes.string,
    enabled: PropTypes.bool,
    onClick: PropTypes.func
};

export class OEFNCheckboxElement extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        return (
            <div className={'flow-nav-checkbox-element ' + this.props.className}>
                <input type="checkbox" defaultChecked={this.props.checked} onClick={this.onClick}/>
                <div>
                    <span>{this.props.title}</span>
                    {this.props.subtitle === '' ? null : <span className="sub-title">{this.props.subtitle}</span>}
                </div>
            </div>
        );
    }

    onClick()   {
        this.props.onClick(this.props.id);
    }
}

OEFNCheckboxElement.defaultProps = {
    id: '',
    className: '',
    title: '',
    subtitle: '',
    checked: false,
    onClick: () => {}
};

OEFNCheckboxElement.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCode: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    checked:  PropTypes.bool,
    onClick: PropTypes.func
};

export class OEFNHyperlinkElement extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        return (
            <div className={'flow-nav-hyperlink-element ' + this.props.className}>
                <span>{this.props.label}</span>
                <a href={this.props.href} onClick={this.props.onClick ? this.onClick : null}>
                    {this.props.title ? this.props.title : this.props.href}
                </a>
            </div>
        );
    }

    onClick()   {
        this.props.onClick(this.props.id);
    }
}

OEFNHyperlinkElement.defaultProps = {
    id: '',
    className: '',
    label: '',
    onClick: () => {}
};

OEFNHyperlinkElement.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func
};

export class OEFNChapter extends React.PureComponent {
    render() {
        return (
            <div className={'flow-nav-chapter ' + this.props.className}>
                <span className="title">{this.props.title}</span>
                <div className="body">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

OEFNChapter.defaultProps = {
    id: '',
    className: '',
    title: ''
};

OEFNChapter.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string
};

export class OEFNParagraph extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        var noChild = !this.props.children || !this.props.children.length;
        var noIcon = this.props.iconCode === '';

        return (
            <div className={'flow-nav-paragraph ' + (noChild ? 'empty ' : '') + (noIcon ? 'no-icon ' : '') + this.props.className}>
                <div className="title-bar">
                    <div className="left" onClick={this.onClick}>
                        {noIcon ? null : <OEIcon code={this.props.iconCode}/>}
                    </div>

                    <div className="main" onClick={this.onClick}>
                        {this.props.title}
                    </div>

                    <div className="right" onClick={this.onClick}>
                    </div>
                </div>
                <div className="body">
                    {this.props.children}
                </div>
            </div>
        );
    }

    onClick()   {
        this.props.onClick(this.props.id);
    }
}

OEFNParagraph.defaultProps = {
    id: '',
    className: '',
    iconCode: '',
    title: '',
    onClick: () => {}
};

OEFNParagraph.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCode: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func
};

export class OEFNStdParagraphItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        var noIcon = this.props.iconCodeLeft === '' && this.props.iconCodeRight === '';

        return (
            <div className={'flow-nav-std-item ' + (noIcon ? 'no-icon ' : '') + this.props.className}>
                <div className="left" onClick={this.onClick}>
                    {this.props.iconCodeLeft === '' ? null : <OEIcon code={this.props.iconCodeLeft}/>}
                    {this.props.iconElementLeft}
                </div>

                <div className="main" onClick={this.onClick}>
                    <span>{this.props.title}</span>
                </div>

                <div className="right" onClick={this.onClick}>
                    {this.props.iconCodeRight === '' ? null : <OEIcon code={this.props.iconCodeRight}/>}
                    {this.props.iconElementRight}
                </div>
            </div>
        );
    }

    onClick()   {
        this.props.onClick(this.props.id);
    }
}

OEFNStdParagraphItem.defaultProps = {
    id: '',
    className: '',
    iconCodeLeft: '',
    title: '',
    iconCodeRight: '',
    onClick: () => {}
};

OEFNStdParagraphItem.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCodeLeft: PropTypes.string,
    title: PropTypes.string,
    iconCodeRight: PropTypes.string,
    onClick: PropTypes.func
};

export class OEFNStdParagraphSubItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        return (
            <div className={'flow-nav-std-sub-item ' + this.props.className}>
                <div className="left" onClick={this.onClick}>
                    {this.props.iconCode === '' ? null : <OEIcon code={this.props.iconCode}/>}
                    {this.props.iconElement}
                </div>

                <div className="main" onClick={this.onClick}>
                    {this.props.html ? <span dangerouslySetInnerHTML={{__html: this.props.html}}/> : <span>{this.props.title}</span>}
                </div>
            </div>
        );
    }

    onClick()   {
        this.props.onClick(this.props.id);
    }
}

OEFNStdParagraphSubItem.defaultProps = {
    id: '',
    className: '',
    iconCode: '',
    title: '',
    onClick: () => {}
};

OEFNStdParagraphSubItem.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCode: PropTypes.string,
    title: PropTypes.string,
    html: PropTypes.string,
    onClick: PropTypes.func
};

export class OEFNBlock extends React.PureComponent {
    render() {
        return (
            <div className={'flow-nav-block ' + this.props.className} style={this.props.style}>
                {this.props.html ? <span dangerouslySetInnerHTML={{__html: this.props.content}}/> : <span>{this.props.content}</span>}
            </div>
        );
    }
}

OEFNBlock.defaultProps = {
    className: ''
};

OEFNBlock.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    html: PropTypes.bool
};

export class OEFNSeparator extends React.PureComponent {
    render() {
        return (
            <div className={'flow-nav-separator ' + this.props.className} />
        );
    }
}

OEFNSeparator.defaultProps = {
    className: ''
};

OEFNSeparator.propTypes = {
    className: PropTypes.string
};

export class OEFNView extends React.PureComponent {
    render() {
        return (
            <div className={'flow-nav-view ' + this.props.className}>
                <OEScrollbars>
                    <div className="flow-nav-view-content flow-nav-view-content-colors">
                        {this.props.children}
                    </div>
                </OEScrollbars>
            </div>
        );
    }
}

OEFNView.defaultProps = {
    className: ''
};

OEFNView.propTypes = {
    className: PropTypes.string
};

const OEFNElement = {
    Spacer: OEFNSpacerElement,
    Menu: OEFNMenuElement,
    Checkbox: OEFNCheckboxElement,
    Hyperlink : OEFNHyperlinkElement,
    Chapter: OEFNChapter,
    Paragraph: OEFNParagraph,
    StdParagraphItem: OEFNStdParagraphItem,
    StdParagraphSubItem: OEFNStdParagraphSubItem,
    Block: OEFNBlock,
    Separator: OEFNSeparator,
    View: OEFNView
};

export default OEFNElement;