import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEButton, OEIcon} from '../oe-controls';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OEToolbox} from '../../lib/oe-toolbox';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEWidgetHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        if(this.props.moduleId) this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.uiControllerType = typeof(this.props.uiControllerType) === 'number' ? this.props.uiControllerType : null;

        this.state = {
            title: ''
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onToggle = this.onToggle.bind(this);
    }

    setUIControllerType(uiControllerType)   {
        let uiControllerType_ = uiControllerType;
        if(typeof(uiControllerType_) === 'object')    {
            uiControllerType_ = uiControllerType_ === null ? uiControllerType_ : uiControllerType_.value;
        }
        if(uiControllerType_ !== null && typeof(uiControllerType_) !== 'number')    return;
        if(this.uiControllerType === uiControllerType_) return;
        this.uiControllerType = uiControllerType_;
        this.updateLanguage();
    }

    componentWillReceiveProps(nextProps) {
		if(nextProps.uiControllerType !== this.props.uiControllerType)     {
            this.setUIControllerType(nextProps.uiControllerType);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateState(released)   {
        if(!this.oe || !this.oe.isReady() || released === true) return;

        retardUpdate(this, () => {
            this.updateLanguage();
        });
    }

    updateLanguage()  {
        if(this.uiControllerType === null || !this.oe || !this.oe.isReady())     return;
        let id = OEToolbox.getTitleStringIdForUIControllerType(this.uiControllerType);
        this.setState({title: id ? this.oe.sharedInterface.getLocalizedStringEnc(id) : ''});
    }

    render()   {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'std-bg std-label-text-color widget-header ' + this.props.className}>
                    <span className="title">{this.props.title ? this.props.title : this.state.title}</span>
                    <div className="buttons">
                        {!this.props.onHelpBtnPressed ? null :
                            <OEButton className={this.props.buttonClassName + ' help-btn'} onPressed={this.props.onHelpBtnPressed}>
                                <OEIcon code={OEIconCodes.help} />
                            </OEButton>
                        }
                        {this.props.additionalButtons ? this.props.additionalButtons({...this.props}) : null}
                        <OEButton className={this.props.buttonClassName + ' close-btn'} onPressed={this.onToggle}>
                            <OEIcon code={OEIconCodes.close} />
                        </OEButton>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onToggle() {
        if(this.props.onToggle)    {    
            this.props.onToggle();
            return;
        }
        if(this.uiControllerType === null || !this.oe || !this.oe.isReady()) return;
        this.oe.sharedInterface.setUIControllerVisible(false, {value: this.uiControllerType});
    }
}

OEWidgetHeader.defaultProps = {
    className: '',
    moduleId: '',
    buttonClassName : ''
};

OEWidgetHeader.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    title: PropTypes.string,
    buttonClassName: PropTypes.string,
    additionalButtons: PropTypes.func,
    onToggle: PropTypes.func,
    onHelpBtnPressed: PropTypes.func,
};