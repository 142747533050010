import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from './oe-interface';

export default class OEInterfaceAdapter extends React.Component {

    constructor(props) {
        super(props);

        this.onPreConnect = this.onPreConnect.bind(this);
        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);  
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.connected && (nextProps.receiver !== this.props.receiver || nextProps.moduleId !== this.props.moduleId))  {
            this.reconnect(nextProps);
        }
        return false;
    }

    componentDidMount() {
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
    }

    reconnect(props)    {
        release();
        connect(props);
    }

    connect(props) {
        if(this.connected)  return;
        var props_ = props || this.props;
        this.connected = true;
        this.oe = oeInterfaceManager.getInterface(props_.moduleId);
        this.receiver = props_.receiver;
        if(this.receiver) this.receiver.oe = this.oe;
        this.oe.register(this.onConnect, this.onRelease);
        this.oe.registerOnPreConnect(this.onPreConnect);
        if(this.oe.isPreReady())   this.onPreConnect();
        if(this.oe.isReady())   this.onConnect();
    }

    release()   {
        if(!this.connected) return;
        this.connected = false;
        this.oe.unregisterOnPreConnect(this.onPreConnect);
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }
    }

    onPreConnect()  {
        if(this.receiver && typeof(this.receiver.onPreConnect) === 'function')    this.receiver.onPreConnect();
    }

    onConnect()  {
        this.updateState();
        if(this.receiver && typeof(this.receiver.onConnect) === 'function')    this.receiver.onConnect();
    }

    onRelease()    {
        this.updateState(true);
        if(this.receiver && typeof(this.receiver.onRelease) === 'function') this.receiver.onRelease();
    }

    updateState(released)   {
        if(this.receiver && typeof(this.receiver.updateState) === 'function') this.receiver.updateState(released);
    }

    render() {
        return null;
    }
}

OEInterfaceAdapter.defaultProps = {
    moduleId: ''
};

OEInterfaceAdapter.propTypes = {
    moduleId: PropTypes.string
};