import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEButton, OEIcon} from '../oe-controls';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {retardUpdate} from '../../lib/update-retarder';

export default class OENotesAreInDocumentButton extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            enabled: false,
            notesIsInDocument: false,
        };

        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.update = this.update.bind(this);

        this.onPressed = this.onPressed.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteIsInDocumentChanged, this.update);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteAdded, this.update);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteRemoved, this.update);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteIsInDocumentChanged, this.update);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteAdded, this.update);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteRemoved, this.update);
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.note) {
            this.updateUIState();
        }
    }

    updateUIState()   {
        this.setState({uiEnabled: this.oe.sharedInterface.getUIControllerNote().getUIEnabled()});
    }

    update()    {
        let note = this.oe.sharedInterface.getUIControllerNote();
        this.setState({enabled: note.getNumNotes() > 0, notesIsInDocument: note.getNotesIsInDocument()});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false});
            return;
        }

        retardUpdate(this, () => {
            this.updateUIState();
            this.update();
        });
    }

    render() {
        if(this.props.isHidden) return null;
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEButton className={this.props.className} disabled={!(this.state.uiEnabled && this.state.enabled)} onPressed={this.onPressed}>
                    <OEIcon code={this.state.notesIsInDocument ? OEIconCodes.noteIsInDocument : OEIconCodes.noteIsNotInDocument} />
                </OEButton>
            </React.Fragment>
        );
    }

    onPressed()  {
        if(!this.oe.isReady())  return;
        let note = this.oe.sharedInterface.getUIControllerNote();
        let isInDocument = note.getNotesIsInDocument();
        isInDocument = isInDocument ? isInDocument : false;
        note.setNotesIsInDocument(!isInDocument);
    }
}

OENotesAreInDocumentButton.defaultProps = {
    moduleId: '',
    isHidden: false
};

OENotesAreInDocumentButton.propTypes = {
    moduleId: PropTypes.string,
    isHidden: PropTypes.bool
};