import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from './app-env';
import {withUILevel} from '../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import OEPopover from './oe-popover';
import {OEGroupControl, OEControl, OEToggleControl, OESliderControl, OEDropdown, OEColorControl, OEButton} from './oe-controls';
import {UIControllerType, OEManualViewLinks, OESettingsControllerDefs, UILevel} from '../lib/oe-types';
import {OEDefaultConfigFactory} from './oe-default-configs';
import {retardUpdate} from '../lib/update-retarder';
import OEMultiColumnContainer from './elements/oe-multi-column-container';

export class OESettingsController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            language: 0,
            switchPrimarySecondaryLanguage: false,
            brightness: 0.5,
            bgColor: {x: 0, y: 0, z: 0},
            defaultBGColor: {x: 0, y: 0, z: 0},
            touchSensitivity: 0.5,
            vpGizmo: true,
            ambientOcclusion: false,
            maxAOQuality: 0,
            rendererPresets: [],
            activeRendererPreset: '',
            uiLevel: UILevel.std,
            componentHighlightingVP: true,
            availableLanguages: [0, 1],
            tutorUIEnabled: false,
            tutorIsClear: true,
            strings:   {
                title: '',
                languageCategory: '',
                backgroundCategory: '',
                touchSensitivityCategory: '',
                graphicsSettingsCategory: '',
                uiCategory: '',
                tutorCategory: '',
                languageListBtn: '',
                languageSwitchLabel: '',
                brightnessSliderLabel: '',
                bgColorButtonLabel: '',
                vpGizmoSwitchLabel: '',
                ambientOcclusionSwitchLabel: '',
                rendererPresetLabel: '',
                languages: {
                    lang_0: 'English',
                    lang_1: 'German',
                    lang_2: 'Chinese',
                    lang_3: 'Italian',
                    lang_4: 'Dutch',
                    lang_5: 'Korean',
                    lang_6: 'Japanese',
                    lang_7: 'Finnish',
                    lang_8: 'Portugese',
                    lang_9: 'Swedish'
                },
                uiLevelLabel: '',
                uiLevels: {
                    std: '',
                    simple: '',
                    advanced: ''
                },
                componentHighlightingVPSwitchLabel: '',
                tutorReset: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateControlsState = this.updateControlsState.bind(this);
        this.updateTutorState = this.updateTutorState.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onLanguageSelected = this.onLanguageSelected.bind(this);
        this.onLanguageSwitchChanged = this.onLanguageSwitchChanged.bind(this);
        this.onBrightnessSliderChanged = this.onBrightnessSliderChanged.bind(this);
        this.onBackgroundColorBtnChanged = this.onBackgroundColorBtnChanged.bind(this);
        this.onVPGizmoSwitchChanged = this.onVPGizmoSwitchChanged.bind(this);
        this.onTouchSensitivitySliderChanged = this.onTouchSensitivitySliderChanged.bind(this);
        this.onAmbientOcclusionSwitchChanged = this.onAmbientOcclusionSwitchChanged.bind(this);
        this.onPresetSelected = this.onPresetSelected.bind(this);
        this.onUILevelSelected = this.onUILevelSelected.bind(this);
        this.onComponentHighlightingVPSwitchChanged = this.onComponentHighlightingVPSwitchChanged.bind(this);
        this.onTutorResetBtnPressed = this.onTutorResetBtnPressed.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.settingsVignetteStrengthChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.settingsBGColorChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.vpGizmoToolEnabledStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.touchSensitivityChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.renderQualityConfigChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.rendererPresetChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiLevelChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentHighlightingVPChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.tutorIsClearStateChanged, this.updateTutorState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.settingsVignetteStrengthChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.settingsBGColorChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.vpGizmoToolEnabledStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.touchSensitivityChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.renderQualityConfigChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.rendererPresetChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiLevelChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentHighlightingVPChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.tutorIsClearStateChanged, this.updateTutorState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        const si = this.oe.sharedInterface;
        const numLanguages = si.getUIControllerSettings().getNumLanguages();

        let languages = {};
        for(let i = 0; i < numLanguages; ++i)   {
            languages['lang_' + i.toString()] =  si.getLanguageTitleString({value: i});
        }

        this.setState({
            language: si.getUIControllerSettings().getLanguage().value,
            switchPrimarySecondaryLanguage: si.getUIControllerSettings().getSwitchPrimarySecondaryLanguage(),
            strings: {
                title: si.getLocalizedStringEnc('settings_view'),
                languageCategory: si.getLocalizedStringEnc('settings_view_lang_header'),
                backgroundCategory: si.getLocalizedStringEnc('settings_view_background_header'),
                touchSensitivityCategory: si.getLocalizedStringEnc('settings_view_touch_sensitivity_header'),
                graphicsSettingsCategory: si.getLocalizedStringEnc('settings_view_graphics_settings_header'),
                uiCategory: si.getLocalizedStringEnc('settings_view_ui_header'),
                tutorCategory: si.getLocalizedStringEnc('settings_view_tutor_header'),
                languageListBtn: si.getLocalizedStringEnc('settings_view_lang_label'),
                languageSwitchLabel: si.getLocalizedStringEnc('settings_view_primary_switch'),
                brightnessSliderLabel: si.getLocalizedStringEnc('settings_view_vignetting'),
                bgColorButtonLabel: si.getLocalizedStringEnc('settings_view_bg_color'),
                vpGizmoSwitchLabel: si.getLocalizedStringEnc('settings_view_vp_gizmo'),
                ambientOcclusionSwitchLabel: si.getLocalizedStringEnc('settings_view_ambient_occlusion'),
                rendererPresetLabel: si.getLocalizedStringEnc('settings_view_renderer_preset'),
                languages: languages,
                uiLevelLabel: si.getLocalizedStringEnc('settings_view_ui_level'),
                uiLevels: {
                    std: si.getLocalizedStringEnc('settings_view_ui_level_std'),
                    simple: si.getLocalizedStringEnc('settings_view_ui_level_simple'),
                    advanced: si.getLocalizedStringEnc('settings_view_ui_level_advanced')
                },
                componentHighlightingVPSwitchLabel: si.getLocalizedStringEnc('settings_view_comp_highlighting_vp'),
                tutorReset: si.getLocalizedStringEnc('settings_view_tutor_reset'),
            }
        });

        this.updateRendererPresets();
    }

    updateRendererPresets() {
        const si = this.oe.sharedInterface;
        var presets = si.getUIControllerSettings().getRendererPresetList();
        presets = presets.map(preset => { return {name: preset, label: si.getLocalizedStringEnc('settings_view_renderer_preset_' + preset)}; });
        this.setState({rendererPresets: presets});
    }

    updateControlsState()   {
        const settings = this.oe.sharedInterface.getUIControllerSettings();
        this.setState({
            brightness: settings.getVignetteStrength(),
            bgColor: settings.getBackgroundColor(),
            defaultBGColor: settings.getDefaultBackgroundColor(),
            touchSensitivity: settings.getTouchSensitivity(),
            vpGizmo: settings.isVPGizmoEnabled(),
            ambientOcclusion: settings.getAOQuality() > 0,
            activeRendererPreset: settings.getRendererPreset(),
            uiLevel: UILevel.levelFromModuleValue(settings.getUILevel().value),
            componentHighlightingVP: settings.getComponentHighlightingVP()
        });
    }

    updateTutorState()  {
        this.setState({
            tutorIsClear: this.oe.sharedInterface.getUIControllerTutor().isClear()
        });
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerSettings().getUIEnabled(),
            tutorUIEnabled: this.oe.sharedInterface.getUIControllerTutor().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false });
            return;
        }

        retardUpdate(this, () => {
            this.setState({maxAOQuality: this.oe.sharedInterface.getUIControllerSettings().getMaxAOQuality()});

            let languages = this.oe.sharedInterface.getUIControllerSettings().getAvailableLanguages();
            languages = languages.map(lang => lang.value);
            this.setState({availableLanguages: languages});

            this.updateLanguage();
            
            this.updateControlsState();
            this.updateTutorState();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type !== this.oe.Module.UIControllerType.settings && userInfo.type !== this.oe.Module.UIControllerType.tutor) return;
        this.updateUIState();
    }

    renderControl(control, key)  {
        const types = OESettingsControllerDefs.controlTypes;
        const disabled = !(this.state.uiEnabled && control.enabled);
        const strings = this.state.strings;
        if(control.type === types.language) {
            let entries = this.state.availableLanguages.map((lang) => { return {key: lang, content: <span className="label">{strings.languages['lang_' + lang.toString()]}</span>}})
            let title = strings.languages['lang_' + this.state.language.toString()];
            return (
                <OEControl key={key}>
                    <OEDropdown
                        title={title}
                        disabled={disabled}
                        chosenKey={this.state.language}
                        entries={entries}
                        onClick={this.onLanguageSelected}
                    />
                </OEControl>
            );
        } else if(control.type === types.languageSwitch) {
            return (
                <OEToggleControl
                    key={key}
                    title = {strings.languageSwitchLabel}
                    active={this.state.switchPrimarySecondaryLanguage}
                    disabled={disabled}
                    onClick={this.onLanguageSwitchChanged}
                />
            );
        } else if(control.type === types.vignette) {
            return (
                <OESliderControl
                    key={key}
                    title = {strings.brightnessSliderLabel}
                    disabled={disabled}
                    min={0}
                    max={1}
                    step={0.005}
                    value={this.state.brightness}
                    onSlide={this.onBrightnessSliderChanged}
                />
            );
        } else if(control.type === types.backgroundColor) {
            return (
                <OEColorControl
                    key={key}
                    title = {strings.bgColorButtonLabel}
                    disabled={disabled}
                    color={this.state.bgColor}
                    defaultColor={this.state.defaultBGColor}
                    onChange={this.onBackgroundColorBtnChanged}
                />
            );
        } else if(control.type === types.vpGizmo) {
            return (
                <OEToggleControl
                    key={key}
                    title = {strings.vpGizmoSwitchLabel}
                    active={this.state.vpGizmo}
                    disabled={disabled}
                    onClick={this.onVPGizmoSwitchChanged}
                />
            );
        } else if(control.type === types.touchSensitivity) {
            return (
                <OESliderControl
                    key={key}
                    disabled={disabled}
                    min={0.3}
                    max={1.3}
                    step={0.005}
                    value={this.state.touchSensitivity}
                    onSlide={this.onTouchSensitivitySliderChanged}
                />
            );
        } else if(control.type === types.ambientOcclusion) {
            return (
                <OEToggleControl
                    key={key}
                    title = {strings.ambientOcclusionSwitchLabel}
                    active={this.state.ambientOcclusion}
                    disabled={disabled || this.state.maxAOQuality == 0}
                    onClick={this.onAmbientOcclusionSwitchChanged}
                />
            );
        } else if(control.type === types.rendererPreset) {
            if(this.state.rendererPresets.length < 2) return null;
            let activePreset = this.state.rendererPresets.find(preset => preset.name === this.state.activeRendererPreset);
            let title = this.state.strings.rendererPresetLabel;// + (activePreset ? ' - ' + activePreset.label : '');
            let entries = this.state.rendererPresets.map(preset => { return {key: preset.name, content: <span className="label">{preset.label}</span>}; });
            return (
                <OEControl key={key}>
                    <OEDropdown
                        title={title}
                        disabled={disabled}
                        chosenKey={this.state.activeRendererPreset}
                        entries={entries}
                        onClick={this.onPresetSelected}
                    />
                </OEControl>
            );
        } else if(control.type === types.uiLevel) {
            let title = strings.uiLevels[this.state.uiLevel];
            let entries = control.data ? control.data : [UILevel.std];
            entries = entries.map((level) => { return {key: level, content: <span className="label">{strings.uiLevels[level]}</span>} } )
            return (
                <OEControl key={key}>
                    <OEDropdown
                        title={title}
                        disabled={disabled}
                        chosenKey={this.state.uiLevel}
                        entries={entries}
                        onClick={this.onUILevelSelected}
                    />
                </OEControl>
            );
        } else if(control.type === types.componentHighlightingVP) {
            return (
                <OEToggleControl
                    key={key}
                    title = {strings.componentHighlightingVPSwitchLabel}
                    active={this.state.componentHighlightingVP}
                    disabled={disabled}
                    onClick={this.onComponentHighlightingVPSwitchChanged}
                />
            );
        } else if(control.type === types.tutorReset) {
            return (
                <OEControl key={key}>
                    <OEButton
                        disabled={disabled || !this.state.tutorUIEnabled || this.state.tutorIsClear}
                        className="btn"
                        onPressed={this.onTutorResetBtnPressed}
                    >
                        {strings.tutorReset}
                    </OEButton>
                </OEControl>
            );
        }
        return null;
    }

    renderControlGroup(group, key, separator)   {
        const strings = this.state.strings;
        const types = OESettingsControllerDefs.controlGroupTypes;
        let title = '';
        switch(group.type)  {
            case types.language: title = strings.languageCategory; break;
            case types.background: title = strings.backgroundCategory; break;
            case types.inputSensitivity: title = strings.touchSensitivityCategory; break;
            case types.graphics: title = strings.graphicsSettingsCategory; break;
            case types.ui: title = strings.uiCategory; break;
            case types.tutor: title = strings.tutorCategory; break;
        }

        const controls = group.controls.map((control, index) => this.renderControl(control, index)).filter(control => control != null);

        return (
            <OEGroupControl key={key} title={title} separator={separator}>
                {controls}
            </OEGroupControl>
        );
    }

    render() {
        if(!this.props.config.enabled) return null;
        const groups = this.props.config.controls.map((group, index) => this.renderControlGroup(group, index, this.props.config.controls.length > index + 1));
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control"
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.settings}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    title={this.state.strings.title}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >
                    <OEMultiColumnContainer className="settings-controller">{groups}</OEMultiColumnContainer>
                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.settings});
    }

    onLanguageSelected(key)   {
        this.oe.sharedInterface.getUIControllerSettings().setLanguage({value: key});        
    }

    onLanguageSwitchChanged() {
        this.oe.sharedInterface.getUIControllerSettings().setSwitchPrimarySecondaryLanguage(!this.state.switchPrimarySecondaryLanguage);
    }
    
    onBrightnessSliderChanged(value) {
        this.oe.sharedInterface.getUIControllerSettings().setVignetteStrength(value);
    }

    onBackgroundColorBtnChanged(color)  {
        if(!this.oe.isReady()) return;  // make sure core interface is ready here, since onBackgroundColorBtnChanged might be called while switching target and color dlg is open
        this.oe.sharedInterface.getUIControllerSettings().setBackgroundColor(color);
    }
    
    onVPGizmoSwitchChanged() {
        this.oe.sharedInterface.getUIControllerSettings().setVPGizmoEnabled(!this.state.vpGizmo);
    }
    
    onTouchSensitivitySliderChanged(value) {
        this.oe.sharedInterface.getUIControllerSettings().setTouchSensitivity(value);
    }
    
    onAmbientOcclusionSwitchChanged() {
        let max = this.oe.sharedInterface.getUIControllerSettings().getMaxAOQuality();
        this.oe.sharedInterface.getUIControllerSettings().setAOQuality(!this.state.ambientOcclusion ? max : 0);
    }

    onPresetSelected(key)   {
        this.oe.sharedInterface.getUIControllerSettings().setRendererPreset(key);
    }

    onUILevelSelected(key)  {
        this.oe.sharedInterface.getUIControllerSettings().setUILevel({value: UILevel.moduleValueFromLevel(key)});
    }

    onComponentHighlightingVPSwitchChanged()    {
        this.oe.sharedInterface.getUIControllerSettings().setComponentHighlightingVP(!this.state.componentHighlightingVP);
    }

    onTutorResetBtnPressed()    {
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.getUIControllerTutor().reset();
    }
}

OESettingsController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.settingsController()
};

OESettingsController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool,
        controls: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            controls: PropTypes.arrayOf(PropTypes.shape({
                type: PropTypes.string.isRequired,
                enabled: PropTypes.bool.isRequired,
                data: PropTypes.any
            }).isRequired).isRequired,
        })).isRequired
    }).isRequired
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.settingsController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.settings)
    }
})(withUILevel(OESettingsController));