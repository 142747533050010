export const OETarget = {
    earMacro:               'earMacro',
    earMacroAmboss:         'earMacroAmboss',
    earMicro:               'earMicro',
    skinMacroIONTO:         'skinMacroIONTO',
    skinMicroIONTO:         'skinMicroIONTO',
    eye:                    'eye',
    eyeMacro:               'eyeMacro',
    eyeMicro:               'eyeMicro',
    eyeCornea:              'eyeCornea',
    snail:                  'snail',
    toxNetz:                'toxNetz',
    zeissOpti:              'zeissOpti',
    zeissOpti2:             'zeissOpti2',
    zeissUV:                'zeissUV',
    sensorySystems:         'sensorySystems',
    sensorySystemsEar:      'sensorySystemsEar',
    sensorySystemsEye:      'sensorySystemsEye',
    hoofDynamics:           'hoofDynamics',
    hoofVessels:            'hoofVessels',
    hoofWeb:                'hoofWeb',
    hoofMicro:              'hoofMicro',
    humanArm:               'humanArm',
    humanArmPathways:       'humanArmPathways',
    humanUpperArmElbow:     'humanUpperArmElbow',
    humanForearm:           'humanForearm',
    humanShoulder:          'humanShoulder',
    humanElbow:             'humanElbow',
    humanHand:              'humanHand',
    humanLeg:               'humanLeg',
    humanAnkleFoot:         'humanAnkleFoot',
    humanHip2:              'humanHip2',
    humanLowerLeg:          'humanLowerLeg',
    humanLegPathways:       'humanLegPathways',
    humanThighKnee:         'humanThighKnee',
    humanHip:               'humanHip',
    humanKnee:              'humanKnee',
    humanKneeImplantcast:   'humanKneeImplantcast',
    humanFoot:              'humanFoot',
    humanTrunk:             'humanTrunk',
    humanSkull:             'humanSkull',
    humanCranialNerves:     'humanCranialNerves',
    humanSpine:             'humanSpine',
    humanSpineFracture:     'humanSpineFracture',
    humanAbdominalWall:     'humanAbdominalWall',
    humanChestWall:         'humanChestWall',
    humanNeckBack:          'humanNeckBack',
    humanPelvis:            'humanPelvis',
    humanShoulderGirdle:    'humanShoulderGirdle',
    humanHead:              'humanHead',
    humanHeadWIP:           'humanHeadWIP',
    humanEye2:              'humanEye2',
    humanEyeRetina:         'humanEyeRetina',
    humanLarynx:            'humanLarynx',
    humanLiverMicro:        'humanLiverMicro',
    humanHeart:             'humanHeart',
    animalCell:             'animalCell',
    plantCell:              'plantCell',
    humanCellEpithelium:    'humanCellEpithelium',
    philipsIcarus:          'philipsIcarus',
// @ADD_TARGET_BEGIN@
//    $TARGET_cC$:          '$TARGET_cC$',
// @ADD_TARGET_END@
    void:                   'void'
};

export const OEResourceTarget = Object.assign({
    zeiss: 'zeiss',
    hoof: 'hoof',
    cell: 'cell',
    amboss: 'amboss',
    ambossImport: 'ambossImport',
    anatomy: 'anatomy',
    philipsIcarusAnatomy: 'philipsIcarusAnatomy',
    skinIONTO: 'skinIONTO',
    eyeRes: 'eyeRes',
    isPureResourceTarget: function(target) {
        return target == 'zeiss' || target == 'hoof' || target == 'cell' || target == 'amboss' || target == 'ambossImport' || target == 'anatomy' || target == 'philipsIcarusAnatomy' || target == 'skinIONTO' || target == 'eyeRes';
    }
}, OETarget);