import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {VelocityTransitionGroup} from 'velocity-react';
import Hammer from 'react-hammerjs';

import {OEMediaViewerContentType, OEMediaViewerPlaybackState, OEMediaViewerReadyState, OEMediaViewerContentSizeMode} from './oe-media-viewer-controller';
import {OEMediaViewerContentController, OEMediaViewerImageContentController, OEMediaViewerVideoContentController, OEMediaViewerHtmlContentController} from './oe-media-viewer-content-controller';
import {OEMediaViewerOverlayController} from './oe-media-viewer-overlay-controller';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEResizeObserver from '../../lib/oe-resize-observer';

class ContentControllerAdapter {

    constructor(element, props, content, progress) {
        this.element = element;
        this.props = props;
        this.content = content;
        this.instance = null;

        this.setInstance = this.setInstance.bind(this);

        this.props.ref = this.setInstance;

        this.props.onReadyStateChanged = (state) => { this.onReadyStateChanged_(state); };
        this.props.onPlaybackStateChanged = (state) => { this.onPlaybackStateChanged_(state); };
        this.props.onDurationChanged = (duration) => { this.onDurationChanged_(duration); };
        this.props.onCurrentTimeChanged = (currentTime) => { this.onCurrentTimeChanged_(currentTime); };
        this.props.onContentSizeChanged = (contentSize) => { this.onContentSizeChanged_(contentSize); };
        this.props.onGoToItem = (name) => { this.onGoToItem_(name); };
        this.props.onShowMedia = (path) => { this.onShowMedia_(path); };

        this.readyState = this.content.type === OEMediaViewerContentType.video ? OEMediaViewerReadyState.noMeta : OEMediaViewerReadyState.ready;
        this.playbackState = OEMediaViewerPlaybackState.pause;
        this.currentTime = 0;
        this.duration = 0;
        this.progress = 0;

        if(progress)    {
            this.setProgress(progress);
        }

        this.contentSize = content.size;
    }

    getReadyState()  {
        return this.readyState;
    }

    getContentSize()    {
        return this.contentSize;
    }

    play()  {
        this.setPlaybackState(OEMediaViewerPlaybackState.play);
    }

    pause()  {
        this.setPlaybackState(OEMediaViewerPlaybackState.pause);
    }

    stop()  {
        this.pause();
        this.seek(0);
    }

    getPlaybackState()  {
        return this.playbackState;
    }

    setPlaybackState(playbackState)  {
        if(this.instance && typeof(this.instance.setPlaybackState) === 'function')  {
            this.instance.setPlaybackState(playbackState);
        }
        this.onPlaybackStateChanged_(playbackState);
    }

    getCurrentTime()    {
        return this.currentTime;
    }

    getDuration()    {
        return this.duration;
    }

    setProgress(progress)   {
        var oldProgress = this.progress;

        if(this.instance && typeof(this.instance.setProgress) === 'function')  {
            this.instance.setProgress(progress);
        } else {
            this.progress = progress;
            this.progressApplied = true;
        }

        if(this.progress !== oldProgress && this.onProgressChanged)   {
            this.onProgressChanged(this, this.progress);
        }
    }

    getProgress()   {
        return typeof(this.progress) === 'number' ? this.progress : (this.getDuration() == 0 ? 0 : this.getCurrentTime()/this.getDuration());
    }

    seek(seconds)   {
        if(this.instance && typeof(this.instance.seek) === 'function')  {
            this.instance.seek(seconds);
        }
        this.onCurrentTimeChanged_(seconds);
        this.progressApplied = false;
    }

    setInstance(instance)   {
        this.instance = instance;

        var readyState = this.readyState;
        var playbackState = this.playbackState;
        var duration = this.duration;
        var currentTime = this.currentTime;
        var contentSize = this.contentSize;

        if(this.instance)   {
            if(this.progressApplied)   {
                this.setProgress(this.progress);
                this.progressApplied = false;
            } else {
                this.seek(this.currentTime);
            }
            this.setPlaybackState(this.playbackState);

            if(typeof(this.instance.getReadyState) === 'function') readyState = this.instance.getReadyState();
            if(typeof(this.instance.getPlaybackState) === 'function') playbackState = this.instance.getPlaybackState();
            if(typeof(this.instance.getDuration) === 'function') duration = this.instance.getDuration();
            if(typeof(this.instance.getCurrentTime) === 'function') currentTime = this.instance.getCurrentTime();
            if(typeof(this.instance.getContentSize) === 'function') contentSize = this.instance.getContentSize();
        } else {
            readyState = this.content.type === OEMediaViewerContentType.video ? OEMediaViewerReadyState.noMeta : OEMediaViewerReadyState.ready;
        }

        this.onReadyStateChanged_(readyState);
        this.onPlaybackStateChanged_(playbackState);
        this.onDurationChanged_(duration);
        this.onCurrentTimeChanged_(currentTime);
        this.onContentSizeChanged_(contentSize);
    }

    updateProgress()    {
        var oldProgress = this.progress;
        this.progress = this.duration == 0 ? 0 : this.currentTime/this.duration;
        if(this.progress !== oldProgress && this.onProgressChanged)   {
            this.onProgressChanged(this, this.progress)
        }
    }

    onReadyStateChanged_(state) {
        if(this.readyState !== state)    {
            this.readyState = state;
            if(this.onReadyStateChanged) this.onReadyStateChanged(this, state);
        }
    }

    onPlaybackStateChanged_(state)   {
        if(this.playbackState !== state)    {
            this.playbackState = state;
            if(this.onPlaybackStateChanged) this.onPlaybackStateChanged(this, state);
        }
    }

    onDurationChanged_(duration)   {
        if(this.duration !== duration)    {
            this.duration = duration;
            this.updateProgress();
            if(this.onDurationChanged) this.onDurationChanged(this, duration);
        }
    }

    onCurrentTimeChanged_(currentTime)   {
        if(this.currentTime !== currentTime)    {
            this.currentTime = currentTime;
            this.updateProgress();
            if(this.onCurrentTimeChanged) this.onCurrentTimeChanged(this, currentTime);
        }
    }

    onContentSizeChanged_(contentSize)  {
        var contentSize_;
        if(contentSize || this.content.size)    {
            contentSize_ = Object.assign({}, contentSize, this.content.size);
        }
        if(!OEToolbox.jsonEqual(this.contentSize, contentSize_))    {
            this.contentSize = contentSize_;
            if(this.onContentSizeChanged) this.onContentSizeChanged(this, contentSize_);
        }
    }

    onGoToItem_(name)   {
        if(this.onGoToItem) this.onGoToItem(this, name);
    }

    onShowMedia_(path)   {
        if(this.onShowMedia) this.onShowMedia(this, path);
    }

    setCallbacks(onReadyStateChanged, onPlaybackStateChanged, onDurationChanged, onCurrentTimeChanged, onProgressChanged, onContentSizeChanged, onGoToItem, onShowMedia)    {
        this.onReadyStateChanged = onReadyStateChanged;
        this.onPlaybackStateChanged = onPlaybackStateChanged;
        this.onDurationChanged = onDurationChanged;
        this.onCurrentTimeChanged = onCurrentTimeChanged;
        this.onProgressChanged = onProgressChanged;
        this.onContentSizeChanged = onContentSizeChanged;
        this.onGoToItem = onGoToItem;
        this.onShowMedia = onShowMedia;
    }

    clearCallbacks()    {
        this.setCallbacks(null, null, null, null, null, null, null, null);
    }

    canSwipe() {
        if(!this.instance || typeof(this.instance.canSwipe) !== 'function') return true;
        return this.instance.canSwipe();
    }
};

export class OEMediaViewerWindowController extends React.PureComponent {

    constructor(props) {
        super(props);

        var _dataSource = null;
        var _actualContentIndex = 0;
        var _contentControllers = {};
        var _contentSize = null;
        var _contentRect = null;

        this.state = {
            actualContentIndex: 0,
            contentControllers:[],
            actualContentController: null,
            animated: false,
            shift: 0,
            readyState: OEMediaViewerReadyState.ready,
            playbackState: OEMediaViewerPlaybackState.pause,
            duration: 0,
            time: 0
        };

        this.getDataSource = function() {
            return _dataSource;
        };

        this.setDataSource = function(dataSource, reload)  {
            if(_dataSource === dataSource)  return;
            var oldValue = _dataSource;
            _dataSource = dataSource;
            if((!oldValue && dataSource) || reload)   {
                this.reload();
            }
        };

        this.getReadyState = function()    {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                return controller.getReadyState();
            }
            return OEMediaViewerReadyState.noMeta;
        }

        this.getContentSize = function()    {
            return _contentSize;
        }

        this.play = function()  {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                controller.play();
            }
        };

        this.pause = function()  {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                controller.pause();
            }
        };

        this.stop = function()  {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                controller.stop();
            }
        };

        this.getPlaybackState = function()  {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                return controller.getProgress();
            }
            return OEMediaViewerPlaybackState.pause;
        }

        this.setPlaybackState = function(state)  {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                return controller.setPlaybackState(state);
            }
        }

        this.getCurrentTime = function()    {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                return controller.getCurrentTime();
            }
            return 0;
        }

        this.getDuration = function()    {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                return controller.getDuration();
            }
            return 0;
        }

        this.getProgress = function()  {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                return controller.getProgress();
            }
            return 0;
        };

        this.setProgress = function(progress)  {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                controller.setProgress(progress);
            }
        };

        this.seek = function(seconds)   {
            var controller = _contentControllers[_actualContentIndex.toString()];
            if(controller) {
                controller.seek(seconds);
            }
        };

        this.getActualContentIndex = function() {
            return _actualContentIndex;
        };

        this.updateCache = function()   {
            if(!this.props.isCaching)   {
                this.clearCache(this.props.preloadWidth);
            } else {
                this.removeNonCacheable(this.props.preloadWidth);
            }

            this.preload();
        };

        this.clearCache = function(distance)   {
            distance = typeof(distance) === 'number' ? distance : 0;

            var keys = Object.keys(_contentControllers);

            for(var i = 0; i<keys.length; ++i)  {
                var key = keys[i];
                var controller = _contentControllers[key];
                if(controller.index < _actualContentIndex-distance || controller.index > _actualContentIndex+distance)  {
                    delete _contentControllers[key];
                }
            }

            this.setState({contentControllers: Object.values(_contentControllers) });
        };

        this.removeNonCacheable = function(distance)   {
            distance = typeof(distance) === 'number' ? distance : 0;

            var keys = Object.keys(_contentControllers);

            for(var i = 0; i<keys.length; ++i)  {
                var key = keys[i];
                var controller = _contentControllers[key];
                if(controller.index < _actualContentIndex-distance || controller.index > _actualContentIndex+distance && controller.content.isChachingEnabled === false)  {
                    delete _contentControllers[key];
                }
            }

            this.setState({contentControllers: Object.values(_contentControllers) });
        };

        this.preload = function()   {
            for(var i = 1; i <= this.props.preloadWidth; ++i)   {
                this.loadContentController(_actualContentIndex - i, null, true);
                this.loadContentController(_actualContentIndex + i, null, true);
            }
        };

        this.set = function(index, animated, force, progress)    {
            if(!this.loadContentController(index, progress)) return;
            this.applyContentController(index, animated, force);
        };

        this.next = function(animated)  {
            var dataSource = _dataSource;
            if(!dataSource) return false;
            var count = dataSource.mediaViewerContentCount();
            if(!(_actualContentIndex+1 < count || count < 0)) return false;
            animated = typeof(animated) === 'boolean' ? animated : true;
            this.set(_actualContentIndex+1, animated);
        };

        this.previous = function(animated)  {
            var dataSource = _dataSource;
            if(!dataSource) return false;
            var count = dataSource.mediaViewerContentCount();
            if(!(_actualContentIndex-1 >= 0 || count < 0)) return false;
            animated = typeof(animated) === 'boolean' ? animated : true;
            this.set(_actualContentIndex-1, animated);
        };

        this.reload = function(animated, index) {
            _contentControllers = {}
            this.setState({contentControllers: [] });
            index = typeof(index) === 'number' ? index : 0;
            this.set(index, animated, true);
        };

        this.update = function(index, animated)  {
            var dataSource = _dataSource;
            if(!dataSource) return;

            var count = dataSource.mediaViewerContentCount();
            if(count >= 0 && (index < 0 || index >= count)) return;

            if(!_contentControllers[index.toString()])  {
                return;
            }

            var progress = _contentControllers[index.toString()].getProgress();

            delete _contentControllers[index.toString()];
            this.setState({contentControllers: Object.values(_contentControllers) });

            if(index === _actualContentIndex)   {
                this.set(index, animated, true, progress);
            } else {
                this.preload();
            }
        };

        this.loadContentController = function(index, progress, isPreload) {
            var dataSource = _dataSource;
            if(!dataSource) return false;
    
            var count = dataSource.mediaViewerContentCount();
            if(count >= 0 && (index < 0 || index >= count)) return false;
    
            if(!_contentControllers[index.toString()])  {
                var content = dataSource.mediaViewerContentForIndex(index);

                if(!content || (isPreload && content.isPreloadEnabled === false)) {
                    return false;
                }

                var controller = this.contentControllerForContent(content, progress);
                controller.props.key = index.toString();
                controller.index = index;
                controller.setProgress(progress);
                _contentControllers[index.toString()] = controller;
                this.setState({contentControllers: Object.values(_contentControllers) });

            } else if(typeof(progress) === 'number')    {
                var contentController = _contentControllers[index.toString()];
                contentController.setProgress(progress);
            }

            return true;
        };

        this.applyContentController = function(index, animated, force) {
            var isIndexChanged = index !== _actualContentIndex;
            if(!(isIndexChanged || force)) return;
            var shift = index < _actualContentIndex ? -1 : 1;

            var actualContentController = _contentControllers[_actualContentIndex.toString()];
            if(actualContentController) {
                actualContentController.clearCallbacks();
                actualContentController.pause();
            }

            _actualContentIndex = index;

            var readyState = OEMediaViewerReadyState.noMeta;
            var playbackState = OEMediaViewerPlaybackState.pause;
            var duration = 0;
            var time = 0;
            var progress = 0;
            var contentSize = _contentSize;

            actualContentController = _contentControllers[_actualContentIndex.toString()];
            if(actualContentController) {

                if(actualContentController.content) {
                    if(actualContentController.content.autoPlayback)    {
                        actualContentController.play();
                    }
                }

                actualContentController.setCallbacks(this.onReadyStateChanged, this.onPlaybackStateChanged, this.onDurationChanged, this.onCurrentTimeChanged, this.onProgressChanged, this.onContentSizeChanged, this.onGoToItem, this.onShowMedia);
                readyState = actualContentController.getReadyState();
                playbackState = actualContentController.getPlaybackState();
                duration = actualContentController.getDuration();
                time = actualContentController.getCurrentTime();
                progress = actualContentController.getProgress();
                contentSize = actualContentController.getContentSize();
            }

            animated = typeof(animated) === 'boolean' ? animated : false;
            this.setState({
                actualContentIndex: _actualContentIndex,
                actualContentController: _contentControllers[index.toString()],
                animated: animated,
                shift: shift,
                readyState: readyState,
                playbackState: playbackState,
                duration: duration,
                time: time
            });
        
            if(!animated)   {
                this.updateCache();
            }

            if(isIndexChanged)  {
                this.props.onMediaItemChanged(_actualContentIndex);
            }

            this.props.onReadyStateChanged(readyState);
            this.props.onPlaybackStateChanged(playbackState);
            this.props.onDurationChanged(duration);
            this.props.onTimeChanged(time);
            this.props.onProgressChanged(progress);

            this.onContentSizeChanged_(contentSize);
        };

        this.onAnimCompleted = function()  {
            this.updateCache();
        };

        this.contentControllerForContent = function(content, progress) {

            progress = typeof(progress) === 'number' ? progress : 0;

            var props = {src: content.path, loop: content.loop, backgroundColor: content.backgroundColor, size: content.size};

            switch(content.type)    {
            case OEMediaViewerContentType.image:
                return new ContentControllerAdapter(OEMediaViewerImageContentController, props, content, progress);
            case OEMediaViewerContentType.video:
                return new ContentControllerAdapter(OEMediaViewerVideoContentController, props, content, progress);
            case OEMediaViewerContentType.web:
                return new ContentControllerAdapter(OEMediaViewerHtmlContentController, props, content, progress);
            default:
                return new ContentControllerAdapter(OEMediaViewerContentController, props, content, progress);
            }
        };

        this.onContentSizeChanged_ = function(contentSize) {
            if(!contentSize || OEToolbox.jsonEqual(contentSize, _contentSize))   return;

            _contentSize = contentSize;

            this.updateContentRect();
            this.props.onContentSizeChanged(contentSize);
        };

        this.updateContentRect = function() {
            if(!this.sizeObserver) return;

            if(!_contentSize)   {
                if(_contentRect)    {
                    _contentRect = null;
                    this.setState({contentRect: null});
                }
                return;
            }

            var size = this.sizeObserver.getSize();
            var contentRect = {x: 0, y: 0, w: size.w, h: size.h, mode: _contentSize.mode};

            if(size.h == 0 || _contentSize.h == 0)  {
                if(_contentSize.mode === OEMediaViewerContentSizeMode.fill) {
                    contentRect.top = contentRect.y;
                    contentRect.left = contentRect.x;
                    contentRect.bottom = size.h - (contentRect.y + contentRect.h);
                    contentRect.right = size.w - (contentRect.x + contentRect.w);

                    if(!OEToolbox.jsonEqual(_contentRect, contentRect)) {
                        _contentRect = contentRect;
                        this.setState({contentRect: contentRect});
                    }
                }  
                return;
            }

            var aspect = size.w / size.h;
            var contentAspect = _contentSize.w / _contentSize.h;

            if(contentAspect > aspect)  {
                contentRect.h = contentRect.w / contentAspect;
                contentRect.y = 0.5 * (size.h - contentRect.h);
            } else {
                contentRect.w = contentRect.h * contentAspect;
                contentRect.x = 0.5 * (size.w - contentRect.w);
            }

            contentRect.top = contentRect.y;
            contentRect.left = contentRect.x;
            contentRect.bottom = size.h - (contentRect.y + contentRect.h);
            contentRect.right = size.w - (contentRect.x + contentRect.w);
            
            if(!OEToolbox.jsonEqual(_contentRect, contentRect))  {
                _contentRect = contentRect;
                this.setState({contentRect: contentRect});
            }
        };

        this.onResize = function(sender, size) {
            this.sizeObserver = sender;
            this.updateContentRect();
        }.bind(this);

        this.mouseEventRoutingOptions = {hideElement: null};

        this.onOverlayElementRef = function(ref) {
            this.mouseEventRoutingOptions.hideElement = ref;
        }.bind(this);

        this.onMouseEventTargetRef = function(ref) {
            if(this.mouseEventTargetRef === ref)    return;
            this.mouseEventTargetRef = ref;
            //OEToolbox.routeMouseEvents(this.mouseEventTargetRef, this.mouseEventRoutingOptions);
        }.bind(this);

        this.onOverlayRef = function(ref)   {
            this.overlayRef = ref;
        }.bind(this);

        this.onMouseMove = function(event) {
            if(this.overlayRef) {
                this.overlayRef.resetShowCycle({x: event.clientX, y: event.clientY});
            }
        }.bind(this);

        this.onSwipeLeft = function(event) {

            if(this.state.actualContentController) {
                if(!this.state.actualContentController.canSwipe()) return;
            }

            //console.log('delta time - ' + event.deltaTime.toString());
            if(this.overlayRef) {
                this.overlayRef.onSwipeLeft(event);
            }
        }.bind(this);

        this.onSwipeRight = function(event) {
            //console.log('delta time - ' + event.deltaTime.toString());

            if(this.state.actualContentController) {
                if(!this.state.actualContentController.canSwipe()) return;
            }

            if(this.overlayRef) {
                this.overlayRef.onSwipeRight(event);
            }
        }.bind(this);

        this.onTap = function(event) {
            if(this.overlayRef) {
                this.overlayRef.resetShowCycle(event.center);
            }
        }.bind(this);

        this.onAnimCompleted = this.onAnimCompleted.bind(this);

        // overlay controller delegation
        this.playbackStateChange = this.playbackStateChange.bind(this);
        this.playbackStateChangeStop = this.playbackStateChangeStop.bind(this);
        this.progressChange = this.progressChange.bind(this);
        this.indexChange = this.indexChange.bind(this);
        this.shouldChangePrev = this.shouldChangePrev.bind(this);
        this.shouldChangeNext = this.shouldChangeNext.bind(this);
        this.onFullscreenBtnPressed = this.onFullscreenBtnPressed.bind(this);
        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);

        // actual content controller delegation
        this.onReadyStateChanged = this.onReadyStateChanged.bind(this);
        this.onPlaybackStateChanged = this.onPlaybackStateChanged.bind(this);
        this.onDurationChanged = this.onDurationChanged.bind(this);
        this.onCurrentTimeChanged = this.onCurrentTimeChanged.bind(this);
        this.onProgressChanged = this.onProgressChanged.bind(this);
        this.onContentSizeChanged = this.onContentSizeChanged.bind(this);
        this.onGoToItem = this.onGoToItem.bind(this);
        this.onShowMedia = this.onShowMedia.bind(this);
    }

    render() {

        var animated = this.state.animated;
        var enter = null;
        var leave = null;

        if(animated)    {

            var shift = this.state.shift;
            if(shift < 0)   {
                enter = { translateX: ['0%', '-100%'], zIndex:[1, 0] };
                leave = { translateX: ['100%', '0%'], zIndex:[0, 1] };
            } else if(shift > 0)   {
                enter = { translateX: ['0%', '100%'], zIndex:[1, 0] };
                leave = { translateX: ['-100%', '0%'], zIndex:[0, 1] };
            }

            /*
            enter = { opacity: 1 };
            leave = { opacity: 0 };
            */
        }

        var aContentController = this.state.actualContentController;
        var aContent = null;

        var mediaType = OEMediaViewerContentType.dummy;

        if(aContentController)  {
            aContent = [React.createElement(aContentController.element, Object.assign(aContentController.props, {contentRect: this.state.contentRect, fullscreen: this.props.fullscreen}))];
            mediaType = aContentController.content.type;
        }

        var cachedContent = this.state.contentControllers.filter(controller => controller.index !== this.state.actualContentIndex && controller.content.type !== OEMediaViewerContentType.video);
        cachedContent = cachedContent.map(controller => React.createElement(controller.element, controller.props));

        return (
            <div
                className={'media-viewer-window ' + this.props.className}
                style={{backgroundColor: this.props.backgroundColor}}
                onMouseMove={this.onMouseMove}
            >

                <OEResizeObserver onResize={this.onResize} />

                <div className="media-viewer-content-cache">
                    {cachedContent}
                </div>

                <Hammer
                    onSwipeLeft={this.onSwipeLeft}
                    onSwipeRight={this.onSwipeRight}
                    onTap={this.onTap}
                >
                    <div className="media-viewer-window-content-ui-layer">
                        <div 
                            className="media-viewer-window-content-container"
                        >
                            <VelocityTransitionGroup enter={{animation: enter, duration: 333}} leave={{animation: leave, duration: 333, complete: this.onAnimCompleted}}>
                                {aContent}
                            </VelocityTransitionGroup>
                        </div>

                        <OEMediaViewerOverlayController
                            contentRect={this.state.contentRect}
                            showCloseBtn={this.props.showCloseBtn}
                            showControlsWhenWindow={this.props.showControlsWhenWindow}
                            showControlsPermanentlyWhenPaused={this.props.showControlsPermanentlyWhenPaused}
                            contentAlignedWhenWindowed={this.props.controlsContentAlignedWhenWindowed}
                            showNextPrevButtons={this.props.showNextPrevButtons}
                            playbackStateChange={this.playbackStateChange}
                            playbackStateChangeStop={this.playbackStateChangeStop}
                            progressChange={this.progressChange}
                            indexChange={this.indexChange}
                            shouldChangePrev={this.shouldChangePrev}
                            shouldChangeNext={this.shouldChangeNext}
                            onFullscreenBtnPressed={this.onFullscreenBtnPressed}
                            onCloseBtnPressed={this.onCloseBtnPressed}
                            mediaType={mediaType}
                            fullscreen={this.props.fullscreen}
                            readyState={this.state.readyState}
                            playbackState={this.state.playbackState}
                            duration={this.state.duration}
                            time={this.state.time}
                            ref={this.onOverlayRef}
                            elementRef={this.onOverlayElementRef}
                            mouseEventTargetRef={this.onMouseEventTargetRef}
                        />

                    </div>
                </Hammer>

            </div>
        );
    }

    // overlay controller delegation
    playbackStateChange(state)  {
        if(state === OEMediaViewerPlaybackState.pause)  {
            this.pause();
        } else {
            this.play();
        }
    }

    playbackStateChangeStop()   {
        this.stop();
    }

    progressChange(progress)    {
        this.setProgress(progress);
    }

    indexChange(change)  {
        this.set(this.getActualContentIndex() + change, true);
    }

    shouldChangePrev()  {
        return this.props.shouldChangePrev(this.getActualContentIndex());
    }

    shouldChangeNext()  {
        return this.props.shouldChangeNext(this.getActualContentIndex());
    }

    onFullscreenBtnPressed(fullscreen)    {
        this.props.onFullscreenBtnPressed(fullscreen);
    }

    onCloseBtnPressed() {
        this.props.onCloseBtnPressed();
    }

    // actual content controller delegation
    onReadyStateChanged(controller, state)  {
        if(controller.index !== this.getActualContentIndex())    return;
        this.setState({readyState: state});
        this.props.onReadyStateChanged(state);
    }

    onPlaybackStateChanged(controller, state)   {
        if(controller.index !== this.getActualContentIndex())    return;
        this.setState({playbackState: state});
        this.props.onPlaybackStateChanged(state);
    }

    onDurationChanged(controller, duration)  {
        if(controller.index !== this.getActualContentIndex())    return;
        this.setState({duration: duration});
        this.props.onDurationChanged(duration);
    }

    onCurrentTimeChanged(controller, currentTime)   {
        if(controller.index !== this.getActualContentIndex())    return;
        this.setState({time: currentTime});
        this.props.onTimeChanged(currentTime);
    }

    onProgressChanged(controller, progress) {
        if(controller.index !== this.getActualContentIndex())    return;
        this.props.onProgressChanged(progress);
    }

    onContentSizeChanged(controller, contentSize) {
        if(controller.index !== this.getActualContentIndex())    return;
        this.onContentSizeChanged_(contentSize);
    }

    onGoToItem(controller, name)    {
        this.props.onGoToItem(name);
    }

    onShowMedia(controller, path)   {
        this.props.onShowMedia(path);
    }
}

OEMediaViewerWindowController.defaultProps = {
    className: '',
    preloadWidth: 2,
    isCaching: true,
    showCloseBtn: false,
    showControlsWhenWindow: false,
    showControlsPermanentlyWhenPaused: true,
    controlsContentAlignedWhenWindowed: true,
    showNextPrevButtons: true,
    fullscreen: false,
    onMediaItemChanged: (index) => {},
    onReadyStateChanged: (state) => {},
    onPlaybackStateChanged: (state) => {},
    onTimeChanged: (time) => {},
    onDurationChanged: (duration) => {},
    onProgressChanged: (progress) => {},
    onContentSizeChanged: (contentSize) => {},
    shouldChangePrev: (index) => { return false; },
    shouldChangeNext: (index) => { return false; },
    onGoToItem: (name) => {},
    onShowMedia: (path) => {},
    onFullscreenBtnPressed: (fullscreen) => { },
    onCloseBtnPressed: () => {},
    backgroundColor: ''
};

OEMediaViewerWindowController.propTypes = {
    className: PropTypes.string,
    preloadWidth: PropTypes.number,
    isCaching: PropTypes.bool,
    showCloseBtn: PropTypes.bool,
    showControlsWhenWindow: PropTypes.bool,
    showControlsPermanentlyWhenPaused: PropTypes.bool,
    controlsContentAlignedWhenWindowed: PropTypes.bool,
    showNextPrevButtons: PropTypes.bool,
    fullscreen: PropTypes.bool,
    onMediaItemChanged: PropTypes.func,
    onReadyStateChanged: PropTypes.func,
    onPlaybackStateChanged: PropTypes.func,
    onTimeChanged: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onProgressChanged: PropTypes.func,
    onContentSizeChanged: PropTypes.func,
    shouldChangePrev: PropTypes.func,
    shouldChangeNext: PropTypes.func,
    onGoToItem: PropTypes.func,
    onShowMedia: PropTypes.func,
    onFullscreenBtnPressed: PropTypes.func,
    onCloseBtnPressed: PropTypes.func,
    backgroundColor: PropTypes.string
};