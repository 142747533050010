import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import {slInterface} from '../../react-oe/sl-interface';
import {OEIcon} from '../oe-controls';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {retardUpdate} from '../../lib/update-retarder';

export default class OELicensingTitleBar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            language: 0,
            availableLanguages: [0, 1],
            strings:   {
                help: 'Help',
                deactivateAll: 'Deactivate all licenses', 
                language: 'Language',
                languages: {
                    lang_0: 'English',
                    lang_1: 'German',
                    lang_2: 'Chinese',
                    lang_3: 'Italian',
                    lang_4: 'Dutch',
                    lang_5: 'Korean',
                    lang_6: 'Japanese',
                    lang_7: 'Finnish',
                    lang_8: 'Portugese',
                    lang_9: 'Swedish'
                }
            }
        };

        this.onConnect = this.onConnect.bind(this);
        
        this.updateLanguage = this.updateLanguage.bind(this);

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount()    {
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
    }
  
    connect() {
        slInterface.register(this.onConnect);
        if(slInterface.isReady())   this.onConnect();
    }

    release()   {
        slInterface.unregister(this.onConnect);
        if(slInterface.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        slInterface.sharedNotificationCenter.register(slInterface.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.updateState(true);
        slInterface.sharedNotificationCenter.unregister(slInterface.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()    {
        let si = slInterface.sharedInterface;

        const numLanguages = si.getSettingsController().getNumLanguages();

        let languages = {};
        for(let i = 0; i < numLanguages; ++i)   {
            let lang = new slInterface.Module.LanguageType();
            lang.value = i;
            languages['lang_' + i.toString()] =  si.getLanguageTitleString(lang);
        }

        this.setState({
            language: si.getSettingsController().getLanguage().value,
            strings: {
                help: si.getLocalizedStringEnc('licensing_start_web_menu_help'),
                deactivateAll: si.getLocalizedStringEnc('licensing_start_web_menu_deactivate_all'),
                language: si.getLocalizedStringEnc('licensing_start_web_menu_language'),
                languages: languages
            }
        });
    }

    updateState(released)   {
        if(!slInterface.isReady() || released === true) {
            return;    
        }

        retardUpdate(this, () => {
            let languages = slInterface.sharedInterface.getSettingsController().getAvailableLanguages();
            languages = languages.map(lang => lang.value);
            this.setState({availableLanguages: languages});
            this.updateLanguage();
        });
    }

    toggle() {
        this.setState((prevState, props) => { 
            let newState = clone(prevState);
            newState.isOpen = !prevState.isOpen;
            return newState;
        });
    }

    render() {
        const strings = this.state.strings;

        let langEntries = this.state.availableLanguages.map(lang => 
            <DropdownItem key={lang} onClick={() => this.onLanguageSelected(lang)}>
                {strings.languages['lang_' + lang.toString()]}
            </DropdownItem>
        );

        return (
            <div className="title-bar">
                <Navbar color="white" light expand="md">
                    <NavbarBrand href="https://www.effigos.com/" target="_blank">
                        <img src="images/licensing-app/logo_effigos.png" />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    <OEIcon code={OEIconCodes.licensingSettings}/>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <div className="dropdown-submenu">
                                        <a className="dropdown-item" tabIndex="-1" href="#">
                                            {strings.language}: {strings.languages['lang_' + this.state.language.toString()]}
                                        </a>
                                        <div className="dropdown-menu">
                                            {langEntries}
                                        </div>
                                    </div>
                                    <DropdownItem href="https://effigos.com/en/contact/" target="_blank">
                                        {strings.help}
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => this.onDeactivateAll()}>
                                        {strings.deactivateAll}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }

    onLanguageSelected(language)   {
        if(!slInterface.isReady())   return;

        let lang = new slInterface.Module.LanguageType();
        lang.value = language;
        slInterface.sharedInterface.getSettingsController().setLanguage(lang);        
    };

    onDeactivateAll()   {
        if(this.props.onDeactivateAll)   this.props.onDeactivateAll();
    }
}

OELicensingTitleBar.defaultProps = {
};

OELicensingTitleBar.propTypes = {
    onDeactivateAll: PropTypes.func
};