
export default class NotificationCenter {

    constructor()   {
        this.methods = {};
    }

    post(name, userInfo)  {
        if(typeof(name) !== 'string')   {
            console.log("Warning - trying to post notification whith non-string type - " + typeof(name));
        }

        if(!(name in this.methods)) return;

        var arr = this.methods[name];
        
        var postFn = function(item)   {
            if(!this.methods[name].includes(item)) return;
            item(name, userInfo);
        }.bind(this);

        arr.forEach(postFn);
    }
    
    register(name, method)  {
        if(typeof(name) !== 'string')   {
            console.log("Warning - trying to register notification handler for non-string type - " + typeof(name));
        }

        if(!(name in this.methods)) {
            this.methods[name] = new Array();
        }

        this.methods[name].push(method);
    }

    unregister(name, method)  {
        if(typeof(name) !== 'string')   {
            console.log("Warning - trying to unregister notification handler for non-string type - " + typeof(name));
        }

        if(!(name in this.methods)) return;

        this.methods[name] = this.methods[name].filter(function(item) { 
            return item !== method;
        });
    }

    isRegistered(name, method) {
        return (name in this.methods) ? this.methods[name].includes(method) : false;
    }
}