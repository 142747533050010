import React from 'react';
import PropTypes from 'prop-types';

import OEAnimationControl from '../../animation-control/oe-animation-control';
import {OEIcon, OEButton, OEToggle} from '../../oe-controls';
import {OEIconCodes} from '../../../lib/oe-icon-codes';

export default class OEZeissOverlayAnimationControl extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    renderHideLensControl() {
        let config = this.props.hideLens;
        if(!config || config.hidden) return;
        return (
            <div className="hide-lens-control">
                <img src="images/icons/glasses.png"/>
                <OEToggle
                    onClick={config.onClick}
                    disabled={!this.props.enabled || config.disabled}
                    active={config.active}
                />
            </div>
        );
    }

    renderCorrectionButton()    {
        let config = this.props.correctionButton;
        if(!config || config.hidden) return;

        return (
            <OEButton
                className="transparent-btn animation-controls-btn correction"
                disabled={!this.props.enabled || !this.props.animationControlEnabled || config.disabled}
                onPressed={config.onPressed}
            >
                <OEIcon code={OEIconCodes.zeissOpti.correction}/>
            </OEButton>
        );
    }

    renderRightLabel()  {
        if(typeof(this.props.rightLabel) !== 'string') return;
        return (
            <span className="right-label">
                {this.props.rightLabel}
            </span>
        );
    }

    render()  {

        let correctionButton = this.renderCorrectionButton();

        return (
            <div 
                className={'view animation-control ' + this.props.className}
                style={{visibility: this.props.visible ? 'visible' : 'hidden', opacity: this.props.visible ? '1.0' : '0.0'}}
            >

                {this.props.header}

                <div 
                    className="body light-bg" 
                    style={{ pointerEvents: this.props.visible ? 'initial' : 'none' }}
                >
                    {this.renderHideLensControl()}

                    <div className="correction-btn-control">
                        {correctionButton}

                        <OEAnimationControl
                            enabled={this.props.enabled && this.props.animationControlEnabled}
                            control={{stopBtnVisible: !correctionButton}}
                            adapter={this.props.adapter}
                            onPlayBtnPressed={this.props.onPlayBtnPressed}
                            onPauseBtnPressed={this.props.onPauseBtnPressed}
                            onStopBtnPressed={this.props.onStopBtnPressed}
                            onProgressSliderChanged={this.props.onProgressSliderChanged}
                        />
                    </div>

                    {this.renderRightLabel()}

                </div>

                {this.props.footer}

            </div>
        );
    }
}

OEZeissOverlayAnimationControl.defaultProps = {
    className: '',
    visible: true,
    enabled: true,
    animationControlEnabled: true
};

OEZeissOverlayAnimationControl.propTypes = {
    className: PropTypes.string,
    visible: PropTypes.bool,
    enabled: PropTypes.bool,
    animationControlEnabled: PropTypes.bool,
    hideLens: PropTypes.shape({
        disabled: PropTypes.bool,
        active: PropTypes.bool,
        hidden: PropTypes.bool,
        onClick: PropTypes.func
    }),
    correctionButton: PropTypes.shape({
        disabled: PropTypes.bool,
        hidden: PropTypes.bool,
        onPressed: PropTypes.func
    }),
    rightLabel: PropTypes.string
};