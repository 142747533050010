import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {delegateOpenState, frefToRef} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEStdModalController from '../../modals/oe-std-modal-controller';
import {OEFNWelcomeEntryTypes} from '../../../lib/oe-types';
import {OENavigationController, OENavigationViewComponent} from '../../../lib/oe-navigation-controller';
import OEFNWelcome from './oe-flow-nav-welcome';
import OEFNHelp from './oe-flow-nav-help';
import {OEBlurModalBackdropLayer} from '../../oe-blur-layer';

export class OEFNStartView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.options = this.props.options;
        this.isOpen_ = false;

        this.navRef = null;

        this.state = {
            title: '',
            isOpen: this.isOpen_,
            options: this.options,
            showCloseBtn: true,
            showLogo: true,
            navRef: this.navRef,
            doBlur: true
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onNavRef = this.onNavRef.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.onStackTopChanged = this.onStackTopChanged.bind(this);
        this.onStackChanged = this.onStackChanged.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
    }

    setOpen(open = true, options)   {
        if((open || typeof(this.props.isOpen) === 'boolean') && !this.props.options)    {
            this.options = options;
            if(typeof(this.props.isOpen) === 'boolean') {
                this.setState({options: this.options});
                return;
            }
        }
        if(typeof(this.props.isOpen) === 'boolean') return;
        let isOpenChanged = this.isOpen_ != open;
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_, options: this.options});
        if(isOpenChanged && typeof(this.props.onOpenStateChanged) === 'function')  this.props.onOpenStateChanged(this, this.isOpen_);
    }

    isOpen()    {
        return this.isOpen_;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.options !== this.props.options)     {
            this.options = nextProps.options;
            this.setState({options: this.options});
        }

        if(nextProps.showCloseBtnAllways !== this.props.showCloseBtnAllways)     {
            this.onStackChanged();
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        setTimeout(this.onTimeout.bind(this), 500);        
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onTimeout() {
        if(this.props.config.showAtStartUp && this.oe.isReady() && (!this.props.config.targetViewShowedAtStartFlag || !this.props.appState.targetViewShowedAtStart) && localStorage.getItem('dontShowStartViewAtStartUp') !== 'true')   {
            this.setOpen(true);
            if(this.props.config.targetViewShowedAtStartFlag)   this.props.setAppState({targetViewShowedAtStart: true});
        }
    }

    updateLanguage()   {
        this.onTitleChanged();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    onNavRef(ref)  {
        if(this.navRef === ref) return;
        this.navRef = ref;
        this.setState({navRef: ref});
        if(this.navRef) this.onStackChanged();
    }

    render() {
        let isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEStdModalController
                    moduleId={this.props.moduleId}
                    className={'simple-header clear-backdrop flow-nav-modal start-view blur-by-manual-view ' + this.props.className}
                    title={this.state.title}
                    logo={this.props.showLogo && this.state.showLogo ? this.props.config.logo : null}
                    isOpen={isOpen}
                    hasCloseBtn={this.state.showCloseBtn}
                    onToggle={this.onToggle}
                >
                    <OENavigationController ref={this.onNavRef} onStackChanged={this.onStackChanged} onStackTopChanged={this.onStackTopChanged} onTitleChanged={this.onTitleChanged}/>

                    {this.state.options !== 'help' ?
                        <OENavigationViewComponent navigationController={this.state.navRef} title="welcome_view_title">
                            <OEFNWelcome
                                appComponent={this.props.appComponent}
                                moduleId={this.props.moduleId}
                                navigationController={this.state.navRef}
                                locStringPrefix={this.props.locStringPrefix}
                                entries={this.props.entries}
                                parent={this}
                            />
                        </OENavigationViewComponent> : 
                        <OENavigationViewComponent navigationController={this.state.navRef}>
                            <OEFNHelp appComponent={this.props.appComponent} moduleId={this.props.moduleId} navigationController={this.state.navRef}/>
                        </OENavigationViewComponent>
                    }

                    <OEBlurModalBackdropLayer/>
                </OEStdModalController>
            </React.Fragment>
        );
    }

    onToggle() { 
        if(this.props.onToggle)  {
            this.props.onToggle();
        } else {
            if(this.navRef && this.navRef.stackSize() > 1) {
                this.navRef.pop();
            } else {
                this.setOpen(false);
            }
        }
    }

    onStackChanged(size)    {
        if(typeof(size) != 'number')    size = this.navRef ? this.navRef.stackSize() : 0;
        this.setState({showCloseBtn: this.options === 'help' || size > 1 || this.props.showCloseBtnAllways});
        this.setState({showLogo: size <= 1});
    }

    onStackTopChanged() {
    }

    onTitleChanged(title)   {
        if(!this.oe.isReady()) return;
        this.titleId = typeof(title) === 'string' ? title : this.titleId;
        this.setState({title: !this.titleId || this.titleId === '' ? '' : this.oe.sharedInterface.getLocalizedStringEnc(this.titleId)});
    }
}

OEFNStartView.defaultProps = {
    moduleId: '',
    className: '',
    config: {
        logo: null,
        showAtStartUp: true,
        targetViewShowedAtStartFlag: false
    },
    locStringPrefix: '',
    entries:  [
        {type: OEFNWelcomeEntryTypes.spacer, height: 52},
        {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text', mask: {licenseValid: true}},
        {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text_no_license', mask: {licenseValid: false}},
        {type: OEFNWelcomeEntryTypes.spacer, height: 32},
        {type: OEFNWelcomeEntryTypes.licensing, mask: {licenseValid: false}},
        {type: OEFNWelcomeEntryTypes.home, stringId: 'welcome_view_home_v2'},
        {type: OEFNWelcomeEntryTypes.mediaCenter, stringId: 'welcome_view_pres_v2'},
        {type: OEFNWelcomeEntryTypes.quickStart},
        {type: OEFNWelcomeEntryTypes.manual},
        {type: OEFNWelcomeEntryTypes.spacer, height: 20},
        {type: OEFNWelcomeEntryTypes.dontShowCheckbox, noSubtitle: true, stringId: 'welcome_view_dont_show_again_title_v2'},
        {type: OEFNWelcomeEntryTypes.spacer, height: 8}
    ],
    showLogo: true,
    showCloseBtnAllways: true
};

OEFNStartView.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    config: PropTypes.shape({
        logo: PropTypes.string,
        showAtStartUp: PropTypes.bool,
    }),
    locStringPrefix: PropTypes.string,
    entries: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf([
            OEFNWelcomeEntryTypes.text,
            OEFNWelcomeEntryTypes.menu,
            OEFNWelcomeEntryTypes.home,
            OEFNWelcomeEntryTypes.licensing,
            OEFNWelcomeEntryTypes.mediaCenter,
            OEFNWelcomeEntryTypes.help,
            OEFNWelcomeEntryTypes.quickStart,
            OEFNWelcomeEntryTypes.manual,
            OEFNWelcomeEntryTypes.spacer,
            OEFNWelcomeEntryTypes.dontShowCheckbox
        ]).isRequired,
        id: PropTypes.string,
        stringId: PropTypes.string,
        string: PropTypes.string,
        html: PropTypes.bool,
        icon: PropTypes.string,
        onClick: PropTypes.func,
        logo: PropTypes.string,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        noSubtitle: PropTypes.bool,
        fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        mask: PropTypes.shape({
            licenseValid: PropTypes.bool
        })
    })),
    showLogo: PropTypes.bool,
    showCloseBtnAllways: PropTypes.bool,
    onToggle: PropTypes.func
};

export default delegateOpenState(connectAppEnv((env) => { return {
    appState: env.state,
    setAppState: env.setState
}})(frefToRef(OEFNStartView)))