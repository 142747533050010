import React from 'react';
import PropTypes from 'prop-types';
import * as screenfull from 'screenfull';

import {connectAppEnv} from '../../app-env';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {retardUpdate} from '../../../lib/update-retarder';
import {OETarget, OEResourceTarget} from '../../../react-oe/oe-target';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEIcon, OEButton} from '../../oe-controls';
import OETargetMenuDef from '../../oe-target-menu';
import OEResizeObserver from '../../../lib/oe-resize-observer';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {OEPhilipsIcarusCustomPropKey, OEPhilipsIcarusMode, OEPhilipsIcarusAnimation} from '../../overlays/oe-philips-icarus-overlay';

export class OEPhilipsIcarusCapBar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.connectFlag = false;

        this.layoutType = {
            min: 'min',
            singleRow: 'single-row',
            twoRow: 'two-row',
            threeRow: 'three-row'
        };

        this.targetType = {
            patient: 'patient',
            xRay: 'xRay',
            anatomy: 'anatomy'
        };

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.target = this.targetType.patient;
        this.mode = OEPhilipsIcarusMode.uninitialized;
        this.activeAnimation = OEPhilipsIcarusAnimation.uninitialized;
        this.anatomyTarget = OETarget.humanKnee;

        this.state = {
            target: this.target,
            layoutType: this.layoutType.min,
            strings:   {
                patient: 'Positioning',
                xRay: 'X-Ray',
                anatomy: 'Anatomy'
            },
            targetBtnBar: this.targetBtnBarState(props, false)
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onCustomPropertyChanged = this.onCustomPropertyChanged.bind(this);

        this.onWindowResized = this.onWindowResized.bind(this);
        this.onTargetTitleResize = this.onTargetTitleResize.bind(this);
        this.onTargetMenuResize = this.onTargetMenuResize.bind(this);
        this.onTargetBtnBarContentResize = this.onTargetBtnBarContentResize.bind(this);
        this.onTargetMenuRef = this.onTargetMenuRef.bind(this);
        this.onMiddleRef = this.onMiddleRef.bind(this);
        this.onTargetBtnBarContentRef = this.onTargetBtnBarContentRef.bind(this);

        this.onMainMenuControlBtnPressed = this.onMainMenuControlBtnPressed.bind(this);
        this.onTargetMenuToggle = this.onTargetMenuToggle.bind(this);
        this.onTargetBtnPressed = this.onTargetBtnPressed.bind(this);
        this.onFullscreenBtnPressed = this.onFullscreenBtnPressed.bind(this);
    }

    componentDidMount()    {
        this.onWindowResized();
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onWindowResized);
    }

    componentWillUpdate(nextProps, nextState)   {
        if(nextProps.target !== this.props.target)  {
            this.updateTargetType(nextProps);
            this.updateTargetBtnBar(nextProps);
            if(nextProps.target !== OETarget.philipsIcarus)    this.anatomyTarget = nextProps.target;
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    updateLanguage()   {
        const si = this.oe.sharedInterface;
        this.setState({strings: {
            patient: si.getLocalizedStringEnc('target_menu_philips_icarus_positioning'),
            xRay: si.getLocalizedStringEnc('target_menu_philips_icarus_x_ray'),
            anatomy: si.getLocalizedStringEnc('target_menu_philips_icarus_anatomy')
        }});
    }

    updateLayout()  {
        if(!this.connectFlag)   return;
        
        if(!this.targetTitleSize)   this.targetTitleSize = {w: 0, h: 0};

        if(!this.targetTitleSize || !this.targetMenuSize || !this.middleRef || !this.targetBtnBarContentRef) {
            this.setState({layoutType: this.layoutType.singleRow});
            return;
        }

        const middleWidth = $(this.middleRef).outerWidth();
        const targetBtnBarContentWidth = $(this.targetBtnBarContentRef).outerWidth();
        const targetBarContentWidth = this.targetTitleSize.w + this.targetMenuSize.w;
        const targetTitlePaddingComp = {
            multiSingle: this.state.layoutType === this.layoutType.singleRow ? 0 : 24,
            singleMulti: this.state.layoutType === this.layoutType.singleRow ? -24 : 0
        };

        let layoutType = this.layoutType.singleRow;
        if(targetBarContentWidth - targetTitlePaddingComp.singleMulti > middleWidth - 16) {
            layoutType = this.layoutType.threeRow;
        } else if(targetBarContentWidth + targetTitlePaddingComp.multiSingle + 4 >= 0.5 * (middleWidth - targetBtnBarContentWidth))  {
            layoutType = this.layoutType.twoRow;
        }

        this.setState({layoutType: layoutType});
    }

    updateTargetType(props) {
        props = props || this.props;
        this.target = props.target === OETarget.philipsIcarus ? (this.mode === OEPhilipsIcarusMode.xRay ? this.targetType.xRay : this.targetType.patient) : this.targetType.anatomy;
        this.setState({target: this.target});
    }

    targetBtnBarState(props, enabled)    {
        props = props || this.props;
        enabled = typeof(enabled) === 'boolean' ? enabled : true;
        return {
            patient: {
                activated: props.target == OETarget.philipsIcarus && this.mode == OEPhilipsIcarusMode.std,
                enabled: enabled
            },
            xRay: {
                activated: props.target == OETarget.philipsIcarus && this.mode == OEPhilipsIcarusMode.xRay,
                enabled: enabled && (this.activeAnimation > OEPhilipsIcarusAnimation.patient || this.target !== this.targetType.patient)
            },
            anatomy: {
                activated: props.target != OETarget.philipsIcarus,
                enabled: enabled
            }
        };
    }

    updateTargetBtnBar(props, enabled)    {
        this.setState({targetBtnBar: this.targetBtnBarState(props, enabled)});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.compControllerWasVisible = false;
            this.updateTargetBtnBar(this.props, false);
            return;
        }

        this.connectFlag = true;

        this.compControllerWasVisible = false;

        const mediaItemAnimationOffset = OEPhilipsIcarusAnimation.knee; // not every animation gets a media item in the active category, i.e., patient media item is filtered

        if(this.props.target === OETarget.philipsIcarus)  {
            if(this.activeAnimation === OEPhilipsIcarusAnimation.patient && this.mode === OEPhilipsIcarusMode.patient)   {
                this.oe.sharedInterface.getUIControllerMediaCenter().setActualMediaItemByCategoryIndex(this.activeAnimation - mediaItemAnimationOffset);
            } else if(this.activeAnimation > OEPhilipsIcarusAnimation.patient || (this.activeAnimation == OEPhilipsIcarusAnimation.patient && this.mode === OEPhilipsIcarusMode.xRay))   {
                switch(this.anatomyTarget)  {
                    case OETarget.humanKnee: this.activeAnimation = OEPhilipsIcarusAnimation.knee; break;
                    case OETarget.humanFoot: this.activeAnimation = OEPhilipsIcarusAnimation.foot; break;
                }
                this.oe.sharedInterface.getUIControllerMediaCenter().setActualMediaItemByCategoryIndex(this.activeAnimation - mediaItemAnimationOffset);
            } else {
                this.activeAnimation = this.oe.sharedInterface.getCustomProperty(OEPhilipsIcarusCustomPropKey.animation).value;
            }

            if(this.mode === OEPhilipsIcarusMode.xRay)  {
                if(this.activeAnimation === OEPhilipsIcarusAnimation.patient)   {
                    this.oe.sharedInterface.setCustomIntProperty(OEPhilipsIcarusCustomPropKey.mode, OEPhilipsIcarusMode.std);
                } else {
                    this.oe.sharedInterface.setCustomIntProperty(OEPhilipsIcarusCustomPropKey.mode, this.mode);
                }
            } else if(this.mode === OEPhilipsIcarusMode.uninitialized)  {
                this.mode = this.oe.sharedInterface.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;
            }
        }

        retardUpdate(this, () => {
            this.updateTargetType();
            this.updateTargetBtnBar();
            this.updateLanguage();
            this.updateLayout();
        });
    }

    onUIControllerStateChanged(message, userInfo)   {
        if(userInfo.type === this.oe.Module.UIControllerType.component && userInfo.visible) {
            this.compControllerWasVisible = false;
            if(this.targetMenuRef) this.targetMenuRef.setOpen(false);
        }
    }

    onCustomPropertyChanged(message, userInfo)  {
        if(this.props.target !== OETarget.philipsIcarus) return;
        if(userInfo.key === OEPhilipsIcarusCustomPropKey.mode || userInfo.key === OEPhilipsIcarusCustomPropKey.animation)   {
            this.mode = this.oe.sharedInterface.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;
            this.activeAnimation = this.oe.sharedInterface.getCustomProperty(OEPhilipsIcarusCustomPropKey.animation).value;
            this.updateTargetType();
            this.updateTargetBtnBar();
        }
    }

    onWindowResized()   {
        this.updateLayout();
    }

    onTargetTitleResize(sender, size)  {
        if(OEToolbox.jsonEqual(this.targetTitleSize, size)) return;
        this.targetTitleSize = size;
        this.updateLayout();
    }

    onTargetMenuResize(sender, size)  {
        if(OEToolbox.jsonEqual(this.targetMenuSize, size)) return;
        this.targetMenuSize = size;
        this.updateLayout();
    }

    onTargetBtnBarContentResize(sender, size)   {
        this.setState({targetBtnBarWidth: size.w});
    }

    onTargetMenuRef(ref)    {
        this.targetMenuRef = ref;
    }

    onMiddleRef(ref)   {
        if(this.middleRef === ref) return;
        this.middleRef = ref;
        this.updateLayout();
    }

    onTargetBtnBarContentRef(ref) {
        if(this.targetBtnBarContentRef === ref) return;
        this.targetBtnBarContentRef = ref;
        this.updateLayout();
    }

    renderTargetBar()   {
        const targetTitle = this.state.strings[this.state.target]; 
        const layoutType = this.state.layoutType === this.layoutType.min ? this.layoutType.singleRow : this.state.layoutType;
        const targetBarStyle =  layoutType === this.layoutType.singleRow && this.state.targetBtnBarWidth ? {width: 'calc(50% - ' + (0.5 * this.state.targetBtnBarWidth).toString() + 'px)'} : null;

        return this.state.layoutType === this.layoutType.min ? (
            <div className="target-bar" style={targetBarStyle}>
                <div className="light-bg-border spacer-flex"/>
            </div>
        ) : (
            <div className="target-bar" style={targetBarStyle}>
                {/*layoutType === this.layoutType.twoRow ? <div className="light-bg-border spacer-flex"/> : null*/} {/*with out target title the target menu should be aligned to the left for two row layout*/}
                <div className="content">
                    <div className="target-title-container">
                        {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
                        <span className="light-bg-border target-title" style={{paddingLeft: 2, paddingRight: 0, verticalAlign: 'inherit'}}> {/*style for empty target title only serving as a spacer for target menu to the left side*/}
                            <OEResizeObserver onResize={this.onTargetTitleResize} />
                            {/*targetTitle*/}
                        </span>
                        {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
                    </div>
                    <div className="target-menu-container">
                        {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
                        <div>
                            <OEResizeObserver onResize={this.onTargetMenuResize} />
                            <OETargetMenuDef moduleId={this.props.moduleId} fadeInOut={false} ref={this.onTargetMenuRef} onToggle={this.onTargetMenuToggle}/>
                        </div>
                        {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
                    </div>
                </div>
                {layoutType !== this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
            </div>
        );
    }

    render() {
        const fullscreenBtn = !this.props.showFullscreenBtn ? null :
            <OEButton className="btn light-hover-bg-border" onPressed={this.onFullscreenBtnPressed}>
                <OEIcon code={this.props.isFullscreen ? OEIconCodes.compress : OEIconCodes.expand} />
            </OEButton>;

        const layoutType = this.state.layoutType === this.layoutType.min ? this.layoutType.singleRow : this.state.layoutType;

        const targetBtnBarStyle =  layoutType === this.layoutType.singleRow && this.state.targetBtnBarWidth ? {left: 'calc(50% - ' + (0.5 * this.state.targetBtnBarWidth).toString() + 'px)'} : null;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'std-label-text-color philips-icarus-cap-bar ' + ((layoutType === this.layoutType.singleRow ? '' : 'multi-row ') + layoutType)}>
                    
                    <div className="left">
                        <OEButton className="btn light-hover-bg-border" onPressed={this.onMainMenuControlBtnPressed}>
                            <OEIcon code={OEIconCodes.mainMenu} />
                        </OEButton>
                        <div className="light-bg-border spacer-flex-v"/>
                    </div>

                    <div className="middle" ref={this.onMiddleRef}>

                        {this.renderTargetBar()}

                        <div className="target-btn-bar" style={targetBtnBarStyle}>

                            {layoutType !== this.layoutType.singleRow ? <div className="light-bg-border spacer-flex"/> : null}

                            <div className="content" ref={this.onTargetBtnBarContentRef}>
                                
                                <OEResizeObserver onResize={this.onTargetBtnBarContentResize} />

                                <OEButton
                                    className="btn light-hover-bg-border str"
                                    activated={this.state.targetBtnBar.patient.activated}
                                    disabled={!this.state.targetBtnBar.patient.enabled}
                                    userData={this.targetType.patient}
                                    onPressed={this.onTargetBtnPressed}
                                >
                                    {this.state.strings.patient}
                                </OEButton>

                                <div className="light-bg-border spacer"/>

                                <OEButton
                                    className="btn light-hover-bg-border str"
                                    activated={this.state.targetBtnBar.xRay.activated}
                                    disabled={!this.state.targetBtnBar.xRay.enabled}
                                    userData={this.targetType.xRay}
                                    onPressed={this.onTargetBtnPressed}
                                >
                                    {this.state.strings.xRay}
                                </OEButton>

                                <div className="light-bg-border spacer"/>

                                <OEButton
                                    className="btn light-hover-bg-border str"
                                    activated={this.state.targetBtnBar.anatomy.activated}
                                    disabled={!this.state.targetBtnBar.anatomy.enabled}
                                    userData={this.targetType.anatomy}
                                    onPressed={this.onTargetBtnPressed}
                                >
                                    {this.state.strings.anatomy}
                                </OEButton>

                            </div>

                            <div className="light-bg-border spacer-flex"/>

                        </div>

                    </div>

                    <div className="right">
                        {fullscreenBtn}
                        {fullscreenBtn ? <div className="light-bg-border spacer-flex-v"/> : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onMainMenuControlBtnPressed()   {
        this.props.appComponent.uiLayer.onMainMenuControlBtnPressed();
    }

    onTargetMenuToggle(isOpen)    {
        if(!this.oe.isReady()) return;
        if(!isOpen) {   // going to open
            this.compControllerWasVisible = this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.component);
            this.oe.sharedInterface.setUIControllerVisible(false, this.oe.Module.UIControllerType.component);
        } else {    // going to close
            if(this.compControllerWasVisible)   this.oe.sharedInterface.setUIControllerVisible(true, this.oe.Module.UIControllerType.component);
            this.compControllerWasVisible = false;
        }
    }

    onTargetBtnPressed(event, sender)    {
        let userData = sender.props.userData;

        if(userData == this.targetType.anatomy)  {
            let target = OETarget.humanKnee;
            switch(this.activeAnimation)    {
                case OEPhilipsIcarusAnimation.knee: target = OETarget.humanKnee; break;
                case OEPhilipsIcarusAnimation.foot: target = OETarget.humanFoot; break;
            }
            this.props.appComponent.app.changeTarget(target);
        } else {
            let mode = userData == this.targetType.patient ? OEPhilipsIcarusMode.std : OEPhilipsIcarusMode.xRay;
            if(this.target == this.targetType.anatomy)  {
                this.mode = mode;
                this.props.appComponent.app.changeTarget(OETarget.philipsIcarus);
            } else {
                this.oe.sharedInterface.setCustomIntProperty(OEPhilipsIcarusCustomPropKey.mode, mode);
            }
        }
    }

    onFullscreenBtnPressed()    {
        this.props.appComponent.app.setFullscreen(!this.props.isFullscreen);
    }
}

OEPhilipsIcarusCapBar.defaultProps = {
    moduleId: '',
    target: ''
};

OEPhilipsIcarusCapBar.propTypes = {
    moduleId: PropTypes.string
};

export default connectAppEnv((env) => { return {
    target: env.config.target,
    showFullscreenBtn: screenfull.enabled,
    isFullscreen: env.state.isFullscreen,
    appComponent: env.component
}})(OEPhilipsIcarusCapBar);