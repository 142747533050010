import React from 'react';
import PropTypes from 'prop-types';

import {OETransitionGroup} from '../lib/oe-transitions';
import {OEToolbox} from '../lib/oe-toolbox';
import {OEColorBtn, OEIcon} from './oe-controls';
import OEScrollbars from './oe-scrollbars';
import {OEIconCodes} from '../lib/oe-icon-codes';
import {OEColorHelper} from './oe-color-helper';
import {AnimationTimer} from '../lib/animation-timer';

export class OEComponentTreeNode extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onHighlightAnimationFrame = this.onHighlightAnimationFrame.bind(this);

        this.animationTimer = new AnimationTimer(null, this.onHighlightAnimationFrame);

        this.onRef = this.onRef.bind(this);
        this.onLabelNameRef = this.onLabelNameRef.bind(this);

        this.onClick = this.onClick.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onCatSwitchBtnPressed = this.onCatSwitchBtnPressed.bind(this);
        this.onActivationBtnPressed = this.onActivationBtnPressed.bind(this);
        this.onSwitchBtnPressed = this.onSwitchBtnPressed.bind(this);
        this.onCatActivationBtnPressed = this.onCatActivationBtnPressed.bind(this);
        this.onRenderModeBtnPressed = this.onRenderModeBtnPressed.bind(this);

        this.onColorPickerShow = this.onColorPickerShow.bind(this);
        this.onColorPickerChange = this.onColorPickerChange.bind(this);
        this.onColorPickerAlphaChange = this.onColorPickerAlphaChange.bind(this);
        this.onColorPickerHide = this.onColorPickerHide.bind(this);
    }

    onHighlightAnimationFrame(sender, time) {
        let currentTime = 0.001 * Date.now();
        let phase = time / 0.9 * 2.0 * Math.PI;
        let intPhase = Math.trunc(phase/Math.PI) % 2;
        let endAnimation = currentTime - this.startTime > 3.0;

        if(endAnimation && this.lastPhase == 1 && intPhase == 0) {
            this.applyHighlighting(1.0);
            return true;
        }

        this.applyHighlighting(Math.pow(Math.cos(phase), 2.0));
        
        this.lastPhase = intPhase;
    }

    startHighlighting() {
        this.startTime = 0.001 * Date.now();
        this.lastPhase = 0;
        if(!this.animationTimer.isPlaying()) this.animationTimer.play();
    }

    stopHighlighting()  {
        if(this.animationTimer.isPlaying())    {
            this.startTime -= 3;
        }
    }

    applyHighlighting(alpha)    {
        if(!this.labelNameRef)  return;
        $(this.labelNameRef).css('opacity', alpha)
    }

    componentWillUnmount()  {
        if(this.props.onNodeRefs) this.props.onNodeRefs(this, true);
    }

    getRef()    {
        return this.ref;
    }

    onRef(ref) {
        this.ref = ref;
    }

    onLabelNameRef(ref) {
        this.labelNameRef = ref;
    }

    renderBtnContainer()    {
        let con = this.props.connector;
        let extra = this.props.extra;
        let disabled = !extra.uiEnabled || !con.activated;

        let rmIconClass = 'button-transparent';
        let locks = {activation: true, switch: true, renderMode: true};
        if(extra.oe.isReady())    {
            if(con.renderMode === extra.oe.Module.RenderMode.opaque)    {
                rmIconClass = 'button-visible';
            } else if(con.renderMode === extra.oe.Module.RenderMode.hide)   {
                rmIconClass = 'button-hide';
            }

            locks.activation = con.isFeatureLocked(extra.oe.Module.ComponentLockFlags.activation);
            locks.switch = con.isFeatureLocked(extra.oe.Module.ComponentLockFlags.switch);
            locks.renderMode = con.isFeatureLocked(extra.oe.Module.ComponentLockFlags.render_mode);
        }

        let hasActivationBtn = con.deactivateable;
        let hasSwitchBtn = con.isSwitch || con.isRemoteSwitch();
        let hasRMBtn = !con.isPureSubstructure;
        let hasCatSwitchBtn = (!extra.config || extra.config.catSwitchBtnEnabled) && (extra.oe.isReady() ? con.categoryMode === extra.oe.Module.ComponentCategoryMode.switch && con.categoriesNumSwitchStates > 0 : false);
        let hasCatActivationBtn = (!extra.config || extra.config.catActivationBtnEnabled) && (extra.oe.isReady() ? con.categoryMode === extra.oe.Module.ComponentCategoryMode.activation && con.categoriesChangeMask != 0 : false);

        let switchState = !hasSwitchBtn ? -1 : (con.isSwitch ? con.switchState : con.remoteSwitchState);

        let activationBtnDisabled = !extra.uiEnabled || locks.activation;
        let switchBtnDisabled = disabled || locks.switch;
        let rmBtnDisabeled = disabled || locks.renderMode;
        let catSwitchBtnDisabled = disabled || (con.parent && (con.parent.activeCategories & con.categoriesChangeMask) == 0);
        let catActivationBtnDisabled = disabled || (con.parent && (con.parent.activeCategories & con.categoriesChangeMask) == 0);

        return (
            <div className="ct_btn_container">

                {!hasCatSwitchBtn ? null :
                    <button
                        type="button"
                        className={'ct_btn ct_cat_btn ct_cat_switch_btn ct_cat_switch_' + (con.categoriesSwitch + 1).toString()}
                        onClick={this.onCatSwitchBtnPressed}
                        disabled={catSwitchBtnDisabled}
                    >
                        <OEIcon
                            className="button-visible"
                            code={OEIconCodes.ctCategoriesSwitch}
                        />
                    </button>
                }

                {!hasCatActivationBtn ? null :
                    <button
                        type="button"
                        className={'ct_btn ct_cat_btn ct_cat_activation_btn ct_cat_activation_' + (con.categoriesActivation ? 'on' : 'off')}
                        onClick={this.onCatActivationBtnPressed}
                        disabled={catActivationBtnDisabled}
                    >
                        <OEIcon
                            className="button-visible"
                            code={OEIconCodes.ctCategoriesActivation}
                        />
                    </button>
                }

                {!hasActivationBtn ? null :
                    <button
                        type="button"
                        className="ct_btn ct_switch_act_btn"
                        onClick={this.onActivationBtnPressed}
                        disabled={activationBtnDisabled}
                    >
                        <OEIcon
                            className={con.activated ? 'button-visible' : 'button-hide'}
                            code={con.activated ? OEIconCodes.ctActivationOn : OEIconCodes.ctActivationOff}
                        />
                    </button>
                }

                {!hasSwitchBtn ? null :
                    <button
                        type="button"
                        className="ct_btn ct_switch_act_btn"
                        onClick={this.onSwitchBtnPressed}
                        disabled={switchBtnDisabled}
                    >
                        <OEIcon
                            className="button-visible"
                            code={switchState === 0 ? OEIconCodes.ctSwitchOff : OEIconCodes.ctSwitchOn}
                        />
                    </button>
                }

                {!extra.isWorkingColor || (extra.config && !extra.config.colorBtnEnabled) ? null :
                    <div className="ct_color_btn">
                        <OEColorBtn
                            className="ct_btn ct_color_btn"
                            disabled={disabled}
                            onShow={this.onColorPickerShow}
                            onChange={this.onColorPickerChange}
                            onAlphaChange={this.onColorPickerAlphaChange}
                            onHide={this.onColorPickerHide}
                            color={{x: con.color.x, y: con.color.y, z: con.color.z, w: con.colorPurity}}
                            defaultColor={{x: con.defaultColor.x, y: con.defaultColor.y, z: con.defaultColor.z, w: con.defaultColorPurity}}
                            showAlpha={!extra.config || extra.config.colorPurityControl}
                            separatedDelegation={true}
                        >
                            <div className={'sp-replacer ' + (disabled ? 'sp-disabled' : '')} style={{backgroundColor: OEColorHelper.vColorToStr({x: con.color.x, y: con.color.y, z: con.color.z, w: 1})}}/>
                        </OEColorBtn>
                    </div>
                }

                {!hasRMBtn ? null :
                    <button
                        type="button"
                        className="ct_btn ct_rm_btn"
                        onClick={this.onRenderModeBtnPressed}
                        disabled={rmBtnDisabeled}
                    >
                        <OEIcon className={rmIconClass} code={OEIconCodes.eye}/>
                    </button>
                }

            </div>
        );
    }

    render() {
        let con = this.props.connector;
        let switchCon = con.switchConnector();
        let childs = switchCon.activeChilds();

        let extra = this.props.extra;
        let disabled = !extra.uiEnabled || !con.activated;

        let hasChilds = switchCon !== null && childs.length > 0;
        let drawChilds = !con.collapsed && con.activated && hasChilds;
        let terminal = !hasChilds;

        const toggle = terminal || !con.isCollapsable() ? null : (
            <div className={'rot-toggle ct_toggle' + (drawChilds ? ' rot-rotate-toggle' : '') + (disabled ? ' disabled' : '')}>
                <OEIcon code={OEIconCodes.caretRight}/>
            </div>
        );

        const childElements = drawChilds ? childs.map((child) =>
            <OEComponentTreeNode key={child.id} connector={child} extra={extra} onAnimFinished={this.props.onAnimFinished} ref={this.props.onNodeRefs} onNodeRefs={this.props.onNodeRefs}/>
        ) : null;

        let hasActivationBtn = con.deactivateable;
        let hasSwitchBtn = con.isSwitch || con.isRemoteSwitch();
        let hasCatSwitchBtn = extra.oe.isReady() ? con.categoryMode === extra.oe.Module.ComponentCategoryMode.switch && con.categoriesNumSwitchStates > 0 : false;
        let hasCatActivationBtn = extra.oe.isReady() ? con.categoryMode === extra.oe.Module.ComponentCategoryMode.activation && con.categoriesChangeMask != 0 : false;

        let labelPaddingLeft = toggle ? 12 : 0;
        let labelPaddingRight = 56 + (hasSwitchBtn ? 32 : 0) + (hasActivationBtn ? 32 : 0) + (hasCatSwitchBtn ? 32 : 0) + (hasCatActivationBtn ? 32 : 0);
        let indentation = !con.noIndentation() ? 12 : 0;

        return (
            <li ref={this.onRef}>
                <div className={'component-tree-node' + (con.isPrincipal() ? ' principal' : '')}>

                    <div className={'ct_header' + (drawChilds ? ' expanded' : '') + (disabled ? ' disabled' : '')}>

                        {toggle}

                        <div
                            className={'ct_label' + (disabled ? ' disabled' : '')}
                            style={{ paddingLeft: labelPaddingLeft.toString() + 'px', paddingRight: labelPaddingRight.toString() + 'px' }}
                        >
                            {
                                terminal ? <div className="icon terminal"/>/*<OEIcon code={OEIconCodes.ctNodeTerminalDot}/>*/ : null
                            /*
                                <OEIcon code={terminal ? OEIconCodes.ctNodeTerminal : (drawChilds ? OEIconCodes.ctNodeExpanded : OEIconCodes.ctNode)}/>
                            */
                            }
                            
                            <span ref={this.onLabelNameRef}>{OEToolbox.decode_utf8(con.name)}</span>
                        </div>

                        <div
                            className="ct_click_target"
                            onClick={this.onClick}
                            onMouseOver={this.onMouseOver}
                            onMouseOut={this.onMouseOut}
                        />

                        {this.renderBtnContainer()}
                        
                    </div>

                    <OETransitionGroup component="ul" onAnimFinished={this.props.onAnimFinished} style={{paddingLeft: indentation.toString() + 'px'}}>
                        {childElements}
                    </OETransitionGroup>
                </div>
            </li>
        );
    }

    onClick()   {
        let con = this.props.connector;
        let extra = this.props.extra;
        let disabled = !extra.uiEnabled || !con.activated;
        if(!disabled) extra.onClick(con.id);
    }

    onMouseOver()   {
        this.props.extra.onMouseOver(this.props.connector.id);
    }

    onMouseOut()   {
        this.props.extra.onMouseOut(this.props.connector.id);
    }

    onCatSwitchBtnPressed() {
        this.props.extra.onCatSwitchBtnPressed(this.props.connector.id);
    }

    onActivationBtnPressed()    {
        this.props.extra.onActivationBtnPressed(this.props.connector.id);
    }

    onSwitchBtnPressed()    {
        this.props.extra.onSwitchBtnPressed(this.props.connector.id);
    }

    onCatActivationBtnPressed() {
        this.props.extra.onCatActivationBtnPressed(this.props.connector.id);
    }

    onRenderModeBtnPressed()    {
        this.props.extra.onRenderModeBtnPressed(this.props.connector.id);
    }

    onColorPickerShow(color)    {
        this.props.extra.onColorPickerShow(this.props.connector.id, color);
    }

    onColorPickerChange(color)    {
        this.props.extra.onColorPickerChange(this.props.connector.id, color);
    }

    onColorPickerAlphaChange(alpha)    {
        this.props.extra.onColorPickerAlphaChange(this.props.connector.id, alpha);
    }

    onColorPickerHide(color)    {
        this.props.extra.onColorPickerHide(this.props.connector.id, color);
    }
}

export class OEComponentTree extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.nodeRefs = {};
        
        this.onScrollbarRef = this.onScrollbarRef.bind(this);
        this.onNodeRefs = this.onNodeRefs.bind(this);

        this.onAnimFinished = this.onAnimFinished.bind(this);
    }

    scrollTo(id, completed)    {
        if(!this.scrollbar || !this.nodeRefs[id]) return;
        let node = this.nodeRefs[id].getRef();

        let element = node;
        let offsetTop = 0;
        while(element && element != this.scrollbar.container)    {
            offsetTop += element.offsetTop;
            element = element.parentElement;
        }

        let itemCenter = offsetTop + 0.5 * node.offsetHeight;
        let scrollViewCenter = 0.5 * this.scrollbar.getClientHeight();
        let scrollTop = itemCenter - scrollViewCenter;
        this.scrollbar.scrollTop(scrollTop, completed);
    }

    getScrollbarRef()   {
        return this.scrollbar;
    }

    getNodeRef(id)   {
        return this.nodeRefs[id];
    }

    onScrollbarRef(e)   {
        this.scrollbar = e;
    }

    onNodeRefs(ref, release)    {
        if(!ref || !ref.props.connector) return;
        if(release) {
            delete this.nodeRefs[ref.props.connector.id];
        } else {
            this.nodeRefs[ref.props.connector.id] = ref;
        }

        if(this.props.onNodeRefs) this.props.onNodeRefs(ref, release);
    }
    
    render() {
        let con = this.props.connector;
        let childs = con ? con.activeChilds() : null;
        let extra = this.props.extra;

        const childElements = extra.oe.isReady() && childs && childs.length > 0 ? childs.map((child) =>
           <OEComponentTreeNode key={child.id} connector={child} extra={extra} onAnimFinished={this.onAnimFinished} ref={this.onNodeRefs} onNodeRefs={this.onNodeRefs}/>
        ) : null;
    
        return (
            <OEScrollbars className="component-tree" ref={this.onScrollbarRef}>
                <OETransitionGroup component="ul" onAnimFinished={this.onAnimFinished} className="root">
                    {childElements}
                </OETransitionGroup>
            </OEScrollbars>
        );
    }

    onAnimFinished()    {
        // workaround for getting react-custom-scrollbars working with OETransitionGroup
        this.scrollbar.update();
    }
}