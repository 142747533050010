import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {VelocityTransitionGroup} from 'velocity-react';

import {OEToolbox} from './oe-toolbox';

export class OEPortal extends React.PureComponent{
	render() {
		return ReactDOM.createPortal(
			this.props.children,
			$(this.props.selector).get(0)
		);
	}
};

OEPortal.defaultProps  ={
	selector: 'body'
};

OEPortal.propTypes = {
	selector: PropTypes.string
};

export class OEWindow extends React.PureComponent {

	constructor(props) {
		super(props);

		this.mounted = false;
		this.state = {mounted: false}

		this.onWindowDidAppear = this.onWindowDidAppear.bind(this);
		this.onWindowDidDisappear = this.onWindowDidDisappear.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	componentDidMount() {
		if(!this.mounted)	{
			this.mounted = true;
			this.setState({mounted: true});
		}
	}

	onWindowDidAppear()	{
		if(typeof(this.props.enterTransition.complete) === 'function')	{
			this.props.enterTransition.complete();
		}
	}

	onWindowDidDisappear()	{
		if(typeof(this.props.leaveTransition.complete) === 'function')	{
			this.props.leaveTransition.complete();
		}

		if(!this.props.isOpen && typeof(this.props.onClosed) === 'function')	{
			this.props.onClosed();
		}
	}
	 
    render() {

		var enter = Object.assign({}, this.props.enterTransition);
		enter.complete = this.onWindowDidAppear;

		var leave = Object.assign({}, this.props.leaveTransition);
		leave.complete = this.onWindowDidDisappear;

		return(
			<OEPortal>
				<VelocityTransitionGroup enter={enter} leave={leave}>
					{!this.props.isOpen || !this.state.mounted ? null :
						<div
							className={'window ' + this.props.className}
							style={this.props.style}
							onClick={this.onClick}
							ref={this.props.elementRef}
						>
							{this.props.children}
						</div>
					}
				</VelocityTransitionGroup>
			</OEPortal>
		);
	} 

	onClick() {
        if(typeof(this.props.onClick) === 'function')   {
            this.props.onClick();
        }
	}
}

OEWindow.defaultProps = {
	className: '',
	enterTransition: {animation: 'fadeIn', duration: 666, easing: 'ease-in-out'},
	leaveTransition: {animation: 'fadeOut', duration: 666, easing: 'ease-in-out'},
};

OEWindow.propTypes = {
	className: PropTypes.string
};

export class OEWindowManager extends React.PureComponent {

    constructor(props) {
        super(props);

        this.counter = 0;

        this.state = {windows: new Object()};

        this.onClosed = this.onClosed.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    addWindow(window) {
        if(typeof(window) === 'function')    {
            return this.addWindow(window(this.counter));
        }

        //
        const id = this.counter; this.counter++;
        var entry = new Object();
        entry[id.toString()] = {id: id, isOpen: true, window: window};

		this.setStateUpdate({windows: {$merge: entry}});
		
        return id;
	}
	
	updateWindow(id, window)	{
		this.setState((prevState, props) => {
            prevState.windows[id.toString()].window = window;
            return prevState;
		});
		this.forceUpdate();
	}

    closeWindow(id)  {
        this.setState((prevState, props) => {
            prevState.windows[id.toString()].isOpen = false;
            return prevState;
		});
		this.forceUpdate();
    }

    onClosed(id)    {
        this.setState((prevState, props) => {
			delete prevState.windows[id.toString()];
			return prevState;
		});
		this.forceUpdate();
    }

    render() {

        var windows = [];
        for (var id in this.state.windows) {
            if (this.state.windows.hasOwnProperty(id)) {
                windows.push(this.state.windows[id]);
            }
        }

        var windowElements = windows.map((entry) =>
            React.cloneElement(entry.window, {key: entry.id, isOpen: entry.isOpen, onClosed: () => this.onClosed(entry.id)})
        );

        return windowElements;
    }
}

export let oeWindowManager = {
	instance: null	
};

export class OEWindowComponent extends React.Component {

	constructor(props) {
		super(props);

		this.id = null;

		this.onWindowDidAppear = this.onWindowDidAppear.bind(this);
		this.onWindowDidDisappear = this.onWindowDidDisappear.bind(this);
		this.onClick = this.onClick.bind(this);

		this.createWindow();
	}

	shouldComponentUpdate() {
        return false;
	}

	componentWillReceiveProps(nextProps) {
		if(!oeWindowManager.instance || this.id === null) return;

		oeWindowManager.instance.updateWindow(this.id, this.createInstance(nextProps)(this.id));
	}

	createWindow()	{
		if(!oeWindowManager.instance || this.id !== null) return;
		
		this.id = oeWindowManager.instance.addWindow(this.createInstance());
	}

	createInstance(props)	{
		var props_ = props || this.props;
		
		var enter = Object.assign({}, props_.enterTransition);
		enter.complete = this.onWindowDidAppear;

		var leave = Object.assign({}, props_.leaveTransition);
		leave.complete = this.onWindowDidDisappear;

		return ((id) =>
			<OEWindow
				className={props_.className}
				style={props_.style}
				isOpen={true}
				enterTransition={enter}
				leaveTransition={leave}
				onClick={this.onClick}
				elementRef={props_.elementRef}
			>
				{props_.children}
			</OEWindow>
		);
	}

    componentWillUnmount() {
		if(!oeWindowManager.instance || this.id === null) return;
		
		oeWindowManager.instance.closeWindow(this.id);
		this.id = null;
	}

	onWindowDidAppear()	{
		if(typeof(this.props.enterTransition.complete) === 'function')	{
			this.props.enterTransition.complete();
		}
	}

	onWindowDidDisappear()	{
		if(typeof(this.props.leaveTransition.complete) === 'function')	{
			this.props.leaveTransition.complete();
		}
	}
	 
    render() {
		return null;
	} 

	onClick() {
        if(typeof(this.props.onClick) === 'function')   {
            this.props.onClick();
        }
	}
}

OEWindowComponent.defaultProps = {
	className: '',
	enterTransition: {animation: 'fadeIn', duration: 333, easing: 'ease-in-out'},
	leaveTransition: {animation: 'fadeOut', duration: 333, easing: 'ease-in-out'},
};

OEWindowComponent.propTypes = {
	className: PropTypes.string
};

export class OEBackdrop extends React.PureComponent { 

    render() {

		if(!this.props.enabled) return null;

		var style = {
			backgroundColor: this.props.options.color,
			pointerEvents: this.props.enabled ? 'initial' : 'none'
		};

		let enterTransition = !this.props.options.animated ? null : {animation: 'fadeIn', duration: this.props.options.duration * 1000, easing: 'ease-in-out'};
		let leaveTransition = !this.props.options.animated ? null : {animation: 'fadeOut', duration: this.props.options.duration * 1000, easing: 'ease-in-out'};

		return(
			<OEWindowComponent
				className={'back-drop ' + this.props.className}
				style={style}
				onClick={this.props.onClick}
				enterTransition={enterTransition}
				leaveTransition={leaveTransition}
				elementRef={this.props.elementRef}
			> 
                {this.props.children}
			</OEWindowComponent>
		);
	} 
}

OEBackdrop.options = {
	clear: {color: 'rgba(0, 0, 0, 0)', animated: true, duration: 0.333}
};

OEBackdrop.defaultProps = {
	enabled: true,
    className: '',
    options: {color: 'rgba(0, 0, 0, 0.2)', animated: true, duration: 0.333}
};

OEBackdrop.propTypes = {
	enabled: PropTypes.bool,
    className: PropTypes.string,
    options: PropTypes.shape({color: PropTypes.string, animated: PropTypes.bool, duration: PropTypes.number})
};