import React from 'react';  
import PropTypes from 'prop-types';

import {connectAppEnv} from './app-env';
import {withUILevel} from '../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import OEPopover from './oe-popover';
import {OEGroupControl, OEControl, OEButton, OEIcon} from './oe-controls';
import {UIControllerType, OEManualViewLinks} from '../lib/oe-types';
import {OEDefaultConfigFactory} from './oe-default-configs';
import {OEComponentSearchFlags, OEComponentSearchControl} from './oe-component-search-control';
import {retardUpdate} from '../lib/update-retarder';
import OEScrollbars from './oe-scrollbars';
import {OEIconCodes} from '../lib/oe-icon-codes';

export class OESearchTableCell extends React.Component {
    
    constructor(props) {
        super(props);

        this.btnIconCodes = [OEIconCodes.searchToolCellCenter, OEIconCodes.searchToolCellTree, OEIconCodes.searchToolCellSelect];

        for(let i = 0; i < 3; ++i)  {
            this['onBtn' + i.toString() + 'Pressed'] = function()   {
                if(this.props.onBtnPressed) this.props.onBtnPressed(this, this.props.id, i);
            }.bind(this);
        }
    }

    render() {
        // buttons indexed from right to left
        let btns = [];
        for(let i = 0; i < 2; ++i)  {
            if(i != 0)  {
                btns.splice(0, 0,
                    <div key={2*i} className="btn-spacer"/>
                );
            }
            btns.splice(0, 0,
                <OEButton
                    disabled={(this.props.btns ? !this.props.btns[i].enabled : false) || (i == 0 && this.props.lock > 0)}
                    key={2*i + 1}
                    className={'transparent-btn btn-' + i.toString()}
                    onPressed={this['onBtn' + i.toString() + 'Pressed']}
                >
                    <OEIcon code={this.btnIconCodes[i]}/>
                </OEButton>
            );
        }

        return(
            <div className={'search-cell ' + (this.props.isReachable ? 'reachable' : 'non-reachable')}>
                <div className="content">
                    <div className="label">
                        <div className="primary"><span>{this.props.primaryLabel}</span></div>
                        <div className="secondary">{this.props.secondaryLabel}</div>
                    </div>
                    <div className="btn-container">
                        {btns}
                    </div>
                </div>
                {!this.props.isLast ? <div className="separator"/> : null}
            </div>
        )
    }
}

OESearchTableCell.propTypes = {
    id: PropTypes.string,
    primaryLabel: PropTypes.string,
    secondaryLabel: PropTypes.string,
    reachable: PropTypes.bool,
    lock: PropTypes.number,
    btns: PropTypes.arrayOf(PropTypes.shape({
        enabled: PropTypes.bool
    })),
    isLast: PropTypes.bool,
    onBtnPressed: PropTypes.func
};

export class OESearchViewController extends React.PureComponent {
    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.searchResult = [];

        this.state = {
            uiEnabled: false,
            cellBtns: [
                {enabled: false}, {enabled: false}, {enabled: false}
            ],
            searchString: '',
            searchResult: [],
            strings:{
                title: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onComponentsLockStateChanged = this.onComponentsLockStateChanged.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onSearchInputChanged = this.onSearchInputChanged.bind(this);
        this.onSearchResultChanged = this.onSearchResultChanged.bind(this);
        this.onCellBtnPressed = this.onCellBtnPressed.bind(this);
    }
    
    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.reset, this.onReset);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsLockStateChanged, this.onComponentsLockStateChanged);
    }
    
    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.reset, this.onReset);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsLockStateChanged, this.onComponentsLockStateChanged);
    }
    
    updateLanguage() {
        const si = this.oe.sharedInterface;
        this.setState({
            strings: {
                title: si.getLocalizedStringEnc('search_view')
            }
        });

        this.updateSearchResultState();
    }

    updateSearchResultState()   {
        if(!this.oe.isReady()) return;
        const comp = this.oe.sharedInterface.getUIControllerComponent();

        this.setState({
            searchResult: this.searchResult.map((item, index) => {
                return {
                    id: item.id,
                    primaryLabel: comp.getComponentName(item.id),
                    secondaryLabel: comp.getComponentSecondaryName(item.id),
                    isReachable: item.isReachable,
                    lock: comp.getComponentLock(item.id)
                };
            })
        });
    }

    reset() {
        this.searchResult = [];
        this.updateSearchResultState();
        this.setState({ searchString: '' });
    }

    updateUIState() {
        const uiEnabled = this.oe.sharedInterface.getUIControllerSearch().getUIEnabled();
        const compUIEnabled = this.oe.sharedInterface.getUIControllerComponent().getUIEnabled();
        const compUIPresentable = this.oe.sharedInterface.getUIControllerComponent().getUIPresentable();
        const contextUIEnabled = this.oe.sharedInterface.getUIControllerContextMenu().getUIEnabled();
        const contextUIPresentable = this.oe.sharedInterface.getUIControllerContextMenu().getUIPresentable();
        const selectionUIEnabled = this.oe.sharedInterface.getUIControllerSelection().getUIEnabled();
        const selectionUIPresentable = this.oe.sharedInterface.getUIControllerSelection().getUIPresentable();
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerSearch().getUIEnabled(),
            cellBtns: [{
                enabled: uiEnabled && compUIEnabled && contextUIEnabled && contextUIPresentable
            },{
                enabled: uiEnabled && compUIEnabled && compUIPresentable
            },{
                enabled: uiEnabled && compUIEnabled && selectionUIEnabled && selectionUIPresentable
            }]
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            retardUpdate(this, () => {
                this.setState({ uiEnabled: false, cellBtns: [{enabled: false}, {enabled: false}, {enabled: false}] });
                this.reset();
            });
            return;
        }
        
        retardUpdate(this, () => {
            this.reset();
            this.updateLanguage();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo) {
        if(userInfo.type === this.oe.Module.UIControllerType.search || userInfo.type === this.oe.Module.UIControllerType.context_menu || userInfo.type === this.oe.Module.UIControllerType.component || userInfo.type === this.oe.Module.UIControllerType.selection) {
            this.updateUIState();
        }
    }

    onReset()   {
        this.reset();
    }

    onComponentsLockStateChanged()  {
        this.updateSearchResultState();
    }
   
    render() {
        if(!this.props.config.enabled) return null;

        const searchCells = this.state.searchResult.map((item, index) =>
            <OESearchTableCell
                key={index}
                btns={this.state.cellBtns}
                isLast={index === this.state.searchResult.length - 1}
                onBtnPressed={this.onCellBtnPressed}
                {...item}
            />
        )

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control" 
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.search}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    title={this.state.strings.title}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >
                    <div className="search-controller">
                        <OEGroupControl>
                            <OEComponentSearchControl
                                moduleId={this.props.moduleId}
                                options={{
                                    autoReset: true,
                                    limit: 50,
                                    flags: OEComponentSearchFlags.limitResults | OEComponentSearchFlags.onlyCategoryEnabled | OEComponentSearchFlags.secondaryLabel
                                }}
                                searchString={this.state.searchString}
                                onSearchInputChanged={this.onSearchInputChanged}
                                onSearchResultChanged={this.onSearchResultChanged}
                            />
                            <OEControl className="search-list">
                                <OEScrollbars>
                                    {searchCells}
                                </OEScrollbars>
                            </OEControl>
                        </OEGroupControl>
                    </div>
                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.search});
    }

    onSearchInputChanged(sender, searchString)  {
        this.setState({searchString: searchString});

        let userInfo = new this.oe.Module.Dictionary();
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceSearchStringChanged, userInfo.setValue('string', searchString));
        userInfo.delete();
    }

    onSearchResultChanged(sender, result, searchString) {
        this.searchResult = result;
        this.updateSearchResultState();
    }

    onCellBtnPressed(sender, id, index) {
        // buttons indexed from right to left
        if(!this.oe.isReady()) return;
        const si = this.oe.sharedInterface;
        if(index === 0) {
            si.getUIControllerSearch().applyFunction(index, id);
            /*
            si.getUIControllerComponent().makeComponentReachable(id);
            si.getUIControllerContextMenu().select(id, true);
            si.getUIControllerContextMenu().setHideOther(true);
            si.centerCameraSelection(0, true);
            */

            let userInfo = new this.oe.Module.Dictionary();
            this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceSearchContextMenu, userInfo.setValue('componentID', id));
            userInfo.delete();

        } else if(index === 1) {
            si.getUIControllerSearch().applyFunction(index, id);
            /*
            si.getUIControllerComponent().makeComponentReachable(id);
            si.getUIControllerComponent().setUIVisible(true);
            */
            this.props.appComponent.componentController.findComponent(id);

            let userInfo = new this.oe.Module.Dictionary();
            this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceSearchFindInComponentTree, userInfo.setValue('componentID', id));
            userInfo.delete();
        }
    }
}

OESearchViewController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.searchViewController()
};

OESearchViewController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool
    }).isRequired
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.searchViewController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.search)
    }
})(withUILevel(OESearchViewController));