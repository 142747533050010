import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEStdModalController from '../modals/oe-std-modal-controller';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {OENavigationController, OENavigationViewComponent} from '../../lib/oe-navigation-controller';
import OEFNHelp from '../start-view/flow-nav/oe-flow-nav-help';
import {OEBlurModalBackdropLayer} from '../oe-blur-layer';

export default class OEHelpViewStd extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.isOpen_ = false;

        this.navRef = null;

        this.state = {
            title: '',
            isOpen: this.isOpen_,
            navRef: this.navRef,
            doBlur: true
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onNavRef = this.onNavRef.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.onStackTopChanged = this.onStackTopChanged.bind(this);
        this.onStackChanged = this.onStackChanged.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
    }

    setOpen(open = true)   {
        if(typeof(this.props.isOpen) === 'boolean' || this.isOpen_ === open) return;
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
        if(typeof(this.props.onOpenStateChanged) === 'function')  this.props.onOpenStateChanged(this, this.isOpen_);
    }

    isOpen()    {
        return this.isOpen_;
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        setTimeout(this.onTimeout.bind(this), 500);        
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onTimeout() {
        let config = this.props.config;
        if(config.showAtStartUp && this.oe.isReady() && localStorage.getItem('dontShowHelpViewAtStartUp') !== 'true')   {
            this.setOpen(true);
        }
    }

    updateLanguage()   {
        this.onTitleChanged();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    onNavRef(ref)  {
        if(this.navRef === ref) return;
        this.navRef = ref;
        this.setState({navRef: ref});
    }

    render() {
        let isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEStdModalController
                    moduleId={this.props.moduleId}
                    title={this.state.title}
                    className="simple-header clear-backdrop flow-nav-modal help-view blur-by-manual-view"
                    isOpen={isOpen}
                    hasCloseBtn={true}
                    onToggle={this.onToggle}
                >
                    <OENavigationController ref={this.onNavRef} onStackChanged={this.onStackChanged} onStackTopChanged={this.onStackTopChanged} onTitleChanged={this.onTitleChanged}/>

                    <OENavigationViewComponent navigationController={this.state.navRef} title="help_view_title">
                        <OEFNHelp appComponent={this.props.appComponent} moduleId={this.props.moduleId} navigationController={this.state.navRef} entries={this.props.config.entries}/>
                    </OENavigationViewComponent>

                    <OEBlurModalBackdropLayer/>
                </OEStdModalController>
            </React.Fragment>
        );
    }

    onToggle() { 
        if(typeof(this.props.onToggle) === 'function')  {
            this.props.onToggle();
        } else {
            if(this.navRef && this.navRef.stackSize() > 1) {
                this.navRef.pop();
            } else {
                this.setOpen(false);
            }
        }
    }

    onStackChanged(size)    {
    }

    onStackTopChanged() {
    }

    onTitleChanged(title)   {
        if(!this.oe.isReady()) return;
        this.titleId = typeof(title) === 'string' ? title : this.titleId;
        this.setState({title: !this.titleId || this.titleId === '' ? '' : this.oe.sharedInterface.getLocalizedStringEnc(this.titleId)});
    }
}

OEHelpViewStd.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.helpView()
};

OEHelpViewStd.propTypes = {
    moduleId: PropTypes.string,
    config: OEDefaultConfigFactory.helpViewDecl
};