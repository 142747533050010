import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import {VelocityTransitionGroup} from 'velocity-react';

import OEThemeIndicatorView from './oe-theme-indicator-view';

export default class OEThemeWaitingController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            show: 0,
            progress: 0
        };
    }

    show() {
        let oldShow = 0;

        this.setState((prevState, props) => {
            let newState = clone(prevState);
            oldShow = prevState.show;
            newState.show = prevState.show + 1;
            return newState;
        });

        if(oldShow === 0)    {
            this.setProgress(0);
        }
    }

    hide() {
        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.show = Math.max(0, prevState.show - 1);
            return newState;
        });
    }

    setProgress(progress)   {
        this.setState({progress: progress});
    }

    render() {
        let show = typeof(this.props.show) === 'boolean' ? this.props.show : this.state.show > 0;
        let progress = typeof(this.props.progress) === 'number' ? this.props.progress : this.state.progress;
        let element = !show ? null : 
            <div className={"theme-indicator-controller " + this.props.className}>
                <OEThemeIndicatorView progress={progress}/>
            </div>;

        return (
            <VelocityTransitionGroup enter={{animation: "fadeIn", duration: 333, easing: "ease-in-out"}} leave={{animation: "fadeOut", duration: 333, easing: "ease-in-out"}}>
                {element}
            </VelocityTransitionGroup>
        );
    }

};

OEThemeWaitingController.defaultProps = {
    className: ''
};

OEThemeWaitingController.propTypes = {
    className: PropTypes.string
};