import React from 'react';
import * as screenfull from 'screenfull';

import {AppMode} from './app-types';
import AppConfig from './app-config';
import {withAppEnv, addAssembledAppEnv} from './app-env';
import {oeStyleSheetTool} from '../lib/oe-style-sheet-tool';
import OECapBar from './bars/cap/oe-cap-bar';
import OEModule from './oe-module';
import {OETarget} from '../react-oe/oe-target';
import {OEStatusType} from '../react-oe/oe-surface';
import OELicensingApp from './licensing-start/oe-licensing-app';
import {OEToolbox} from '../lib/oe-toolbox';

export class App extends React.PureComponent {

    constructor(props) {
        super(props);

        oeStyleSheetTool.updateColorStyle();

        this.moduleId = OEModule.defaultProps.moduleId;
        this.oe = null;

        this.props.appEnv.setComponent({app: this});
        this.config = new AppConfig({initial: true});
        this.isFullscreen = false;
        this.props.appEnv.setState({isFullscreen: this.isFullscreen});

        this.state = {
            mode: this.config.startAppMode,
            showFullscreenBtn: screenfull.enabled
        };

        if(screenfull.enabled) {
            screenfull.onchange(this.onFullscreenChange.bind(this));
        }

        this.onStatusChanged = this.onStatusChanged.bind(this);

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onBgColorChanged = this.onBgColorChanged.bind(this);

        this.onShouldPlayProduct = this.onShouldPlayProduct.bind(this);

        this.setupPageScrollForMode(config.startAppMode);
        this.onConfigSet();
    }

    changeMode(mode)    {
        this.setState({mode: mode});
        this.setupPageScrollForMode(mode);
    }

    setConfig(config)   {
        this.config = config;
        this.onConfigSet();
    }

    setFullscreen(fullscreen)   {
        if(!screenfull.enabled) return;
        if(fullscreen) {
            screenfull.request();
        } else {
            screenfull.exit();
        }
    }

    changeTarget(target)    {
        if(this.state.mode === AppMode.play && (!this.oe || !this.oe.isReady())) return false;
        if(this.config.target === target) return true;
        let appComponent = this.props.appEnv.component;
        if(appComponent && appComponent.uiLayer) appComponent.uiLayer.closeAllPopovers();
        this.setConfig(new AppConfig({target: target}));
        return true;
    }

    onConnect(sender, oe)   {
        this.oe = oe;
        this.onBgColorChanged();
        oe.sharedNotificationCenter.register(oe.NotificationName.bgColorChanged, this.onBgColorChanged);
        oe.sharedNotificationCenter.register(oe.NotificationName.vignetteStrengthChanged, this.onBgColorChanged);
    }

    onRelease(sender, oe)   {
        oe.sharedNotificationCenter.unregister(oe.NotificationName.bgColorChanged, this.onBgColorChanged);
        oe.sharedNotificationCenter.unregister(oe.NotificationName.vignetteStrengthChanged, this.onBgColorChanged);
        this.oe = null;
    }

    onBgColorChanged()  {
        if(!this.oe || !this.oe.isReady()) return;

        let brightness = this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness();
        let borderBrightness = this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundBorderBrightness();

        oeStyleSheetTool.updateColorStyle(brightness, borderBrightness);
    }

    onFullscreenChange()  {
        this.isFullscreen = !this.isFullscreen;
        this.props.appEnv.setState({isFullscreen: this.isFullscreen});
    }

    componentWillUpdate(nextProps, nextState)   {
        if(this.state.mode !== nextState.mode && nextState.mode === AppMode.start)  {
            if(screenfull.enabled && this.isFullscreen) {
                screenfull.exit();
            }
        }
    }

    setupPageScrollForMode(mode)    {
        var m = mode || this.state.mode;

        if(m === AppMode.start) {
            $('html').removeClass('disable-page-scrolling'); $('body').removeClass('disable-page-scrolling');
        } else {
            if(!$('html').hasClass('disable-page-scrolling'))    $('html').addClass('disable-page-scrolling');
            if(!$('body').hasClass('disable-page-scrolling'))    $('body').addClass('disable-page-scrolling');
        }
    }

    onConfigSet()   {
        oeStyleSheetTool.updateForConfig(this.config);
        this.props.appEnv.setConfig(this.config);
    }

    onStatusChanged(status) {
        if(status.type !== OEStatusType.initialized)    {
            oeStyleSheetTool.updateColorStyle();
        }
    }

    renderLogo()    {
        return (typeof(this.props.appEnv.config.logo) !== 'string' ? null :
            <img className="company-logo" src={this.props.appEnv.config.logo} />
        );
    }

    renderStartMode()   {
        return (
            <OELicensingApp
                appComponent={this.props.appEnv.component}
                config={this.props.appEnv.config.licensingAppConfig}
                onShouldPlayProduct={this.onShouldPlayProduct}
            />
        );
    }

    renderPlayMode()    {
        return (
            <div className={'app-container target-' + this.props.appEnv.config.target}>
                <OECapBar moduleId={this.moduleId} opaque={true}/>
                <OEModule
                    moduleId={this.moduleId}
                    onStatusChanged={this.onStatusChanged}
                    onConnect={this.onConnect}
                    onRelease={this.onRelease}
                />
                {this.renderLogo()}
            </div>
        );
    }

    render() {
        return this.state.mode === AppMode.start ? this.renderStartMode() : this.renderPlayMode();
    }

    onShouldPlayProduct(id)   {
        var target = OEToolbox.targetForProductId(id);
        if(target === OETarget.void)    return;

        this.changeTarget(target);
        this.changeMode(AppMode.play);
    }
}

export default withAppEnv(addAssembledAppEnv(App));
  