import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {OETarget} from '../../../react-oe/oe-target';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEButton, OEIcon} from '../../oe-controls';
import {OEModal} from '../../modals/oe-modal';
import {OEBlurLayer} from '../../oe-blur-layer';

export class OEZeissOptiTargetModal extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            targetViewOpen: false,
            strings: {
                anatomy: 'Anatomy & Pathology',
                uv: 'UVProtect',
                opti: 'Optics & Optometry'
            }
        };

        this.onTargetEye = this.onTargetEye.bind(this);
        this.onTargetOpti = this.onTargetOpti.bind(this);
        this.onTargetUV = this.onTargetUV.bind(this);
    }

    onTargetEye()   {
        this.props.onTarget(OETarget.eyeMacro);
    }

    onTargetOpti()   {
        this.props.onTarget(OETarget.zeissOpti2);
    }

    onTargetUV()   {
        this.props.onTarget(OETarget.zeissUV);
    }

    render()    {
        return (
            <OEModal
                isOpen={this.props.isOpen}
                rootClassName="zeiss-opti-target-controller"
                className="clear-backdrop v-center clear-bg"
                noHeader={true}
                onToggle={this.props.onToggle}
                toggleOnBackdrop={true}
            >
                <div className="target-menu">
                    <OEButton className="btn" onPressed={this.onTargetEye}>
                        <span>{this.state.strings.anatomy}</span>
                    </OEButton>

                    <OEButton className="btn" onPressed={this.onTargetOpti}>
                        <span>{this.state.strings.opti}</span>
                    </OEButton>

                    <OEButton className="btn" onPressed={this.onTargetUV}>
                        <span>{this.state.strings.uv}</span>
                    </OEButton>
                </div>
                <OEBlurLayer apply={true} selector="body > div:not(.zeiss-opti-target-controller)"/>
            </OEModal>
        );
    }
}

OEZeissOptiTargetModal.defaultProps = {
    isOpen: false,
    onToggle: () => {},
    onTarget: () => {}
};

OEZeissOptiTargetModal.propTypes = {
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    onTarget: PropTypes.func
};

export class OEZeissOptiTargetController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_
        };

        this.onToggle = this.onToggle.bind(this);
        this.onTarget = this.onTarget.bind(this);
    }

    setOpen(open = true)    {
        if(typeof(this.props.isOpen) === 'boolean' || this.isOpen_ === open) return;
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
        if(typeof(this.props.onOpenStateChanged) === 'function')  this.props.onOpenStateChanged(this, this.isOpen_);
    }

    isOpen()    {
        return this.isOpen_;
    }

    close() {
        this.setOpen(false);
    }

    render()    {
        const isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;

        return (
            <OEZeissOptiTargetModal
                isOpen={isOpen}
                onToggle={this.onToggle}
                onTarget={this.onTarget}
            />
        );
    }

    onToggle()    {
        if(typeof(this.props.onToggle) === 'function')   {
            this.props.onToggle();
        }

        this.setOpen(!this.state.isOpen);
    }

    onTarget(target)    {
        if(typeof(this.props.onTarget) === 'function')   this.props.onTarget(target);
        if(this.props.app && this.props.app.changeTarget(target))   {
            this.close();
        }
    }
}

const OEZeissOptiTargetControllerConnected = connectAppEnv((env) => { return {
    app: env.component.app
}})(OEZeissOptiTargetController);

export default OEZeissOptiTargetControllerConnected;

export const OEZeissOptiTargetControllerButton = connectAppEnv((env) => { return {
    app: env.component.app
}})(
class extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
        this.onRef = this.onRef.bind(this);
        this.onBtnPressed = this.onBtnPressed.bind(this);
    }

    onRef(ref) {
        this.ref = ref;
    }

    render()    {
        return (
            <div className={this.props.className}>
                <a onClick={this.onBtnPressed}>
                    <OEIcon code={OEIconCodes.zeissOpti.targetSwiffel}/>
                </a>

                <OEZeissOptiTargetController
                    app={this.props.app}
                    ref={this.onRef}
                />
            </div>
        );
    }

    onBtnPressed()    {
        if(this.ref) this.ref.setOpen();
    }
}
);

OEZeissOptiTargetControllerButton.defaultProps = {
    className: ''
};

OEZeissOptiTargetControllerButton.propTypes = {
    className: PropTypes.string
};