import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';

import {connectAppEnv} from '../app-env';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {UIControllerType, OEManualViewLinks} from '../../lib/oe-types';
import OEPopover from '../oe-popover';
import {OEControl, OEButton, OEIcon} from '../oe-controls';
import OEPresentationList from './oe-presentation-list';
import OEPresentationSlideList from './oe-presentation-slide-list';
import OEPresentationAnimationController from './oe-presentation-animation-controller';
import {OEPresetType} from '../oe-preset-controller';
import OEPresetPopoverController from '../oe-preset-controller';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {retardUpdate} from '../../lib/update-retarder';
import OENotesAreInDocumentButton from '../elements/oe-notes-are-in-document-button';

export class OEPresentationController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.activePresID = -2;
        this.activeSlideID = -1;

        this.isViewerLevel = false;
        this.isProLevel = false;

        this.state = {
            uiEnabled: false,
            activePresID: -2,
            activeSlideID: -1,
            strings:   {
                title: ''
            },
            buttons: {
                addPres: { enabled: false },
                removePres: { enabled: false },
                addSlide: { enabled: false },
                removeSlide: { enabled: false },
                set : { enabled: false },
                slide : { enabled: false },
                load : { enabled: false },
                save : { enabled: false }
            }
        };

        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onPresentationAnimationModeChanged = this.onPresentationAnimationModeChanged.bind(this);
        this.onActivePresentationChanged = this.onActivePresentationChanged.bind(this);
        this.onActivePresentationSlideChanged = this.onActivePresentationSlideChanged.bind(this);
        this.onPresentationAdded = this.onPresentationAdded.bind(this);
        this.onPresentationSlideAdded = this.onPresentationSlideAdded.bind(this);
        this.onFeatureAccessChanged = this.onFeatureAccessChanged.bind(this);

        this.onFileLoadInputRef = this.onFileLoadInputRef.bind(this);
        this.onPresetControllerRef = this.onPresetControllerRef.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onLoadBtnPressed = this.onLoadBtnPressed.bind(this);
        this.onSaveBtnPressed = this.onSaveBtnPressed.bind(this);
        this.onRemovePresBtnPressed = this.onRemovePresBtnPressed.bind(this);
        this.onAddPresBtnPressed = this.onAddPresBtnPressed.bind(this);
        this.onPresetBtnPressed = this.onPresetBtnPressed.bind(this);
        this.onSetBtnPressed = this.onSetBtnPressed.bind(this);
        this.onRemoveSlideBtnPressed = this.onRemoveSlideBtnPressed.bind(this);
        this.onAddSlideBtnPressed = this.onAddSlideBtnPressed.bind(this);
        this.onLoadInputResult = this.onLoadInputResult.bind(this);
        this.savePresentation = this.savePresentation.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationAnimationModeChanged, this.onPresentationAnimationModeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationSlideChanged, this.onActivePresentationSlideChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.featureAccessChanged, this.onFeatureAccessChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationAnimationModeChanged, this.onPresentationAnimationModeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationSlideChanged, this.onActivePresentationSlideChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.featureAccessChanged, this.onFeatureAccessChanged);
    }

    onLanguageChanged()   {
        this.setState({
            strings: {
                title: this.oe.sharedInterface.getLocalizedStringEnc('presentation_view'),
            }
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.presentation) {
            this.updateUIState();
        }
    }

    onPresentationAnimationModeChanged()    {
        this.updateBtnState();
    }

    onActivePresentationChanged(message, userInfo)   {
        let activePresID = this.oe.sharedInterface.getUIControllerPresentation().getActivePresentationID();

        if(activePresID !== this.activePresID)  {
            this.activePresID = activePresID;
            this.setState({activePresID: this.activePresID});

            // enable animation if a actual presentation is set
            this.oe.sharedInterface.getUIControllerPresentation().stopAnimation();
        
            this.onActivePresentationSlideChanged();
        }
    }

    onActivePresentationSlideChanged(message, userInfo)  {
        this.activeSlideID = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerPresentation().getActiveSlideID() : userInfo.slideID;
        this.setState({activeSlideID: this.activeSlideID});

        this.updateBtnState();
    }

    onPresentationAdded(message, userInfo)  {
        this.oe.sharedInterface.getUIControllerPresentation().setActivePresentationID(userInfo.presID);
    }

    onPresentationSlideAdded(message, userInfo)  {
        if(this.activePresID === userInfo.presID)   {
            this.oe.sharedInterface.getUIControllerPresentation().setActiveSlideID(userInfo.slideID);
        }
    }

    onFeatureAccessChanged()    {
        let featureSet = this.oe.sharedInterface.getUIControllerFeatureAccess().getFeatureSet();

        this.isViewerLevel = featureSet.hasFeature(this.oe.Module.Feature.play_pres);
        this.isProLevel = featureSet.hasFeature(this.oe.Module.Feature.edit_pres);

        this.updateBtnState();
    }

    updateUIState()   {
        this.setState({ uiEnabled: this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled() });
        this.updateBtnState();
    }

    updateBtnState(reset)    {
        let uiEnabled = false;
        let isPlaying = false;
        let hasActivePresentation = false;
        let hasActiveSlide = false;
        let isProLevel = true, isViewerLevel = true;

        if(reset !== true)  {
            let presController = this.oe.sharedInterface.getUIControllerPresentation();
            uiEnabled = presController.getUIEnabled();
            isPlaying = presController.getAnimationMode() === this.oe.Module.AnimationMode.playing;
            
            hasActivePresentation = this.activePresID >= 0;
            hasActiveSlide = this.activeSlideID >= 0;
        
            isProLevel = this.isProLevel, isViewerLevel = this.isViewerLevel;
        }

        let uiEnabledNotPlaying = uiEnabled && !isPlaying;
        let uiEnabledNotPlayingPro = uiEnabledNotPlaying && isProLevel;

        this.setState({ 
            buttons: {
                addPres: { enabled: uiEnabledNotPlayingPro },
                removePres: { enabled: uiEnabledNotPlayingPro && hasActivePresentation },
                addSlide: { enabled: uiEnabledNotPlayingPro && hasActivePresentation },
                removeSlide: { enabled: uiEnabledNotPlayingPro && hasActiveSlide },
                set : { enabled: uiEnabledNotPlayingPro && hasActiveSlide },
                slide : { enabled: uiEnabledNotPlayingPro && hasActiveSlide },
                load : { enabled: uiEnabledNotPlaying && isViewerLevel },
                save : { enabled: uiEnabledNotPlayingPro && hasActivePresentation }
            }
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            
            this.activePresID = -2;
            this.activeSlideID = -1;

            this.isViewerLevel = false;
            this.isProLevel = false;

            this.setState({ uiEnabled: false, activePresID: -2, activeSlideID: -1 });
            this.updateBtnState(true);

            if(this.presetControllerRef) this.presetControllerRef.close();

            return;
        }

        retardUpdate(this, () => {
            this.onLanguageChanged();
            this.onActivePresentationChanged();
            this.onFeatureAccessChanged();
            this.updateUIState();
        });
    }

    onFileLoadInputRef(ref)    {
        this.fileLoadInput = ref;
    }

    onPresetControllerRef(ref)  {
        this.presetControllerRef = ref;
    }

    render() {
        if(!this.props.config.enabled) return null;
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control"
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.presentation}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    title={this.state.strings.title}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >

                    <input id="presFileLoad"
                        type="file"
                        accept=".xbin"
                        ref={this.onFileLoadInputRef}
                        style={{display: 'none'}}
                        onChange={this.onLoadInputResult}
                    />

                    <div className="presentation-controller">

                        <OEControl>
                            <OEPresentationList className="view-bg std-border" moduleId={this.props.moduleId}/>
                        </OEControl>
                        
                        <OEControl className="split no-vertical-padding">
                            <div className="left">
                                <OEButton className="full-transparent-btn" disabled={!this.state.buttons.load.enabled} onPressed={this.onLoadBtnPressed}>
                                    <OEIcon code={OEIconCodes.cloudDownload} />
                                </OEButton>
                                <OEButton className="full-transparent-btn" disabled={!this.state.buttons.save.enabled} onPressed={this.onSaveBtnPressed}>
                                    <OEIcon code={OEIconCodes.cloudUpload} />
                                </OEButton>
                            </div>

                            <div className="right text-right">
                                <OEButton className="full-transparent-btn" disabled={!this.state.buttons.removePres.enabled} onPressed={this.onRemovePresBtnPressed}>
                                    <OEIcon code={OEIconCodes.presRemove} />
                                </OEButton>

                                <OEButton className="full-transparent-btn" disabled={!this.state.buttons.addPres.enabled} onPressed={this.onAddPresBtnPressed}>
                                    <OEIcon code={OEIconCodes.presAdd} />
                                </OEButton>
                            </div>

                        </OEControl>

                        <OEControl>
                            <OEPresentationSlideList className="view-bg std-border" moduleId={this.props.moduleId} presID={this.state.activePresID}/>
                        </OEControl>
                        
                        <OEControl className="split no-vertical-padding">

                            <div className="left">
                                <OEButton id="presentation-controller-preset-btn" className={'full-transparent-btn' + (this.props.config.showPresetBtn ? '' : ' d-none')} onPressed={this.onPresetBtnPressed}>
                                    <OEIcon code={OEIconCodes.preset} />
                                </OEButton>
                                <OENotesAreInDocumentButton className="full-transparent-btn" moduleId={this.props.moduleId} isHidden={!this.props.config.showNotesAreInDocumentButton}/>
                            </div>

                            <div className="right">
                                <OEButton className="full-transparent-btn" disabled={!this.state.buttons.set.enabled} onPressed={this.onSetBtnPressed}>
                                    <OEIcon code={OEIconCodes.refresh} />
                                </OEButton>

                                <OEButton className="full-transparent-btn" disabled={!this.state.buttons.removeSlide.enabled} onPressed={this.onRemoveSlideBtnPressed}>
                                    <OEIcon code={OEIconCodes.presRemove} />
                                </OEButton>

                                <OEButton className="full-transparent-btn" disabled={!this.state.buttons.addSlide.enabled} onPressed={this.onAddSlideBtnPressed}>
                                    <OEIcon code={OEIconCodes.presAdd} />
                                </OEButton>
                            </div>

                        </OEControl>

                        <OEControl>
                            <OEPresentationAnimationController moduleId={this.props.moduleId} />
                        </OEControl>
                    
                    </div>
                    <OEPresetPopoverController
                        ref={this.onPresetControllerRef}
                        moduleId={this.props.moduleId}
                        appComponent={this.props.appComponent}
                        target="presentation-controller-preset-btn"
                        presetType={[OEPresetType.camera, OEPresetType.cut]}
                    />
                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.presentation});
    }

    onLoadBtnPressed()  {
        this.fileLoadInput.value = null; // empties the file list so that onLoadInputResult gets called when loading the same file multiple times successively
        this.fileLoadInput.click();
    }

    onSaveBtnPressed()  {
        this.props.appComponent.showWaitingController();

        this.savePresentation(function(result)  {
            this.props.appComponent.hideWaitingController();

            if(result !== this.oe.Module.PresentationSerializerResult.ok)   {
                console.log("Saving presentation failed with error - " + result.constructor.name);
            }

        }.bind(this));
    }

    onRemovePresBtnPressed()    {
        this.oe.sharedInterface.getUIControllerPresentation().removePresentation();
    }

    onAddPresBtnPressed()  {
        this.oe.sharedInterface.getUIControllerPresentation().addNewPresentation(true);
    }

    onPresetBtnPressed()    {
        if(this.presetControllerRef) this.presetControllerRef.open();
    }

    onSetBtnPressed()   {
        this.oe.sharedInterface.getUIControllerPresentation().set();
    }

    onRemoveSlideBtnPressed()   {
        this.oe.sharedInterface.getUIControllerPresentation().removeSlide();
    }

    onAddSlideBtnPressed()  {
        //this.oe.sharedInterface.getUIControllerPresentation().addSlide();
        this.oe.sharedInterface.getUIControllerPresentation().insertSlide();
    }

    onLoadInputResult(event)  {
        event.stopPropagation();
        event.preventDefault();

        if(event.target.files.length == 0)  return;

        this.props.appComponent.showWaitingController();

        let allFileProgress = {i: 0, num: event.target.files.length};

        let onLoadFunc = function(event)  {
            if(event.target.readyState != FileReader.DONE)  return;

            if(!this.oe.isReady)    {
                allFileProgress.i = allFileProgress.i + 1;
                if(allFileProgress.i == allFileProgress.num)    {
                    this.props.appComponent.hideWaitingController();
                }
                return;
            }

            let arrayBuffer = event.target.result;
            let result = this.oe.sharedInterface.getUIControllerPresentation().addPresentationFromBuffer(arrayBuffer);

            if(result !== this.oe.Module.PresentationSerializerResult.ok)   {
                console.log('Loading presentation failed with error - ' + result.constructor.name);
            }

            allFileProgress.i = allFileProgress.i + 1;
            if(allFileProgress.i == allFileProgress.num)    {
                this.props.appComponent.hideWaitingController();
            }

         }.bind(this);

        for(let i = 0; i < event.target.files.length; ++i)  {
            let reader = new FileReader();
            reader.onload = onLoadFunc;
            reader.readAsArrayBuffer(event.target.files[i]);
        }
    }

    savePresentation(callback)  {
        let presController = this.oe.sharedInterface.getUIControllerPresentation();
        let resultType = this.oe.Module.PresentationSerializerResult;

        let activePresentation = presController.getActivePresentation();
        if(activePresentation < 0)    {
            callback(resultType.unexpected);
            return;
        }

        let result = presController.savePresentation();

        if(result.result !== resultType.ok)    {
            callback(result.result);
            return;
        }

        let presData = presController.getPresentationData(activePresentation);
        let name = presData.name.get() ? presData.name.get() : this.oe.sharedInterface.getLocalizedStringEnc('presentation_filename');

        FileSaver.saveAs(new Blob([new Uint8Array(result.buffer)]), name + '.xbin');
        callback(resultType.ok);
    }
}

OEPresentationController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.presentationController()
};

OEPresentationController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showPresetBtn: PropTypes.bool,
        showNotesAreInDocumentButton: PropTypes.bool
    }).isRequired
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.presentationController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.presentation)
    }
})(OEPresentationController);