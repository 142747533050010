import React from 'react';  
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import {Input} from 'reactstrap';

import {withIsOpenState} from '../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OEButton, OEIcon} from './oe-controls';
import {OEIconCodes} from '../lib/oe-icon-codes';
import OEPopover from './oe-popover';
import OEPopoverMenuController from './oe-popover-menu-controller';
import {OEToolbox} from '../lib/oe-toolbox';
import OEScrollbars from './oe-scrollbars';
import {retardUpdate} from '../lib/update-retarder';

export const OEPresetType = {
    unknown: 0,
    any: 1,
    dummy: 2,
    note: 3,
    camera: 4,
    cut: 5,
    stringTable: ['unknown', 'any', 'dummy', 'note', 'camera', 'cut'],
    toString: function(type) {
        return typeof(type) !== 'number' || type < 0 || type >= this.stringTable.length ? undefined : this.stringTable[type];
    }
};

export class OEPresetTableCell extends React.Component {

    constructor(props)  {
        super(props); 

        this.onInputRef = this.onInputRef.bind(this);

        this.onNameChanged = this.onNameChanged.bind(this);
        this.onCheckBoxChanged = this.onCheckBoxChanged.bind(this);
        this.onPresetApplyBtnPressed = this.onPresetApplyBtnPressed.bind(this);

        this.typeControllerName
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.name != nextProps.name || nextProps.type != this.props.type || !OEToolbox.shallowEqual(this.props.controllerUIEnabled, nextProps.controllerUIEnabled) || nextProps.selected != this.props.selected || nextProps.showSeparator != this.props.showSeparator || nextProps.showIcon != this.props.showIcon;
    }

    componentDidMount() {
        if(this.props.checkIfAdded && this.props.checkIfAdded(this.props.id)) {
            this.inputField.focus();
        }
    }

    onInputRef(ref) {
        this.inputField = ref;
    }

    render()    {
        let typeAsString = OEPresetType.toString(this.props.type);
        let applyEnabled = typeAsString && this.props.controllerUIEnabled[typeAsString];

        return(
            <div className="preset-cell">
                <div className="content">
                    <Input
                        type="checkbox"
                        className="check-box"
                        checked={this.props.selected}
                        onChange={this.onCheckBoxChanged}
                    />
                    <Input
                        innerRef={this.onInputRef}
                        type="text"
                        className="text-field"
                        spellCheck={false}
                        value={this.props.name}
                        onChange={this.onNameChanged}
                    />
                    {!this.props.showIcon && typeAsString && OEIconCodes.presetType[typeAsString] ? null : <OEIcon code={OEIconCodes.presetType[typeAsString]}/>}
                    <OEButton
                        className="transparent-btn apply-btn"
                        disabled={!applyEnabled}
                        onPressed={this.onPresetApplyBtnPressed}
                    >
                        <OEIcon code={OEIconCodes.presetApply}/>
                    </OEButton>
                </div>
                {this.props.showSeparator ? <div className="separator"/> : null}
            </div>
        );
    }

    onNameChanged(evt)  {
        if(this.props.onPresetNameChanged)  this.props.onPresetNameChanged(this.props.id, evt.target.value);
    }

    onCheckBoxChanged(evt)  {
        if(this.props.onPresetSelectionChange)  this.props.onPresetSelectionChange(this.props.id, evt.target.checked);
    }

    onPresetApplyBtnPressed()   {
        if(this.props.onPresetApplyBtnPressed)  this.props.onPresetApplyBtnPressed(this.props.id);
    }
}

OEPresetTableCell.defaultProps = {
    controllerUIEnabled: {
        note: false,
        camera: false,
        cut: false
    }
};

OEPresetTableCell.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
    controllerUIEnabled: PropTypes.shape({
        note: PropTypes.bool,
        camera: PropTypes.bool,
        cut: PropTypes.bool
    }),
    selected: PropTypes.bool,
    showSeparator: PropTypes.bool,
    showIcon: PropTypes.bool,
    onPresetNameChanged: PropTypes.func,
    onPresetSelectionChange: PropTypes.func,
    onPresetApplyBtnPressed: PropTypes.func,
    checkIfAdded: PropTypes.func
};

export class OEPresetController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.presetSetData = [];
        this.presetType = this.props.presetType;
        this.controllerTypes = [];

        this.addMenuEntries = this.addMenuEntries();
        this.state = {
            uiEnabled: false,
            presetSetData: [],
            controllerUIEnabled: {
                note: false,
                camera: false,
                cut: false
            },
            btns: {
                dump: false,
                save: false,
                load: false,
                add: false
            },
            addMenuEntries: this.addMenuEntries
        }

        this.onPresetAdded = this.onPresetAdded.bind(this);
        this.onPresetRemoved = this.onPresetRemoved.bind(this);
        this.onPresetChanged = this.onPresetChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onPresetNameChanged = this.onPresetNameChanged.bind(this);
        this.onPresetSelectionChange = this.onPresetSelectionChange.bind(this);
        this.onPresetApplyBtnPressed = this.onPresetApplyBtnPressed.bind(this);
        this.checkIfAdded = this.checkIfAdded.bind(this);

        this.onFileLoadInputRef = this.onFileLoadInputRef.bind(this);
        this.onAddMenuRef = this.onAddMenuRef.bind(this);

        this.onAddBtnPressed = this.onAddBtnPressed.bind(this);
        this.onAddMenuSelection = this.onAddMenuSelection.bind(this);
        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);
        this.onDumpBtnPressed = this.onDumpBtnPressed.bind(this);
        this.onLoadBtnPressed = this.onLoadBtnPressed.bind(this);
        this.onSaveBtnPressed = this.onSaveBtnPressed.bind(this);
        this.onLoadInputResult = this.onLoadInputResult.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.presetType !== this.props.presetType) {
            this.presetType = nextProps.presetType;
            this.updateAddMenuEntries(nextProps);
            this.updatePresetSet();
        }
        
        if(nextProps.refId !== this.props.refId) {
            this.updateAddMenuEntries(nextProps);
            this.updateUIState(nextProps);
        }
    }

    onConnect() {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presetAdded, this.onPresetAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presetRemoved, this.onPresetRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presetChanged, this.onPresetChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease() {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presetAdded, this.onPresetAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presetRemoved, this.onPresetRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presetChanged, this.onPresetChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    presetTypeToArray(type) {
        if(Array.isArray(type)) return type;
        if(typeof(type) === 'number') return [type];
        return [];
    }

    presetTypeFromCore(type)    {
        if(Array.isArray(type)) return type.map(e => e.value);
        if(type) return type.value;
    }

    presetTypeToCore(type)  {
        if(Array.isArray(type)) return type.map(e =>  {
            return {value: e};
        });

        if(typeof(type) === 'number')   return {value: type};
    }

    addMenuEntries(props) {
        let props_ = props || this.props;
        if(!Array.isArray(this.presetType) && typeof(this.presetType) !== 'number') return [];
        let addableTypes = typeof(props_.refId) === 'number' ? [OEPresetType.note, OEPresetType.camera, OEPresetType.cut] : [OEPresetType.camera, OEPresetType.cut];

        let types = Array.isArray(this.presetType) ? this.presetType : [this.presetType];
        types = types.includes(OEPresetType.any) ? addableTypes : addableTypes.filter(type => types.includes(type));

        return types.map(type => {
            let typeAsString = OEPresetType.toString(type);
            return {id: type, icon: OEIconCodes.presetType[typeAsString]};
        });
    }

    updateAddMenuEntries(props)  {
        this.addMenuEntries = this.addMenuEntries(props);
        this.setState({addMenuEntries: this.addMenuEntries});
    }

    updatePresetSet() {
        let coreTypes = this.presetTypeToCore(this.presetType);
        let presetSetData = coreTypes ? this.oe.sharedInterface.getUIControllerPreset().getPresetSetData(coreTypes) : [];
        this.presetSetData = presetSetData.map(data => Object.assign({selected: false}, data, {type: this.presetTypeFromCore(data.type), added: false}));
        this.setState({presetSetData: Array.from(this.presetSetData)});
        this.updateBtnStates();
    }

    updateBtnStates() {
        let uiEnabled = this.oe.sharedInterface.getUIControllerPreset().getUIEnabled();
        let hasSelection = this.presetSetData.findIndex(preset => preset.selected === true) >= 0 ? true : false;
        this.setState({
            btns: {
                dump: uiEnabled && hasSelection, 
                save: uiEnabled && this.presetSetData.length > 0,
                load: uiEnabled && this.presetTypeToArray(this.presetType).filter(type => type != OEPresetType.unknown).length > 0,
                add: uiEnabled && this.addMenuEntries.length > 0
            }
        });
    }

    updateUIState(props) {
        let props_ = props || this.props;
        let uiEnabled = this.oe.sharedInterface.getUIControllerPreset().getUIEnabled();

        let controllerUIEnabled = {
            note: this.oe.sharedInterface.getUIControllerNote().getUIEnabled() && typeof(props_.refId) === 'number',
            camera: true,
            cut: this.oe.sharedInterface.getUIControllerCut().getUIEnabled()
        };

        this.setState({uiEnabled: uiEnabled, controllerUIEnabled: controllerUIEnabled});

        this.updateBtnStates();
    }

    updateState(released) {
        if(!this.oe.isReady() || released === true) {
            this.presetSetData = [];
            this.setState({
                uiEnabled: false,
                presetSetData: [],
                controllerUIEnabled: {
                    note: false,
                    camera: false,
                    cut: false
                },
                btns: {
                    dump: false,
                    save: false,
                    load: false,
                    add: false
                }
            });
            if(this.addMenuRef) this.addMenuRef.close();
            return;
        }

        retardUpdate(this, () => {
            this.updatePresetSet();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo) {
        if(userInfo.type === this.oe.Module.UIControllerType.preset || userInfo.type === this.oe.Module.UIControllerType.note || userInfo.type === this.oe.Module.UIControllerType.cut) {
            this.updateUIState();
        }
    }

    onPresetAdded(message, userInfo) {
        this.presetSetData.push(Object.assign({selected: false}, userInfo.data, {type: this.presetTypeFromCore(userInfo.data.type), added: true}));
        this.setState({presetSetData: Array.from(this.presetSetData)});
        this.updateBtnStates();
    }

    onPresetRemoved(message, userInfo) {
        let index = this.presetSetData.findIndex(preset => preset.id === userInfo.ID);
        this.presetSetData.splice(index, 1);
        this.setState({presetSetData: Array.from(this.presetSetData)});
        this.updateBtnStates();
    }

    onPresetChanged(message, userInfo) {
        let index = this.presetSetData.findIndex(preset => preset.id === userInfo.data.id);
        this.presetSetData[index].name = userInfo.data.name;
        this.setState({presetSetData: Array.from(this.presetSetData)});
    }

    onPresetNameChanged(id, text) {
        this.oe.sharedInterface.getUIControllerPreset().setPresetName(id, OEToolbox.encode_utf8(text));
    }

    onPresetSelectionChange(id, selected) {
        let index = this.presetSetData.findIndex(preset => preset.id === id);
        this.presetSetData[index].selected = selected;
        this.setState({presetSetData: Array.from(this.presetSetData)});
        this.updateBtnStates();
    }

    onPresetApplyBtnPressed(id) {
        this.oe.sharedInterface.getUIControllerPreset().applyPreset(id, this.props.refId);
    }

    checkIfAdded(id) {
        let index = this.presetSetData.findIndex(preset => preset.id === id);
        let added = this.presetSetData[index].added;
        this.presetSetData[index].added = false;
        return added;
    }

    onFileLoadInputRef(ref)    {
        this.fileLoadInput = ref;
    }

    onAddMenuRef(ref)   {
        this.addMenuRef = ref;
    }
    
    render() {
        let showCellIcon = Array.isArray(this.props.presetType) && this.props.presetType.length > 1;

        let cells = this.state.presetSetData.map((preset, i) =>
            <OEPresetTableCell
                key={preset.id}
                id={preset.id}
                name={preset.name}
                type={preset.type}
                controllerUIEnabled={this.state.controllerUIEnabled}
                selected={preset.selected}
                showSeparator={i + 1 < this.state.presetSetData.length}
                showIcon={showCellIcon}
                onPresetNameChanged={this.onPresetNameChanged}
                onPresetSelectionChange={this.onPresetSelectionChange}
                onPresetApplyBtnPressed={this.onPresetApplyBtnPressed}
                checkIfAdded={this.checkIfAdded}
             />
        );

        return  (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="preset-controller">

                    <input id="presetFileLoad"
                        type="file"
                        accept=".xpres"
                        ref={this.onFileLoadInputRef}
                        style={{display: 'none'}}
                        onChange={this.onLoadInputResult}
                    />

                    <div className="top-bar">
                        <div className="left">
                            <OEButton
                                className="transparent-btn dump-btn"
                                onPressed={this.onDumpBtnPressed}
                                disabled={!this.state.btns.dump}
                            >
                                <OEIcon code={OEIconCodes.presetDump}/>
                            </OEButton>
                        </div>
                    
                        <div className="right">
                            
                            <OEButton
                                className="transparent-btn save-btn"
                                onPressed={this.onSaveBtnPressed}
                                disabled={!this.state.btns.save}
                            >
                                <OEIcon code={OEIconCodes.presetSave}/>
                            </OEButton>

                            <OEButton
                                className="transparent-btn load-btn"
                                onPressed={this.onLoadBtnPressed}
                                disabled={!this.state.btns.load}
                            >
                                <OEIcon code={OEIconCodes.presetLoad}/>
                            </OEButton>

                            <OEButton
                                id="preset-controller-add-btn"
                                className="transparent-btn add-btn"
                                onPressed={this.onAddBtnPressed}
                                disabled={!this.state.btns.add}
                            >
                                <OEIcon code={OEIconCodes.presetAdd}/>
                            </OEButton>

                            <OEButton
                                className="transparent-btn close-btn"
                                onPressed={this.onCloseBtnPressed}
                            >
                                <OEIcon code={OEIconCodes.close}/>
                            </OEButton>

                        </div>
                    </div>

                    <div className="separator"/>

                    <div className="preset-list">
                        <OEScrollbars>
                            {cells}
                        </OEScrollbars>
                    </div>

                    <OEPopoverMenuController
                        ref={this.onAddMenuRef}
                        moduleId={this.props.moduleId}
                        boundariesElement={this.props.boundariesElement}
                        target="preset-controller-add-btn"
                        entries={this.state.addMenuEntries}
                        onChange={this.onAddMenuSelection}
                    />
                </div>
            </React.Fragment>
        );
    }

    onAddBtnPressed() {
        if(!this.oe.isReady() || this.state.addMenuEntries.length === 0)  return;
        if(this.state.addMenuEntries.length > 1)    {
            if(this.addMenuRef) this.addMenuRef.open();
        } else {
            let presetType = this.presetTypeToCore(this.state.addMenuEntries[0].id);
            this.oe.sharedInterface.getUIControllerPreset().addPreset('', presetType, this.props.refId);
        }
    }

    onAddMenuSelection(id)    {
        if(this.addMenuRef) this.addMenuRef.close();
        if(!this.oe.isReady()) return;
        let presetType = this.presetTypeToCore(id);
        this.oe.sharedInterface.getUIControllerPreset().addPreset('', presetType, this.props.refId);
    }

    onCloseBtnPressed() {
        if(this.props.onToggle) this.props.onToggle();
    }

    onDumpBtnPressed() {
        if(!this.oe.isReady())  return;
        let si = this.oe.sharedInterface;
        let selected = this.presetSetData.filter(preset => preset.selected === true);
        selected.forEach(preset => {si.getUIControllerPreset().removePreset(preset.id)});
    }

    onLoadBtnPressed()  {
        this.fileLoadInput.value = null; // empties the file list so that onLoadInputResult gets called when loading the same file multiple times successively
        this.fileLoadInput.click();
    }
    
    onSaveBtnPressed() {
        this.props.appComponent.showWaitingController();
        this.savePreset(function(result)    {
            this.props.appComponent.hideWaitingController();
            if(result !== this.oe.Module.PresetResult.ok)   console.log('Saving preset failed with error - ' + result.constructor.name);
        }.bind(this));
    }

    savePreset(callback) {
        let presetController = this.oe.sharedInterface.getUIControllerPreset();
        let presetType = this.presetTypeToCore(this.presetType);

        if(!presetType) {
            callback(this.oe.Module.PresetResult.unexpected);
            return;
        }

        let result = presetController.save(presetType);

        if(result.result !== this.oe.Module.PresetResult.ok) {
            callback(result.result);
            return;
        }

        let types = this.presetTypeToArray(this.presetType)
        let filename = 'presets.xpres';
        if(types.length == 1 && types[0] !== OEPresetType.any) {
            let prefix = '';
            switch(types[0]) {
                case OEPresetType.unknown: prefix = 'unknown'; break;
                case OEPresetType.any: prefix = 'any'; break;
                case OEPresetType.dummy: prefix = 'dummy'; break;
                case OEPresetType.note: prefix = 'note'; break;
                case OEPresetType.camera: prefix = 'camera'; break;
                case OEPresetType.cut: prefix = 'cut'; break;
                default: break;
            }
            filename = prefix + '-presets.xpres';
        }

        FileSaver.saveAs(new Blob([new Uint8Array(result.buffer)]), filename);
        callback(this.oe.Module.PresetResult.ok);
    }

    loadPreset(files, callback) {
        let presetType = this.presetTypeToCore(this.presetType);
        if(!presetType) {
            callback(this.oe.Module.PresetResult.unexpected);
            return;
        }

        let allFileProgress = {i: 0, num: files.length, aborted: false};

        let onLoadFunction = function(event) {
            if(allFileProgress.aborted || event.target.readyState != FileReader.DONE) return;

            if(!this.oe.isReady) {
                allFileProgress.aborted = true;
                callback(this.oe.Module.PresetResult.ok);
                return;
            }

            let arrayBuffer = event.target.result;
            let result = this.oe.sharedInterface.getUIControllerPreset().loadFromBuffer(arrayBuffer, presetType);

            if(result !== this.oe.Module.PresetResult.ok && result !== this.oe.Module.PresetResult.noPresetsFound) {
                allFileProgress.aborted = true;
                callback(result);
                return;
            }

            allFileProgress.i = allFileProgress.i + 1;
            if(allFileProgress.i == allFileProgress.num) callback(this.oe.Module.PresetResult.ok);

        }.bind(this);

        for(let i = 0; i < files.length; ++i)  {
            if(allFileProgress.aborted) break;
            let reader = new FileReader();
            reader.onload = onLoadFunction;
            reader.readAsArrayBuffer(files[i]);
        }
    }

    onLoadInputResult(event) {
        event.stopPropagation();
        event.preventDefault();
        if(event.target.files.length == 0) return;
        this.props.appComponent.showWaitingController();

        this.loadPreset(event.target.files, result => {
            this.props.appComponent.hideWaitingController();
            if(result !== this.oe.Module.PresetResult.ok) console.log('Loading presets failed with error - ' + result.constructor.name);
        });
    }
}

OEPresetController.defaultProps = {
    moduleId: ''
};

OEPresetController.propTypes = {
    moduleId: PropTypes.string,
    onToggle: PropTypes.func,
    presetType: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    refId: PropTypes.number
};

export class OEPresetPopoverController extends React.PureComponent {
    render() {
        return  (
            <OEPopover
                className="popover-control"
                boundariesElement={this.props.boundariesElement}
                target={this.props.target}
                placement="left"
                noHeader={true}
                moduleId={this.props.moduleId}
                isOpen={this.props.isOpen}
                backdrop={true}
                onToggle={this.props.onToggle}
            >
                <OEPresetController
                    appComponent={this.props.appComponent}
                    moduleId={this.props.moduleId}
                    boundariesElement={this.props.boundariesElement}
                    presetType={this.props.presetType}
                    refId={this.props.refId}
                    onToggle={this.props.onToggle}
                />
            </OEPopover>
        );
    }
}

OEPresetPopoverController.defaultProps = {
    moduleId: '',
    target: ''
};

OEPresetPopoverController.propTypes = {
    moduleId: PropTypes.string,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    presetType: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    refId: PropTypes.number
};

export default withIsOpenState(OEPresetPopoverController);