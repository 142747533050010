import React from 'react';
import PropTypes from 'prop-types';

export class OEAnimationLoop extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;

        this._isRunning = this.props.isInitiallyRunning;

        this.loop = this.loop.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isRunning !== this.props.isRunning)     {
            if(this.isRunning(nextProps))   {
                this.start();
            } else {
                this.stop();
            }
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.apply();
    }

    componentWillUnmount()    {
        if(!this.isRunning())    this.stop();
        this.apply(false);
        this.mounted = false;
    }

    isRunning(props)   {
        var props_ = props || this.props;
        return typeof(props_.isRunning) === 'boolean' ? props_.isRunning : this._isRunning;
    }

    stop()  {
        this._isRunning = false;
        this.apply();
    }

    start() {
        this._isRunning = true;
        this.apply();
    }

    apply(run) {
        if(!this.mounted) return;
        var run_ = typeof(run) === 'boolean' ? run : this.isRunning();

        if((run_ && this._frameId) || (!run_ && !this._frameId))   return;

        if(run_)    {
            //console.log('requestAnimationFrame');
            this._frameId = window.requestAnimationFrame( this.loop );
        } else {
            //console.log('cancelAnimationFrame');
            window.cancelAnimationFrame( this._frameId );
            this._frameId = null;
            this.lastTime = null;
        }
    }

    loop(timestamp)  {

        var time = 0.001 * timestamp;
        var deltaTime = 0;
        if(typeof(this.lastTime) === 'number')  {
            deltaTime = time - this.lastTime;
        }

        this.lastTime = time;

        //console.log('loop - time: ' + time.toString() + ' deltaTime: ' + deltaTime.toString());

        this.props.onFramemove(time, deltaTime);

        this._frameId = window.requestAnimationFrame( this.loop );
    }

    render() {
        const style = { display: 'none' };
        return (
            <div style={style}/>
        );
    }
}

OEAnimationLoop.defaultProps = {
    isInitiallyRunning: true,
    onFramemove: () => {}
};

OEAnimationLoop.propTypes = {
    isInitiallyRunning: PropTypes.bool,
    onFramemove: PropTypes.func
};