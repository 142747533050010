import React from 'react';
import PropTypes from 'prop-types';
import * as screenfull from 'screenfull';

import {connectAppEnv} from '../app-env';
import {AppMode} from '../app-types';
import {OEZeissOptiTargetControllerButton} from '../custom/zeiss/oe-zeiss-opti-target-controller';
import {OEVersion} from '../../react-oe/oe-version';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OEIcon} from '../oe-controls';
import OETargetMenu from '../oe-target-menu';

export class OERightTopBarEntryFactory extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onFullscreenBtnPressed = this.onFullscreenBtnPressed.bind(this);
        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);
    }

    render() {
        var key = 0;
        var elements = [];

        elements.push(<OETargetMenu key={key++} moduleId={this.props.moduleId} hideWhenMediaCenterOpen={true} subOrientation={OETargetMenu.subOrientation.left}/>);

        /*
        if(this.props.version === OEVersion.zeissOpti)  {
            elements.push(<OEZeissOptiTargetControllerButton key={key++} className="main-menu-control-button right-top-bar-button view-port-label-text-color-border"/>);
        }
        */

        if(this.props.showFullscreenBtn)    {
            elements.push( 
                <div key={key++} className="main-menu-control-button right-top-bar-button view-port-label-text-color-border">
                    <a onClick={this.onFullscreenBtnPressed}>
                        <OEIcon code={this.props.isFullscreen ? OEIconCodes.compress : OEIconCodes.expand} />
                    </a>
                </div>
            );
        }

        if(this.props.showCloseBtn)    {
            elements.push( 
                <div key={key++} className="main-menu-control-button right-top-bar-button view-port-label-text-color-border">
                    <a onClick={this.onCloseBtnPressed}>
                        <OEIcon code={OEIconCodes.close} />
                    </a>
                </div>
            );
        }

        return (
            <React.Fragment>
                {elements}
            </React.Fragment>
        );
    }

    onFullscreenBtnPressed()    {
        if(typeof(this.props.onFullscreenBtnPressed) === 'function')    {
            this.props.onFullscreenBtnPressed();
        } else if(this.props.app) {
            this.props.app.setFullscreen(!this.props.isFullscreen);
        }
    }

    onCloseBtnPressed() {
        if(typeof(this.props.onCloseBtnPressed) === 'function')    {
            this.props.onCloseBtnPressed();
        } else if(this.props.app) {
            this.props.app.changeMode(AppMode.start);
        }
    }
}

export const OERightTopBarEntryFactoryConnected = connectAppEnv((env) => { return {
    version: env.config.version,
    showFullscreenBtn: screenfull.enabled,
    isFullscreen: env.state.isFullscreen,
    showCloseBtn: env.config.isLicensingStart,
    app: env.component.app
}})(OERightTopBarEntryFactory);

export default class OERightTopBar extends React.PureComponent {
    render() {
        return (
            <div className="right-top-bar" style={this.props.style}>
                <OERightTopBarEntryFactoryConnected moduleId={this.props.moduleId}/>
            </div>
        );
    }
}

OERightTopBar.defaultProps = {
    moduleId: ''
};

OERightTopBar.propTypes = {
    moduleId: PropTypes.string
};