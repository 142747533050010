import React from 'react';
import PropTypes from 'prop-types';
import {Transition, TransitionGroup} from 'react-transition-group';
//import {VelocityTransitionGroup} from 'velocity-react';

export class OETransition extends React.PureComponent {

    constructor(props) {
        super(props);

        //this.state = { style: {height: 0} };

        this.onEnter = this.onEnter.bind(this);
        this.onEntering = this.onEntering.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    /*
    setStyle(height, animated)  {
        if(typeof(height) === 'undefined') {
            this.setState({style: {}});
            return;
        }
        let style = {height: height.toString() + 'px'};
        if(animated) style.transition = 'height ' + this.props.duration + 'ms ease';
        this.setState({style: style});
    }
    */

    setStyleToNode(node, height, animated)  {
        if(!node) return;
        let style = 'overflow: hidden; ';
        if(typeof(height) === 'number') style = style + 'height: ' + height.toString() + 'px; '
        if(animated) style = style + ' transition: height ' + this.props.duration + 'ms ease;';
        node.setAttribute('style', style);
    }

    onEnter(node, isAppearing)   {
        this.entering = true;
        if(this.exiting) return;
        //this.setStyle(0);
        this.setStyleToNode(node, 0);
    }

    onEntering(node, isAppearing)    {
        let clientHeight;
        if(node) clientHeight = node.scrollHeight;
        //this.setStyle(clientHeight, true);
        this.setStyleToNode(node, clientHeight, true);
    }

    onEntered(node, isAppearing) {
        //this.setStyle();
        this.setStyleToNode(node);
        this.props.onAnimFinished(node);
        this.entering = false;
    }

    onExit(node)    {
        this.exiting = true;
        if(this.entering) return;
        let clientHeight;
        if(node) clientHeight = node.scrollHeight;
        //this.setStyle(clientHeight, true);
        this.setStyleToNode(node, clientHeight);
    }

    onExiting(node) {
        //this.setStyle(0, true);
        window.requestAnimationFrame(() => {
            this.setStyleToNode(node, 0, true);
        });
    }

    onExited(node)  {
        //this.setStyle(0);
        this.setStyleToNode(node, 0);
        this.props.onAnimFinished();
        this.exiting = false;
    }

    render()    {
        const {children, onAnimFinished, duration, ...rest} = this.props;

        return (
            <Transition
                timeout={this.props.duration}
                {...rest}
                onEnter={this.onEnter}
                onEntering={this.onEntering}
                onEntered={this.onEntered}
                onExit={this.onExit}
                onExiting={this.onExiting}
                onExited={this.onExited}
            >
                {this.props.children /*{!this.props.children ? null : React.cloneElement(this.props.children, {style: this.state.style})*/}
            </Transition>
        );
    }
}

export class OETransitionGroup extends React.PureComponent {

    /*
    render()    {
        const {children, onAnimFinished, ...rest} = this.props;
        return (
            <VelocityTransitionGroup
                enter={{animation: "slideDown", duration: this.props.duration, easing: "ease-in-out", complete: this.props.onAnimFinished}}
                leave={{animation: "slideUp", duration: this.props.duration, easing: "ease-in-out", complete: this.props.onAnimFinished}}
                component={this.props.component}
                {...rest}
            >
                {this.props.children}
            </VelocityTransitionGroup>
        );
    }
    */

    render()    {
        const childs = React.Children.map(this.props.children, (child, index) =>
            <OETransition
                key={index}
                onAnimFinished={this.props.onAnimFinished}
                duration={this.props.duration}
            >
                {child}
            </OETransition>
        );

        const {children, onAnimFinished, duration, ...rest} = this.props;

        return (
            <TransitionGroup {...rest}>
                {childs}
            </TransitionGroup>
        );
    }
}

OETransitionGroup.defaultProps = {
    duration: 333,
    onAnimFinished: () => {}
};

OETransitionGroup.propTypes = {
    component: PropTypes.string,
    duration: PropTypes.number,
    onAnimFinished: PropTypes.func
};