import React from 'react';
import PropTypes from 'prop-types';
import * as screenfull from 'screenfull';

import {connectAppEnv} from '../../app-env';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {retardUpdate} from '../../../lib/update-retarder';
import {OETarget} from '../../../react-oe/oe-target';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEIcon, OEButton} from '../../oe-controls';
import OETargetMenuDef from '../../oe-target-menu';
import OEResizeObserver from '../../../lib/oe-resize-observer';
import {OEToolbox} from '../../../lib/oe-toolbox';

export class OEZeissOptiCapBar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.connectFlag = false;

        this.layoutType = {
            min: 'min',
            singleRow: 'single-row',
            twoRow: 'two-row',
            threeRow: 'three-row'
        };

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            mainTarget: this.mainTarget(this.props.target),
            layoutType: this.layoutType.min,
            strings:   {
                anatomy: 'Anatomy & Pathology',
                opti: 'Optics & Optometry',
                uv: 'UVProtect'
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        
        this.onWindowResized = this.onWindowResized.bind(this);
        this.onTargetTitleResize = this.onTargetTitleResize.bind(this);
        this.onTargetMenuResize = this.onTargetMenuResize.bind(this);
        this.onTargetMenuRef = this.onTargetMenuRef.bind(this);
        this.onMiddleRef = this.onMiddleRef.bind(this);
        this.onTargetBtnBarContentRef = this.onTargetBtnBarContentRef.bind(this);

        this.onMainMenuControlBtnPressed = this.onMainMenuControlBtnPressed.bind(this);
        this.onTargetMenuToggle = this.onTargetMenuToggle.bind(this);
        this.onTargetBtnPressed = this.onTargetBtnPressed.bind(this);
        this.onFullscreenBtnPressed = this.onFullscreenBtnPressed.bind(this);
    }

    componentDidMount()    {
        this.onWindowResized();
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onWindowResized);
    }

    componentWillUpdate(nextProps, nextState)   {
        if(nextProps.target !== this.props.target)  this.setState({mainTarget: this.mainTarget(nextProps.target)});
    }

    mainTarget(target)  {
        return target === OETarget.zeissOpti || target === OETarget.zeissOpti2 || target === OETarget.zeissUV ? target : OETarget.eyeMacro;
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        const si = this.oe.sharedInterface;
        this.setState({strings: {
            anatomy: si.getLocalizedStringEnc('target_menu_anatomy'),
            opti: si.getLocalizedStringEnc('target_menu_zeissOpti2'),
            uv: si.getLocalizedStringEnc('target_menu_zeissUV')
        }});
    }

    updateLayout()  {
        if(!this.connectFlag)   return;
        
        if(!this.targetTitleSize || !this.targetMenuSize || !this.middleRef || !this.targetBtnBarContentRef) {
            this.setState({layoutType: this.layoutType.singleRow});
            return;
        }

        const middleWidth = $(this.middleRef).outerWidth();
        const targetBtnBarContentWidth = $(this.targetBtnBarContentRef).outerWidth();
        const targetBarContentWidth = this.targetTitleSize.w + this.targetMenuSize.w;
        const targetTitlePaddingComp = {
            multiSingle: this.state.layoutType === this.layoutType.singleRow ? 0 : 24,
            singleMulti: this.state.layoutType === this.layoutType.singleRow ? -24 : 0
        };

        let layoutType = this.layoutType.singleRow;
        if(targetBarContentWidth - targetTitlePaddingComp.singleMulti > middleWidth - 16) {
            layoutType = this.layoutType.threeRow;
        } else if(targetBarContentWidth + targetTitlePaddingComp.multiSingle + 4 >= 0.5 * (middleWidth - targetBtnBarContentWidth))  {
            layoutType = this.layoutType.twoRow;
        }

        //console.log('layoutType: ' + layoutType);
        this.setState({layoutType: layoutType});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.compControllerWasVisible = false;
            return;
        }

        this.connectFlag = true;

        this.compControllerWasVisible = false;
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateLayout();
        });
    }

    onUIControllerStateChanged(message, userInfo)   {
        if(userInfo.type === this.oe.Module.UIControllerType.component && userInfo.visible) {
            this.compControllerWasVisible = false;
            if(this.targetMenuRef) this.targetMenuRef.setOpen(false);
        }
    }

    onWindowResized()   {
        this.updateLayout();
    }

    onTargetTitleResize(sender, size)  {
        if(OEToolbox.jsonEqual(this.targetTitleSize, size)) return;
        this.targetTitleSize = size;
        this.updateLayout();
    }

    onTargetMenuResize(sender, size)  {
        if(OEToolbox.jsonEqual(this.targetMenuSize, size)) return;
        this.targetMenuSize = size;
        this.updateLayout();
    }

    onTargetMenuRef(ref)    {
        this.targetMenuRef = ref;
    }

    onMiddleRef(ref)   {
        if(this.middleRef === ref) return;
        this.middleRef = ref;
        this.updateLayout();
    }

    onTargetBtnBarContentRef(ref) {
        if(this.targetBtnBarContentRef === ref) return;
        this.targetBtnBarContentRef = ref;
        this.updateLayout();
    }

    renderTargetBar()   {
        const strings = this.state.strings;
        const mainTarget = this.state.mainTarget;
        const targetTitle = mainTarget === OETarget.zeissUV ? strings.uv : ([OETarget.zeissOpti, OETarget.zeissOpti2].indexOf(mainTarget) >= 0 ? strings.opti : strings.anatomy); 
        
        const layoutType = this.state.layoutType === this.layoutType.min ? this.layoutType.singleRow : this.state.layoutType;

        return this.state.layoutType === this.layoutType.min ? (
            <div className="target-bar">
                <div className="light-bg-border spacer-flex"/>
            </div>
        ) : (
            <div className="target-bar">
                {layoutType === this.layoutType.twoRow ? <div className="light-bg-border spacer-flex"/> : null}
                <div className="content">
                    <div className="target-title-container">
                        {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
                        <span className="light-bg-border target-title">
                            <OEResizeObserver onResize={this.onTargetTitleResize} />
                            {targetTitle}
                        </span>
                        {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
                    </div>
                    <div className="target-menu-container">
                        {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
                        <div>
                            <OEResizeObserver onResize={this.onTargetMenuResize} />
                            <OETargetMenuDef moduleId={this.props.moduleId} fadeInOut={false} ref={this.onTargetMenuRef} onToggle={this.onTargetMenuToggle}/>
                        </div>
                        {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
                    </div>
                </div>
                {layoutType !== this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}
            </div>
        );
    }

    render() {
        const mainTarget = this.state.mainTarget;
        
        const fullscreenBtn = !this.props.showFullscreenBtn ? null :
            <OEButton className="btn light-hover-bg-border" onPressed={this.onFullscreenBtnPressed}>
                <OEIcon code={this.props.isFullscreen ? OEIconCodes.compress : OEIconCodes.expand} />
            </OEButton>;

        const layoutType = this.state.layoutType === this.layoutType.min ? this.layoutType.singleRow : this.state.layoutType;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'std-label-text-color zeiss-opti-cap-bar ' + ((layoutType === this.layoutType.singleRow ? '' : 'multi-row ') + layoutType)}>
                    
                    <div className="left">
                        <OEButton className="btn light-hover-bg-border" onPressed={this.onMainMenuControlBtnPressed}>
                            <OEIcon code={OEIconCodes.mainMenu} />
                        </OEButton>
                        <div className="light-bg-border spacer-flex-v"/>
                    </div>

                    <div className="middle" ref={this.onMiddleRef}>

                        {this.renderTargetBar()}

                        <div className="target-btn-bar">

                            {layoutType !== this.layoutType.singleRow ? <div className="light-bg-border spacer-flex"/> : null}

                            <div className="content" ref={this.onTargetBtnBarContentRef}>

                                <OEButton
                                    className="btn light-hover-bg-border"
                                    activated={mainTarget === OETarget.eyeMacro}
                                    userData={OETarget.eyeMacro}
                                    onPressed={this.onTargetBtnPressed}
                                    tooltip={this.state.strings.anatomy}
                                >
                                    <OEIcon code={OEIconCodes.zeissOpti.anatomyTarget} />
                                </OEButton>

                                <OEButton
                                    className="btn light-hover-bg-border"
                                    activated={mainTarget === OETarget.zeissOpti2}
                                    userData={OETarget.zeissOpti2}
                                    onPressed={this.onTargetBtnPressed}
                                    tooltip={this.state.strings.opti}
                                >
                                    <OEIcon code={OEIconCodes.zeissOpti.optiTarget} />
                                </OEButton>

                                <OEButton
                                    className="btn light-hover-bg-border"
                                    activated={mainTarget === OETarget.zeissUV}
                                    userData={OETarget.zeissUV}
                                    onPressed={this.onTargetBtnPressed}
                                    tooltip={this.state.strings.uv}
                                >
                                    <OEIcon code={OEIconCodes.zeissOpti.UVTarget} />
                                </OEButton>

                            </div>

                            <div className="light-bg-border spacer-flex"/>

                        </div>

                    </div>

                    <div className="right">
                        {fullscreenBtn}
                        {fullscreenBtn ? <div className="light-bg-border spacer-flex-v"/> : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onMainMenuControlBtnPressed()   {
        this.props.appComponent.uiLayer.onMainMenuControlBtnPressed();
    }

    onTargetMenuToggle(isOpen)    {
        if(!this.oe.isReady()) return;
        if(!isOpen) {   // going to open
            this.compControllerWasVisible = this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.component);
            this.oe.sharedInterface.setUIControllerVisible(false, this.oe.Module.UIControllerType.component);
        } else {    // going to close
            if(this.compControllerWasVisible)   this.oe.sharedInterface.setUIControllerVisible(true, this.oe.Module.UIControllerType.component);
            this.compControllerWasVisible = false;
        }
    }

    onTargetBtnPressed(event, sender)    {
        this.props.appComponent.app.changeTarget(sender.props.userData);
    }

    onFullscreenBtnPressed()    {
        this.props.appComponent.app.setFullscreen(!this.props.isFullscreen);
    }
}

OEZeissOptiCapBar.defaultProps = {
    moduleId: '',
    target: ''
};

OEZeissOptiCapBar.propTypes = {
    moduleId: PropTypes.string
};

export default connectAppEnv((env) => { return {
    target: env.config.target,
    showFullscreenBtn: screenfull.enabled,
    isFullscreen: env.state.isFullscreen,
    appComponent: env.component
}})(OEZeissOptiCapBar);