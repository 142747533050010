import React from 'react';
import PropTypes from 'prop-types';
import * as screenfull from 'screenfull';

import {connectAppEnv} from '../../app-env';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {retardUpdate} from '../../../lib/update-retarder';
import {OEZeissOptiTargetControllerButton} from '../../custom/zeiss/oe-zeiss-opti-target-controller';
import {OETarget} from '../../../react-oe/oe-target';
import {OEVersion} from '../../../react-oe/oe-version';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEIcon, OEButton} from '../../oe-controls';
import OETargetMenu from '../../oe-target-menu';

export class OEZeissOptiCapBarOpaque extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings:   {
                anatomy: 'Anatomy & Pathology',
                opti: 'Optics & Optometry',
                uv: 'UVProtect'
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onFullscreenBtnPressed = this.onFullscreenBtnPressed.bind(this);
        this.onTargetBtnPressed = this.onTargetBtnPressed.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {
        const si = this.oe.sharedInterface;
        this.setState({strings: {
            anatomy: si.getLocalizedStringEnc('target_menu_anatomy'),
            opti: si.getLocalizedStringEnc('target_menu_zeissOpti2'),
            uv: si.getLocalizedStringEnc('target_menu_zeissUV')
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
        });
    }

    render() {
        const target = this.props.target;
        const strings = this.state.strings;

        let fullscreenBtn = !this.props.showFullscreenBtn ? null :
            <OEButton className="btn" onPressed={this.onFullscreenBtnPressed}>
                <OEIcon code={this.props.isFullscreen ? OEIconCodes.compress : OEIconCodes.expand} />
            </OEButton>;

        let zeissLogo = [OETarget.zeissUV, OETarget.zeissOpti, OETarget.zeissOpti2].indexOf(target) < 0 && this.props.version !== OEVersion.zeissOpti ? null :
            <img className="logo" src="images/zeiss_logo.png"/>;

        let targetTitle = target === OETarget.zeissUV ? strings.uv : ([OETarget.zeissOpti, OETarget.zeissOpti2].indexOf(target) >= 0 ? strings.opti : strings.anatomy); 

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="zeiss-opti-cap-bar-opaque">
                    <div className="left">
                        <img className="logo gray-filter" src="images/logo/logo_eye.png"/>
                    </div>

                    <div className="middle">
                        <OEButton className="btn target-title" onPressed={this.onTargetBtnPressed}>
                            <OEIcon code={OEIconCodes.zeissOpti.targetArrow}/>
                            <span>{targetTitle}</span>
                        </OEButton>
                        {zeissLogo}
                        <OETargetMenu moduleId={this.props.moduleId} fadeInOut={false}/>
                    </div>

                    <div className="right">
                        {/*
                        <OEZeissOptiTargetControllerButton className="btn"/>
                        */}
                        {fullscreenBtn}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onFullscreenBtnPressed()    {
        this.props.appComponent.app.setFullscreen(!this.props.isFullscreen);
    }

    onTargetBtnPressed()    {
        if(!this.props.appComponent.uiLayer.startView) return;
        this.props.appComponent.uiLayer.startView.open();
    }
}

OEZeissOptiCapBarOpaque.defaultProps = {
    moduleId: '',
    target: ''
};

OEZeissOptiCapBarOpaque.propTypes = {
    moduleId: PropTypes.string
};

export default connectAppEnv((env) => { return {
    target: env.config.target,
    showFullscreenBtn: screenfull.enabled,
    isFullscreen: env.state.isFullscreen,
    appComponent: env.component
}})(OEZeissOptiCapBarOpaque);