
import {OEMediaViewerContentType, OEMediaViewerContent, OEMediaViewerDataSource} from '../media-viewer/oe-media-viewer-controller';

export default class OEMediaCenterMediaViewerDataSource extends OEMediaViewerDataSource  {

    constructor(items, categories, oe) {
        super();
        this.updateContent(items, categories, oe);
    }

    mediaViewerContentCount() {
        return this.content.length;
    }

    mediaViewerContentForIndex(index)   {
        return index >= 0 && index < this.content.length ? this.content[index] : null;
    }

    updateContent(items, categories, oe)    {
        this.content = [];
        if(!items || !categories || !oe) return;

        const mediaTypes = [oe.Module.MediaCenterItemType.image, oe.Module.MediaCenterItemType.video, oe.Module.MediaCenterItemType.html];

        var last = null;
        var lastLast = null;
        var newCategory = false;

        for(var categoryIndex = 0; categoryIndex < categories.length; ++categoryIndex)  {
            var category = categories[categoryIndex];
            newCategory = true;

            if(last)   {
                last.blockNext = true;
            }

            var itemsInCategory = items.filter((item) => (item.categoryFlag & category.categoryFlags) != 0);

            for(var itemIndex = 0; itemIndex < itemsInCategory.length; ++itemIndex)  {
                var item = itemsInCategory[itemIndex];

                var primaryItem = false;
                var newItem = this.mediaViewContent(item, categoryIndex, oe);
                
                if(newItem)    {

                    newItem.itemId = {item: item.id, subItem: -1};
                    this.content.push(newItem);

                    lastLast = last;
                    last = newItem;
                    
                    if(item.type === oe.Module.MediaCenterItemType.html)    {
                        if(lastLast)    lastLast.blockNext = true;
                        last.blockPrev = true;
                        last.blockNext = true;
                    }

                    if(lastLast && lastLast.blockNext)    {
                        last.blockPrev = true;
                    }

                    if(newCategory) {
                        if(lastLast)    lastLast.blockNext = true;
                        last.blockPrev = true;
                        newCategory = false;
                    }

                    primaryItem = true;
                }

                var hasSubItemsContent = false;

                for(var subItemIndex = 0; subItemIndex < item.subItems.length; ++subItemIndex)  {
                    var subItem = item.subItems[subItemIndex];

                    var newItem = this.mediaViewContent(item, categoryIndex, oe);

                    if(newItem)    {

                        if(primaryItem && !hasSubItemsContent)  {
                            if(lastLast)    lastLast.blockNext = true;
                            last.blockPrev = true;
                            hasSubItemsContent = true;
                        }

                        newItem.itemId = {item: item.id, subItem: subItem.id};
                        this.content.push(newItem);

                        lastLast = last;
                        last = newItem;

                        if(item.type === oe.Module.MediaCenterItemType.html)    {
                            if(lastLast)    lastLast.blockNext = true;
                            last.blockPrev = true;
                            last.blockNext = true;
                        }
    
                        if(lastLast && lastLast.blockNext)    {
                            last.blockPrev = true;
                        }
    
                        if(newCategory || !hasSubItemsContent) {
                            if(lastLast)    lastLast.blockNext = true;
                            last.blockPrev = true;
                            newCategory = false;
                            hasSubItemsContent = true;
                        }
                    }
                }

                if(hasSubItemsContent)  {
                    last.blockNext = true;
                }
            }
        }
    }

    updateContentLocalization(oe)   {
        var si = oe.sharedInterface;
        var resourcePath = 'app/oe/' + oe.Module.filePackagePathURL;

        var ret = [];

        for(var i = 0; i < this.content.length; ++i)    {
            var content = this.content[i];
            var item = content.item;
            var change = false;

            if(item.type === oe.Module.MediaCenterItemType.video)    {
                var path = resourcePath + si.getLocalizedStringEnc(item.path);
                var coverImagePath = si.getLocalizedStringEnc(item.previewImagePath);
                change = path !== content.path || content.coverImagePath !== coverImagePath;
                content.path = path;
                content.coverImagePath = coverImagePath;
            } else if(item.type === oe.Module.MediaCenterItemType.image)    {
                var path = resourcePath + si.getLocalizedStringEnc(item.path);
                change = path !== content.path;
                content.path = path;
            } else if(item.type === oe.Module.MediaCenterItemType.html)    {
                var path = resourcePath + si.getLocalizedStringEnc(item.path);
                change = path !== content.path;
                content.path = path;
            }

            if(change)  {
                ret.push(i);
            }
        }

        return ret;
    }

    mediaViewContent(item, categoryIndex, oe)   {
        var si = oe.sharedInterface;
        var resourcePath = 'app/oe/' + oe.Module.filePackagePathURL;

        var result = null;
        if(item.type === oe.Module.MediaCenterItemType.video)    {
            var path = resourcePath + si.getLocalizedStringEnc(item.path);
            var coverImagePath = si.getLocalizedStringEnc(item.previewImagePath);
            result = new OEMediaViewerContent(OEMediaViewerContentType.video, path, false, coverImagePath);
        } else if(item.type === oe.Module.MediaCenterItemType.image)    {
            var path = resourcePath + si.getLocalizedStringEnc(item.path);
            result = new OEMediaViewerContent(OEMediaViewerContentType.image, path);
            result.isCachingEnabled = false;
        } else if(item.type === oe.Module.MediaCenterItemType.html)    {
            var path = resourcePath + si.getLocalizedStringEnc(item.path);
            result = new OEMediaViewerContent(OEMediaViewerContentType.web, path);
            result.parameter = item.name;
            result.isCachingEnabled = false;
            result.isPreloadEnabled = false;
        }

        if(!result) return null;

        result.blockNext = false;
        result.blockPrev = false;
        result.item = item;
        
        return result;
    }

    indexForItem(id)    {
        return this.content.findIndex((item) => item.itemId.item === id.item && item.itemId.subItem === id.subItem);
    }
};