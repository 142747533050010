import React from 'react';
import PropTypes from 'prop-types';

import {VelocityTransitionGroup} from 'velocity-react';

import {OEToolbox} from './oe-toolbox';

export class OENavigationController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.counter = 0;
        this.title = '';

        this.stack = new Object();
        this.state = {stack: this.stack};
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    push(view) {

        //
        if(typeof(view) === 'function')    {
            return this.push(view(this.counter));
        }

        //
        const id = this.counter; this.counter++;
        var entry = new Object();
        entry[id.toString()] = {id: id, view: view, title: view.props.title};

        this.stack = Object.assign({}, this.stack, entry);
		this.setState({stack: this.stack});
        
        this.onStackChanged();
        this.onStackTopChanged();

        return id;
	}
	
	update(id, view)	{
        var isTopId = this.isTopId(id);
        this.stack = Object.assign({}, this.stack);
        this.stack[id.toString()].view = view;
        this.stack[id.toString()].title = view.props.title;
        this.setState({stack: this.stack});

        if(isTopId && view.props.title !== this.title) {
            this.title = view.props.title;
            if(typeof(this.props.onTitleChanged) === 'function')    {
                this.props.onTitleChanged(this.title);
            }
        }
	}

    close(id)  {
        var isTopId = this.isTopId(id);
        this.stack = Object.assign({}, this.stack);
        delete this.stack[id.toString()];
        this.setState({stack: this.stack});
        this.onStackChanged();
        if(isTopId) this.onStackTopChanged();
    }

    topId()    {
        var topId = -1;
        for (var id in this.stack) {
            if (this.stack.hasOwnProperty(id)) {
                topId = Math.max(topId, this.stack[id].id);
            }
        }
        return topId;
    }

    isTopId(id) {
        var topId = this.topId();
        return topId >= 0 && id >= 0 && id === topId;
    }

    top()   {
        var topId = this.topId();
        if(topId < 0) return;
        return this.stack[topId.toString()];
    }

    pop()   {
        var topId = this.topId();
        if(topId < 0) return;
        this.close(topId);
    }

    onStackTopChanged() {

        var top = this.top();
        var title = top ? top.title : '';

        if(title !== this.title) {
            this.title = title;
            if(typeof(this.props.onTitleChanged) === 'function')    {
                this.props.onTitleChanged(title);
            }
        }

        if(typeof(this.props.onStackTopChanged) === 'function')    {
            this.props.onStackTopChanged();
        }
    }

    onStackChanged()    {
        if(typeof(this.props.onStackChanged) === 'function')    {
            this.props.onStackChanged(this.stackSize());
        }
    }

    stackSize() {
        var ret = 0;
        for(var id in this.stack) {
            if (this.stack.hasOwnProperty(id)) {
                ret++;
            }
        }
        return ret;
    }

    render() {

        var views = new Array();

        for(var id in this.state.stack) {
            if (this.state.stack.hasOwnProperty(id)) {
                views.push(this.state.stack[id]);
            }
        }

        var viewElements = views.map((entry, index) =>
            React.cloneElement(entry.view, {key: entry.id, onTop: index === views.length - 1})
        );

        return (
            <div className={'navigation-controller ' + this.props.className}>
                {viewElements}
            </div>
        );

        /*
        return (
            <div className={'navigation-controller ' + this.props.className}>
                <VelocityTransitionGroup enter={{animation: {translateX: [0, '100%']}, duration: 3000}} leave={{animation: {translateX: [0, '-100%']}, duration: 3000}}>
                    {viewElements}
                </VelocityTransitionGroup>
            </div>
        );
        */
    }
}

OENavigationController.defaultProps = {
	className: ''
};

OENavigationController.propTypes = {
	className: PropTypes.string
};

export class OENavigationView extends React.PureComponent {
    render() {
        return (
            <div className={'navigation-view ' + (this.props.onTop ? 'on-top ' : 'not-on-top ') + this.props.className}>
                {this.props.children}
            </div>
        );
    }
}

OENavigationView.defaultProps = {
    className: '',
    title: ''
};

OENavigationView.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string
};

export class OENavigationViewComponent extends React.Component {

	constructor(props) {
		super(props);

        this.id = null;

		this.createView();
	}

	shouldComponentUpdate() {
        return false;
	}

	componentWillReceiveProps(nextProps) {

        if(this.props.navigationController !== nextProps.navigationController && nextProps.navigationController)  {
            this.id = nextProps.navigationController.push(this.createInstance(nextProps));
            return;
        }

		if(this.props.navigationController && this.id !== null) {
            this.props.navigationController.update(this.id, this.createInstance(nextProps)(this.id));
        }
	}

	createView()	{
		if(!this.props.navigationController || this.id !== null) return;
		
		this.id = this.props.navigationController.push(this.createInstance());
	}

	createInstance(props)	{
        var props_ = props || this.props;

		return ((id) =>
			<OENavigationView
				className={props_.className}
				style={props_.style}
                ref={props_.ref}
                title={props_.title}
                id={id}
			>
				{props_.children}
			</OENavigationView>
		);
	}

    componentWillUnmount() {
		if(!this.props.navigationController || this.id === null) return;
		
		this.props.navigationController.close(this.id);
		this.id = null;
	}
	 
    render() {
		return null;
	}
}

OENavigationViewComponent.defaultProps = {
    className: '',
    title: ''
};

OENavigationViewComponent.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string
};

