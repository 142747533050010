
export const OEIconCodes = {

    mainMenu: '\uF0C9',

    home: '\u2009',
    settings: '\uF013',
    tree: '\uF22a',
    substructure: '\uF23B',
    selection: '\u2006',
    search: '\u2005',
    cut: '\u200A',
    label: '\uF02C',
    note: '\u2007',
    arrow: '\uF22F',
    info: '\uF129',
    infoQuestionMark: '\u00FF',
    help: '\u00FF',
    stateBack: '\uF053',
    stateForth: '\uF054',
    animation: '\uF01D',
    presentation: '\u221E',
    mediaCenter: '\uF144',
    screenshot: '\u00C5',
    color: '\uF2B2',
    explode: '\uF29B',

    key: '\uF21A',
    
    werkman: '\uF29E',

    close: '\uF00D',
    compress: '\uF066',
    expand: '\uF065',

    textFieldClear: '\uF057',

    contextMenuFade: '\uF297',
    contextMenuHide: '\uF298',
    contextMenuFadeOther: '\uF296',
	contextMenuHideOther: '\uF299',
	contextMenuInfo: '\uF05a',

    cutToolTouch: '\uF2A6',
    cutToolSwipe: '\uF2A5',
    cutToolAll: '\uF29A',
    cutToolTouched: '\uF299',

    searchToolCellSelect: '\u2006',
    searchToolCellTree: '\uF22a',
    searchToolCellCenter: '\uF00e',

    cloudDownload: '\uF0ED',
    cloudUpload: '\uF0EE',

    presAdd: '\uF055',
    presRemove: '\uf056',

    refresh: '\uF021',

    reset: '\uF0E2',
    help: '\u00FF',

    stop: '\uF04D',
    play: '\uF04B',
    pause: '\uF04C',

    chevronLeft: '\uF053',
    chevronRight: '\uF054',

    caretLeft: '\uF0D9',
    caretRight: '\uF0DA',

    forward: '\uF04E',
    backward: '\uF04A',

    eye: '\uF06E',

    addArrowNote: '\uF055',
    arrowApplyToAll: '\uF122',
	arrowDump: '\uF1F8',
	arrowLength: '\uF07E',
	arrowWidth: '\uF07D',
	
	noteApplyToAll: '\uF122',
	noteDump: '\uF1F8',
	noteShowAlways: '\uF06E',
	noteNotShowAlways: '\uF070',
	noteDecFont: '\uF031',
    noteIncFont: '\uF031',
    noteTextColor: '\uF031',
	noteLineColor: '\uF040',
	noteBackgroundColor: '\uF043',
	noteBorder : '\uF24D',
	noteNoBorder: '\uF096',
	noteShowColor: '\uF068',
    noteIsInDocument: '\uF247',
    noteIsNotInDocument: '\uF248',

	noteHAlignmentLeft: '\uF226',
	noteHAlignmentCenter: '\uF225',
	noteHAlignmentRight: '\uF224',
	noteVAlignmentTop: '\uF221',
	noteVAlignmentCenter: '\uF222',
	noteVAlignmentBottom: '\uF223',

	preset: '\uF045',
	presetAdd: '\uF067',
	presetSave: '\uF0EE',
	presetLoad: '\uF0ED',
	presetDump: '\uF014',
    presetApply: '\uF090',
    presetType: {
        unknown: '\u00FF',
        dummy: '\uF10C',
        note: '\u2007',
        camera: '\uF030',
        cut: '\u200A'
    },

    ctSwitchOff: '\uF1B2',
    ctSwitchOn: '\uF1B3',

    ctActivationOn: '\uF00C',
    ctActivationOff: '\uF00C',

    ctCategoriesSwitch: '\uF23B',
    ctCategoriesActivation: '\uF23B',

    ctNodeTerminal: '\uF016',
    ctNodeExpanded: '\uF07C',
    ctNode: '\uF07B',
    ctNodeTerminalDot: '\uF111',

    licensingSettings: '\uF013',
    licensingPlay: '\uF144',
    licensingBuy: '\uF07A',
    licensingEnvelope: '\uF0E0',
    licensingKeyInput: '\uF0A9',
    licensingInfoRefresh: '\uF0E2',
    licensingInfoDeactivate: '\uF057',
    licensingInfoRemove: '\uF014',
    licensingInfoAdd: '\uF067',

    download: '\uF019',

    mcItemWithoutSound: '\uF1F7',
    mcItemWithSound: '\uF0A2',
    mcPresentationSlides: '\u221E',
    mcPresentationDuration: '\uF017',
    mcPlayModeBackward: '\uF053',
    mcPlayModeForward: '\uF054',

    helpView: {
        advice: '\uF2b7',
        manual: '\uF2b7',
        quickStart: '\u00C6',
        videoTutorials: '\u00C4',
        tutorReset: '\u00C7',
        dot: '\uF111',
        helpHandling: {
            navigation: {
                rotateTouch: '\u1FF6',
                rotateMouse: '\u1FFB',
                moveTouch: '\u1FF5',
                moveMouse: '\u1FFD',
                zoomTouch: '\u1FF4',
                zoomMouse: '\u1FFC',
                resetTouch: '\u1FF2',
                resetMouse: '\u00C3'
            },
            view: {
                contextTouch: '\u1FF8',
                contextMouse: '\u1FFE'
            },
            cut: {
                touchTouch: '\u1FF8',
                touchMouse: '\u1FFD',
                swipeTouch: '\u1FF6',
                swipeMouse: '\u1FFB',
                doubleClickTouch: '\u1FF7',
                doubleClickMouse: '\u1FFF',
                shiftTouch: '',
                shiftMouse: '\u1FFC'
            }
        }
    },

    zeissOpti: {
        targetSwiffel: '\uF021',
        targetArrow: '\uF116',
        pencil: '\uF21C',
        correction: '\uF21B',
        anatomyTarget: '\uF238',
        UVTarget: '\uF239',
        optiTarget: '\uF23A'
    },

    philipsIcarus: {
        anatomyTarget: '\uF238',
        philipsTarget: '\uF23A',
        resetCamera: '\uF07E',
    },

    tutorForward: '\uF138',
    tutorBackward: '\uF137',
    tutorClose: '\uF057'
};