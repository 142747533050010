import React from 'react';
import PropTypes from 'prop-types';
import Plyr from 'react-plyr';

import {connectAppEnv} from '../app-env';
import {delegateOpenState} from '../../lib/oe-higher-order-components';
import {frefToRef} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEButton, OEIcon} from '../oe-controls';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OEStdModalController from '../modals/oe-std-modal-controller';
import OENotification from '../../lib/oe-notification';
import {OEViewType} from '../../lib/oe-types';
import {OEManualViewLinks, OEManualViewLinkType} from '../../lib/oe-types';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {OEBlurLayer, OEBlurModalBackdropLayer} from '../oe-blur-layer';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEPDFView from './oe-pdf-view'

export class OEManualView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.options = this.props.options || { link: OEManualViewLinks.app };
        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_,
            options: this.options,
            strings: {
                title: '',
                download: 'Manual'
            }
        };

        this.onLanguageChanged = this.onLanguageChanged.bind(this);

        this.renderAdditionalButtons = this.renderAdditionalButtons.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.onDownloadBtnPressed = this.onDownloadBtnPressed.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    setOpen(open = true, options)   {
        if((open || typeof(this.props.isOpen) === 'boolean') && !OEToolbox.shallowEqual(this.options, options, true))    {
            this.options = options;
            this.onOptionsChanged();
        }
        if(typeof(this.props.isOpen) === 'boolean') return;
        let isOpenChanged = this.isOpen_ != open;
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_, options: this.options});
        if(isOpenChanged)  this.onOpenStateChanged(this, this.isOpen_);
    }

    isOpen()    {
        return this.isOpen_;
    }

    onOpenStateChanged(sender, isOpen)    {
        if(this.props.onOpenStateChanged)   this.props.onOpenStateChanged(sender, isOpen);

        if(!this.props.appComponent || !this.props.appComponent.uiLayer) return;
        this.props.appComponent.uiLayer.notificationCenter.post(OENotification.viewOpenStateChanged, {
            type: OEViewType.manual,
            isOpen: isOpen,
            sender: sender
        });
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.shallowEqual(nextProps.options, this.props.options, true))     {
            this.options = nextProps.options;
            this.onOptionsChanged(nextProps);
        } else if(!OEToolbox.shallowEqual(nextProps.config, this.props.config, true))     {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        setTimeout(this.onTimeout.bind(this), 500);        
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
    }

    onTimeout() {
        let config = this.props.config;

        if(config.showAtStartUp && this.oe.isReady() && localStorage.getItem('dontShowHelpViewAtStartUp') !== 'true')   {
            this.setOpen(true);
        }
    }

    onOptionsChanged(props)   {
        this.updateLanguage(props);
        this.setState({options: this.options});
    }

    onLanguageChanged() {
        this.updateLanguage();
    }

    updateLanguage(props)   {
        let si = this.oe.sharedInterface;
        props = props || this.props;
        let link = (this.options ? props.config.links[this.options.link] : undefined) || {type: OEManualViewLinkType.none};

        let ref = typeof(link.ref) === 'string' ? link.ref : '';
        let locRef = si.getLocalizedStringEnc(ref);
        ref = locRef !== '#null' ? locRef : ref;
        
        let stateUpdate = {strings: {
            title: si.getLocalizedStringEnc(this.options.title ? this.options.title : 'help_view_manual'),
            download: si.getLocalizedStringEnc('help_view_manual_download')
        }};

        if(link.type == OEManualViewLinkType.youtube)   {
            stateUpdate['youtubeRef'] = ref;
        } else if(link.type == OEManualViewLinkType.pdf)   {
            stateUpdate['pdfRef'] = ref;
        }

        this.setState(stateUpdate);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    renderAdditionalButtons(props)  {
        return (
            <React.Fragment>
                <OEButton
                    className="btn download-btn"
                    onPressed={this.onDownloadBtnPressed}>
                    <OEIcon code={OEIconCodes.download} />
                </OEButton>
            </React.Fragment>
        );
    }

    renderContent(link) {
        if(!link)   return null;
        let config = this.props.config;

        if(link.type === OEManualViewLinkType.youtube && this.state.youtubeRef)    {
            return (
                <Plyr
                    className="react-plyr"
                    type="youtube"
                    videoId={this.state.youtubeRef} 
                    controls={['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']}
                />);
        }

        if(link.type === OEManualViewLinkType.pdf && this.state.pdfRef)    {
            return <OEPDFView fileURL={config.pdfURL + this.state.pdfRef} page={Math.max(typeof(link.page) === 'number' ? link.page : 1, 1)}/>;
        }
        return null;
    }

    render() {
        let isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;
        let link = (this.state.options ? this.props.config.links[this.options.link] : undefined) || {type: OEManualViewLinkType.none};
        
        let title = this.props.title;
        if(this.state.options && (this.state.options.rawTitle || (this.state.strings.title !== '#null' && this.state.strings.title !== '')))    {
            title = this.state.options.rawTitle ? this.state.options.rawTitle : this.state.strings.title;
        }

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>

                <OEStdModalController
                    className="simple-header clear-backdrop flow-nav-modal help-modal center"
                    moduleId={this.props.moduleId}
                    title={title}
                    isOpen={isOpen}
                    hasCloseBtn={true}
                    additionalButtons={link.type === OEManualViewLinkType.pdf && this.state.pdfRef ? this.renderAdditionalButtons : null}
                    onToggle={this.onToggle}
                >
                    {this.renderContent(link)}

                    <OEBlurModalBackdropLayer/>
                    <OEBlurLayer apply={true} ruleName={'blur-manual-view-modal-backdrop'} selector={'body .blur-by-manual-view'}/>
                </OEStdModalController>
            </React.Fragment>
        );
    }

    onToggle() { 
        if(typeof(this.props.onToggle) === 'function')  {
            this.props.onToggle();
        } else {
            this.setOpen(false);
        }
    }

    onDownloadBtnPressed()  {
        let link = document.createElement('a');
        link.download = this.state.strings.download + '.pdf';
        link.href = this.state.pdfRef;
        link.click();
    }
}

OEManualView.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.manualView(),
    options: null
};

OEManualView.propTypes = {
    moduleId: PropTypes.string,
    title: PropTypes.string,
    config: PropTypes.shape({
        logo: PropTypes.string,
        showAtStartUp: PropTypes.bool,
        pdfURL: PropTypes.string.isRequired,
        links: PropTypes.object.isRequired
    }).isRequired,
    options: PropTypes.shape({
        link: PropTypes.string.isRequired
    })
};

export default delegateOpenState(connectAppEnv((env) => { return {
    appComponent: env.component,
    config: env.config.module.uiLayerConfig.manualViewConfig
}})(frefToRef(OEManualView)));