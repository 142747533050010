import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

import OEScrollbars from '../oe-scrollbars';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEResizeObserver from '../../lib/oe-resize-observer';
import OEThemeWaitingController from '../oe-theme-waiting-controller';

export default class OEPDFView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.renderModeSVG = false;
        this.explicitePageWidth = true;

        this.scrollTop = null;

        this.deferredScrollTo = typeof(this.props.page) === 'number' ? {page: this.props.page} : null;
        //this.deferredScrollTo = {page: 5};    // for testing deferred scrolling

        this.documentLoaded = false;
        
        this.numPages = 0;
        this.pages = {};
        this.numPagesRendered = 0;

        this.state = {
            numPages: this.numPages,
            pages: clone(this.pages),
            numPagesRendered: this.numPagesRendered
        };

        this.onScrollbarResize = this.onScrollbarResize.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onScrollStop = this.onScrollStop.bind(this);
        this.onScrollbarRef = this.onScrollbarRef.bind(this);

        this.onLoadSuccess = this.onLoadSuccess.bind(this);
        this.onPageLoadSuccess = this.onPageLoadSuccess.bind(this);
        this.onPageRenderSuccess = this.onPageRenderSuccess.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.fileURL != this.props.fileURL) {
            this.scrollTop = null;
            this.deferredScrollTo = null;
            this.documentLoaded = false;
            this.numPages = 0;
            this.pages = {};
            this.numPagesRendered = 0;
            this.setState({ numPages: this.numPages, page: clone(this.pages), numPagesRendered: this.numPagesRendered});
        }

        if(nextProps.page != this.props.page) {
            this.scrollTo(nextProps.page);
        }
    }

    getPageRef(page)    {
        if(!this.documentLoaded || !this.numPages || !this.scrollbarRef) return;
        let pageRef = $(this.scrollbarRef.container).find('[data-page-number="' + page.toString() + '"]');
        return pageRef.length ? pageRef[0] : undefined;
    }

    allPagesLoaded(toPage)    {
        if(!this.documentLoaded) return false;
        toPage = typeof(toPage) === 'number' ? Math.min(Math.max(toPage, 1), this.numPages) : this.numPages;
        for(let page = 1; page <= toPage; ++page)   {
            if(!this.pages['page' + page.toString()]) return false;
        }
        return true;
    }

    allPagesRendered(toPage)    {
        if(!this.documentLoaded) return false;
        toPage = typeof(toPage) === 'number' ? Math.min(Math.max(toPage, 1), this.numPages) : this.numPages;
        for(let page = 1; page <= toPage; ++page)   {
            let page_ = this.pages['page' + page.toString()];
            if(!page_ || !page_.rendered) return false;
        }
        return true;
    }

    allowScrolling(toPage)    {
        let afterAllPagesRenderer = this.renderModeSVG || this.explicitePageWidth;
        return afterAllPagesRenderer ? this.allPagesRendered(toPage) : this.allPagesLoaded(toPage);
    }

    scrollTo(page, completed)    {
        let node = this.getPageRef(page);
        if(!node || !this.allowScrolling(page))    {
            this.deferredScrollTo = {page: page, completed: completed};
            return;
        }

        this.scrollTop = null;
        this.deferredScrollTo = null;

        let element = node;
        let offsetTop = 0;
        while(element && element != this.scrollbarRef.container)    {
            offsetTop += element.offsetTop;
            element = element.parentElement;
        }

        //let itemCenter = offsetTop + 0.5 * node.offsetHeight;
        //let scrollViewCenter = 0.5 * this.scrollbarRef.getClientHeight();
        let scrollTop = offsetTop;//itemCenter - scrollViewCenter;
        this.scrollTop = scrollTop;
        this.scrollbarRef.scrollTop(scrollTop, completed);
    }

    tryDeferredScrollTo()   {
        if(!this.deferredScrollTo) return;
        let deferredScrollTo = clone(this.deferredScrollTo);
        this.scrollTo(deferredScrollTo.page, deferredScrollTo.completed);
    }

    updateDocument(pdf)    {
        this.documentLoaded = true;
        this.numPages = pdf.numPages;
        this.setState({ numPages: this.numPages });
    }

    updatePage(page, rendered)    {
        let number = page.pageNumber;
        let page_ = this.pages['page' + number.toString()];
        let needsStateUpdate = false;
        let pageSet = false;
        if(!page_)  {
            page_ = {size: {w: page.originalWidth, h: page.originalHeight}};
            this.pages['page' + number.toString()] = page_;
            needsStateUpdate = true;
            pageSet = true;
        }

        if(rendered && !page_.rendered) {
            page_.rendered = true;
            this.numPagesRendered++;
            needsStateUpdate = true;
        }

        if(needsStateUpdate)    this.setState({ pages: clone(this.pages), numPagesRendered: this.numPagesRendered});
        if(pageSet && number == 1) this.updateLayout();
    }

    updateLayout()  {
        let scrollbarSize = this.scrollbarSize;
        if(!scrollbarSize)  {
            if(!this.scrollbarRef)  return;
            scrollbarSize = {w: this.scrollbarRef.container.clientWidth, h: this.scrollbarRef.container.clientHeight};
        }
        let refPage = this.pages['page1'];
        let height = scrollbarSize.w * 1296 / 1920.0;
        if(refPage) height = scrollbarSize.w * refPage.size.h / refPage.size.w;
        height *= 1.05;
        this.setState({ width: scrollbarSize.w, height: height });
    }

    onScrollbarResize(sender, size) {
        if(OEToolbox.shallowEqual(size, this.scrollbarSize)) return;
        let scrollTopScale = this.scrollbarSize && this.allowScrolling() ? size.w / this.scrollbarSize.w : 1;
        this.scrollbarSize = size.w == 0 || size.h == 0 ? null : size;
        this.updateLayout();

        if(typeof(this.scrollTop) === 'number' && scrollTopScale != 1 && this.allowScrolling() && !this.deferredScrollTo)  {
            this.scrollTop *= scrollTopScale;
            if(this.scrollbarRef)   this.scrollbarRef.scrollTop(this.scrollTop);
        }
    }

    onScroll(event) {
        //console.log('onScroll');
        //if(this.scrollbarRef)   this.scrollTop = this.scrollbarRef.getScrollTop();
    }

    onScrollStop(event) {
        //console.log('onScrollStop');
        if(this.scrollbarRef)   this.scrollTop = this.scrollbarRef.getScrollTop();
    }

    onScrollbarRef(ref) {
        if(this.scrollbarRef === ref)   return;
        this.scrollbarRef = ref;
        if(this.scrollbarRef)   {
            this.updateLayout();
            this.tryDeferredScrollTo();
        }
    }

    render() {
        let pages = [];
        let pageProps = this.renderModeSVG ? {loading: null, width: this.state.width, renderMode: 'svg', onLoadSuccess: this.onPageLoadSuccess, onRenderSuccess: this.onPageRenderSuccess} :
                            {loading: null, width: this.explicitePageWidth ? this.state.width : undefined, scale: 1, renderMode: 'canvas', onLoadSuccess: this.onPageLoadSuccess, onRenderSuccess: this.onPageRenderSuccess};
        for(let i = 1; i <= this.state.numPages; ++i)    {
            pages.push(<Page key={i} className={i === this.state.numPages ? 'last' : ''} pageNumber={i} {...pageProps}/>)
        }

        let style;
        if(typeof(this.state.height) === 'number')  style = {height: this.state.height};

        return (
            <div className="pdf-view">
                
                <OEScrollbars ref={this.onScrollbarRef} onScroll={this.onScroll} onScrollStop={this.onScrollStop} style={style}>
                    <div className="scroll-content">
                        <OEResizeObserver onResize={this.onScrollbarResize} />
                        <Document
                            file={this.props.fileURL}
                            loading={null}
                            onLoadSuccess={this.onLoadSuccess}
                        >
                            {pages}
                        </Document>
                    </div>
                </OEScrollbars>

                <OEThemeWaitingController show={this.state.numPagesRendered < this.state.numPages} progress={this.state.numPagesRendered / this.state.numPages} />
            </div>
            
        );
    }

    onLoadSuccess(pdf)  {
        this.updateDocument(pdf);
    }

    onPageLoadSuccess(page)    {
        this.updatePage(page);
        this.tryDeferredScrollTo(); // element of page already in DOM and appropriate sized
    }

    onPageRenderSuccess(page)   {
        this.updatePage(page, true);
        this.tryDeferredScrollTo(); // element of page already in DOM and appropriate sized
    }
}

OEPDFView.defaultProps = {
    fileURL: ''
};

OEPDFView.propTypes = {
    fileURL: PropTypes.string,
    page: PropTypes.number
};