import React from 'react';  
import PropTypes from 'prop-types';

import {connectAppEnv} from './app-env';
import {connectModuleEnv} from './oe-module-env';
import {frefToRef} from '../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OEButton, OEColorBtn, OEIcon} from './oe-controls';
import {Form} from 'reactstrap';
import {OEIconCodes} from '../lib/oe-icon-codes';
import OEPopover from './oe-popover';
import {OEColorHelper} from './oe-color-helper'
import {OEToolbox} from '../lib/oe-toolbox';
import {OEPopoverMenuController} from './oe-popover-menu-controller';
import {OEPresetType, OEPresetPopoverController} from './oe-preset-controller';
import {retardUpdate} from '../lib/update-retarder';

export class OENoteControllerPopover extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.id = null;
        this.isOpen = false;
        this.rect = null;

        this.defaultState = {
            id: null,
            isOpen: this.isOpen,
            rect: null,
            style: {
                hAlignment: null,
                vAlignment: null,
                textColor: {x: 0, y: 0, z: 0, w: 1},
                backgroundColor: {x: 1, y: 1, z: 1, w: 1},
                lineColor: {x: 0, y: 0, z: 0, w: 1},
                hasBorder: true,
                fontSize: 16,
                showAlways: false,
            },
            noteText: '',
            isInDocument: false,
            defaultTextColor: {x: 0, y: 0, z: 0, w: 1},
            defaultBackgroundColor: {x: 1, y: 1, z: 1, w: 1},
            defaultLineColor: {x: 0, y: 0, z: 0, w: 1},
            showHAlignment: false,
            showVAlignment: false,
            showPresetController: false
        };

        this.state = this.defaultState;

        this.onNoteLongTouched = this.onNoteLongTouched.bind(this);
        this.updateIsOpenState = this.updateIsOpenState.bind(this);
        this.onNoteStyleChanged = this.onNoteStyleChanged.bind(this);
        this.onNoteTextChanged = this.onNoteTextChanged.bind(this);
        this.onIsInDocumentChanged = this.onIsInDocumentChanged.bind(this);
        this.onNoteRectChanged = this.onNoteRectChanged.bind(this);
        this.onNoteRemoved = this.onNoteRemoved.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onPresetControllerRef = this.onPresetControllerRef.bind(this);

        this.renderAdditionalButtons = this.renderAdditionalButtons.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.onApplyToAllNotesBtnPressed = this.onApplyToAllNotesBtnPressed.bind(this);
        this.onAlwaysVisibleBtnPressed = this.onAlwaysVisibleBtnPressed.bind(this);
        this.onDumpBtnPressed = this.onDumpBtnPressed.bind(this);
        this.onFontSizeDecBtnPressed = this.onFontSizeDecBtnPressed.bind(this);
        this.onFontSizeIncBtnPressed = this.onFontSizeIncBtnPressed.bind(this);
        this.onTextColorBtnPressed = this.onTextColorBtnPressed.bind(this);
        this.onBackgroundColorBtnPressed = this.onBackgroundColorBtnPressed.bind(this);
        this.onLineColorBtnPressed = this.onLineColorBtnPressed.bind(this);
        this.onBorderShownBtnPressed = this.onBorderShownBtnPressed.bind(this);
        this.onNoteChanged = this.onNoteChanged.bind(this);

        this.onHAlignmentPressed = this.onHAlignmentPressed.bind(this);
        this.onVAlignmentPressed = this.onVAlignmentPressed.bind(this);
        this.closeHAlignmentMenu = this.closeHAlignmentMenu.bind(this);
        this.closeVAlignmentMenu = this.closeVAlignmentMenu.bind(this);
        this.changeHAlignment = this.changeHAlignment.bind(this);
        this.changeVAlignment = this.changeVAlignment.bind(this);

        this.hAlignment = [
            {id: 0, icon: OEIconCodes.noteHAlignmentLeft}, 
            {id: 1, icon: OEIconCodes.noteHAlignmentCenter},
            {id: 2, icon: OEIconCodes.noteHAlignmentRight}
        ];

        this.vAlignment = [
            {id: 0, icon: OEIconCodes.noteVAlignmentTop},
            {id: 1, icon: OEIconCodes.noteVAlignmentCenter},
            {id: 2, icon: OEIconCodes.noteVAlignmentBottom}
        ];

        this.onPresetBtnPressed = this.onPresetBtnPressed.bind(this);
        this.onTogglePresetController = this.onTogglePresetController.bind(this);

        this.onIsInDocumentBtnPressed = this.onIsInDocumentBtnPressed.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteLongTouched, this.onNoteLongTouched);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.notesStateChanged, this.updateIsOpenState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteEditModeChanged, this.updateIsOpenState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteStyleChanged, this.onNoteStyleChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteTextChanged, this.onNoteTextChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteIsInDocumentChanged, this.onIsInDocumentChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteRectChanged, this.onNoteRectChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteRemoved, this.onNoteRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteLongTouched, this.onNoteLongTouched);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.notesStateChanged, this.updateIsOpenState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteEditModeChanged, this.updateIsOpenState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteStyleChanged, this.onNoteStyleChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteTextChanged, this.onNoteTextChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteIsInDocumentChanged, this.onIsInDocumentChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteRectChanged, this.onNoteRectChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteRemoved, this.onNoteRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    componentDidUpdate(prevProps, prevState)    {
        if(this.presetControllerRef && !OEToolbox.shallowEqual(this.state.rect, prevState.rect))    {
            this.presetControllerRef.forceUpdate(); // needed for positioning update
        }
    }

    onOpenStateChanged()	{
        if(!this.oe.isReady()) return;
        let controller = this.oe.sharedInterface.getUIControllerModelState();
        controller.setUpdateEnabled(!this.isOpen);

        let userInfo = new this.oe.Module.Dictionary();
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceNotePopoverOpenStateChanged, userInfo.setBoolValue('open', this.isOpen));
        userInfo.delete();
    }

    close()	{
        if(!this.isOpen) return;
        this.isOpen = false;
        this.setState({isOpen: false});
        this.onOpenStateChanged();
    }

    open(rect)	{
        if(!this.canOpen()) return;

        if(this.isOpen)	{
            if(!rect || OEToolbox.shallowEqual(rect, this.rect)) return;
            this.rect = rect;
            this.setState({rect: rect});
            return;
        }

        this.isOpen = true;
        if(rect)	{
            this.rect = rect;
            this.setState({isOpen: true, rect: this.rect});
        } else {
            this.setState({isOpen: true});
        }
        
        this.onOpenStateChanged();
    }

    canOpen()	{
        if(!this.oe.isReady()) return false;
        let controller = this.oe.sharedInterface.getUIControllerNote();
        return controller.getUIEnabled() && controller.getEnabled() && controller.isEditingEnabled();
    }

    setRect(rect){
        if(OEToolbox.shallowEqual(rect, this.rect)) return;
        this.rect = rect;
        this.setState({rect: this.rect});
    }

    updateId(newId) {
        this.id = newId;
        this.setState({id: newId});
    }

    onNoteLongTouched(message, userInfo) {
        if(!this.canOpen()) return;

        this.updateId(userInfo.ID);

        let rect = {x: userInfo.rect.x, y: userInfo.rect.y, w: userInfo.rect.z, h: userInfo.rect.w};
        let devicePR = 1 / this.oe.Module.devicePixelRatio();
        rect = {x: rect.x * devicePR, y: rect.y * devicePR, w: rect.w * devicePR, h: rect.h * devicePR};

        this.open(rect);

        this.updateNoteStyle(null, true);
        this.updateNoteText();
        this.updateIsInDocument();
    }

    updateIsOpenState()   {
        if(!this.canOpen())	{
            this.close();
        }
    }

    onNoteStyleChanged(message, userInfo)   {
        if(userInfo.ID !== this.id)  return;
        this.updateNoteStyle(userInfo.style);
    }
    
    onNoteTextChanged(message, userInfo)   {
        if(userInfo.ID !== this.id)  return;
        this.updateNoteText(userInfo.text);
    }

    onIsInDocumentChanged(message, userInfo) {
        if(userInfo.ID !== this.id)  return;
        this.updateIsInDocument(userInfo.isInDocument);
    }

    onNoteRectChanged() {
        if(!this.oe.isReady() || this.id === null) return;	// it is not save to call UIControllerNote methods with null for id

        let noteRect = this.oe.sharedInterface.getUIControllerNote().getNoteRect(this.id);
        let rect = {x: noteRect.x, y: noteRect.y, w: noteRect.z, h: noteRect.w};
        let devicePR = 1 / this.oe.Module.devicePixelRatio();
        rect = {x: rect.x * devicePR, y: rect.y * devicePR, w: rect.w * devicePR, h: rect.h * devicePR};

        this.setRect(rect);
    }

    onNoteRemoved() {
        if(this.id === null) return;
        this.close();
    }

    updateNoteStyle(style, defColor)  {
        if((!this.oe.isReady() || this.id === null) && !style)    return;

        let style_ = style || this.oe.sharedInterface.getUIControllerNote().getNoteStyle(this.id);

        this.setState({
            style: {
                hAlignment: style_.hAlignment,
                vAlignment: style_.vAlignment,
                textColor: style_.textColor,
                backgroundColor: style_.backgroundColor,
                lineColor: style_.lineColor,
                hasBorder: style_.hasBorder,
                fontSize: style_.fontSize,
                showAlways: style_.showAlways
            }
        });
        
        if(defColor)    {
            this.setState({
                defaultTextColor: style_.textColor,
                defaultLineColor: style_.lineColor,
                defaultBackgroundColor: style_.backgroundColor
            });
        }
    }
    
    updateNoteText(text)  {
        if((!this.oe.isReady() || this.id === null) && !text)    return;
        let text_ = text || this.oe.sharedInterface.getUIControllerNote().getNoteText(this.id);
        this.setState({noteText: OEToolbox.decode_utf8(text_)});
    }

    updateIsInDocument(isInDocument)    {
        if((!this.oe.isReady() || this.id === null) && typeof(isInDocument) !== 'boolean')    return;
        this.setState({isInDocument: typeof(isInDocument) === 'boolean' ? isInDocument : this.oe.sharedInterface.getUIControllerNote().getNoteIsInDocument(this.id)});
    }

    updateState(released)   {
        retardUpdate(this, () => {
            if(!this.oe.isReady() || released === true)   {
                this.id = null;
                this.rect = null;
                this.setState(this.defaultState);
                this.close();
                return;
            }

            this.updateIsOpenState();
            this.updateNoteStyle(null, true);
            this.updateNoteText();
            this.updateIsInDocument();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.note) {
            this.updateIsOpenState();
        }
    }
    
    getHAlignmentIcon() {
        let value = this.state.style.hAlignment !== null ? this.state.style.hAlignment.value : 0;
        return this.hAlignment[value].icon;
    }

    getVAlignmentIcon() {
        let value = this.state.style.vAlignment !== null ? this.state.style.vAlignment.value : 0;
        return this.vAlignment[value].icon;
    }

    onPresetControllerRef(ref)  {
        this.presetControllerRef = ref;
    }

    renderAdditionalButtons(props)  {
        return (
            <React.Fragment>
                <OEButton
                    className={props.buttonClassName + ' apply-to-all-btn'}
                    onPressed={this.onApplyToAllNotesBtnPressed}>
                    <OEIcon className="rotate-180" code={OEIconCodes.noteApplyToAll} />
                </OEButton>
                <OEButton
                    className={props.buttonClassName + ' always-visible-btn'}
                    onPressed={this.onAlwaysVisibleBtnPressed}
                >
                    <OEIcon code={props.showAlways ? OEIconCodes.noteShowAlways : OEIconCodes.noteNotShowAlways}/>
                </OEButton>
                <OEButton
                    className={props.buttonClassName + ' dump-btn'}
                    onPressed={this.onDumpBtnPressed}>
                    <OEIcon code={OEIconCodes.noteDump}/>
                </OEButton>
            </React.Fragment>
        );
    }

    render() {
        const hAlignment = (
            <OEPopoverMenuController 
                moduleId={this.props.moduleId}
                target="note-hAlignment-btn"
                isOpen={this.state.showHAlignment}
                onToggle={this.closeHAlignmentMenu}
                entries={this.hAlignment}
                onChange={this.changeHAlignment}
            />
        );

        const vAlignment = (
            <OEPopoverMenuController 
                moduleId={this.props.moduleId}
                target="note-vAlignment-btn"
                isOpen={this.state.showVAlignment}
                onToggle={this.closeVAlignmentMenu}
                entries={this.vAlignment}
                onChange={this.changeVAlignment}
            />
        );

        const presetController = (
            <OEPresetPopoverController
                ref={this.onPresetControllerRef}
                moduleId={this.props.moduleId}
                appComponent={this.props.appComponent}
                target="note-preset-btn"
                isOpen={this.state.showPresetController}
                onToggle={this.onTogglePresetController}
                presetType={OEPresetType.note}
                refId={this.state.id}
            />
        );
        
        const rect = !this.state.rect ? null : {x: this.state.rect.x + this.props.offset.x, y: this.state.rect.y + this.props.offset.y, w: this.state.rect.w, h: this.state.rect.h};

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover
                    className="popover-control"
                    boundariesElement={this.props.boundariesElement}
                    rect={rect}
                    placement="bottom"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    isOpen={this.state.isOpen && this.state.rect !== null}
                    additionalButtons={this.renderAdditionalButtons}
                    backdrop={true}
                    onToggle={this.onToggle}
                    showAlways={this.state.style.showAlways}
                >		
        
                    <div className="note-popover">
                    
                        <Form>
                            <textarea
                                type="text"
                                style={{fontSize: this.state.style.fontSize}}
                                value={this.state.noteText}
                                onChange={this.onNoteChanged}
                            />	
                        </Form>
                        
                        <OEButton
                            className="transparent-btn btns-font-dec"
                            onPressed={this.onFontSizeDecBtnPressed}
                        >
                        <OEIcon code={OEIconCodes.noteDecFont}/>
                        </OEButton>

                        <OEButton
                            className="transparent-btn"
                            onPressed={this.onFontSizeIncBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.noteIncFont}/>
                        </OEButton>

                        <OEColorBtn
                            className="transparent-btn"
                            color={this.state.style.textColor}
                            defaultColor={this.state.style.textColor}
                            showAlpha = {true}
                            onChange={this.onTextColorBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.noteTextColor}/>

                            <div style={{color: OEColorHelper.vColorToStr(this.state.style.textColor)}} className="color-bar">
                                <OEIcon code={OEIconCodes.noteShowColor}/>
                            </div>
                        </OEColorBtn> 

                        <OEColorBtn
                            className="transparent-btn"
                            color={this.state.style.lineColor}
                            defaultColor={this.state.style.lineColor}
                            showAlpha={true}
                            onChange={this.onLineColorBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.noteLineColor}/>

                            <div style={{color: OEColorHelper.vColorToStr(this.state.style.lineColor)}} className="color-bar">
                                <OEIcon code={OEIconCodes.noteShowColor}/>
                            </div>

                        </OEColorBtn>

                        <OEColorBtn
                            className="transparent-btn"
                            color = {this.state.style.backgroundColor}
                            defaultColor={this.state.style.backgroundColor}
                            showAlpha={true}
                            onChange={this.onBackgroundColorBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.noteBackgroundColor}/>

                            <div style={{color: OEColorHelper.vColorToStr(this.state.style.backgroundColor)}} className="color-bar">
                                <OEIcon code={OEIconCodes.noteShowColor}/>
                            </div>

                        </OEColorBtn>

                        <OEButton
                            className="transparent-btn"
                            onPressed={this.onBorderShownBtnPressed}
                        >
                            <OEIcon code={this.state.style.hasBorder ? OEIconCodes.noteBorder : OEIconCodes.noteNoBorder}/>
                        </OEButton>

                        <OEButton
                            id="note-hAlignment-btn"
                            className="transparent-btn hAlignment"
                            onPressed={this.onHAlignmentPressed}
                        >
                            <OEIcon code={this.getHAlignmentIcon()}/>
                        </OEButton>

                        <OEButton
                            id="note-vAlignment-btn"
                            className="transparent-btn vAlignment"
                            onPressed={this.onVAlignmentPressed}
                        >
                            <OEIcon code={this.getVAlignmentIcon()}/>
                        </OEButton>

                        <OEButton
                            id="note-preset-btn"
                            className="transparent-btn preset"
                            onPressed={this.onPresetBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.preset}/>
                        </OEButton>

                        {!this.props.devFeatures ? null :
                            <OEButton
                                className="transparent-btn is-in-document"
                                onPressed={this.onIsInDocumentBtnPressed}
                            >
                                <OEIcon code={this.state.isInDocument ? OEIconCodes.noteIsInDocument : OEIconCodes.noteIsNotInDocument}/>
                            </OEButton>
                        }

                    </div>
                    
                    {hAlignment}
                    {vAlignment}
                    {presetController}

                </OEPopover>
            </React.Fragment>
        );
    }
    
    onToggle() {
        this.close();
    }

    onApplyToAllNotesBtnPressed()	{
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().applyNoteStyleToAll(this.id);
    }
    
    onAlwaysVisibleBtnPressed()	{
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteShowAlways(this.id, !this.state.style.showAlways);
    }

    onDumpBtnPressed()	{
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().removeNote(this.id);
    }

    onFontSizeDecBtnPressed()	{
        if(this.id === null) return;
        var noteController = this.oe.sharedInterface.getUIControllerNote();
        if(noteController.getNoteFontSize(this.id) > 5){
            noteController.incNoteFontSize(this.id, -1);
        }
    }

    onFontSizeIncBtnPressed()	{
        if(this.id === null) return;
        var noteController = this.oe.sharedInterface.getUIControllerNote();
        if(noteController.getNoteFontSize(this.id) < 32){
            noteController.incNoteFontSize(this.id, 1);
        }
    }

    onTextColorBtnPressed(color)	{
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteTextColor(this.id, color);
    }

    onBackgroundColorBtnPressed(color)	{
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteBackgroundColor(this.id, color);
    }

    onLineColorBtnPressed(color)	{
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteLineColor(this.id, color);
    }

    onBorderShownBtnPressed(){
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteHasBorder(this.id, !this.state.style.hasBorder);
    }
    
    onNoteChanged(evt)	{
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteText(this.id, OEToolbox.encode_utf8(evt.target.value));
    }
    
    onHAlignmentPressed() {
        this.setState({ showHAlignment: true });
    }

    onVAlignmentPressed() {
        this.setState({ showVAlignment: true });
    }

    closeHAlignmentMenu() {
        this.setState({ showHAlignment: false });
    }

    closeVAlignmentMenu() {
        this.setState({ showVAlignment: false });
    }

    changeHAlignment(id) {
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteHAlignment(this.id, {value: id});
        this.closeHAlignmentMenu();
    }

    changeVAlignment(id) {
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteVAlignment(this.id, {value: id});
        this.closeVAlignmentMenu();
    }

    onPresetBtnPressed() {
        this.setState({ showPresetController: true });
    }

    onTogglePresetController() {
        this.setState({ showPresetController: false });
    }

    onIsInDocumentBtnPressed()  {
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteIsInDocument(this.id, !this.state.isInDocument);
    }
}

OENoteControllerPopover.defaultProps = {
    moduleId: '',
    devFeatures: false
};

OENoteControllerPopover.propTypes = {
    moduleId: PropTypes.string,
    devFeatures: PropTypes.bool
};

export default connectAppEnv((env) => { 
    return {
        appComponent: env.component,
        devFeatures: env.config.module.flags && env.config.module.flags.includes('import')
    };
})(connectModuleEnv((env) => {
    return {
        offset: {x: 0, y: -env.ui.capBar.size.h}
    };
})(frefToRef(OENoteControllerPopover)));