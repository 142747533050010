import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from './app-env';
import {withUILevel} from '../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {UIControllerType, OEManualViewLinks} from '../lib/oe-types';
import OEPopover from './oe-popover';
import {OEGroupControl, OEToggleControl} from './oe-controls';
import {OEDefaultConfigFactory} from './oe-default-configs';
import {retardUpdate} from '../lib/update-retarder';

export class OENoteController extends React.PureComponent {

    constructor(props){
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            enabled: false,
            isEditingEnabled: false,
            isEditingAddRemoveEnabled: false,
            showDocument: false,
            strings:{
                title: '',
                enableNote: '',
                editLabel: '',
                addRemoveLabel: '',
                showDocumentLabel: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateControlsState = this.updateControlsState.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onNoteSwitchChanged = this.onNoteSwitchChanged.bind(this);
        this.onEditSwitchChanged = this.onEditSwitchChanged.bind(this);
        this.onEditAddRemoveSwitchChanged = this.onEditAddRemoveSwitchChanged.bind(this);
        this.onShowDocumentSwitchChanged = this.onShowDocumentSwitchChanged.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.notesStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteEditModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteShowDocumentChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.notesStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteEditModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteShowDocumentChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        this.setState({strings: {
            title: this.oe.sharedInterface.getLocalizedStringEnc('note_view'),
            enableNote: this.oe.sharedInterface.getLocalizedStringEnc('note_view_enable'),
            editLabel: this.oe.sharedInterface.getLocalizedStringEnc('note_view_edit'),
            addRemoveLabel: this.oe.sharedInterface.getLocalizedStringEnc('note_view_add_remove'),
            showDocumentLabel: this.oe.sharedInterface.getLocalizedStringEnc('note_view_show_document')
        }});
    }

    updateControlsState()   {
        let note = this.oe.sharedInterface.getUIControllerNote();
        this.setState({
            enabled: note.getEnabled(),
            isEditingEnabled: note.isEditingEnabled(),
            isEditingAddRemoveEnabled: note.isEditingAddRemoveEnabled(),
            showDocument: note.getShowDocument()
        });
    }

    updateUIState()   {
        this.setState({
          uiEnabled: this.oe.sharedInterface.getUIControllerNote().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false });
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateControlsState();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.note) {
            this.updateUIState();
        }
    }

    render()    {
        if(!this.props.config.enabled) return null;
        let editSwitchEnabled = this.state.uiEnabled && this.state.enabled;
        let editAddRemoveSwitchEnabled = this.state.isEditingEnabled && editSwitchEnabled;
        let showDocumentSwitchEnabled = this.state.enabled && editSwitchEnabled;

        return(
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover
                    className="popover-control"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.note}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    title={this.state.strings.title}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >
                    <div className="note-controller">
                        <OEGroupControl>
                            <OEToggleControl
                                title={this.state.strings.enableNote}
                                active={this.state.enabled}
                                disabled={!this.state.uiEnabled}
                                onClick={this.onNoteSwitchChanged}
                            />
                            <OEToggleControl
                                title={this.state.strings.editLabel}
                                active={this.state.isEditingEnabled}
                                disabled={!editSwitchEnabled}
                                onClick={this.onEditSwitchChanged}
                            />
                            <OEToggleControl
                                title={this.state.strings.addRemoveLabel}
                                active={this.state.isEditingAddRemoveEnabled}
                                disabled={!editAddRemoveSwitchEnabled}
                                onClick={this.onEditAddRemoveSwitchChanged}
                            />
                            {!this.props.devFeatures ? null :
                                <OEToggleControl
                                    title={this.state.strings.showDocumentLabel}
                                    active={this.state.showDocument}
                                    disabled={!showDocumentSwitchEnabled}
                                    onClick={this.onShowDocumentSwitchChanged}
                                />
                            }
                        </OEGroupControl>            
                    </div>
                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.note});
    }

    onNoteSwitchChanged() {
        this.oe.sharedInterface.getUIControllerNote().setEnabled(!this.state.enabled, true, 0.333);
    }

    onEditSwitchChanged() {
        this.oe.sharedInterface.getUIControllerNote().enableEditing(!this.state.isEditingEnabled);
    }

    onEditAddRemoveSwitchChanged() {
        if(this.oe.sharedInterface.getUIControllerNote().isEditingEnabled()) {
            this.oe.sharedInterface.getUIControllerNote().enableEditingAddRemove(!this.state.isEditingAddRemoveEnabled);
        }
    }

    onShowDocumentSwitchChanged() {
        this.oe.sharedInterface.getUIControllerNote().setShowDocument(!this.state.showDocument);
    }
}

OENoteController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.noteController(),
    devFeatures: false
};

OENoteController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool
    }).isRequired,
    devFeatures: PropTypes.bool
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.noteController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.note),
        devFeatures: env.config.module.flags && env.config.module.flags.includes('import')
    }
})(withUILevel(OENoteController));