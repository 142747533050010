import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from './app-env';
import {connectModuleEnv} from './oe-module-env';
import {frefToRef} from '../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OEGroupControl, OEColorBtn, OEButton, OEIcon, OESliderControl} from './oe-controls';
import {OEIconCodes} from '../lib/oe-icon-codes';
import OEPopover from './oe-popover';
import {OEToolbox} from '../lib/oe-toolbox';
import {retardUpdate} from '../lib/update-retarder';

export class OEArrowPopoverController extends React.PureComponent {

    constructor(props){
        super(props);

		this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.id = null;
        this.isOpen = false;
        this.position = null;

        this.defaultState = {
            isOpen: this.isOpen,
			position: this.position,
            defaultArrowColor: {x: 0, y: 0, z: 0},
            tipStyle: null,
            endStyle: null,
            arrowLength: 1,
            arrowWidth: 1,
            arrowColor: {x: 0, y: 0, z: 0}
        };

        this.state = this.defaultState;
        
        // since there is no reason to include constant values in this.state we just define them in class scope
        this.minWidthBound = 0.15;
        this.maxWidthBound = 0.75*3.5;
        this.minLengthBound = 0.3;
        this.maxLengthBound = 3.5;

        this.tipStyleImages = [
            "../images/components/arrow/arrowTip_1x.png",
            "../images/components/arrow/arrowTail_1x.png",
            "../images/components/arrow/arrowRound_1x.png",
            "../images/components/arrow/arrowFlat_1x.png"
        ];

        this.endStyleImages = [
            "../images/components/arrow/arrowTip_1x2.png",
            "../images/components/arrow/arrowTail_1x2.png",
            "../images/components/arrow/arrowRound_1x2.png",
            "../images/components/arrow/arrowFlat_1x2.png"
        ];
    
		this.onWindowResized = this.onWindowResized.bind(this);
		
		this.onArrowLongTouched = this.onArrowLongTouched.bind(this);
		this.updateIsOpenState = this.updateIsOpenState.bind(this);
		this.onArrowChanged = this.onArrowChanged.bind(this);
		this.updateArrowState = this.updateArrowState.bind(this);
        this.onArrowRemoved = this.onArrowRemoved.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        // definition of callbacks used in the render method 
        /* Since inline definitions for function properties, e.g., definitions of the kind property={() => this.callback()}, 
           are providing a new function object to the child component every time the render method is called, 
           which leads to unnecessary updates of the child component,
           we consider such inline definitions as bad practise and define the callbacks in class scope.
           Note that this is especially critical for callbacks for the ref property, because every time a new function is set to ref react calls the function immediately, even if the ref is not changed!
           This can even cause feedback loops if obtaining a reference leads to state updates!!
        */   
        this.renderAdditionalButtons = this.renderAdditionalButtons.bind(this);

		this.onApplyToAllArrowsBtnPressed = this.onApplyToAllArrowsBtnPressed.bind(this);
		this.onDumpBtnPressed = this.onDumpBtnPressed.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onTipChangeBtnPressed = this.onTipChangeBtnPressed.bind(this);
        this.onEndChangeBtnPressed = this.onEndChangeBtnPressed.bind(this);
        this.onArrowColorButtonChanged = this.onArrowColorButtonChanged.bind(this);
        this.onArrowLengthChanged = this.onArrowLengthChanged.bind(this);
        this.onArrowWidthChanged = this.onArrowWidthChanged.bind(this);
    }
    
    componentDidMount()    {
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onWindowResized);
    }

    onConnect()  {
		this.oe.sharedNotificationCenter.register(this.oe.NotificationName.arrowLongTouched, this.onArrowLongTouched);
		this.oe.sharedNotificationCenter.register(this.oe.NotificationName.arrowsStateChanged, this.updateIsOpenState);
		this.oe.sharedNotificationCenter.register(this.oe.NotificationName.arrowEditModeChanged, this.updateIsOpenState);
		this.oe.sharedNotificationCenter.register(this.oe.NotificationName.arrowChanged, this.onArrowChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.arrowRemoved, this.onArrowRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
		this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.arrowLongTouched, this.onArrowLongTouched);
		this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.arrowsStateChanged, this.updateIsOpenState);
		this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.arrowEditModeChanged, this.updateIsOpenState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.arrowChanged, this.onArrowChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.arrowRemoved, this.onArrowRemoved)	;
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);	
	}

    onOpenStateChanged()	{
		if(!this.oe.isReady()) return;
		let controller = this.oe.sharedInterface.getUIControllerModelState();
        controller.setUpdateEnabled(!this.isOpen);
        
        let userInfo = new this.oe.Module.Dictionary();
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceArrowPopoverOpenStateChanged, userInfo.setBoolValue('open', this.isOpen));
        userInfo.delete();
    }

	close()	{
		if(!this.isOpen) return;
		this.isOpen = false;
		this.setState({isOpen: false});
		this.onOpenStateChanged();
	}

	open(pos)	{
        if(!this.canOpen()) return;

		if(this.isOpen)	{
			if(!pos || OEToolbox.shallowEqual(pos, this.position)) return;
			this.position = pos;
		    this.setState({position: this.position});
			return;
		}

		this.isOpen = true;
		if(pos)	{
			this.position = pos;
			this.setState({isOpen: true, position: this.position});
		} else {
			this.setState({isOpen: true});
		}
		
		this.onOpenStateChanged();
    }
    
    canOpen()	{
		if(!this.oe.isReady()) return false;
		var controller = this.oe.sharedInterface.getUIControllerArrow();
		return controller.getUIEnabled() && controller.getEnabled() && controller.isEditingEnabled();
	}

	getPosition()   {
        return this.position;
	}

	setPosition(pos)    {
        if(OEToolbox.shallowEqual(pos, this.position)) return;
        this.position = pos;
		this.setState({position: this.position});
    }
    
    updatePos() {
        if(!this.oe.isReady() || this.id === null) return;    // it is not save to call UIControllerArrow methods with null for id

        let pos = this.oe.sharedInterface.getUIControllerArrow().getArrowScreenCenter(this.id);

        let devicePR = 1 / this.oe.Module.devicePixelRatio();
        pos = {x: pos.x * devicePR, y: pos.y * devicePR};

        this.setPosition(pos);
    }
	
	onWindowResized(){
        this.updatePos();

        // the arrow is not correct repositioned, since the core module viewport has not been resized when the window sends the resize event
        // quick workaround -> update the position at a later time when the viewport has been updated 
        window.requestAnimationFrame(function() {
            window.requestAnimationFrame(this.updatePos.bind(this));
        }.bind(this));
	}
	
	onArrowLongTouched(message, userInfo){
		if(!this.canOpen()) return;

		this.id = userInfo.ID;
        let pos = {x: userInfo.screenPos.x, y: userInfo.screenPos.y};
        let devicePR = 1 / this.oe.Module.devicePixelRatio();
        pos = {x: pos.x * devicePR, y: pos.y * devicePR};

        this.open(pos);

		this.updateArrowState(null, true);
	}
    
    onArrowChanged(message, userInfo)   {
        // gets called when arrow state is changed
        if(userInfo.ID !== this.id)  return;    // ignore notifications for other arrows
        this.updateArrowState(userInfo.data);
    }

    updateArrowState(data, defColor)  {
        if((!this.oe.isReady() || this.id === null) && !data)    {
            return;
        }

        // if data { data_ = data } else { data_ = this.oe.sharedInterface.getUIControllerArrow().getArrowData(this.id) }
        // furthermore note that this code pattern does not work for numbers and bools, otherwise it is very practical for optional parameter, here data is optional
        var data_ = data || this.oe.sharedInterface.getUIControllerArrow().getArrowData(this.id);

        this.setState({
            tipStyle: data_.tipStyle,
            endStyle: data_.endStyle,
            arrowLength: data_.length,
            arrowWidth: data_.width,
            arrowColor: data_.color,
		});

        if(defColor)    {
            this.setState({
                defaultArrowColor: data_.color,
            });
		}
    }

	updateIsOpenState(){
		if(!this.canOpen()) {
			this.close();
		}
	}

    updateState_(released)   {
        if(!this.oe.isReady() || released === true)   {
            // here we have lost the core interface, i.e., this.oe is released 
            // we set the component in a save state which should also reflect that user input is not possible 
			this.id = null;
			this.position = null;
            this.setState(this.defaultState);
            this.close();
            return;
        }
        
		this.updateIsOpenState();
        this.updateArrowState(null, true);
    }

    updateState(released)   {
        retardUpdate(this, () => {
            this.updateState_(released)
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.arrow) {
            this.updateIsOpenState();
        }
    }

    renderAdditionalButtons(props)  {
        return (
            <React.Fragment>
                <OEButton 
                    className={props.buttonClassName + ' apply-to-all-btn'}
                    onPressed={this.onApplyToAllArrowsBtnPressed}
                >
                    <OEIcon className="rotate-180" code={OEIconCodes.arrowApplyToAll}/>
                </OEButton>
                <OEButton 
                    className={props.buttonClassName + ' dump-btn'} 
                    onPressed={this.onDumpBtnPressed}
                >
                    <OEIcon code={OEIconCodes.arrowDump}/>
                </OEButton>
            </React.Fragment>
        );
    }
    
    getTipStyleImage()  {
        var value = this.state.tipStyle !== null ? this.state.tipStyle.value : 0;
        return this.tipStyleImages[value % 4];
    }

    getEndStyleImage()  {
        var value = this.state.endStyle !== null ? this.state.endStyle.value : 2;
        return this.endStyleImages[value % 4];
    }

    render(){
        const position = !this.state.position ? null : {x: this.state.position.x + this.props.offset.x, y: this.state.position.y + this.props.offset.y};

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover
                    className="popover-control"
                    boundariesElement={this.props.boundariesElement}
                    position={position}
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    isOpen={this.state.isOpen && this.state.position !== null}
                    additionalButtons={this.renderAdditionalButtons}
                    backdrop={true}
                    onToggle={this.onToggle}
                >

                    <div className="arrow-popover">
                        <div className="arrow-tool-padding">
                            <OEColorBtn
                                color={this.state.arrowColor} 
                                defaultColor={this.state.defaultArrowColor} 
                                onChange={this.onArrowColorButtonChanged}
                            />
                            <OEButton
                                className="arrow-tool-tip"
                                onPressed={this.onTipChangeBtnPressed}
                            >
                                <img src={this.getTipStyleImage()}/>
                            </OEButton>
                            <OEButton 
                                className="arrow-tool-middle"
                            >
                                <img src="../images/components/arrow/arrowMiddle_1x.png"/>
                            </OEButton>
                            <OEButton
                                className=""
                                onPressed={this.onEndChangeBtnPressed}
                            >
                                <img src={this.getEndStyleImage()}/>
                            </OEButton>
                        </div>
                        <OEGroupControl>
                            <OESliderControl
                                title={<OEIcon code={OEIconCodes.arrowLength}/>}
                                className="popover-slider-setting"
                                min={this.minLengthBound}
                                max={this.maxLengthBound}
                                step={0.001}
                                value={this.state.arrowLength}
                                onSlide={this.onArrowLengthChanged}
                            />
                            <OESliderControl
                                title={<OEIcon code={OEIconCodes.arrowWidth}/>}
                                className="popover-slider-setting"
                                min={this.minWidthBound}
                                max={this.maxWidthBound}
                                step={0.001}
                                value={this.state.arrowWidth}
                                onSlide={this.onArrowWidthChanged}
                            />
                        </OEGroupControl>            
                    </div>         

                </OEPopover>
            </React.Fragment>
        );
    }

    onApplyToAllArrowsBtnPressed(){
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerArrow().applyArrowStyleToAll(this.id);
    }
    
    onDumpBtnPressed()  {
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerArrow().removeArrow(this.id);
	}
	
	onArrowRemoved() {
		if(this.id === null) return;
		this.close();
	}

    onToggle() {
		this.close();
    }

    onTipChangeBtnPressed() {
        if(this.id === null) return;
        var style = this.oe.sharedInterface.getUIControllerArrow().getArrowTipStyle(this.id);
        this.oe.sharedInterface.getUIControllerArrow().setArrowTipStyle(this.id, {value: (style.value + 1) % 4});
    }

    onEndChangeBtnPressed() {
        if(this.id === null) return;
        var style = this.oe.sharedInterface.getUIControllerArrow().getArrowEndStyle(this.id);
        this.oe.sharedInterface.getUIControllerArrow().setArrowEndStyle(this.id, {value: (style.value + 1) % 4});
    }

    onArrowColorButtonChanged(color) {
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerArrow().setArrowColor(this.id, color, false, 0.0);
    }

    onArrowLengthChanged(value) {
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerArrow().setArrowLength(this.id, value);
    }

    onArrowWidthChanged(value) {
        if(this.id === null) return;
        this.oe.sharedInterface.getUIControllerArrow().setArrowWidth(this.id, value);
    }
}

OEArrowPopoverController.defaultProps = {
    moduleId: ''
};

OEArrowPopoverController.propTypes = {
    moduleId: PropTypes.string
}; 

export default connectAppEnv((env) => { 
    return {
        appComponent: env.component
    };
})(connectModuleEnv((env) => {
    return {
        offset: {x: 0, y: -env.ui.capBar.size.h}
    };
})(frefToRef(OEArrowPopoverController)));