import React from 'react';
import PropTypes from 'prop-types';
import Plyr from 'react-plyr';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInfoViewItemView from './oe-info-view-item-view';
import {OEToolbox} from '../../lib/oe-toolbox';
import {OEColorHelper} from '../oe-color-helper';
import {OEMediaViewerController, OEMediaViewerDataSourceIntrinsic, OEMediaViewerContent, OEMediaViewerContentType} from '../media-viewer/oe-media-viewer-controller';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEInfoViewItemTutorials extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.plyrInstances = {}

        this.state = {
            strings: {
                title: 'Media'
            },
            textColor: OEColorHelper.stdLabelTextColor(0.6),
            media: []
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onBgColorChanged = this.onBgColorChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }

        if(nextProps.content !== this.props.content)    {
            this.updateLocalizedContent(nextProps.content);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    updateLocalizedContent(content)    {
        if(!this.oe.isReady()) return;

        var content_ = content || this.props.content;

        var si = this.oe.sharedInterface;
        var media = !content_ ? null : this.props.content.map((src) => si.getLocalizedStringEnc(src));
        this.setState({media: media});
    }

    updateLanguage()   {

        var si = this.oe.sharedInterface;

        this.setState({ strings: {
            title: si.getLocalizedStringEnc('info_view_media_label')
        }});

        this.updateLocalizedContent();
    }

    onBgColorChanged(message, userInfo)  {
        var brightness = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness() : userInfo.brightness;

        this.setState({
            textColor: OEColorHelper.stdLabelTextColor(brightness)
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.onBgColorChanged();
        });
    }

    render() {

/*
        var videos = !this.state.media ? null : this.state.media.map((src, index) =>
            <div className="video-container">
                <iframe 
                    key={index}
                    className="youtube-embed"
                    src={'https://www.youtube.com/embed/' + src + '?loop=1&playlist='+ src +'&modestbranding=1&rel=0&showinfo=0&autoplay=0'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    />
            </div>    
            );
*/
        
/*
        var videos = !this.state.media ? null : this.state.media.map((src, index) =>
            <Plyr
                key={index}
                className="react-plyr"
                style={{paddingTop: index > 0 ? '15px' : '0px'}}
                type="video"
                sources={[{
                    src: 'media-ut/zeiss/uv_protect.mp4',
                    type: 'video/mp4'
                }]} 
                controls={['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']}
                ref={e => this.plyrInstances[index.toString()]=e}
                onEnd={() => this.plyrInstances[index.toString()].play()}
            />);
*/

        var videos = !this.state.media ? null : this.state.media.map((src, index) =>
            <div key={index} className="video-container">
                <OEMediaViewerController
                    className="info-media-viewer"
                    dataSource={new OEMediaViewerDataSourceIntrinsic(new OEMediaViewerContent(
                        OEMediaViewerContentType.video,
                        index == 0 ? '/media-ut/zeiss/uvprotect.mp4' : '/media-ut/zeiss/blaufilter.mp4',
                        false, null, null, null, true
                    ))}
                    showControlsWhenWindow={true}
                    showNextPrevButtons={false}
                    shouldChangePrev={this.shouldChange}
                    shouldChangeNext={this.shouldChange}/>
            </div>
        );

        return (
            <OEInfoViewItemView
                moduleId={this.props.moduleId}
                elementRef={this.props.elementRef}
                isMaximizeable={true}
                isMaximized={this.props.isMaximized}
                notMinimizeable={this.props.notMinimizeable}
                hasSeparator={this.props.hasSeparator}
                className="ivi-media"
                title={this.state.strings.title}
                onToggle={this.props.onToggle}
                onAnimFinished={this.props.onAnimFinished}
            >
                <div className="media-container">
                    {videos}
                </div>
            </OEInfoViewItemView>
        );
    }

    shouldChange()  {
        return false;
    }
}

OEInfoViewItemTutorials.defaultProps = {
    moduleId: '',
    elementRef: '',
    isMaximized: false,
    notMinimizeable: false,
    hasSeparator: true
};

OEInfoViewItemTutorials.propTypes = {
    moduleId: PropTypes.string,
    isMaximized: PropTypes.bool,
    notMinimizeable: PropTypes.bool,
    hasSeparator: PropTypes.bool
};