import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {connectAppEnv} from './app-env';
import {withUILevel} from '../lib/oe-higher-order-components';
import {withIsOpenState, delegateOpenState, frefToRef} from '../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import OENotification from '../lib/oe-notification';
import OEStdModalController from './modals/oe-std-modal-controller';
import {OEViewType, OEManualViewLinks} from '../lib/oe-types';
import OEPopover from './oe-popover';
import {OEGroupControl, OEToggleControl, OEButtonControl} from './oe-controls';
import {OEDefaultConfigFactory} from './oe-default-configs';
import {retardUpdate} from '../lib/update-retarder';
import OEScreenshotTool from '../lib/oe-screenshot-tool';
import { OEToolbox } from '../lib/oe-toolbox';

export class OEScreenshotController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            uiLayer: false,
            transparency: false,
            fileFormat: 'png',
            strings:   {
                title: '',
                uiLayer: '',
                transparency: '',
                fileFormat: '',
                fileFormats: {
                    png: 'PNG',
                    jpg: 'JPEG'
                },
                takeBtn: '',
                saveBtn: '',
                previewTitle: ''
            },
            showPreview: false
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onUILayerSwitchChanged = this.onUILayerSwitchChanged.bind(this);
        this.onTransparencySwitchChanged = this.onTransparencySwitchChanged.bind(this);
        this.onTakeBtnPressed = this.onTakeBtnPressed.bind(this);
        this.onPreviewClosed = this.onPreviewClosed.bind(this);
        this.onPreviewToggle = this.onPreviewToggle.bind(this);
        this.onSaveBtnPressed = this.onSaveBtnPressed.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {
        this.setState({strings: {
            title: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_view'),
            uiLayer: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_view_ui_layer'),
            transparency: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_view_transparency'),
            fileFormat: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_view_file_format'),
            fileFormats: {
                png: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_view_file_format_png'),
                jpg: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_view_file_format_jpg')
            },
            takeBtn: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_view_take_btn'),
            saveBtn: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_view_save_btn'),
            previewTitle: this.oe.sharedInterface.getLocalizedStringEnc('screenshot_preview_view')
        }});
    }

    updateUIState()   {
        this.setState({ uiEnabled: this.oe.isReady() });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false });
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateUIState();
        });
    }

    render() {
        if(!this.props.config.enabled) return null;
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control screenshot-filter-hide" 
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    isOpen={this.props.isOpen}
                    title={this.state.strings.title}
                    onToggle={this.props.onToggle}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >
                    <div className="screenshot-controller">
                        <OEGroupControl>
                            <OEToggleControl
                                title = {this.state.strings.uiLayer}
                                active={this.state.uiLayer}
                                disabled={!this.state.uiEnabled}
                                onClick={this.onUILayerSwitchChanged}
                            />
                            {/*
                            <OEToggleControl
                                title = {this.state.strings.transparency}
                                active={this.state.transparency}
                                disabled={!this.state.uiEnabled}
                                onClick={this.onTransparencySwitchChanged}
                            />
                            */}
                            <OEButtonControl disabled={!this.state.uiEnabled} onPressed={this.onTakeBtnPressed}>{this.state.strings.takeBtn}</OEButtonControl>
                        </OEGroupControl>

                        <OEStdModalController
                            moduleId={this.props.moduleId}
                            title={this.state.strings.previewTitle}
                            className="simple-header flow-nav-modal no-navigation-controller center fit-content screenshot-controller-preview"
                            isOpen={this.state.showPreview}
                            hasCloseBtn={true}
                            onClosed={this.onPreviewClosed}
                            onToggle={this.onPreviewToggle}
                        >
                            <img src={this.state.previewImage}/>

                            <div className="btn-bar">
                                <OEButtonControl className="save-btn" disabled={!this.state.uiEnabled} onPressed={this.onSaveBtnPressed}>{this.state.strings.saveBtn}</OEButtonControl>
                            </div>

                        </OEStdModalController>

                    </div>

                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.screenshot});
    }

    onUILayerSwitchChanged() {
        this.setState((prevState) => {
            let newState = clone(prevState);
            newState.uiLayer = !newState.uiLayer;
            return newState;
        });
    }

    onTransparencySwitchChanged() {
        this.setState((prevState) => {
            let newState = clone(prevState);
            newState.transparency = !newState.transparency;
            return newState;
        });
    }

    onTakeBtnPressed()  {
        this.options = {
            uiLayer: this.state.uiLayer,
            transparency: this.state.transparency,
            bgColor: undefined, //'rgba(255, 0, 0, 1)',
            imageFormat: this.state.fileFormat,
            quality: undefined,
            filename_: OEToolbox.lowerCamelcaseToLowercaseUnderscore(this.props.appTarget, '-') + '-screenshot.' + (this.state.fileFormat === 'png' ? 'png' : 'jpg')
        };

        if(this.props.appComponent)    this.props.appComponent.showWaitingController();

        OEScreenshotTool.takeDataURL(this.props.moduleId, this.options).then(dataUrl => {
            this.setState({showPreview: true, previewImage: dataUrl});

            if(this.props.appComponent)    this.props.appComponent.hideWaitingController();
        });
    }

    onPreviewClosed()   {
        this.setState({previewImage: null});
    }

    onPreviewToggle()   {
        this.setState({showPreview: false});
    }

    onSaveBtnPressed()  {
        if(!this.options) return;
        OEScreenshotTool.safe(this.state.previewImage, this.options.filename_);
        this.setState({showPreview: false});
    }
}

OEScreenshotController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.screenshotController()
};

OEScreenshotController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool
    }).isRequired
};

export default delegateOpenState(connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        appTarget: env.config.target,
        appVersion: env.config.version,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.screenshotController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.screenshot)
    }
})(withUILevel(frefToRef(withIsOpenState(OEScreenshotController, (sender, isOpen) => {
    let appComponent = sender.props.appComponent;
    if(!appComponent || !appComponent.uiLayer) return;
    appComponent.uiLayer.notificationCenter.post(OENotification.viewOpenStateChanged, {
        type: OEViewType.screenshot,
        isOpen: isOpen,
        sender: sender
    });
})))));