import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import {OEDefaultConfigFactory} from '../../oe-default-configs';

export class OEStartViewStdHeaderTutorial extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                welcome: 'Welcome to the Effigos Explorer App. This is a short tutorial to help find your way and get started with exploring.',
                dontShowAgain: "Don't show this tutorial again on startup."
            },
            dontShowStartViewAtStartUp: localStorage.getItem('dontShowStartViewAtStartUp') === 'true'
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onDontShowAgainClicked = this.onDontShowAgainClicked.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {

        this.setState({ strings: {
            welcome: this.oe.sharedInterface.getLocalizedStringEnc('start_view_welcome'),
            dontShowAgain: this.oe.sharedInterface.getLocalizedStringEnc('start_view_dont_show_again'),
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    render() {

        var config = this.props.config || OEDefaultConfigFactory.startView();

        return (
            <div className="start-view-header-tutorial">
                <span>{this.state.strings.welcome}</span>
                <br/>
                {!config.showAtStartUp ? null : (
                    <div>
                        <input className="show-again-cb" type="checkbox" defaultChecked={this.state.dontShowStartViewAtStartUp} onClick={this.onDontShowAgainClicked}/>
                        <span>{this.state.strings.dontShowAgain}</span>
                    </div>
                )}
                <br/>
            </div>
        );
    }

    onDontShowAgainClicked()    {
        var newState = !this.state.dontShowStartViewAtStartUp;
        localStorage.setItem('dontShowStartViewAtStartUp', newState ? 'true' : 'false');
        this.setState({dontShowStartViewAtStartUp: newState});
    }
}

OEStartViewStdHeaderTutorial.defaultProps = {
    moduleId: ''
};

OEStartViewStdHeaderTutorial.propTypes = {
    moduleId: PropTypes.string
};

export class OEZeissUVStartViewHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                welcome: 'Welcome to the',
                title: 'ZEISS Eye Explorer',
                sneak: 'SNEAK PREVIEW',
                onlineLeft: 'Technology & Innovation Oct-2018',
                onlineRight: 'online until 12/31/2018'
            }
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {

        this.setState({ strings: {
            welcome: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_01'),
            title: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_02'),
            sneak: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_03'),
            onlineLeft: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_04'),
            onlineRight: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_05')
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    render() {

        return (
            <div className="start-view-header-zeiss">
                <span className="d-block">{this.state.strings.welcome}</span>
                <br/>
                <div style={{marginLeft: '16px'}}>
                    <span className="d-block">{this.state.strings.title}</span>
                    <span className="d-block">{this.state.strings.sneak}</span>
                    <br/>
                    <div className="d-block" style={{fontSize: '12px'}}>
                        {this.state.strings.onlineLeft}
                        <span className="float-right">{this.state.strings.onlineRight}</span>
                    </div>
                </div>
                <br/>
            </div>
        );
    }
}

OEZeissUVStartViewHeader.defaultProps = {
    moduleId: ''
};

OEZeissUVStartViewHeader.propTypes = {
    moduleId: PropTypes.string
};