import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {OETarget} from '../../react-oe/oe-target';
import {OEVersion} from '../../react-oe/oe-version';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OEIcon} from '../oe-controls';

export class OEMainMenuControlBarEntryFactory extends React.PureComponent {
    render() {
        var key = 0;
        var elements = [];

        if(this.props.version !== OEVersion.zeissOpti && this.props.version !== OEVersion.philipsIcarus)   {
            elements.push(
                <div key={key++} className="main-menu-control-button view-port-label-text-color-border">
                    <a onClick={this.props.onMainMenuControlBtnPressed}>
                        <OEIcon code={OEIconCodes.mainMenu} />
                    </a>
                </div>
            );
        }

        /*
        if([OETarget.zeissUV, OETarget.zeissOpti, OETarget.zeissOpti2].indexOf(this.props.target) >= 0 || this.props.version === OEVersion.zeissOpti)   {
            elements.push(<img key={key++} className="main-menu-control-button-logo" src="images/zeiss_logo.png" />);
        }
        */

        if(this.props.version === OEVersion.kind)   {
            elements.push(<img key={key++} className="main-menu-control-button-logo" src="images/logo_KIND_128.png" />);
        }

        return (
            <React.Fragment>
                {elements}
            </React.Fragment>
        );
    }
}

export const OEMainMenuControlBarEntryFactoryConnected = connectAppEnv((env) => { return {
    version: env.config.version,
    target: env.config.target
}})(OEMainMenuControlBarEntryFactory);

export default class OEMainMenuControlBar extends React.PureComponent {
    render() {
        return (
            <div
                className="main-menu-control-button-bar"
                style={this.props.style}
            >
                <OEMainMenuControlBarEntryFactoryConnected {...this.props}/>
            </div>
        );
    }
}

OEMainMenuControlBar.defaultProps = {
    moduleId: ''
};

OEMainMenuControlBar.propTypes = {
    moduleId: PropTypes.string
};