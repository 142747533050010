import React from 'react';
import PropTypes from 'prop-types';

import {withIsOpenState} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEStdModalController from '../../modals/oe-std-modal-controller';
import {OEDefaultConfigFactory} from '../../oe-default-configs';
import {OEBlurModalBackdropLayer} from '../../oe-blur-layer';

export class OEStartViewCambridgeCell extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                dontShowAgain: "Don't show again."
            },
            dontShowStartViewAtStartUp: localStorage.getItem('dontShowStartViewAtStartUp') === 'true'
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onDontShowAgainClicked = this.onDontShowAgainClicked.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        setTimeout(this.onTimeout.bind(this), 500);        
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {
    }

    onTimeout() {
        let config = this.props.config || OEDefaultConfigFactory.startView();

        if(config.showAtStartUp && this.oe.isReady() && localStorage.getItem('dontShowStartViewAtStartUp') !== 'true' && this.props.openState)   {
            this.props.openState.setOpen(true);
        }
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }
        this.updateLanguage();
    }

    render() {
        let config = this.props.config || OEDefaultConfigFactory.startView();

        return (
            <OEStdModalController 
                moduleId={this.props.moduleId}
                headerStringId="start_view"
                className="start-view cambridge"
                isOpen={this.props.isOpen}
                onToggle={this.props.onToggle}
                logo={config.logo}
                renderFooter={() => <div className="bg-footer"/>}
            >
                <div className="paragraph-1">
                    This model shows some of the basic organelles inside a cell. While it is very detailed, you should note that eukaryotic cells can be extremely complex and this model does not include everything you might find inside a cell. For example, we have not included actin filaments or microtubules (that make the shape of the cell (cytoskeleton) and help transport molecules around the cell). Moreover, the internal components of a cell are not static - there is a constant movement of organelles and membrane-bound vesicles and large macromolecules such as DNA constantly undergoing dynamic conformational changes. In addition, some organelles are shown at a low number to keep the model easy to use (i.e. a cell can contain thousands of mitochondria but there are only a handful shown here).
                </div>

                <div className="paragraph-2">
                    We have chosen to simplify in order to aid your understanding. If you want to learn more about what goes on inside a cell and find out where we are still trying to discover new information about the inner workings of cells, please check out our research: 
                </div>

                <div className="paragraph-3">
                    <a className="std-label-text-color" href="https://www.gurdon.cam.ac.uk" target="_blank">www.gurdon.cam.ac.uk</a>
                </div>

                <div className="paragraph-4">
                    {!config.showAtStartUp ? null : (
                    <div>
                        <input className="show-again-cb" type="checkbox" defaultChecked={this.state.dontShowStartViewAtStartUp} onClick={this.onDontShowAgainClicked}/>
                        <span>{this.state.strings.dontShowAgain}</span>
                    </div>
                    )}
                </div>

                <OEBlurModalBackdropLayer/>

            </OEStdModalController>
        );
    }

    onDontShowAgainClicked()    {
        let newState = !this.state.dontShowStartViewAtStartUp;
        localStorage.setItem('dontShowStartViewAtStartUp', newState ? 'true' : 'false');
        this.setState({dontShowStartViewAtStartUp: newState});
    }
}

OEStartViewCambridgeCell.defaultProps = {
    moduleId: ''
};

OEStartViewCambridgeCell.propTypes = {
    moduleId: PropTypes.string
};

export default withIsOpenState(OEStartViewCambridgeCell);