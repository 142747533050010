import React from 'react';
import PropTypes from 'prop-types';

import Hammer from 'react-hammerjs';

import {OEColorHelper} from '../oe-color-helper';
import {OEToolbox} from '../../lib/oe-toolbox';
import {OEMediaViewerPlaybackState, OEMediaViewerReadyState, OEMediaViewerContentSizeMode} from './oe-media-viewer-controller';
import NotificationCenter from '../../lib/notification-center';
import OEResizeObserver from '../../lib/oe-resize-observer';
import {OEAnimationLoop} from '../../lib/oe-animation-loop';

export class OEMediaViewerContentController extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {

        var style;

        if(this.props.contentRect)  {

            if(this.props.contentRect.mode === OEMediaViewerContentSizeMode.fill)   {
                style = {backgroundColor: OEColorHelper.vColorToStr(this.props.backgroundColor), width: '100%', height: '100%'};
            } else {
                style = {
                    backgroundColor: OEColorHelper.vColorToStr(this.props.backgroundColor),
                    top: this.props.contentRect.top, 
                    right: this.props.contentRect.right, 
                    bottom: this.props.contentRect.bottom, 
                    left: this.props.contentRect.left, 
                    width:'auto', 
                    height:'auto'
                };
            }

        } else {
            style = { backgroundColor: 'rgba(0,0,0,0)', width: '100%', height: '100%' };
        }

        return (
            <div 
                className={'media-viewer-content ' + this.props.className}
            >
                <div 
                    className={'content-container'}
                    style={style}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

OEMediaViewerContentController.defaultProps = {
    className: ''
};

OEMediaViewerContentController.propTypes = {
    className: PropTypes.string
};

export class OEMediaViewerImageContentController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.size = null;
        this.contentSize = null;
        this.contentAspectSize = null;

        this.maxZoom = 4;
        this.zoom = {x: 0, y: 0, s: 1};

        this.state = {
            zoom: this.zoom,
            size: null,
            contentSize: null,
            contentAspectSize: null
        };

        this.src = this.props.src;
        this.onGotSize = this.onGotSize.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onPan = this.onPan.bind(this);
        this.onPinch = this.onPinch.bind(this); 
        this.onDoubleTap = this.onDoubleTap.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.framemove = this.framemove.bind(this);

        this.updateContentSize();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.src !== this.props.src)     {
            this.src = nextProps.src;
            this.updateContentSize();
        }
    }

    getContentSize()    {
        return this.contentSize;
    }

    getMeta(src, callback){
        $('<img/>').attr('src', src).on('load', function()    {
            callback({w: this.width, h: this.height}, src);
        });
    }

    updateContentSize() {
        if(!this.src || this.src === '')    return;
        this.getMeta(this.src, this.onGotSize);
    }

    updateContentAspectSize()   {
        if(!this.size || !this.contentSize || !this.size.h || !this.contentSize.h) {
            this.contentAspectSize = null;
            this.setState({contentAspectSize: this.contentAspectSize});
            return;
        }

        this.contentAspectSize = {w: this.size.w, h: this.size.h};
        var aspect = this.size.w / this.size.h;
        var contentAspect = this.contentSize.w / this.contentSize.h;

        if(contentAspect > aspect)  {
            this.contentAspectSize.h = this.contentAspectSize.w / contentAspect;
        } else {
            this.contentAspectSize.w = this.contentAspectSize.h * contentAspect;
        }

        this.setState({contentAspectSize: this.contentAspectSize});
    }

    constrainedZoom(z, f)   {
        var delta = Math.min(Math.max(typeof(f) === 'number' ? f : 1, 0), 1);

        var zoom = {x: z.x, y: z.y, s: z.s};
        var size = this.size;
        var contentAspectSize = this.contentAspectSize;

        if(!size || !contentAspectSize)    return zoom;

        var zSize = {w: zoom.s * contentAspectSize.w, h: zoom.s * contentAspectSize.h};

        var pos = {x: zoom.x, y: zoom.y};

        if(zoom.s <= 1) {

            pos.x = 0.5 * (size.w - zSize.w);
            pos.y = 0.5 * (size.h - zSize.h);

        } else {

            if(zSize.w > size.w)    {
                if(size.w > zSize.w + pos.x)    {
                    pos.x = size.w - zSize.w;
                } else if(pos.x > 0)    {
                    pos.x = 0;
                }
            } else {
                if(size.w < pos.x + zSize.w)    {
                    pos.x = size.w - zSize.w;
                } else if(pos.x < 0)    {
                    pos.x = 0;
                }
            }

            if(zSize.h > size.h)    {
                if(size.h > zSize.h + pos.y)    {
                    pos.y = size.h - zSize.h;
                } else if(pos.y > 0)    {
                    pos.y = 0;
                }
            } else {
                if(size.h < pos.y + zSize.h)    {
                    pos.y = size.h - zSize.h;
                } else if(pos.y < 0)    {
                    pos.y = 0;
                }
            }
        }

        var dis = {x: (pos.x - zoom.x), y: (pos.y - zoom.y)};

        zoom.x += delta * dis.x;
        zoom.y += delta * dis.y;

        return zoom;
    }

    applyConstraints(f)  {
        var zoom = this.constrainedZoom(this.zoom, f);
    
        if(!OEToolbox.jsonEqual(zoom, this.zoom))   {
            this.zoom = zoom;
            this.setState({zoom: this.zoom});
        }
    }

    getRelativeCenter() {
        if(!this.size || !this.contentAspectSize || !this.size.w || !this.size.h) return;
        var zSize = {w: this.zoom.s * this.contentAspectSize.w, h: this.zoom.s * this.contentAspectSize.h};
        return {x: (this.zoom.x + 0.5 * zSize.w) / this.size.w, y: (this.zoom.y + 0.5 * zSize.h) / this.size.h };
    }

    setRelativeCenter(center) {
        if(!center || !this.size || !this.contentAspectSize) return;

        var zSize = {w: this.zoom.s * this.contentAspectSize.w, h: this.zoom.s * this.contentAspectSize.h};

        var zoom = {x: center.x * this.size.w - 0.5 * zSize.w, y: center.y * this.size.h - 0.5 * zSize.h, s: this.zoom.s};

        if(!OEToolbox.jsonEqual(zoom, this.zoom))   {
            this.zoom = zoom;
            this.setState({zoom: this.zoom});
        }
    }

    onGotSize(size, src) {
        if(src != this.src || OEToolbox.jsonEqual(this.contentSize, size)) return;
        
        var center = this.getRelativeCenter();
        
        this.contentSize = size;
        this.setState({contentSize: this.contentSize});
        this.updateMaxZoom();
        this.updateContentAspectSize();
        this.setRelativeCenter(center);
        this.applyConstraints();
        this.props.onContentSizeChanged(size);
    }

    onResize(sender, size) {
        this.sizeObserver = sender;

        var center = this.getRelativeCenter();

        this.size = size;
        this.setState({size: size});
        this.updateMaxZoom();
        this.updateContentAspectSize();
        this.setRelativeCenter(center);
        this.applyConstraints();
    }

    updateMaxZoom() {
        if(!this.size || !this.contentSize || !this.size.w || !this.size.h) {
            this.maxZoom = 4;
            return;
        }

        this.maxZoom = 4 * Math.max(Math.max(this.contentSize.w / this.size.w, this.contentSize.h / this.size.h), 1);
    }

    canSwipe()  {
        return this.zoom.s <= 1;
    }

    setZoom(zoom, animated, duration) {

        if(!animated && !OEToolbox.jsonEqual(zoom, this.zoom))   {
            this.zoom = {x: zoom.x, y: zoom.y, s: zoom.s};
            this.setState({zoom: this.zoom});
            this.animateZoom = null;
        } else {
            this.animateZoom = {
                start: {x: this.zoom.x, y: this.zoom.y, s: this.zoom.s},
                final: {x: zoom.x, y: zoom.y, s: zoom.s},
                duration: duration || 0.333,
                time: 0
            };
        }
    }

    isAnimatingZoom()   {
        return this.animateZoom != null;
    }

    applyScale(scale, pos, animated, duration)  {
        if(scale === this.zoom.s)   return;
        var pos_ = pos || (this.size ? {x: 0.5 * this.size.w, y: 0.5 * this.size.h} : {x: 0, y: 0});
        var s = scale / this.zoom.s;
        var zoom = {x: s * this.zoom.x + pos_.x * (1 - s), y: s * this.zoom.y + pos_.y * (1 - s), s: scale};
        this.setZoom(zoom, animated, duration);
    }

    onWheel(event)  {
        if(!this.sizeObserver || this.isAnimatingZoom()) return;
        var refPos = this.sizeObserver.getClientPos();
        if(!refPos) return;

        //console.log('onWheel - deltaY: ' + event.deltaY.toString() + ' deltaMode: ' + event.deltaMode.toString());
        var wheelParams = OEToolbox.normalizedWheelParams(event);

        var pos = {x: event.clientX - refPos.x, y: event.clientY - refPos.y};
        var s = 1 + -0.001*wheelParams.deltaY;
        if(s === 1) return;

        var newS = this.zoom.s * s;

        if(newS < 1 && this.zoom.s >= 1)    {
            newS = 1;
        }

        this.applyScale(newS, pos);
    }

    onPan(event)   {
        if(event.type === 'panstart')   {
            this.isPan = true;
            this.pan = {deltaX: 0, deltaY: 0};
            return;
        }

        if(event.type === 'panend' || event.type === 'pancancel')   {
            this.isPan = false;
            return;
        }

        if(!this.isPan) return;

        var delta = {x: event.deltaX - this.pan.deltaX, y: event.deltaY - this.pan.deltaY};
        this.pan = {deltaX: event.deltaX, deltaY: event.deltaY};

        if(this.isAnimatingZoom()) return;

        this.vel = {x: 1000 * event.velocityX, y: 1000 * event.velocityY};

        if(this.zoom.s <= 1 || (delta.x === 0 && delta.y === 0)) return;

        //console.log('pan - deltaX: ' + delta.x.toString() + ' deltaY: ' + delta.y.toString());
        var zoom = {x: this.zoom.x + delta.x, y: this.zoom.y + delta.y, s: this.zoom.s};
        this.zoom = zoom;
        this.setState({zoom: this.zoom});
    }

    onPinch(event)    {
        if(event.type === 'pinchstart')   {
            this.isPinch = true;
            this.pinch = {scale: 1};
            return;
        }

        if(event.type === 'pinchend' || event.type === 'pinchcancel')   {
            this.isPinch = false;
            return;
        }

        if(!this.isPinch) return;

        var delta = event.scale / this.pinch.scale;
        this.pinch = {scale: event.scale};

        if(this.isAnimatingZoom() || !this.sizeObserver || !this.sizeObserver.getClientPos()) return;

        var refPos = this.sizeObserver.getClientPos();
        var pos = {x: event.center.x - refPos.x, y: event.center.y - refPos.y};

        this.applyScale(this.zoom.s * delta, pos);
    }

    onDoubleTap(event)  {
        if(this.zoom.s > 1)    {
            var pos = this.size && this.contentAspectSize ? {x: 0.5 * (this.size.w - this.contentAspectSize.w), y: 0.5 * (this.size.h - this.contentAspectSize.h)} : {x: 0, y: 0};
            this.setZoom({x: pos.x, y: pos.y, s: 1}, true, 0.333);
        } else if(this.sizeObserver && this.sizeObserver.getClientPos()) {
            var refPos = this.sizeObserver.getClientPos();
            var pos = {x: event.center.x - refPos.x, y: event.center.y - refPos.y};
            this.applyScale(this.maxZoom / 4, pos, true, 0.333);
        }
    }

    onMouseLeave()  {
        this.isPan = false;
    }

    framemove(time, deltaTime)  {

        // damp scroll velocity
        if(this.vel)    {
            var damp = Math.max(1 - 5.0*deltaTime, 0);
            this.vel.x *= damp;
            this.vel.y *= damp;
        }
        
        // apply zoom animation
        if(this.isAnimatingZoom())  {
            this.animateZoom.time += deltaTime;
            var zoom = {x: this.animateZoom.final.x, y: this.animateZoom.final.y, s: this.animateZoom.final.s};

            if(this.animateZoom.time < this.animateZoom.duration)   {
                var frac = OEToolbox.easeTiming(this.animateZoom.time / this.animateZoom.duration);
                zoom = {
                    x: (1-frac) * this.animateZoom.start.x + frac * this.animateZoom.final.x,
                    y: (1-frac) * this.animateZoom.start.y + frac * this.animateZoom.final.y,
                    s: (1-frac) * this.animateZoom.start.s + frac * this.animateZoom.final.s
                } ;
            } else {
                this.animateZoom = null;
            }

            if(!OEToolbox.jsonEqual(zoom, this.zoom))   {
                this.zoom = zoom;
                this.setState({zoom: this.zoom});
            }

            this.vel = null;

            return;
        }

        // min max zoom constraints
        var s = this.zoom.s;

        if(s < 1)   {
            s = Math.min(s + 4.0 * Math.max((1 - s), 1) * deltaTime, 1);
        }

        if(s > this.maxZoom)   {
            s = Math.max(s + 4.0 * Math.min((this.maxZoom - s), -1) * deltaTime, this.maxZoom);
        }

        if(s !== this.zoom.s)    {
            this.applyScale(s);
        }

        // scroll with repsect to velocity and delta time
        var zoom = {x: this.zoom.x, y: this.zoom.y, s: this.zoom.s};
        
        if(zoom.s > 1 && !this.isPan && !this.pinch && this.vel) {
            zoom.x += deltaTime * this.vel.x;
            zoom.y += deltaTime * this.vel.y;
        }

        // apply scroll constraints
        zoom = this.constrainedZoom(zoom, Math.min(10.0 * deltaTime, 1));

        //
        if(!OEToolbox.jsonEqual(zoom, this.zoom))   {
            this.zoom = zoom;
            this.setState({zoom: this.zoom});
        }
    }

    render() {

        var contentRectStyle;

        if(this.props.contentRect)  {

            if(this.props.contentRect.mode === OEMediaViewerContentSizeMode.fill || this.props.fullscreen)   {
                contentRectStyle = {backgroundColor: OEColorHelper.vColorToStr(this.props.backgroundColor), width: '100%', height: '100%'};
            } else {
                contentRectStyle = {
                    backgroundColor: OEColorHelper.vColorToStr(this.props.backgroundColor),
                    top: this.props.contentRect.top,
                    left: this.props.contentRect.left,
                    width: this.props.contentRect.w,
                    height: this.props.contentRect.h
                };
            }

        } else {
            contentRectStyle = { backgroundColor: 'rgba(0,0,0,0)' };
        }

        var zoomStyle;

        if(!this.state.contentAspectSize)   {
            zoomStyle = {width: '100%', height: '100%'};
        } else {

            zoomStyle = {
                top: this.state.zoom.y,
                left: this.state.zoom.x,
                width: this.state.zoom.s * this.state.contentAspectSize.w,
                height: this.state.zoom.s * this.state.contentAspectSize.h
            };
        }

        return (
            <OEMediaViewerContentController
                className={this.props.className}
            >

                <Hammer
                    onPanStart={this.onPan} onPan={this.onPan} onPanEnd={this.onPan} onPanCancel={this.onPan}
                    onPinchStart={this.onPinch} onPinch={this.onPinch} onPinchEnd={this.onPinch} onPinchCancel={this.onPinch}
                    onDoubleTap={this.onDoubleTap}
                    direction="DIRECTION_ALL"
                    options={{
                        recognizers: {
                           pinch: { enable: true }
                        }
                    }}
                >

                    <div
                        className="zoom-container"
                        style={contentRectStyle}
                        onWheel={this.onWheel}
                        onMouseLeave={this.onMouseLeave}
                    >

                        <OEAnimationLoop onFramemove={this.framemove} />

                        <OEResizeObserver onResize={this.onResize} />

                        <img
                            src={this.props.src}
                            style={zoomStyle}
                        />

                    </div>

                </Hammer>

            </OEMediaViewerContentController>
        );
    }
}

OEMediaViewerImageContentController.defaultProps = {
    className: '',
    onContentSizeChanged: (size) => {}
};

OEMediaViewerImageContentController.propTypes = {
    className: PropTypes.string,
    onContentSizeChanged: PropTypes.func
};

export class OEMediaViewerVideoContentController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            autoPlay: true,  // we set autoPlay attribute for video element to true since some browsers don't start caching otherwise, i.e., iOS safari
            readyState: OEMediaViewerReadyState.noMeta
        };

        this.canPlayState = 4;  // ready state at wich playback is possible

        this.ref = null;
        this.playbackState = OEMediaViewerPlaybackState.pause;
        this.currentTime = 0;
        this.duration = 0;
        this.readyState = OEMediaViewerReadyState.noMeta;
        this.contentSize = null;

        this.setRef = this.setRef.bind(this);

        this.onLoadedMetaData = this.onLoadedMetaData.bind(this);
        this.onWaiting = this.onWaiting.bind(this);
        this.onCanPlay = this.onCanPlay.bind(this);
        this.onError = this.onError.bind(this);
        this.onDurationChanged = this.onDurationChanged.bind(this);
        this.onTimeUpdate = this.onTimeUpdate.bind(this);
        this.onPlaybackStateChanged = this.onPlaybackStateChanged.bind(this);
    }

    getReadyState() {
        return this.readyState;
    }

    getContentSize()    {
        return this.contentSize;
    }

    play()  {
        this.setPlaybackState(OEMediaViewerPlaybackState.play);
    }

    pause()  {
        this.setPlaybackState(OEMediaViewerPlaybackState.pause);
    }

    stop()  {
        this.pause();
        this.seek(0);
    }

    getPlaybackState()  {
        return this.playbackState;
    }

    setPlaybackState(playbackState)  {
        if(this.ref && this.ref.readyState > 0 && !this.disablePlaybackStateListener) {
            if(playbackState === OEMediaViewerPlaybackState.pause)  {
                this.ref.pause();
            } else {
                this.ref.play().catch((error) => {
                    // just catch the promise error and do nothing,
                    // it is usually thrown when the browser does not allow autoplaying and the app tries to start playback outside of a user action handler
                    // we handle this case by doing nothing which results in the behavior that automatic playback just does not start & the user has to start playback with an user action
                });
            }
        } else {
            if(playbackState !== this.playbackState)  {
                this.playbackState = playbackState;
                this.props.onPlaybackStateChanged(this.playbackState);
            }
        }
    }

    getCurrentTime()    {
        return this.currentTime;
    }

    getDuration()    {
        return this.duration;
    }

    getProgress()   {
        return !this.ref && typeof(this.appliedProgress) === 'number' ? this.appliedProgress : (this.duration > 0 ? this.currentTime / this.duration : 0);
    }

    setProgress(progress)   {
        if(this.ref && this.ref.readyState > 0) {
            var time = progress * this.duration;
            this.seek(time);
        } else {
            this.appliedProgress = progress;
        }
    }

    seek(seconds)    {
        if(this.ref && this.ref.readyState > 0) {
            if(this.ref.currentTime !== seconds)    {
                this.ref.currentTime = seconds;
            }
        } else {
            if(seconds !== this.currentTime)  {
                this.currentTime = seconds;
                this.props.onCurrentTimeChanged(this.currentTime);
            }
        }
        this.appliedProgress = null;
    }

    updateStateFromRef()    {

        var oldReadyState = this.readyState;
        var oldPlaybackState = this.playbackState;
        var oldCurrentTime = this.currentTime;
        var oldDuration = this.duration;
        var oldContentSize = this.contentSize;

        if(!this.ref)   {
            this.readyState = OEMediaViewerReadyState.noMeta;
            this.playbackState = OEMediaViewerPlaybackState.pause;
            this.currentTime = 0;
            this.duration = 0;
        } else {

            if(this.ref.readyState > 0)   {
                if(typeof(this.appliedProgress) === 'number')    {
                    this.setProgress(this.appliedProgress);
                    this.appliedProgress = null;
                } else {
                    this.seek(this.currentTime);
                }
                this.setPlaybackState(this.playbackState);

                this.playbackState = this.ref.paused ? OEMediaViewerPlaybackState.pause : OEMediaViewerPlaybackState.play;
                this.currentTime = this.ref.currentTime;
                this.duration = this.ref.duration;
            }

            if(this.ref.readyState >= this.canPlayState && !this.ref.seeking)   {
                this.readyState = OEMediaViewerReadyState.ready;
            } else if(this.ref.readyState > 0) {
                this.readyState = OEMediaViewerReadyState.waiting;
            } else {
                this.readyState = OEMediaViewerReadyState.noMeta;
            }

            if(this.readyState > OEMediaViewerReadyState.noMeta)    {
                this.contentSize = {w: this.ref.videoWidth, h: this.ref.videoHeight};
            }
        }

        if(oldReadyState !== this.readyState)    {
            this.setState({readyState: this.readyState});
            this.props.onReadyStateChanged(this.readyState);
        }

        if(oldPlaybackState !== this.playbackState)    {
            this.props.onPlaybackStateChanged(this.playbackState);
        }

        if(oldCurrentTime !== this.currentTime)    {
            this.props.onCurrentTimeChanged(this.currentTime);
        }

        if(oldDuration !== this.duration)    {
            this.props.onDurationChanged(this.duration);
        }

        if(!OEToolbox.jsonEqual(this.contentSize, oldContentSize))    {
            this.props.onContentSizeChanged(this.contentSize);
        }

    }

    setRef(ref) {
        if(ref === this.ref)    return;
        this.ref = ref;

        this.updateStateFromRef();

        if(!this.ref)   return;

        var _this = this;

        this.ref.addEventListener('loadedmetadata', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video loadedmetadata', e);
            _this.onLoadedMetaData();
        }, false);

        this.ref.addEventListener('waiting', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video waiting', e);
            _this.onWaiting();
        }, false);

        this.ref.addEventListener('canplay', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video canplay', e);

            if(!_this.ref.seeking)  {
                _this.onCanPlay();
            }

        }, false);

        this.ref.addEventListener('seeking', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video seeking', e);

            if(bowser.safari)   {
                // second part of the workaround for safari, see seekend
                _this.disablePlaybackStateListener = true;
                _this.ref.pause();
            }

            _this.onWaiting();
        }, false);

        this.ref.addEventListener('seeked', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video seeked - ready state - ' + _this.ref.readyState.toString(), e);

            if(bowser.safari)   {
                // workaround for safari showing strange behaviour when video element is playing while seeking
                _this.disablePlaybackStateListener = false;
                if(_this.playbackState === OEMediaViewerPlaybackState.play)  {
                    _this.ref.play();
                }
            }

            if(bowser.msie || _this.ref.readyState >= _this.canPlayState)   {
                // for ie a ready state check is not necessary & missleading because ready state at seekend for ie could be < 4
                _this.onCanPlay();
            }

        }, false);

        this.ref.addEventListener('durationchange', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video durationchange', e);
            _this.onDurationChanged();
        }, false);

        this.ref.addEventListener('timeupdate', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video timeupdate', e);
            _this.onTimeUpdate();
        }, false);

        this.ref.addEventListener('play', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video play', e);
            if(_this.disablePlaybackStateListener) return;
            _this.onPlaybackStateChanged();
        }, false);

        this.ref.addEventListener('pause', function(e) {
            if(_this.ref !== e.target)  return;
            //console.log('Video pause', e);
            if(_this.disablePlaybackStateListener) return;
            _this.onPlaybackStateChanged();
        }, false);

        this.ref.addEventListener('error', function(e) {
            if(_this.ref !== e.target)  return;
            console.log('Video error', e);
            _this.onError();
        }, false);

        /*
        this.ref.addEventListener('stalled', function(e) {
            if(_this.ref !== e.target)  return;
            console.log('Video stalled', e);
        }, false);

        this.ref.addEventListener('suspend', function(e) {
            if(_this.ref !== e.target)  return;
            console.log('Video suspended', e);
        }, false);
        */
    }

    componentDidUpdate(prevProps) {
        if(prevProps.src !== this.props.src)     {
            this.updateStateFromRef();
        }
    }

    render() {

        var style;

        if(this.props.contentRect)  {

            if(this.props.contentRect.mode === OEMediaViewerContentSizeMode.fill)   {
                style = {backgroundColor: OEColorHelper.vColorToStr(this.props.backgroundColor), width: '100%', height: '100%'};
            } else {
                style = {
                    backgroundColor: this.props.size || this.state.readyState === OEMediaViewerReadyState.noMeta ? OEColorHelper.vColorToStr(this.props.backgroundColor) : 'rgba(0,0,0,0)',
                    top: this.props.contentRect.top,
                    left: this.props.contentRect.left,
                    width: this.props.contentRect.w,
                    height: this.props.contentRect.h
                };
            }
        } else {
            style = { backgroundColor: 'rgba(0,0,0,1)' };
        }

        return (
            <OEMediaViewerContentController
                className={this.props.className}
            >
                <video
                    src={this.props.src}
                    ref={this.setRef}
                    autoPlay={this.state.autoPlay || this.props.autoPlay}
                    preload="auto"
                    loop={this.props.loop}
                    style={style}
                />
            </OEMediaViewerContentController>
        );
    }

    onLoadedMetaData()  {

        if(this.readyState === OEMediaViewerReadyState.noMeta) {
            this.readyState = OEMediaViewerReadyState.waiting;
            this.setState({readyState: this.readyState});
            this.props.onReadyStateChanged(this.readyState);
        }

        var oldPlaybackState = this.playbackState;
        var oldCurrentTime = this.currentTime;
        var oldContentSize = this.contentSize;

        if(this.ref)    {
            if(this.ref.readyState > 0)   {
                if(typeof(this.appliedProgress) === 'number')    {
                    this.setProgress(this.appliedProgress);
                    this.appliedProgress = null;
                } else {
                    this.seek(this.currentTime);
                }
                this.setPlaybackState(this.playbackState);

                this.playbackState = this.ref.paused ? OEMediaViewerPlaybackState.pause : OEMediaViewerPlaybackState.play;
                this.currentTime = this.ref.currentTime;
            }

            if(this.readyState > OEMediaViewerReadyState.noMeta)    {
                this.contentSize = {w: this.ref.videoWidth, h: this.ref.videoHeight};
            }
        }

        if(oldPlaybackState !== this.playbackState)    {
            this.props.onPlaybackStateChanged(this.playbackState);
        }

        if(oldCurrentTime !== this.currentTime)    {
            this.props.onCurrentTimeChanged(this.currentTime);
        }

        if(!OEToolbox.jsonEqual(this.contentSize, oldContentSize))    {
            this.props.onContentSizeChanged(this.contentSize);
        }
    }

    onWaiting() {
        if(this.readyState !== OEMediaViewerReadyState.waiting) {
            this.readyState = OEMediaViewerReadyState.waiting;
            this.setState({readyState: this.readyState});
            this.props.onReadyStateChanged(this.readyState);
        }
    }

    onCanPlay() {
        if(this.readyState !== OEMediaViewerReadyState.ready) {
            this.readyState = OEMediaViewerReadyState.ready;
            this.setState({readyState: this.readyState});
            this.props.onReadyStateChanged(this.readyState);
        }
    }

    onError()   {
        if(this.readyState !== OEMediaViewerReadyState.error) {
            this.readyState = OEMediaViewerReadyState.error;
            this.setState({readyState: this.readyState});
            this.props.onReadyStateChanged(this.readyState);
        }
    }

    onDurationChanged()  {
        if(!this.ref) return;
        var duration = this.ref.duration;

        if(duration !== this.duration)  {
            this.duration = duration;
            this.props.onDurationChanged(this.duration);
        }
    }

    onTimeUpdate()  {
        if(!this.ref) return;
        var currentTime = this.ref.currentTime;

        if(currentTime !== this.currentTime)  {
            this.currentTime = currentTime;
            this.props.onCurrentTimeChanged(this.currentTime);
        }
    }

    onPlaybackStateChanged()  {
        if(!this.ref) return;
        var playbackState = this.ref.paused ? OEMediaViewerPlaybackState.pause : OEMediaViewerPlaybackState.play;

        if(playbackState !== this.playbackState)  {
            this.playbackState = playbackState;
            this.props.onPlaybackStateChanged(this.playbackState);
        }
    }
}

OEMediaViewerVideoContentController.defaultProps = {
    className: '',
    autoPlay: false,
    loop: false,
    onReadyStateChanged: (state) => {},
    onDurationChanged: (duration) => {},
    onCurrentTimeChanged: (currentTime) => {},
    onPlaybackStateChanged: (state) => {},
    onContentSizeChanged: (size) => {}
};

OEMediaViewerVideoContentController.propTypes = {
    className: PropTypes.string,
    autoPlay: PropTypes.bool,
    loop: PropTypes.bool,
    onReadyStateChanged: PropTypes.func,
    onCurrentTimeChanged: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onPlaybackStateChanged: PropTypes.func,
    onContentSizeChanged: PropTypes.func
};

export class OEMediaViewerHtmlContentController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.ref = null;
        this.progress = 0;
        
        this.setRef = this.setRef.bind(this);
        this.onLoad = this.onLoad.bind(this);

        this.onIFrameProgress = this.onIFrameProgress.bind(this);
        this.onIFrameGoToItem = this.onIFrameGoToItem.bind(this);
        this.onIFrameShowMedia = this.onIFrameShowMedia.bind(this);
    }

    getContentSize()    {
        return {mode: OEMediaViewerContentSizeMode.fill};
    }

    getProgress()   {
        return this.progress;
    }

    setProgress(progress)   {
        if(this.progress != progress) {
            this.progress = progress;
            this.props.onProgressChanged(progress);
        }
    }

    applyProgress() {

    }

    setRef(ref) {
        if(ref === this.ref)    return;

        if(this.ref)    {
            this.ref.contentWindow.oeNotificationCenter = null;
        }

        this.ref = ref;
        OEToolbox.bubbleIframeMouseEvents(this.ref);

        if(this.ref)    {
            var center = new NotificationCenter();
            this.ref.contentWindow.oeNotificationCenter = center;
            center.register('progress', this.onIFrameProgress);
            center.register('goToItem', this.onIFrameGoToItem);
            center.register('showMedia', this.onIFrameShowMedia);
        }
    }

    onIFrameProgress(message, userInfo)  {
        var progress = userInfo[message];
        //console.log('onIFrameProgress - ' + progress.toString());
    }

    onIFrameGoToItem(message, userInfo)  {
        var name = userInfo[message];
        //console.log('onIFrameGoToItem - ' + name);
        this.props.onGoToItem(name);
    }

    onIFrameShowMedia(message, userInfo)  {
        var path = userInfo[message];
        //console.log('onIFrameShowMedia - ' + path);
        this.props.onShowMedia(path);
    }

    onLoad()    {}

    render() {
        return (
            <OEMediaViewerContentController
                className={this.props.className}
                backgroundColor={this.props.backgroundColor}
                contentRect={this.props.contentRect}
            >
                <iframe
                    src={this.props.src}
                    ref={this.setRef}
                    onLoad={this.onLoad}
                />
            </OEMediaViewerContentController>
        );
    }
}

OEMediaViewerHtmlContentController.defaultProps = {
    className: '',
    onProgressChanged: (state) => {},
    onGoToItem: (name) => {},
    onShowMedia: (path) => {}
};

OEMediaViewerHtmlContentController.propTypes = {
    className: PropTypes.string,
    onProgressChanged: PropTypes.func,
    onGoToItem: PropTypes.func,
    onShowMedia: PropTypes.func
};