
class SLInterface {

    constructor() {
        this.preReady = false;
        this.ready = false;
        this.sharedNotificationCenter = null;
        this.NotificationName = {};
        this.sharedInterface = null;
        this.Module = null;

        //
        this.connectCallbacks = [];

        this.onInitialized = this.onInitialized.bind(this);

        //
        if(typeof(Module) === 'undefined')    {
            return;
        }

        this.sharedNotificationCenter = sharedNotificationCenter;
        this.NotificationName = NotificationName;
        this.Module = Module;
        
        this.preReady = true;

        if(this.Module.statusManager.isInitialized()) {
            this.onInitialized();
            return;
        }

        this.sharedNotificationCenter.register(this.NotificationName.initialized, this.onInitialized);
    }

    register(callback) {
        if(this.connectCallbacks.includes(callback)) return;
        this.connectCallbacks.push(callback);
    }

    unregister(callback) {
        this.connectCallbacks = this.connectCallbacks.filter((item) => { return item !== callback; });
    }

    onInitialized() {
        this.ready = true;
        this.sharedInterface = Module.sharedInterface;

        this.connectCallbacks.forEach((callback) => callback(this));

        this.sharedNotificationCenter.unregister(this.NotificationName.initialized, this.onInitialized);
    }

    isPreReady()   {
        return this.preReady;
    }

    isReady()   {
        return this.ready;
    }
};

export let slInterface = new SLInterface();