export const OEProductId = {
    humanFoot:              '1000100000',
    humanEye:               '1000200000',
    humanSkull:             '1000300000',
    humanCranialNerves:     '1000310000',
    humanSkin:              '1000500000',
    humanKnee:              '1000600000',
    humanKneeImplantcast:   '1000610000',
    humanEar:               '1000700000',
    humanLeg:               '1000800000',
    humanAnkleFoot:         '1000810000',
    humanHip2:              '1000820000',
    humanLegPathways:       '1000830000',
    humanLowerLeg:          '1000840000',
    humanThighKnee:         '1000850000',
    humanArm:               '1000900000',
	humanArmPathways:       '1000910000',
	humanForearm:           '1000920000',
	humanUpperArmElbow:     '1000930000',
    humanHip:               '1001000000',
	humanShoulder:          '1001100000',
	humanElbow:             '1001200000',
    humanHand:              '1001300000',
    humanTrunk:             '1001400000',
    humanAbdominalWall:     '1001410000',
    humanChestWall:         '1001420000',
    humanNeckBack:          '1001430000',
    humanPelvis:            '1001440000',
    humanShoulderGirdle:    '1001450000',
    humanSpine:             '1001460000',
    humanSpineFracture:     '1001470000',
    humanHead:              '1001500000',
    humanHeadWIP:           '1001530000',
    humanLarynx:            '1001510000',
    humanEye2:              '1001520000',
    humanEyeRetina:         '1001540000',
    humanLiverMicro:        '1002010000',
    humanHeart:             '1002100000',
    equineHoof:             '1010100000',
    equineHead:             '1010800000',
    mouseBrain:             '1040900000',
    combustionEngine:       '1212000000',
    snail:                  '1055000000',
    toxNetz:                '1989800000',
    zeissUV:                '2000000001',
    zeissOpti:              '2000000002',
    sensorySystems:         '3000000001',
    optics:                 '4000000001',
    animalCell:             '1500000000',
    plantCell:              '1600000000',
    humanCellEpithelium:    '1700000000',
    philipsIcarus:          '4000000002',
// @ADD_TARGET_BEGIN@ productId (see object_explorer/ObjExplorer/Classes/Misc/OEProductId.h)
//  $TARGET_cC$: 'PRODUCT_ID_STRING',
// @ADD_TARGET_END@
};
